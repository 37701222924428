<!-- First Deadline for cyclic orders-->
<div class="form-group"
     ng-class="{
       'has-error': formCyclic.firstDeadlineDate.$invalid
     }">
  <label class="control-label">{{'CYCLIC_ORDER_FIRST_DEADLINE_DATE' | translate}}</label>
  <div class="input-group">
    <input class="form-control"
           id="firstDeadlineDate"
           name="firstDeadlineDate"
           uib-datepicker-popup="dd.MM.yyyy"
           datepicker-options="{
             minDate: edit.today
           }"
           ng-model="edit.order.firstDeadlineDate"
           readonly="readonly"
           is-open="edit.order.firstDeadlineDateOpen"
           type="text"
           show-button-bar="false"
           ng-change="edit.validateForm(edit.order, formCyclic)"
           required />
    <div class="input-group-btn">
      <button class="btn btn-default"
              ng-click="edit.order.firstDeadlineDateOpen = !edit.order.firstDeadlineDateOpen"
              ng-disabled="!edit.isCreate && !edit.canEdit">
        <span class="glyphicon glyphicon-calendar"></span>
      </button>
    </div>
  </div>
  <p class="help-block"
     ng-messages="formCyclic.firstDeadlineDate.$error">
    <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
    <span ng-message="validationError">{{'CYCLIC_FORM_FIRSTDEADLINEDATE_ERROR' | translate}}</span>
    <!-- <span ng-message="cyclicOrderFormValidator">{{'INVALID' | translate}}</span> -->
  </p>
</div>


<div class="row">
  <!-- Cyclic order interval -->
  <div class="form-group col-xs-6"
       ng-class="{
         'has-error': formCyclic.repetitionInterval.$invalid
       }">
    <label class="control-label"
           for="model">{{'CYCLIC_ORDER_REPETITION_INTERVAL' | translate}}</label>
    <input class="form-control"
           type="number"
           min="0"
           max="24"
           name="repetitionInterval"
           id="repetitionInterval"
           ng-disabled="!edit.isCreate && !edit.canEdit"
           ng-model="edit.order.repetitionInterval"
           ng-change="edit.validateForm(edit.order, formCyclic)"
           required />
    <p class="help-block"
       ng-messages="formCyclic.repetitionInterval.$error">
      <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
    </p>
  </div>

  <!-- Cyclic order interval Unit -->
  <div class="form-group col-xs-6"
       ng-class="{
         'has-error': formCyclic.repetitionUnit.$invalid
       }">
    <label class="control-label"
           for="model">&nbsp;</label>
    <select class="form-control"
            id="repetitionUnit"
            name="repetitionUnit"
            ng-model="edit.order.repetitionUnit"
            ng-disabled="!edit.isCreate && !edit.canEdit"
            ng-change="edit.validateForm(edit.order, formCyclic)"
            required>
      <option ng-if="edit.isCreate"
              value="">
        {{'PLEASE_MAKE_CHOICE' | translate}}
      </option>
      <option value="days">{{'DAYS' | translate}}</option>
      <option value="weeks">{{'WEEKS' | translate}}</option>
      <option value="months">{{'MONTHS' | translate}}</option>
      <option value="years">{{'YEARS' | translate}}</option>
    </select>
    <p class="help-block"
       ng-messages="formCyclic.repetitionUnit.$error">
      <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
    </p>
  </div>
</div>

<!-- Cyclic order interval Gap -->
<div class="form-group"
     ng-class="{
       'has-error': formCyclic.creationGap.$invalid
     }">
  <label class="control-label"
         for="model">{{'CYCLIC_ORDER_CREATION_GAP' | translate}}</label>
  <input class="form-control"
         type="number"
         min="0"
         max="31"
         name="creationGap"
         id="creationGap"
         ng-disabled="!edit.isCreate && !edit.canEdit"
         ng-model="edit.order.creationGap"
         ng-change="edit.validateForm(edit.order, formCyclic)"
         required />
  <small class="form-text text-muted">{{'CYCLIC_ORDER_CREATION_GAP_HELP' | translate}}</small>
  <p class="help-block"
     ng-messages="formCyclic.creationGap.$error">
    <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
    <span ng-message="validationError">{{'CYCLIC_FORM_FIRSTDEADLINEDATE_ERROR' | translate}}</span>
  </p>
</div>
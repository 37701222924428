(function () {
  'use strict';

  /* @ngdoc object
   * @name poi.edit
   * @description
   *
   */
  angular
    .module('poi.edit', [
      'ui.router',
      'colorpicker.module'
    ]);
}());

(function () {
  'use strict';

  class FactsheetCtrl {
    constructor($stateParams, CrudApi, UserService) {
      let vm = this;
      vm.ctrlName = 'FactsheetCtrl';
      vm.domainWachau = UserService.getVcToken().accountUuid === '7f1d4f34-a92d-4b8e-906b-60046d14cbfb';
      console.log('Factsheet');
      console.log('BusinessId: ' + $stateParams.id);
      console.log(`Is DW: ${vm.domainWachau}`);

      CrudApi.get({
        entity: 'businesses',
        id: $stateParams.id,
        subentity: 'factsheet'
      }, (data) =>{
        vm.facts = data;
      });
    }

  }


  FactsheetCtrl.$inject = ['$stateParams', 'CrudApi', 'UserService'];

  /**
   * @ngdoc object
   * @name businesses.factsheet.controller:FactsheetCtrl
   *
   * @description
   *
   */
  angular
    .module('businesses.factsheet')
    .controller('FactsheetCtrl', FactsheetCtrl);
}());

<div class="">
  <div class="table-responsive">
    <table class="table table-bordered table-hover">
      <tbody>
        <tr ng-repeat="item in TemporaryWorkersDownload.packageWorkers">
          <td>{{item.label}}</td>
          <td>
            <button class="btn btn-link"
                    ng-click="TemporaryWorkersDownload.download(item, 'tw');">
              <span class="glyphicon glyphicon-cloud-download"
                    uib-tooltip="{{'TOOLTIP_TEMP_WORKER_DOWNLOAD' | translate }}"
                    tooltip-placement = "left"
                    tooltip-append-to-body = "true"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ul uib-pagination
      total-items="TemporaryWorkersDownload.total"
      ng-if="TemporaryWorkersDownload.total > TemporaryWorkersDownload.itemsPerPage"
      ng-model="TemporaryWorkersDownload.page"
      items-per-page="TemporaryWorkersDownload.itemsPerPage"
      ng-change="TemporaryWorkersDownload.paginate()"
      max-size="TemporaryWorkersDownload.maxSize"
      previous-text="&lsaquo;"
      next-text="&rsaquo;"
      first-text="&laquo;"
      last-text="&raquo;">
  </ul>
</div>
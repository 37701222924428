(function () {
  'use strict';

  class OrdersDeleteCtrl {
    constructor(item, type) {
      let vm = this;
      vm.item = item;
      vm.type = type;
    }
  }

  OrdersDeleteCtrl.$inject = ['item', 'type'];
  /**
   * @ngdoc object
   * @name orders.delete.controller:OrdersDeleteCtrl
   *
   * @description
   *
   */
  angular
    .module('orders.delete')
    .controller('OrdersDeleteCtrl', OrdersDeleteCtrl);
}());

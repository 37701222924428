(function () {
  'use strict';

  angular
    .module('admin.customers.edit')
    .config(config);

  function config($stateProvider, permissions) {
    $stateProvider
      .state('customers.edit', {
        url: '/edit/{id:int}',
        templateUrl: 'admin/customers/edit/views/edit.tpl.html',
        controller: 'CustomersEditCtrl',
        controllerAs: 'edit',
        params: {
          message: {
            value: null
          }
        }
      })
      .state('customers.create', {
        url: '/create',
        templateUrl: 'admin/customers/edit/views/edit.tpl.html',
        controller: 'CustomersEditCtrl',
        controllerAs: 'edit',
        data: {requiredPerms: [permissions.ADMIN_CAN_USE, permissions.ADMIN_CAN_CREATE_CUSTOMER]}
      });
  }
}());

(function () {
  'use strict';

  angular
    .module('auth.forgotPassword')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('forgotPassword', {
        url: '/forgot-password',
        templateUrl: 'auth/forgot-password/views/forgot-password.tpl.html',
        controller: 'ForgotPasswordCtrl',
        controllerAs: 'forgotPassword'
      });
  }
}());

<form name="deleteTemporaryWorkersForm">
  <div class="modal-header">
    <button type="button" class="close" ng-click="$dismiss();"><span>&times;</span></button>
    <h4 class="modal-title" ng-if="delete.type === 'cyclic'">{{'CYCLIC_ORDER_DELETE' | translate}}</h4>
    <h4 class="modal-title" ng-if="delete.type === 'regular'">{{'ORDER_DELETE' | translate}}</h4>
  </div>
  <div class="modal-body text-center">
    <h3>{{delete.item.label}}</h3>
  </div>
  <div class="modal-body" ng-bind-html="delete.type === 'cyclic'? 'CYCLIC_ORDER_DELETE_CONFIRM' : 'ORDER_DELETE_CONFIRM' | translate"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" ng-click="$dismiss();">{{'ABORT' | translate}}</button>
    <button type="button" class="btn btn-danger" ng-click="$close();">{{'OK' | translate}}</button>
  </div>
</form>
<main-menu></main-menu>

<div class="base-layout container">
  <h2>{{factsheet.facts.businessLabel}}</h2>

    <div class="col-md-6">
      <h3>{{'FACTSHEET.HECTAR.TITLE'| translate}}</h3>

      <span
        ng-if="factsheet.facts.areaGrossTotal == null && factsheet.facts.areaNetTotal == null || !factsheet.facts">
      {{'FACTSHEET.NOITEMS' | translate}}
    </span>

      <div ng-if="factsheet.facts.areaGrossTotal !== null && factsheet.facts.areaNetTotal !== null && factsheet.facts">
        <p><b>{{'FACTSHEET.HECTAR.BRUTTO'| translate}}:</b> {{factsheet.facts.areaGrossTotal.toFixed(4)}} ha</p>
        <p><b>{{'FACTSHEET.HECTAR.NETTO'| translate}}:</b> {{factsheet.facts.areaNetTotal.toFixed(4)}} ha</p>
      </div>

    </div>

    <div class="col-md-6">
      <h3>{{'FACTSHEET.ROW_LENGTH.TITLE' | translate}}</h3>

      <span ng-if="factsheet.facts.rowLengthAvg == null || !factsheet.facts">
      {{'FACTSHEET.NOITEMS' | translate}}
    </span>

      <div ng-if="factsheet.facts.rowLengthAvg !== null && factsheet.facts">
        <p><b>{{'FACTSHEET.ROW_LENGTH.SHORTEST'| translate}}:</b> {{factsheet.facts.rowLengthMin.toFixed(0)}} m</p>
        <p><b>{{'FACTSHEET.ROW_LENGTH.LONGEST'| translate}}:</b> {{factsheet.facts.rowLengthMax.toFixed(0)}} m</p>
        <p><b>{{'FACTSHEET.AVERAGE'| translate}}:</b> {{factsheet.facts.rowLengthAvg.toFixed(0)}} m</p>
      </div>
    </div>

    <div class="col-md-6">
      <h3>{{'FACTSHEET.GRAPE_VARIETY.TITLE' | translate}}</h3>

      <span
        ng-if="factsheet.facts.areaByCategory.length == 0 && factsheet.facts.areaByFruitColor.length == 0 && factsheet.facts.areaByVariety.length == 0 || !factsheet.facts">
      {{'FACTSHEET.NOITEMS' | translate}}
    </span>

      <div class="col-md-6" ng-if="factsheet.facts.areaByCategory.length !== 0 && factsheet.facts">
        <h4>{{'FACTSHEET.GRAPE_VARIETY.CATEGORY' | translate}}</h4>
        <p ng-repeat="item in factsheet.facts.areaByCategory"><b>{{item.label}}:</b> {{item.value.toFixed(4)}} ha</p>
      </div>
      <div class="col-md-6" ng-if="factsheet.facts.areaByFruitColor.length !== 0 && factsheet.facts">
        <h4>{{'FACTSHEET.GRAPE_VARIETY.COLOR' | translate}}</h4>
        <p ng-repeat="item in factsheet.facts.areaByFruitColor"><b>{{item.label}}:</b> {{item.value.toFixed(4)}} ha</p>
      </div>
      <div class="col-md-6" ng-if="factsheet.facts.areaByVariety.length !== 0 && factsheet.facts">
        <h4 ng-if="!factsheet.domainWachau">{{'FACTSHEET.GRAPE_VARIETY.WINE' | translate}}</h4>
        <h4 ng-if="factsheet.domainWachau">Rebsortenspiegel</h4>
        <p ng-repeat="item in factsheet.facts.areaByVariety"><b>{{item.label}}:</b> {{item.value.toFixed(4)}} ha</p>
      </div>
    </div>

    <div class="col-md-6">
      <h3 ng-if="!factsheet.domainWachau">{{'FACTSHEET.VINEYARD.TITLE'| translate}}</h3>
      <h3 ng-if="factsheet.domainWachau">Weingarten</h3>

      <span
        ng-if="factsheet.facts.averageAge == 0 && factsheet.facts.youngestPlantedDate  == null && factsheet.facts.oldestPlantedDate == null || !factsheet.facts">
      {{'FACTSHEET.NOITEMS' | translate}}
    </span>

      <div class="col-md-6"
           ng-if="factsheet.facts.averageAge !== null && factsheet.facts.youngestPlantedDate  !== null && factsheet.facts.oldestPlantedDate !== null && factsheet.facts">
        <p><b>{{'FACTSHEET.VINEYARD.YOUNGEST' | translate}}:</b> {{factsheet.facts.youngestPlantedDate | date :
          'dd.MM.yyyy'}}</p>
        <p><b>{{'FACTSHEET.VINEYARD.OLDEST'| translate}}:</b> {{factsheet.facts.oldestPlantedDate | date :
          'dd.MM.yyyy'}}
        </p>
        <p><b>{{'FACTSHEET.AVERAGE'| translate}}:</b> {{factsheet.facts.averageAge.toFixed(2)}} Jahre</p>
      </div>

    </div>

</div>

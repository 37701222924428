(function () {
    'use strict';

    class OrderTypeMaterialCtrl {
        constructor($scope, $filter, CrudApi, $q, orderStatus) {
            let vm = this,
                promises = {},
                deregMapDataWatcher;
            vm.orderStatus = orderStatus;

            promises.articles = CrudApi.query({
                entity: 'orders',
                subentity: 'articles'
            }).$promise;

            $q.all(promises).then(({articles}) => {
                vm.articles = articles;
                $scope.$watch('edit.selectedSites', (data, oldValue) => {
                    vm.totalArea = 0;
                    vm.selectedVineyards = data;
                    data.forEach(vineyard => {
                        vm.totalArea += vineyard.area;
                    });
                    if (angular.isArray($scope.edit.additionalData.articles)) {
                        $scope.edit.additionalData.articles.forEach(vm.amountTotalToPerHa);
                    }

                    if (angular.isDefined(oldValue) && !angular.equals(data, oldValue)) {
                        // the vineyard list was changed so the material results might be inaccurate now
                        vm.showVineyardWarning = true;
                    }
                }, true);
                // on initial load we have to color the vineyards correctly if we are selected
                deregMapDataWatcher = $scope.$watch('edit.mapData', mapData => {
                    if (angular.isDefined(mapData)) {
                        // we only need to do this once
                        deregMapDataWatcher();
                        deregMapDataWatcher = null;
                    }
                });
            });

            vm.getAvailableArticles = (search, selection) => {
                let result = [];
                vm.articles.forEach((article) => {
                    let articleInList = $filter('filter')(selection, {
                        article: {
                            id: article.id
                        }
                    }, true) || [];
                    if (article.label.match(new RegExp(search), 'ig') && !articleInList.length) {
                        result.push(article);
                    }
                });
                return result;
            };

            vm.sanitizeSelection = (item, selection) => {
                let selectionItem = $filter('filter')(selection, {
                    id: item.id
                })[0];
                selection.splice(selection.indexOf(selectionItem), 1);
                selection.push({
                    article: selectionItem
                });
            };

            vm.amountPerHaToTotal = (article) => {
                article.amount = round(article.amountPerHa * vm.totalArea, 4);
            };

            vm.amountTotalToPerHa = (article) => {
                article.amountPerHa = round(article.amount / vm.totalArea, 4);
            };

            vm.calcAmountOfArticle = (article, site) => {
                return article.amountPerHa * site.area;
            };

            function round(number, precision) {
                let factor = Math.pow(10, precision);
                return Math.round(number * factor) / factor;
            }
        }
    }

    OrderTypeMaterialCtrl.$inject = ['$scope', '$filter', 'CrudApi', '$q', 'orderStatus'];

    angular
        .module('orders.edit')
        .controller('OrderTypeMaterialCtrl', OrderTypeMaterialCtrl);
}());

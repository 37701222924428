<form name="createOrderTypeForm">
    <div class="modal-header">
        <button type="button"
                class="close"
                ng-click="$dismiss();"
                ng-disabled="$root.activeRequests > 0">
            <span>&times;</span>
        </button>
        <h4 class="modal-title">{{'ORDER_TYPE_NEW' | translate}}</h4>
    </div>
    <div class="modal-body"
         ng-include="'order-types/edit/views/edit.tpl.html'">
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn btn-default"
                ng-click="$dismiss();"
                ng-disabled="$root.activeRequests > 0">
            {{'ABORT' | translate}}
        </button>
        <button type="button"
                class="btn btn-success"
                ng-click="edit.doSave($close);"
                ng-disabled="$root.activeRequests > 0 || createOrderTypeForm.$invalid">
            {{(($root.activeRequests > 0) ? 'PLEASE_WAIT' : 'OK') | translate}}
        </button>
    </div>
</form>

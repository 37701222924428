<span ng-class="{
  'text-danger': edit.countErrorsOnTab(formAdditional)
}">
  <span ng-switch="(edit.orderTypes | filter:{id: edit.order.typeId}:true)[0].group">
    <span ng-switch-when="FERTILIZATION">{{'ORDER_ORDER_TYPE_LABEL_FERTILIZATION' | translate}}</span>
    <span ng-switch-when="PLANT_PROTECTION">{{'ORDER_ORDER_TYPE_LABEL_PLANT_PROTECTION' | translate}}</span>
    <span ng-switch-default>{{'material.label' | translate}}</span>
  </span>
  <span style="margin-left: 5px;"
        class="badge"
        ng-if="edit.countErrorsOnTab(formAdditional)">{{edit.countErrorsOnTab(formAdditional)}}
  </span>
</span>

(function () {
  'use strict';

  class TimeTrackingCostCentersEditCtrl {
    constructor($filter, $stateParams, UserService, permissions, CrudApi, $state, $q, CostCenterHelper) {
      let vm = this,
          promises = {};

      vm.message = $stateParams.message;
      vm.canEdit = angular.isDefined($stateParams.id) && UserService.hasPerm(permissions.TIME_TRACKING_CAN_EDIT_COST_CENTER) ||
                   angular.isUndefined($stateParams.id) && UserService.hasPerm(permissions.TIME_TRACKING_CAN_CREATE_COST_CENTER);
      vm.canEditVisibility = UserService.hasPerm(permissions.ADMIN_CAN_EDIT_VISIBILITY_MODE);

      // Add items to submenu
      if (vm.canEdit) {
        vm.submenu = {
          items: [{
            type: 'button',
            text: 'BUTTON_SAVE',
            click: () => {
              CrudApi.save({
                id: $stateParams.id,
                entity: 'costCenter'
              }, vm.costCenter, (data) => {
                $state.go('timeTracking.costCenters.edit', {
                  id: data.id,
                  message: 'savingSuccess'
                }, {
                  reload: true
                });
              }, (response) => {
                if (response.status === 410) {
                  vm.message = 'entityHasChanged';
                } else {
                  vm.message = 'savingError';
                }
              });
            }
          }]
        };
      }

      // Initialize mainmenu items
      vm.mainmenu = {
        items: []
      };

      // Fetch cost centers
      promises.costCenters = CrudApi.query({
        entity: 'costCenter',
        customOnly: true
      }).$promise;

      if ($stateParams.id) {
        promises.costCenter = CrudApi.get({
          id: $stateParams.id,
          entity: 'costCenter'
        }).$promise;
      } else {
        // promises.costCenter = null;
        //  NEW CostCenter: CostCenter object initialization
        vm.costCenter = {
          costCenterVisibilityMode: 'TENANT',
        };
      }

      $q.all(promises).then(({costCenter, costCenters}) => {
        if (costCenter) {
          if (!costCenter.customerId) {
            $state.go('timeTracking.costCenters.list');
          }
          vm.mainmenu.items.push({
            label: costCenter.label,
            translate: false,
            state: 'timeTracking.costCenters.edit',
            params: {
              id: $stateParams.id
            }
          });
          // CostCenter object
          vm.costCenter = costCenter;

          if (vm.costCenter.visibilityMode === null) {
            vm.costCenter.visibilityMode = 'TENANT';
          }

        } else {
          vm.mainmenu.items.push({
            label: 'TIME_TRACKING_COST_CENTERS_NEW',
            state: 'timeTracking.costCenters.create'
          });
          vm.costCenter = {};
        }

        if (costCenters) {
          if (vm.costCenter.id) {
            costCenters = CostCenterHelper.makeFlat(costCenters, 0, [costCenter.id]);
          } else {
            costCenters = CostCenterHelper.makeFlat(costCenters, 0);
          }
          CostCenterHelper.setIndentedLabel(costCenters);

          vm.costCenters = costCenters;
        }
      }, () => {
        vm.message = 'loadingError';
      });
    }
  }

  TimeTrackingCostCentersEditCtrl.$inject = ['$filter', '$stateParams', 'UserService', 'permissions', 'CrudApi', '$state', '$q', 'CostCenterHelper'];

  /**
   * @ngdoc object
   * @name costCenters.edit.controller:TimeTrackingCostCentersEditCtrl
   *
   * @description
   *
   */
  angular
    .module('timeTracking.costCenters.edit')
    .controller('TimeTrackingCostCentersEditCtrl', TimeTrackingCostCentersEditCtrl);
}());

(function () {
  'use strict';

  angular
    .module('orderTypes')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('orderTypes', {
        url: '/order-types',
        abstract: true,
        template: '<ui-view></ui-view>'
      });
  }
}());

(function () {
  'use strict';

  angular
    .module('poi')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('poi', {
        url: '/poi',
        abstract: true,
        template: '<ui-view />'
      });
  }
}());

(function () {
  'use strict';

  class IndexCtrl {
    constructor(UserService) {
      let vm = this;
      vm.UserService = UserService;
    }
  }

  IndexCtrl.$inject = ['UserService'];

  /**
   * @ngdoc object
   * @name vcMain.controller:IndexCtrl
   *
   * @description
   *
   */
  angular
    .module('vcMain')
    .controller('IndexCtrl', IndexCtrl);
}());

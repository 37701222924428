(function () {
  'use strict';

  class RegistrationCtrl {
    constructor($state, $stateParams, AuthApi, $timeout) {
      let vm = this;
      vm.ctrlName = 'RegistrationCtrl';
      // variable to show or hide the form
      vm.showForm = false;
      // variable to compare to for token validation on the view
      vm.tokenInvalid = false;
      // error message variable to show on the top of the page
      vm.tokenInvalidPass = false;
      // array of statuses of bad tokens
      vm.badStatus = ['EXPIRED', 'ERROR', 'NOT_FOUND', 'NO_USER_ID'];
      // object for user
      vm.user = {
        password: '',
        token: $stateParams.valid
      };
      // object fo the email token
      vm.userToken = {
        emailToken: $stateParams.valid
      };
      // call the checktoken api to check for the token on page load
      AuthApi.get({
        action: 'checktoken',
        emailToken: $stateParams.valid
      }, (data) => {
        // variable to check if there is an error with the token
        let foundError = false;
        // loop through the bad statuses array to check if there is any
        angular.forEach(vm.badStatus, (item) => {
          if (data.status === item) {
            foundError = true;
          }
        });
        // there is no error
        if (!foundError) {
          // show the form to the user
          vm.showForm = true;
          // set the email to be sent as the email from the token
          vm.user.email = data.email;
        } else {
          // set the token invalid parameter
          vm.tokenInvalid = true;
          // hide the form
          vm.showForm = false;
        }
      }, () => {
        vm.tokenInvalid = true;
      });
      // function to be called on form submit to change the password
      vm.registrate = () => {
        // do not show any errors
        vm.error = false;
        // do not show password too short
        vm.passwordError = false;
        // do not show changed success message
        vm.changeSuccess = false;

        vm.tokenInvalidPass = false;
            // if no error in the token was found
            // check for the password matching
        if (vm.user.password === vm.checkPassword) {
          // password matched so make a changepassword api call
          AuthApi.save({
            action: 'newpassword'
          }, vm.user, () => {
            vm.changeSuccess = true;
            // wait 3 seconds before changing the state so that the user knows he changed his password
            $timeout(function () {
              $state.go('login');
            }, 3000);
          }, (data) => {
            if (data.status === 404) {
              vm.tokenInvalidPass = true;
            }
            if (data.status === 400 && angular.isUndefined(data.data.status)) {
              vm.passwordError = true;
            } else if (data.status === 400 && angular.isDefined(data.data.status)) {
              vm.tokenInvalidPass = true;
            }
          });
        } else {
          vm.error = true;
        }
      };
    }
  }

  /**
   * @ngdoc object
   * @name auth.registration.controller:RegistrationCtrl
   *
   * @description
   *
   */

  RegistrationCtrl.$inject = ['$state', '$stateParams', 'AuthApi', '$timeout'];
  angular
    .module('auth.registration')
    .controller('RegistrationCtrl', RegistrationCtrl);
}());

(function () {
    'use strict';

    class OrderTypeFertilizationFertilizationCtrl {
        constructor($scope, $filter, CrudApi, $q, orderStatus, fertilizationIngredient, IconHelper, MapHelper, numberFilter, $translate, siteEntities) {
            let vm = this,
                promises = {},
                deregMapDataWatcher;
            vm.vineyardAreas = {};
            vm.vineyards = {};
            vm.totalIngredientAmountsPerHa = {};
            vm.orderStatus = orderStatus;
            vm.ingredient = fertilizationIngredient;

            promises.articles = CrudApi.query({
                entity: 'fertilization',
                subentity: 'articles'
            }).$promise;

            promises.vineyards = CrudApi.query({
                entity: siteEntities
            }).$promise;

            promises.warnIcon = IconHelper.getIconDescriptor('maps', 'local_florist');

            $q.all(promises).then(({articles, vineyards, warnIcon}) => {
                vm.articles = articles;
                vm.warnIcon = warnIcon;
                vineyards.forEach(vineyard => {
                    vm.vineyardAreas[vineyard.id] = vineyard.area;
                    vm.vineyards[vineyard.id] = vineyard;
                });

                if ($scope.edit && $scope.edit.order && angular.isArray($scope.edit.order.siteIds)) {
                    calculateTotalArea($scope.edit.order.siteIds);
                }

                if (angular.isArray($scope.edit.additionalData.articles)) {
                    $scope.edit.additionalData.articles.forEach(
                        vm.amountPerHa = (article) => {
                            article.amountPerHa = round(article.amount / vm.totalArea, 4);
                        }
                    );
                    $scope.edit.additionalData.articles.forEach(vm.amountTotalToPerHa);
                }

                // when the selected vineyards change we need to update the total area and our own mapping of selected vineyards
                $scope.$watch('edit.order.siteIds', (data, oldValue) => {

                    if (angular.isDefined(oldValue) && !angular.equals(data, oldValue)) {
                        calculateTotalArea($scope.edit.order.siteIds);
                    }
                    if (angular.isArray($scope.edit.additionalData.articles)) {
                        $scope.edit.additionalData.articles.forEach(vm.amountPerHaToTotal);
                    }

                    if (angular.isUndefined(vm.initialTotalIngredientAmountsPerHa)) {
                        vm.initialTotalIngredientAmountsPerHa = getTotalIngredientAmounts();
                    }
                    // the function vm.amountTotalToPerHa() actually calls updateVineyardColor() but we still need to
                    // call it explicitely to handle the case of initial load. On initial load the sequence of events is:
                    // vm.amountTotalToPerHa() -> getTotalIngredientAmounts()
                    // This order is required as getTotalIngredientAmounts() requires the amountPerHa values.
                    // But updateVineyardColor() requires the total values. So on initial load the updateVineyardColor() call
                    // in vm.amountTotalToPerHa() will not produce the correct colors initially.
                    updateVineyardIcons();

                    if (angular.isDefined(oldValue) && !angular.equals(data, oldValue)) {
                        // the vineyard list was changed so the fertilization results might be inaccurate now
                        vm.showVineyardWarning = true;
                    }
                }, true);
                // on initial load we have to color the vineyards correctly if we are selected
                deregMapDataWatcher = $scope.$watch('edit.mapData', mapData => {
                    if (angular.isDefined(mapData)) {
                        updateVineyardIcons();
                        // we only need to do this once
                        deregMapDataWatcher();
                        deregMapDataWatcher = null;
                    }
                });
            });

            vm.getAvailableArticles = (search, selection) => {
                let result = [];
                vm.articles.forEach((article) => {
                    let articleInList = $filter('filter')(selection, {
                        article: {
                            id: article.id
                        }
                    }, true) || [];
                    if (article.label.match(new RegExp(search), 'ig') && !articleInList.length) {
                        result.push(article);
                    }
                });
                return result;
            };

            vm.sanitizeSelection = (item, selection) => {
                let selectionItem = $filter('filter')(selection, {
                    id: item.id
                })[0];
                selection.splice(selection.indexOf(selectionItem), 1);
                selection.push({
                    article: selectionItem
                });
            };

            vm.amountPerHaToTotal = (article) => {
                article.amount = round(article.amountPerHa * vm.totalArea, 4);
                article.article.ingredients.forEach(ingredient => amountTotalToIngredientAmounts(article, ingredient));
                vm.totalIngredientAmountsPerHa = getTotalIngredientAmounts();
                updateVineyardIcons();
            };

            vm.amountTotalToPerHa = (article) => {
                article.amountPerHa = round(article.amount / vm.totalArea, 4);
                article.article.ingredients.forEach(ingredient => amountTotalToIngredientAmounts(article, ingredient));
                vm.totalIngredientAmountsPerHa = getTotalIngredientAmounts();
                updateVineyardIcons();
            };

            vm.ingredientPerHaToArticle = (article, ingredient) => {
                let originalPerHaAmount = ingredient.calcAmountPerHa;
                ingredient.calcAmount = round(ingredient.calcAmountPerHa * vm.totalArea, 4);
                vm.ingredientTotalToArticle(article, ingredient);
                // we want to avoid that the value the user just entered changes because of rounding effects
                // But we need to take the edge case into account that the calculation result
                // is 0. This can occur if the calcAmount is also 0 and then calcAmountPerHa
                // needs to be 0 too.
                if (ingredient.calcAmountPerHa !== 0) {
                    ingredient.calcAmountPerHa = originalPerHaAmount;
                }
                vm.totalIngredientAmountsPerHa = getTotalIngredientAmounts();
                updateVineyardIcons();
            };

            vm.ingredientTotalToArticle = (article, ingredient) => {
                let factor = getNormalizationFactor(article.article.unit, ingredient.unit),
                    originalCalcAmount = ingredient.calcAmount,
                    articleAmount = ingredient.calcAmount / factor / ingredient.amount;
                // we need to handle the edge case of dividing by 0 when ingredient.amount is 0.
                // This occurs if the article has no amount of the given ingredient.
                if (isFinite(articleAmount)) {
                    article.amount = round(articleAmount, 4);
                    vm.amountTotalToPerHa(article);
                    // make sure the value the user entered does not change
                    ingredient.calcAmount = originalCalcAmount;
                } else {
                    // there is no amount of the ingredient in the article so we need to set
                    // the amunts to 0
                    ingredient.calcAmount = 0;
                    ingredient.calcAmountPerHa = 0;
                }
                vm.totalIngredientAmountsPerHa = getTotalIngredientAmounts();
                updateVineyardIcons();
            };

            vm.recalculateTotalIngredientAmounts = () => {
                vm.totalIngredientAmountsPerHa = getTotalIngredientAmounts();
                updateVineyardIcons();
            };

            function updateVineyardIcons() {
                if (angular.isArray(vm.selectedVineyards)) {
                    $scope.edit.modifiedIconData = angular.extend({}, $scope.edit.iconData);
                    vm.selectedVineyards.forEach((vineyard) => {
                        if (vineyard.maximumNitrogenValue !== null && angular.isDefined(vm.initialTotalIngredientAmountsPerHa) && angular.isDefined(vm.totalIngredientAmountsPerHa)) {
                            // it's possible for totalIngredientAmountsPerHa to not contain nitrogen of phosphate values when there are no articles
                            // present that contain either nitrogen or phosphate
                            let nitrogenValue = vineyard.maximumNitrogenValue - vineyard.nitrogenDeductionPending - vineyard.nitrogenDeductionFinished + vm.initialTotalIngredientAmountsPerHa[vm.ingredient.NITROGEN] - (vm.totalIngredientAmountsPerHa[vm.ingredient.NITROGEN] || 0),
                                phosphateValue = vineyard.maximumPhosphateValue - vineyard.phosphateDeductionPending - vineyard.phosphateDeductionFinished + vm.initialTotalIngredientAmountsPerHa[vm.ingredient.PHOSPHATE] - (vm.totalIngredientAmountsPerHa[vm.ingredient.PHOSPHATE] || 0);
                            if (nitrogenValue < 0 || phosphateValue < 0) {
                                // non numeric icon id will also prevent state transition to the PoI edit view
                                // on click because the PoI edit view only accepts integer ids
                                $scope.edit.modifiedIconData['fertilizationIcon' + vineyard.id] = {
                                    vineyardId: vineyard.id,
                                    options: MapHelper.generateMarkerOptionsForPath(vm.warnIcon.path, vm.warnIcon.width, vm.warnIcon.height, 'red', 'transparent'),
                                    infoMessage: '<h4>' + vineyard.label + '</h4>' +
                                        '<dl>' +
                                        '<dt>' + $translate.instant('FERTILIZATION_ARTICLE_INGREDIENTS_NITROGEN') + '</dt>' +
                                        '<dd>' + numberFilter(nitrogenValue, 4) + '</dd>' +
                                        '<dt>' + $translate.instant('FERTILIZATION_ARTICLE_INGREDIENTS_PHOSPHATE') + '</dt>' +
                                        '<dd>' + numberFilter(phosphateValue, 4) + '</dd>' +
                                        '</dl>'
                                };
                                return;
                            }
                        }
                    });
                }
            }

            function amountTotalToIngredientAmounts(article, ingredient) {
                let factor = getNormalizationFactor(article.article.unit, ingredient.unit),
                    calcAmount = factor * ingredient.amount * article.amount;
                ingredient.calcAmount = round(calcAmount, 4);
                ingredient.calcAmountPerHa = round(calcAmount / vm.totalArea, 4);
            }

            function getTotalIngredientAmounts() {
                let totalIngredientAmountsPerHa = {};
                if ($scope.edit && $scope.edit.additionalData && angular.isArray($scope.edit.additionalData.articles)) {
                    $scope.edit.additionalData.articles.forEach((article) => {
                        if (article.article && article.article.ingredients) {
                            article.article.ingredients.forEach((ingredient) => {
                                if (!angular.isNumber(totalIngredientAmountsPerHa[ingredient.ingredient.id])) {
                                    totalIngredientAmountsPerHa[ingredient.ingredient.id] = 0;
                                }
                                if (isFinite(ingredient.calcAmountPerHa)) {
                                    totalIngredientAmountsPerHa[ingredient.ingredient.id] += ingredient.calcAmountPerHa;
                                }
                            });
                        }
                    });
                }
                return totalIngredientAmountsPerHa;
            }

            function getNormalizationFactor(articleUnit, ingredientUnit) {
                let factor;
                switch (ingredientUnit) {
                    case '%':
                        switch (articleUnit) {
                            case 'dt':
                                factor = 100.0 / 100.0;
                                break;
                            case 't':
                                factor = 1000.0 / 100.0;
                                break;
                            case 'm3':
                                // We are using the factor that assumes 1m³ of water
                                // Revisit this conversion if necessary
                                factor = 1000.0 / 100.0;
                                break;
                            default:
                                // KG and L will be treated like a straight 1:1 conversion
                                factor = 1.0 / 100.0;
                                break;
                        }
                        break;
                    default:
                        // KG and L will be treated like a straight 1:1 conversion
                        factor = 1.0;
                        break;
                }
                return factor;
            }

            function round(number, precision) {
                let factor = Math.pow(10, precision);
                return Math.round(number * factor) / factor;
            }

            function calculateTotalArea(selectedSiteIds) {
                vm.totalArea = 0;
                vm.selectedVineyards = [];
                selectedSiteIds.forEach(vId => {
                    vm.totalArea += angular.isDefined(vm.vineyardAreas[vId]) ? vm.vineyardAreas[vId] : 0;
                    if (vm.vineyards.hasOwnProperty(vId)) {
                        vm.selectedVineyards.push(vm.vineyards[vId]);
                    } else {
                        console.warn('Could not find vineyard object for id: ' + vId);
                    }
                });

            }
        }
    }

    OrderTypeFertilizationFertilizationCtrl.$inject = ['$scope', '$filter', 'CrudApi', '$q', 'orderStatus', 'fertilizationIngredient', 'IconHelper', 'MapHelper', 'numberFilter', '$translate', 'siteEntities'];

    /**
     * @ngdoc object
     * @name orders.edit.controller:OrderTypeFertilizationFertilizationCtrl
     *
     * @description
     *
     */
    angular
        .module('orders.edit')
        .controller('OrderTypeFertilizationFertilizationCtrl', OrderTypeFertilizationFertilizationCtrl);
}());

<sub-menu items="list.submenu.items"></sub-menu>
<main-menu></main-menu>

<ng-form name="form"
         class="base-layout management-unit-list">
    <div class="base-layout-default">
        <main-message message="list.message" fluid="'nested'"></main-message>
        <div class="table-responsive" ng-if="list.items.length">
            <table class="table table-hover">
                <thead>
                <th ng-click="list.sort('label')">
                    {{'MANAGEMENT_UNIT' | translate}}
                    <span class="glyphicon pull-right text-muted"
                          ng-if="list.sorter === 'label'"
                          ng-class="{
                    'glyphicon-chevron-down': list.dir === 'asc',
                    'glyphicon-chevron-up': list.dir === 'desc'
                  }"></span>
                </th>
                <th class="text-center">{{'ENTITY_OWNERSHIP' | translate}}</th>
                <th class="text-center">{{'DELETE' | translate}}</th>
                </thead>
                <tbody>
                <tr ng-repeat="item in list.items"
                    ui-sref="managementUnits.edit({
                id:item.id
              })">
                    <td>{{item.label}}</td>
                    <td class="text-center shrink" ng-if="list.ownEntity(item.customerId)">
                        {{'OWN_ENTITY' | translate}}
                    </td>
                    <td class="text-center shrink" ng-if="!list.ownEntity(item.customerId)">
                        {{'NOT_OWN_ENTITY' | translate}}
                    </td>
                    <td class="text-center shrink">
                        <button class="btn btn-link"
                                ng-click="$event.stopPropagation(); list.delete(item)"
                                ng-disabled="!list.canDeleteEntity(item.customerId)">
                            <span class="glyphicon glyphicon-trash"
                                    uib-tooltip="{{'TOOLTIP_MANAGEMENT_UNIT_DELETE' | translate }}"
                                    tooltip-placement="left"
                                    tooltip-append-to-body="true">
                            </span>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <ul uib-pagination
            total-items="list.total"
            ng-if="list.total > list.itemsPerPage"
            ng-model="list.page"
            items-per-page="list.itemsPerPage"
            ng-change="list.paginate()"
            boundary-links="true"
            max-size="list.maxSize"
            previous-text="&lsaquo;"
            next-text="&rsaquo;"
            first-text="&laquo;"
            last-text="&raquo;">
        </ul>
    </div>
</ng-form>

(function () {
  'use strict';

  class ManagementUnitDeleteCtrl {
    constructor(item) {
      let vm = this;
      vm.item = item;
    }
  }

  ManagementUnitDeleteCtrl.$inject = ['item'];
  /**
   * @ngdoc object
   * @name managementUnits.delete.controller:DeleteCtrl
   *
   * @description
   *
   */
  angular
    .module('managementUnits.delete')
    .controller('ManagementUnitDeleteCtrl', ManagementUnitDeleteCtrl);
}());

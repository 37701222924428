<sub-menu items="edit.submenu.items"
    form="form"></sub-menu>
<main-menu items="edit.mainmenu.items"></main-menu>

<div class="fertilization-ph-demand-exports-edit export-edit base-layout">
  <div class="base-layout-default">
    <main-message message="edit.message" fluid="'nested'"></main-message>
    <ng-form name="form">
      <!-- LABEL -->
      <div class="form-group"
        ng-class="{
          'has-error': form.label.$invalid
        }">
        <label class="control-label">{{'FERTILIZATION_EXPORT_LABEL' | translate}}</label>
        <input class="form-control"
                type="text"
                name="label"
                id="label"
                ng-model="edit.export.label"
                required />
      </div>

      <!-- PERIOD -->
      <div>
        <label class="control-label">{{'FERTILIZATION_EXPORT_PERIOD' | translate}}</label>
        <div class="row">
            <div class="col-md-6">
              <div class="form-group"
                  ng-class="{
                    'has-error': form.period.$invalid
                  }">
                <label class="control-label soft-label">{{'FERTILIZATION_EXPORT_FROM' | translate}}</label>
                <input type="hidden"
                      ng-model="edit.export.periodDummyModel"
                      name="period"
                      ui-validate="{
                        overlap: 'edit.validatePeriod()'
                      }"
                      ui-validate-watch="'edit.export.period'"
                      ui-validate-watch-object-equality="true" />
                <div class="input-group">
                  <input class="form-control"
                        uib-datepicker-popup="dd.MM.yyyy"
                        ng-model="edit.export.period.periodStart"
                        ng-change="edit.export.period.periodStartMoment = edit.moment(edit.export.period.periodStart)"
                        readonly="readonly"
                        is-open="edit.export.period.periodStartOpen"
                        type="text"
                        name="periodStart"
                        datepicker-options="edit.periodStartOptions"
                        required
                        show-button-bar="false" />
                  <div class="input-group-btn">
                    <button class="btn btn-default"
                            ng-click="edit.export.period.periodStartOpen = !edit.export.period.periodStartOpen">
                      <span class="glyphicon glyphicon-calendar"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group"
                  ng-class="{
                    'has-error': form.period.$invalid
                  }">
                <label class="control-label soft-label">{{'FERTILIZATION_EXPORT_TO' | translate}}</label>
                <div class="input-group">
                  <input class="form-control"
                        uib-datepicker-popup="dd.MM.yyyy"
                        ng-model="edit.export.period.periodEnd"
                        ng-change="edit.export.period.periodEndMoment = edit.moment(edit.export.period.periodEnd)"
                        readonly="readonly"
                        is-open="edit.export.period.periodEndOpen"
                        type="text"
                        name="periodEnd"
                        datepicker-options="edit.periodEndOptions"
                        required
                        show-button-bar="false" />
                  <div class="input-group-btn">
                    <button class="btn btn-default"
                            ng-click="edit.export.period.periodEndOpen = !edit.export.period.periodEndOpen">
                      <span class="glyphicon glyphicon-calendar"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      <!-- PARCELS -->
      <div class="form-group">
        <label class="control-label">{{'FERTILIZATION_EXPORT_VINEYARDS' | translate}}</label>
        <parcels-selection name="vineyards"
                          ng-model="edit.export.vineyardIds"
                          initial-edit="true"
                          required
                          map-enabled="true"
                          sums="false"></parcels-selection>
        <p class="help-block">
          <span>{{'FERTILIZATION_EXPORT_HELP' | translate}}</span>
        </p>
      </div>
    </ng-form>
  </div>
</div>

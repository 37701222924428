(function () {
  'use strict';

  /* @ngdoc object
   * @name temporaryWorkers
   * @description
   *
   */
  angular
    .module('temporaryWorkers', [
      'ui.router',
      'temporaryWorkers.list',
      'temporaryWorkers.edit',
      'temporaryWorkers.delete'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name vcAdmin.directive:autoFocus
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="vcAdmin">
       <file name="index.html">
        <auto-focus></auto-focus>
       </file>
     </example>
   *
   */
  angular
    .module('vcMain')
    .directive('autoFocus', autoFocus);

  function autoFocus() {
    return {
      restrict: 'A',
      scope: {
        trigger: '=autoFocus'
      },
      link(scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        scope.$watch('trigger', (value) => {
          if (value) {
            let el = element[0],
                strLength = element.val().length * 2;
            // Set focus only if viewport is greater than 768 i.e. bigger than a mobile/tablet to prevent keyboard opening on each view
            if (el.value.length > 0) {
              el.focus();
              el.setSelectionRange(strLength, strLength);
            }
          }
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name vcMain.filter:nl2br
   *
   * @description a filter that replaces all newlines with <br> tags
   *
   * @param {String} input a string with newlines
   * @returns {String} a string where all newlines have been replaced by <br>
   *
   */
  angular
    .module('vcMain')
    .filter('nl2br', nl2br);

  function nl2br() {
    return (input) => {
      return String(input).replace(/\n/g, '<br>');
    };
  }
}());

<div class="form-group">
  <table class="table table-bordered">
    <thead>
    <th class="shrink text-center">{{'HASHTAG' | translate}}</th>
    <th class="shrink text-center">{{'HASHTAG' | translate}}</th>
    <th class="shrink text-center">{{'FIRSTNAME' | translate}}</th>
    <th class="shrink text-center">{{'LASTNAME' | translate}}</th>
    <th class="shrink text-center">{{'PATH_TIME_TRAVELDURATION' | translate}}</th>
    <th class="shrink text-center">{{'PATH_TIME_WORKDURATION' | translate}}</th>
    <th class="shrink text-center">{{'PATH_TIME_OVERALLTIME' | translate}}</th>
    <th class="shrink text-center text-center-wide">{{'PATH_TIME_VIEWSLIDER' | translate}}</th>

    </thead>
    <tbody>
    <tr ng-repeat="user in edit.users|filter:{assigned: true}" class="shrink text-center">
      <td class="shrink text-center">{{$index + 1}}</td>
      <td class="text-center">
        <input type="checkbox"
               ng-disabled="!edit.isCreate && !edit.canEdit"
               ng-model="user.track"
               ng-click="edit.getProgress();">
      </td>
      <td class="shrink text-center"  style="color:{{edit.colors[user.id]}}">{{user.firstname}}</td>
      <td class="shrink text-center" style="color:{{edit.colors[user.id]}}">{{user.lastname}}</td>
      <td class="shrink text-center">{{edit.msToTime(edit.pathtime[user.id].travelDuration)}}</td>
<!--      <td class="text-center">{{edit.slider[user.id].min.toString()}}</td>-->
      <td class="shrink text-center">{{edit.msToTime(edit.pathtime[user.id].workDuration)}}</td>
<!--      <td class="text-center">{{edit.slider[user.id].max.toString()}}</td>-->
      <td class="shrink text-center">{{edit.msToTime(edit.pathtime[user.id].overallTime)}}</td>
      <td class="text-center text-center-wide">
<!--        <rzslider ng-mouseleave="edit.getProgress();"-->
        <rzslider ng-disabled="undefined == edit.masterUsersRaw[user.id] || edit.masterUsersRaw[user.id].length < 3"
          rz-slider-model="edit.sliderMin[user.id]"
          rz-slider-high="edit.sliderMax[user.id]"
          rz-slider-options="edit.sliderOptions[user.id]">
        </rzslider>
      </td>
    </tr>
    <tr ng-if="!edit.isEmpty(edit.masterUsers)" border = "2">
      <td colspan="4">{{'PATH_TIME_ACUMULATED'|translate}}</td>
      <td>{{edit.msToTime(edit.pathTimeSummary.travelDuration)}} ({{edit.pathTimeSummary.travelDurationNorm}}%)</td>
      <td>{{edit.msToTime(edit.pathTimeSummary.workDuration)}} ({{edit.pathTimeSummary.workDurationNorm}}%)</td>
      <td>{{edit.msToTime(edit.pathTimeSummary.overallTime)}} ({{edit.pathTimeSummary.overallTimeNorm}}%)</td>
      <td></td>
    </tr>
    <tr ng-if="!edit.users|filter:{assigned: true}.length">
      <td>
        {{MESSAGE_INFO_NO_DATA | translate}}
      </td>
    </tr>
    </tbody>
  </table>
</div>

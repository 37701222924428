(function () {
  'use strict';

  angular
    .module('parcels.list')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('parcels.list', {
        url: '/list?sorter&dir&query',
        templateUrl: 'parcels/list/views/list.tpl.html',
        controller: 'ParcelsListCtrl',
        controllerAs: 'list',
        params: {
          query: {
            type: 'json',
            value: {}
          }
        }
      });
  }
}());

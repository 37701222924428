(function () {
  'use strict';

  angular
    .module('fertilization.nDemandExports.edit')
    .config(config);

  function config($stateProvider, permissions) {
    $stateProvider
      .state('fertilization.nDemandExports.edit', {
        url: '/edit',
        templateUrl: 'fertilization/n-demand-exports/edit/views/edit.tpl.html',
        controller: 'FertilizationNDemandExportsEditCtrl',
        controllerAs: 'edit',
        params: {
          message: {
            value: null
          }
        },
        data: {requiredPerms: [
          permissions.FERTILIZATION_CAN_CREATE_N_DEMAND_EXPORT
        ]}
      });
  }
}());

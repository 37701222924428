<sub-menu items="list.submenu.items" fluid="true"></sub-menu>
<main-menu></main-menu>

<div class="base-layout">
  <div class="base-layout-map">
    <div id="parcelTable" class="base-layout-map-list">
      <main-message message="list.message"
                    style="margin-top: 20px;"
                    fluid="true"
                    ng-if="list.message"></main-message>
      <div ng-if="list.checkedEntities.length !== 0">
        <batch-edit
          entities="list.checkedEntities"
          entity-name="'vineyards'"
          can-delete="list.batchEditDeleteAvailable"
          field-descriptors="list.fieldDescriptors"
          on-step-changed="list.onStepChanged(step)"></batch-edit>
      </div>
      <div class="table-responsive"
           ng-if="list.items.length"
           style="margin-bottom: 0;">
        <table class="table table-hover" style="margin-bottom: 0;">
          <thead>
          <tr ng-include="'parcels/list/views/table_headers.html'"></tr>
          </thead>
          <tbody>
          <tr ng-repeat="item in list.filteredVineyards"
              ng-class="{
                'active': list.selection === item.id
              }"
              ui-sref="parcels.edit.orders.list({
                id: item.id
              })"
              ng-include="'parcels/list/views/table_body.html'">
          </tr>
          </tbody>
        </table>
      </div>
      <ul style="margin-top: .5em;" uib-pagination class="pagination-md" boundary-links="true" ng-if="list.items.length > list.itemsPerPage"
          ng-change="list.pageChanged()" total-items="list.items.length" items-per-page="list.itemsPerPage"
          ng-model="list.currentPage" force-ellipses="true" max-size="5" previous-text="&lsaquo;"
          next-text="&rsaquo;" first-text="&laquo;" last-text="&raquo;">
      </ul>
    </div>
    <div class="base-layout-map-map">
      <vc-map-display
        map-id="list.mapId"
        icon-data="list.iconData"
        vineyard-map-data="list.mapData"
        selected-vineyards="list.selectedVineyards"
        on-vineyard-mouse-over="list.rowHover(vineyardId, true, false)"
        on-vineyard-mouse-out="list.rowHover(vineyardId, false, false)"
        zoom-to-selection="list.zoomToSelection">
      </vc-map-display>
    </div>
  </div>
</div>

<div class="table-responsive">
    <table class="table table-bordered table-striped">
        <tr>
            <th>{{'Datum' | translate}}</th>
            <th>{{'Benutzer' | translate}}</th>
            <th>{{'Restriktionen' | translate }}</th>
        </tr>
        <tr ng-repeat="restriction in edit.restrictions">
            <td>{{restriction.lastModified | amDateFormat: 'DD.MM.yyyy'}}</td>
            <td>{{restriction.user.label}}</td>
            <td>
                <ul>
                    <li ng-repeat="entries in restriction.entries">
                        {{entries}}
                    </li>
                </ul>
            </td>
        </tr>
    </table>
</div>

(function () {
  'use strict';

  angular
    .module('poi.list')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('poi.list', {
        url: '/list?page',
        templateUrl: 'poi/list/views/list.tpl.html',
        controller: 'PoiListCtrl',
        controllerAs: 'list',
        params: {
          message: {
            value: null
          },
          page: {
            type: 'int',
            squash: true,
            value: 1
          }
        }
      });
  }
}());

(function () {
  'use strict';

  angular
    .module('plantProtection.exports.edit')
    .config(config);

  function config($stateProvider, permissions) {
    $stateProvider
      .state('plantProtection.exports.edit', {
        url: '/edit',
        templateUrl: 'plant-protection/exports/edit/views/edit.tpl.html',
        controller: 'PlantProtectionExportsEditCtrl',
        controllerAs: 'edit',
        params: {
          message: {
            value: null
          }
        },
        data: {requiredPerms: [
          permissions.PLANT_PROTECTION_CAN_CREATE_EXPORT
        ]}
      });
  }
}());

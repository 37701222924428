(function () {
  'use strict';

  /* @ngdoc object
   * @name exports.edit
   * @description
   *
   */
  angular
    .module('fertilization.phDemandExports.edit', [
      'ui.router'
    ]);
}());

<sub-menu items="list.submenu.items"></sub-menu>
<main-menu></main-menu>

<div class="export-list base-layout">
  <div class="base-layout-default">
    <main-message message="list.message" fluid="'nested'"></main-message>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <th></th>
          <th>{{'EXPORTS_LABEL' | translate}}</th>
          <th></th>
          <th>{{'EXPORTS_CREATED_LABEL' | translate}}</th>
          <th></th>
          <th>{{'EXPORTS_DELETE' | translate}}</th>
        </thead>
        <tbody>
          <tr ng-repeat="export in list.exports">
            <td class="text-center shrink">
              <button class="btn btn-link"
                ng-click="export.expanded = !export.expanded">
                <span ng-if="export.expanded" class="glyphicon glyphicon-chevron-up"></span>
                <span ng-if="!export.expanded" class="glyphicon glyphicon-chevron-down"></span>
              </button>
            </td>
            <td>{{export.label}}
              <ul ng-show="export.expanded">
                <li ng-if="export.fromTime && export.toTime">{{export.fromTime | date: 'longDate'}} - {{export.toTime | date: 'longDate'}}</li>
                <li ng-if="export.year">{{export.year}}</li>
                <li ng-if="export.vineyardIds && export.vineyardIds.length !== 0">{{export.vineyards}}</li>
                <li ng-if="export.survey">{{export.survey}}</li>
              </ul>
            </td>
            <td>{{list.translatedJobs[export.jobType]}}</td>
            <td class="text-center shrink">{{export.createdAt | date: 'short'}}</td>
            <td class="text-center shrink">
              <span ng-if="export.jobStatus === 'error'" class="glyphicon glyphicon-alert btn-link" tooltip-placement="bottom" uib-tooltip="{{'EXPORTS_ERROR_TOOLTIP' | translate}}"></span>
              <span ng-if="export.jobStatus === 'in progress'" class="glyphicon glyphicon-hourglass btn-link" tooltip-placement="bottom" uib-tooltip="{{'EXPORTS_LOADING_TOOLTIP' | translate}}"></span>


              <div ng-if="export.jobStatus === 'finished'" class="btn-group" uib-dropdown dropdown-append-to-body is-open="status.isopen" on-toggle="list.getFiles(export, open)">
                <button id="single-button" type="button" class="btn btn-primary" uib-dropdown-toggle ng-disabled="!list.canDownload[export.jobType]">
                  <span class="glyphicon glyphicon-cloud-download"></span> Download <span class="caret"></span>
                </button>
                <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="single-button">
                  <li role="menuitem" ng-repeat="file in export.files"><a target="_blank" ng-href="{{file.downloadURL}}" download="{{file.filename}}">{{file.filename}}</a></li>
                </ul>
              </div>
            </td>
            <td class="text-center shrink">
              <button class="btn btn-link"
                    ng-disabled="!list.canDelete[export.jobType]"
                    ng-click="$event.stopPropagation(); list.delete(export)">
                <span class="glyphicon glyphicon-trash"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

(function () {
  'use strict';

  angular
    .module('timeTracking.costCenters.list')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('timeTracking.costCenters.list', {
        url: '/list?page',
        templateUrl: 'time-tracking/cost-centers/list/views/list.tpl.html',
        controller: 'TimeTrackingCostCentersListCtrl',
        controllerAs: 'list',
        params: {
          message: {
            value: null
          }
        }
      });
  }
}());

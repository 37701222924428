<ng-form class="form-group order-bonitur"
         name="boniturForm"
         style="display: block;"
         ng-controller="OrderTypeBoniturCtrl as additional">
 <ng-form class="form-group"
          name="boniturSurveyForm"
          style="display: block"
          ng-class="{
            'has-error': boniturSurveyForm.$invalid
          }">
   <!-- SURVEY -->
   <label class="control-label">{{'BONITUR_SURVEY' | translate}}</label>
   <ui-select ng-disabled="(!edit.isCreate && !edit.canEdit) || edit.isReadOnlyOrder()"
              ng-model="edit.additionalData.surveyId"
              theme="bootstrap"
              name="surveySelect"
              style="margin-bottom: 5px;"
              ng-if="additional.surveys"
              on-select="additional.showSelected($item)"
              required>
     <ui-select-match placeholder="  {{'PLEASE_MAKE_CHOICE' | translate}}">
       {{additional.selected}}
     </ui-select-match>
     <ui-select-choices repeat="survey.id as survey in additional.surveys">
       {{survey.label}}
     </ui-select-choices>
   </ui-select>
   <div>
     <div style="color: #A94442" ng-show="boniturSurveyForm.surveySelect.$error.required">{{'BONITUR_SURVEY_MISSING_SURVEY' | translate}}</div>
   </div>
 </ng-form>
  <ng-form class="form-group"
           name="boniturNachboniturForm"
           style="display: block">
    <label class="control-label">{{'Nachklassifizierung' | translate}}</label>
    <div>
      <ul class="list-group scrollbox">
        <li class="list-group-item" ng-repeat="nachbonitur in additional.selectedNachbonitur">
          <input type="checkbox"
                 checked
                 value="{{nachbonitur.boniturId}}"
                 ng-click="additional.updateSelection(nachbonitur, $event)"
                 style="margin-right: 15px;" />
          <div style="display: inline-flex; justify-content: space-between; width: 95%;">
            <span>{{nachbonitur.siteLabel}}</span>
            <span>{{nachbonitur.boniturLabel}}</span>
          </div>
        </li>
      </ul>
    </div>
  </ng-form>
</ng-form>

(function () {
  'use strict';

  class ParcelListFilterCtrl {
    constructor(filters) {
      let vm = this;

      vm.filters = filters;
    }
  }

  ParcelListFilterCtrl.$inject = ['filters'];

  /**
   * @ngdoc object
   * @name parcels.list.controller:ParcelListFilterCtrl
   *
   * @description
   *
   */
  angular
    .module('parcels.list')
    .controller('ParcelListFilterCtrl', ParcelListFilterCtrl);
}());

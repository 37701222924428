<label class="control-label">{{item.label | translate}}</label>
<input ng-if="item.type === 'text'" class="form-control" type="text"
        ng-change="item.value.readableValue=item.value.value" ng-model="item.value.value" name="item.fieldname" />
<input ng-if="item.type === 'number'" class="form-control" type="number"
        ng-change="item.value.readableValue=item.value.value" ng-model="item.value.value" name="item.fieldname" step="item.step" />
<rzslider ng-if="item.type === 'slider'"
        rz-slider-model="item.reference.value"
        rz-slider-options="item.options">
</rzslider>
<select class="form-control"
        ng-if="item.type === 'select'"
        name="item.fieldname"
        ng-model="item.reference.value"
        ng-selected="item.value.readableValue=item.reference.value.label; item.value.value=item.reference.value.value"
        ng-options="option as option.label for option in item.options">
    <option value=""
        ng-if="item.addPlaceholderOption">{{'PLEASE_MAKE_CHOICE' | translate}}</option>
</select>
<div class="input-group" ng-if="item.type === 'date'">
    <input class="form-control"
            id="item.fieldname"
            name="item.fieldname"
            uib-datepicker-popup="dd.MM.yyyy"
            readonly="readonly"
            is-open="item.dateOpen"
            ng-model="item.value.value"
            ng-change="item.value.readableValue=(item.value.value | date : 'mediumDate')"
            type="text"
            show-button-bar="false" />
    <div class="input-group-btn">
        <button class="btn btn-default" ng-click="item.dateOpen = !item.dateOpen">
            <span class="glyphicon glyphicon-calendar"></span>
        </button>
    </div>
</div>
<div ng-if="item.type === 'wineSelection'" class="form-group" ng-class="{
    'has-error': form.wineFirst.$invalid || form.wineSecond.$invalid || form.wineThird.$invalid
    }">
    <select class="form-control"
        name="wineFirst"
        ng-model="item.reference.wineFirst"
        ng-change="item.reference.wineSecond = null; item.reference.wineThird = null; batchEdit.translateValue(item.reference.wineFirst, 'wineFirst'); item.value['wineFirst'] = {value: (item.reference.wineFirst | translate), readableValue: (item.reference.wineFirst | translate)}"
        ng-options="(fWine | translate)  as (fWine | translate) for fWine in item.options.firstWines">
        <option value=""></option>
    </select>
    <br>
    <select class="form-control"
        name="wineSecond"
        ng-model="item.reference.wineSecond"
        ng-required="item.reference.wineFirst"
        ng-change="item.reference.wineThird = null; batchEdit.translateValue(item.reference.wineSecond, 'wineSecond'); item.value['wineSecond'] = {value: (item.reference.wineSecond | translate), readableValue: (item.reference.wineSecond | translate)}"
        ng-options="(sWine | translate)  as (sWine | translate) for sWine in item.options.tree[batchEdit.wineKeys.wineFirst]">
        <option value=""></option>
    </select>
    <br>
    <select class="form-control"
        name="wineThird"
        ng-required="item.reference.wineFirst"
        ng-model="item.reference.wineThird"
        ng-change="item.value['wineThird'] = {value: (item.reference.wineThird | translate), readableValue: (item.reference.wineThird | translate)}"
        ng-options="(tWine | translate)  as (tWine | translate) for tWine in item.options.tree[batchEdit.wineKeys.wineSecond]">
        <option value=""></option>
    </select>
</div>

<div class="registration-page">
  <div class="registration">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-sm-offset-3">
          <form ng-submit="registration.registrate()" name="registrationForm" ng-if="registration.showForm">
            <img class="image-responsive" src="images/logo_white.svg" />
            <h4>{{'NEW_PASSWORD_DIALOG' | translate}}</h4>
            <div class="form-group" ng-class="{
              'has-error': loginForm.email.$error.email
            }">
              <input class="form-control" type="password" ng-model="registration.user.password" name="password" placeholder="{{'PASSWORD' | translate}}" required />
            </div>
            <div class="form-group">
              <input class="form-control" type="password" ng-model="registration.checkPassword" placeholder="{{'PASSWORD_REPEAT' | translate}}" required />
            </div>
            <div class="alert alert-danger" ng-if="registration.error">
              {{'MESSAGE_PASSWORDS_DO_NOT_MATCH' | translate}}
            </div>
            <div class="alert alert-danger" ng-if="registration.passwordError">
              {{'PASSWORD_SHORT' | translate}}
            </div>
            <div class="alert alert-danger" ng-if="registration.tokenInvalidPass">
              <p>{{'MESSAGE_TOKEN_INVALID' | translate}}<a href="#" ui-sref="forgotPassword">{{'HERE' | translate}}</a></p>
            </div>
            <div class="alert alert-success" role="alert" ng-if="registration.changeSuccess">
              <h4 class="alert-heading">{{'MESSAGE_PASSWORDS_CHANGED' | translate}}</h4>
              <p>{{'MESSAGE_PASSWORDS_CHANGED_REDIRECT' | translate}}</p>
            </div>
            <div class="form-group">
              <input class="btn btn-primary" type="submit" value="{{'SUBMIT_PASSWORD' | translate}}" ng-disabled="registrationForm.$invalid" />
            </div>
          </form>
          <div class="alert alert-danger noToken" role="alert" ng-if="registration.tokenInvalid">
            <h4 class="alert-heading">{{'TOKEN_INVALID_ERROR' | translate}}</h4>
            <p>{{'MESSAGE_TOKEN_INVALID' | translate}} <a href="#" ui-sref="forgotPassword">{{'HERE' | translate}}</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div ng-include src="'views/footer.tpl.html'"></div>
</div>

(function () {
  'use strict';

  class BeaconsEditCtrl {
    constructor(beacon) {
      let vm = this;
      vm.beacon = beacon;
      vm.newBeacon = !beacon;
    }
  }

  BeaconsEditCtrl.$inject = ['beacon'];

  /**
   * @ngdoc object
   * @name parcels.edit.beacons.controller:BeaconsEditCtrl
   *
   * @description
   *
   */
  angular
    .module('parcels.edit.beacons')
    .controller('BeaconsEditCtrl', BeaconsEditCtrl);
}());

<ng-form name="parcelFertilizationForm"
         style="display: block;">
    <!-- Period selection -->
    <div class="well">
        <div ng-class="{
      'input-group': (!edit.fertilization.create && edit.fertilization.permissions.create) || edit.fertilization.create || edit.fertilization.permissions.delete
    }">
            <select class="form-control"
                    ng-options="period as period.label for period in edit.fertilization.periods"
                    ng-model="edit.fertilization.period"
                    name="period"
                    ng-disabled="!edit.fertilization.periods.length || parcelFertilizationDataForm.$dirty || edit.fertilization.create"
                    required>
                <option value=""
                        ng-show="!edit.fertilization.periods.length">
                    {{'FERTILIZATION_PARCEL_FERTILIZATION_YEAR_NONE' | translate}}
                </option>
            </select>
            <div class="input-group-btn"
                 ng-if="(!edit.fertilization.create && edit.fertilization.permissions.create) || edit.fertilization.create || edit.fertilization.permissions.delete">
                <button class="btn btn-default"
                        ng-click="edit.newFertilizationPeriod()"
                        ng-disabled="parcelFertilizationDataForm.$dirty"
                        ng-if="!edit.fertilization.create && edit.fertilization.permissions.create">
                    {{'FERTILIZATION_PARCEL_FERTILIZATION_YEAR_NEW' | translate}}
                </button>
                <button class="btn btn-default"
                        ng-click="edit.copyFertilizationPeriod()"
                        ng-if="!edit.fertilization.create && edit.fertilization.permissions.create && edit.fertilization.period.id">
                    <span class="glyphicon glyphicon-copy"></span>
                </button>
                <button class="btn btn-default"
                        ng-click="edit.deleteFertilizationPeriod()"
                        ng-if="!edit.fertilization.create && edit.fertilization.permissions.delete && edit.fertilization.periods.length">
                    <span class="glyphicon glyphicon-trash"></span>
                </button>
                <button class="btn btn-default"
                        ng-click="edit.newFertilizationPeriodCancel()"
                        ng-if="edit.fertilization.create">
                    <span class="glyphicon glyphicon-remove"></span>
                </button>
            </div>
        </div>
    </div>

    <div class="alert alert-info"
         ng-if="parcelFertilizationDataForm.$dirty">
        {{'FERTILIZATION_PARCEL_FERTILIZATION_YEAR_LOCKED_INFO' | translate}}
    </div>

    <ng-form name="parcelFertilizationDataForm">
        <uib-accordion ng-if="edit.fertilization.period && edit.fertilization.periods.length">
            <div uib-accordion-group
                 class="panel-default"
                 is-open="true">
                <uib-accordion-heading>
          <span ng-class="{
            'text-danger': parcelFertilizationYearPeriodForm.$invalid
          }">{{'FERTILIZATION_PARCEL_FERTILIZATION_YEAR_PERIOD' | translate}}</span>
                    <span class="glyphicon glyphicon-warning-sign pull-right text-danger"
                          ng-if="parcelFertilizationYearPeriodForm.$invalid"></span>
                </uib-accordion-heading>
                <ng-form name="parcelFertilizationYearPeriodForm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group"
                                 ng-class="{
                     'has-error': parcelFertilizationYearPeriodForm.period.$invalid
                   }">
                                <label class="control-label soft-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_YEAR_FROM' | translate}}</label>
                                <input type="hidden"
                                       ng-model="edit.fertilization.periodDummyModel"
                                       name="period"
                                       ui-validate="{
                         overlap: 'edit.validatePeriod()'
                       }"
                                       ui-validate-watch="'edit.fertilization.period'"
                                       ui-validate-watch-object-equality="true"/>
                                <div class="input-group">
                                    <input class="form-control"
                                           uib-datepicker-popup="dd.MM.yyyy"
                                           ng-model="edit.fertilization.period.periodStart"
                                           ng-change="edit.fertilization.period.periodStartMoment = edit.fertilization.period.periodStart | amUtc"
                                           readonly="readonly"
                                           is-open="edit.fertilization.period.periodStartOpen"
                                           type="text"
                                           name="periodStart"
                                           datepicker-options="edit.periodStartOptions"
                                           required
                                           show-button-bar="false"/>
                                    <div class="input-group-btn">
                                        <button class="btn btn-default"
                                                ng-click="edit.fertilization.period.periodStartOpen = !edit.fertilization.period.periodStartOpen"
                                                ng-disabled="(!edit.fertilization.permissions.create && edit.fertilization.create) || (!edit.fertilization.permissions.edit && !edit.fertilization.create)">
                                            <span class="glyphicon glyphicon-calendar"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group"
                                 ng-class="{
                     'has-error': parcelFertilizationYearPeriodForm.period.$invalid
                   }">
                                <label class="control-label soft-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_YEAR_TO' | translate}}</label>
                                <div class="input-group">
                                    <input class="form-control"
                                           uib-datepicker-popup="dd.MM.yyyy"
                                           ng-model="edit.fertilization.period.periodEnd"
                                           ng-change="edit.fertilization.period.periodEndMoment = edit.fertilization.period.periodEnd | amUtc"
                                           readonly="readonly"
                                           is-open="edit.fertilization.period.periodEndOpen"
                                           type="text"
                                           name="periodEnd"
                                           datepicker-options="edit.periodEndOptions"
                                           required
                                           show-button-bar="false"/>
                                    <div class="input-group-btn">
                                        <button class="btn btn-default"
                                                ng-click="edit.fertilization.period.periodEndOpen = !edit.fertilization.period.periodEndOpen"
                                                ng-disabled="(!edit.fertilization.permissions.create && edit.fertilization.create) || (!edit.fertilization.permissions.edit && !edit.fertilization.create)">
                                            <span class="glyphicon glyphicon-calendar"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-danger"
                         ng-if="parcelFertilizationYearPeriodForm.$invalid && !edit.dateIgnore">
                        {{'FERTILIZATION_PARCEL_FERTILIZATION_YEAR_PERIOD_OVERLAP' | translate}}
                    </div>
                </ng-form>
            </div>
            <div uib-accordion-group
                 class="panel-default">
                <uib-accordion-heading>
          <span ng-class="{
            'text-danger': parcelFertilizationYieldGrowthForm.$invalid
          }">{{'FERTILIZATION_PARCEL_FERTILIZATION_YIELD_GROWTH' | translate}}</span>
                    <span class="glyphicon glyphicon-warning-sign pull-right text-danger"
                          ng-if="parcelFertilizationYieldGrowthForm.$invalid"></span>
                </uib-accordion-heading>
                <ng-form name="parcelFertilizationYieldGrowthForm">
                    <div class="row">
                        <div class="col-sm-6">
                            <!-- Yield -->
                            <div class="form-group"
                                 ng-class="{
                     'has-error': parcelFertilizationYieldGrowthForm.yield.$invalid
                   }">
                                <label class="control-label soft-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_YIELD' | translate}}</label>
                                <div class="input-group">
                                    <input class="form-control"
                                           ng-model="edit.fertilization.period.yield"
                                           min="0"
                                           type="number"
                                           pattern="^\d{1,3}(?:[,\.]\d{1,4})?$"
                                           required
                                           ng-disabled="(!edit.fertilization.permissions.create && edit.fertilization.create) || (!edit.fertilization.permissions.edit && !edit.fertilization.create)"
                                           name="yield"/>
                                    <span class="input-group-addon">{{'FERTILIZATION_PARCEL_FERTILIZATION_YIELD_UNIT' | translate}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <!-- Growth -->
                            <div class="form-group"
                                 ng-class="{
                     'has-error': parcelFertilizationYieldGrowthForm.growth.$invalid
                   }">
                                <label class="control-label soft-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_GROWTH' | translate}}</label>
                                <select class="form-control"
                                        ng-model="edit.fertilization.period.growth"
                                        name="growth"
                                        ng-disabled="(!edit.fertilization.permissions.create && edit.fertilization.create) || (!edit.fertilization.permissions.edit && !edit.fertilization.create)"
                                        required>
                                    <option value=""
                                            ng-if="!edit.fertilization.period.growth">{{'MAKE_CHOICE' | translate}}
                                    </option>
                                    <option value="weak">
                                        {{'FERTILIZATION_PARCEL_FERTILIZATION_GROWTH_WEAK' | translate}}
                                    </option>
                                    <option value="medium">
                                        {{'FERTILIZATION_PARCEL_FERTILIZATION_GROWTH_MEDIUM' | translate}}
                                    </option>
                                    <option value="strong">
                                        {{'FERTILIZATION_PARCEL_FERTILIZATION_GROWTH_STRONG' | translate}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </ng-form>
            </div>
            <div uib-accordion-group class="panel-default">
                <uib-accordion-heading>
          <span ng-class="{
            'text-danger': parcelFertilizationSoilDeterminationForm.$invalid
          }">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_DETERMINATION' | translate}}</span>
                    <span class="glyphicon glyphicon-warning-sign pull-right text-danger"
                          ng-if="parcelFertilizationSoilDeterminationForm.$invalid"></span>
                </uib-accordion-heading>
                <ng-form name="parcelFertilizationSoilDeterminationForm">
                    <!-- Soil Analysis Year -->
                    <div class="form-group"
                         ng-class="{
                     'has-error': parcelFertilizationSoilDeterminationForm.periodSoilAnalysisYear.$invalid
                   }">
                        <label class="control-label soft-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_ANALYSIS_YEAR' | translate}}</label>
                        <div class="input-group">
                            <input class="form-control"
                                   uib-datepicker-popup="yyyy"
                                   ng-model="edit.fertilization.period.soilAnalysisYear"
                                   ng-change="edit.fertilization.period.soilAnalysisYearMoment = edit.fertilization.period.soilAnalysisYear | amUtc"
                                   readonly="readonly"
                                   is-open="edit.fertilization.period.soilAnalysisYearOpen"
                                   datepicker-options="edit.soilAnalysisYearOptions"
                                   type="text"
                                   name="periodSoilAnalysisYear"
                                   required
                                   show-button-bar="false"/>
                            <div class="input-group-btn">
                                <button class="btn btn-default"
                                        ng-click="edit.fertilization.period.soilAnalysisYearOpen = !edit.fertilization.period.soilAnalysisYearOpen">
                                    <span class="glyphicon glyphicon-calendar"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- Soil Type -->
                    <div class="form-group"
                         ng-class="{
                 'has-error': parcelFertilizationSoilDeterminationForm.soilType.$invalid
               }">
                        <label class="control-label soft-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_TYPE' | translate}}</label>
                        <select class="form-control"
                                ng-model="edit.fertilization.period.soilType"
                                name="soilType"
                                ng-disabled="(!edit.fertilization.permissions.create && edit.fertilization.create) || (!edit.fertilization.permissions.edit && !edit.fertilization.create)"
                                required>
                            <option value=""
                                    ng-if="!edit.fertilization.period.soilType">{{'MAKE_CHOICE' | translate}}
                            </option>
                            <option value="S">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_TYPE_S' | translate}}</option>
                            <option value="I'S">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_TYPE_I_S' | translate}}
                            </option>
                            <option value="IS">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_TYPE_IS' | translate}}
                            </option>
                            <option value="sL">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_TYPE_SL' | translate}}
                            </option>
                            <option value="uL">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_TYPE_UL' | translate}}
                            </option>
                            <option value="t'L">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_TYPE_T_L' | translate}}
                            </option>
                            <option value="tL">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_TYPE_TL' | translate}}
                            </option>
                            <option value="IT">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_TYPE_IT' | translate}}
                            </option>
                            <option value="T">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_TYPE_T' | translate}}</option>
                        </select>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <!-- Humus -->
                            <div class="form-group"
                                 ng-class="{
                     'has-error': parcelFertilizationSoilDeterminationForm.humusPercentage.$invalid
                   }">
                                <label class="control-label soft-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_HUMUS' | translate}}</label>
                                <div class="input-group">
                                    <input class="form-control"
                                           type="number"
                                           max="100"
                                           min="0"
                                           ng-model="edit.fertilization.period.humusPercentage"
                                           name="humusPercentage"
                                           pattern="^\d{1,3}(?:[,\.]\d{1,4})?$"
                                           ng-disabled="(!edit.fertilization.permissions.create && edit.fertilization.create) || (!edit.fertilization.permissions.edit && !edit.fertilization.create)"
                                           required/>
                                    <span class="input-group-addon">{{'FERTILIZATION_PARCEL_FERTILIZATION_HUMUS_UNIT' | translate}}</span>
                                </div>
                                <span class="help-block"
                                      ng-messages="parcelFertilizationForm.humusPercentage.$error">
                  <span ng-message="pattern">{{'FERTILIZATION_PARCEL_FERTILIZATION_HUMUS_PATTERN' | translate}}</span>
                </span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <!-- Stone -->
                            <div class="form-group"
                                 ng-class="{
                     'has-error': parcelFertilizationSoilDeterminationForm.stonePercentage.$invalid
                   }">
                                <label class="control-label soft-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_STONE' | translate}}</label>
                                <div class="input-group">
                                    <input class="form-control"
                                           type="number"
                                           max="100"
                                           min="0"
                                           ng-model="edit.fertilization.period.stonePercentage"
                                           name="stonePercentage"
                                           pattern="^\d{1,3}(?:[,\.]\d{1,4})?$"
                                           ng-disabled="(!edit.fertilization.permissions.create && edit.fertilization.create) || (!edit.fertilization.permissions.edit && !edit.fertilization.create)"
                                           required/>
                                    <span class="input-group-addon">{{'FERTILIZATION_PARCEL_FERTILIZATION_STONE_UNIT' | translate}}</span>
                                </div>
                                <span class="help-block"
                                      ng-messages="parcelFertilizationForm.stonePercentage.$error">
                  <span ng-message="pattern">{{'FERTILIZATION_PARCEL_FERTILIZATION_STONE_PATTERN' | translate}}</span>
                </span>
                            </div>
                        </div>
                    </div>
                </ng-form>
            </div>
            <div uib-accordion-group class="panel-default">
                <uib-accordion-heading>
                    {{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_MANAGEMENT' | translate}}
                </uib-accordion-heading>
                <div class="form-group"
                     ng-repeat="smGroup in [[1,2,3,4,5,6],[7,8,9],[10],[11]] track by $index">
                    <label class="control-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_MANAGEMENT_HEADLINE_' + $index | translate}}</label>
                    <div class="row">
                        <div class="col-sm-6"
                             ng-repeat="sm in smGroup track by $index">
                            <div class="form-group">
                                <label class="control-label soft-label"
                                       ng-if="smGroup.length > 1">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_MANAGEMENT_' + sm | translate}}</label>
                                <select class="form-control"
                                        ng-model="edit.fertilization.period['soilManagement' + sm]"
                                        name="soilManagement{{sm}}"
                                        ng-disabled="(!edit.fertilization.permissions.create && edit.fertilization.create) || (!edit.fertilization.permissions.edit && !edit.fertilization.create)">
                                    <option value="">
                                        {{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_MANAGEMENT_NONE' | translate}}
                                    </option>
                                    <option value="secondRow">
                                        {{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_MANAGEMENT_SECONDROW' | translate}}
                                    </option>
                                    <option value="everyRow">
                                        {{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_MANAGEMENT_EVERYROW' | translate}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div uib-accordion-group class="panel-default">
                <uib-accordion-heading>
          <span ng-class="{
            'text-danger': parcelFertilizationSoilValuesForm.$invalid
          }">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_VALUES' | translate}}</span>
                    <span class="glyphicon glyphicon-warning-sign pull-right text-danger"
                          ng-if="parcelFertilizationSoilValuesForm.$invalid"></span>
                </uib-accordion-heading>
                <ng-form name="parcelFertilizationSoilValuesForm">
                    <div class="form-group"
                         ng-class="{
                 'has-error': parcelFertilizationSoilValuesForm.phosphateContent.$invalid
               }">
                        <label class="control-label soft-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_VALUES_PHOSPHATE' | translate}}</label>
                        <div class="input-group">
                            <input class="form-control"
                                   type="number"
                                   max="100000"
                                   min="0"
                                   ng-model="edit.fertilization.period.phosphateContent"
                                   name="phosphateContent"
                                   pattern="^\d{1,6}(?:[,\.]\d{1,4})?$"
                                   ng-disabled="(!edit.fertilization.permissions.create && edit.fertilization.create) || (!edit.fertilization.permissions.edit && !edit.fertilization.create)"
                                   required/>
                            <span class="input-group-addon">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_VALUES_PHOSPHATE_UNIT' | translate}}</span>
                        </div>
                        <span class="help-block"
                              ng-messages="parcelFertilizationSoilValuesForm.phosphateContent.$error">
              <span ng-message="pattern">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_VALUES_PHOSPHATE_PATTERN' | translate}}</span>
            </span>
                    </div>
                    <hr/>
                    <div class="row"
                         ng-repeat="chemicalPair in [['boric', 'copper'], ['iron', 'magnesium'], ['manganese', 'potassium'], ['sulfur', 'zinc']] track by $index">
                        <div class="col-sm-6"
                             ng-repeat="chemical in chemicalPair track by $index">
                            <div class="form-group"
                                 ng-class="{
                     'has-error': parcelFertilizationSoilValuesForm[chemical + 'Content'].$invalid
                   }">
                                <label class="control-label soft-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_VALUES_' + (chemical | uppercase) | translate}}</label>
                                <div class="input-group">
                                    <input class="form-control"
                                           type="number"
                                           max="100000"
                                           ng-model="edit.fertilization.period[chemical + 'Content']"
                                           name="{{chemical}}Content"
                                           pattern="^\d{1,6}(?:[,\.]\d{1,4})?$"
                                           ng-disabled="(!edit.fertilization.permissions.create && edit.fertilization.create) || (!edit.fertilization.permissions.edit && !edit.fertilization.create)"/>
                                    <span class="input-group-addon">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_VALUES_' + (chemical | uppercase) + '_UNIT' | translate}}</span>
                                </div>
                                <span class="help-block"
                                      ng-messages="parcelFertilizationSoilValuesForm[chemical + 'Content'].$error">
                  <span ng-message="pattern">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_VALUES_' + (chemical | uppercase) + '_PATTERN' | translate}}</span>
                </span>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group"
                                 ng-class="{
                     'has-error': parcelFertilizationSoilValuesForm.nitrogenPercentage.$invalid
                   }">
                                <label class="control-label soft-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_VALUES_NITROGEN' | translate}}</label>
                                <div class="input-group">
                                    <input class="form-control"
                                           type="number"
                                           max="100"
                                           min="0"
                                           ng-model="edit.fertilization.period.nitrogenPercentage"
                                           name="nitrogenPercentage"
                                           pattern="^\d{1,3}(?:[,\.]\d{1,4})?$"
                                           ng-disabled="(!edit.fertilization.permissions.create && edit.fertilization.create) || (!edit.fertilization.permissions.edit && !edit.fertilization.create)"/>
                                    <span class="input-group-addon">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_VALUES_NITROGEN_UNIT' | translate}}</span>
                                </div>
                                <span class="help-block"
                                      ng-messages="parcelFertilizationSoilValuesForm.nitrogenPercentage.$error">
                  <span ng-message="pattern">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_VALUES_NITROGEN_PATTERN' | translate}}</span>
                </span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group"
                                 ng-class="{
                     'has-error': parcelFertilizationSoilValuesForm.carbonateNitrogenRatio.$invalid
                   }">
                                <label class="control-label soft-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_VALUES_CARBON_NITRON_RATIO' | translate}}</label>
                                <div class="input-group">
                                    <input class="form-control"
                                           type="number"
                                           ng-model="edit.fertilization.period.carbonateNitrogenRatio"
                                           name="carbonateNitrogenRatio"
                                           pattern="^\d{1,6}(?:[,\.]\d{1,4})?$"
                                           ng-disabled="(!edit.fertilization.permissions.create && edit.fertilization.create) || (!edit.fertilization.permissions.edit && !edit.fertilization.create)"/>
                                    <span class="input-group-addon">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_VALUES_CARBON_NITRON_RATIO_UNIT' | translate}}</span>
                                </div>
                                <span class="help-block"
                                      ng-messages="parcelFertilizationSoilValuesForm.carbonateNitrogenRatio.$error">
                  <span ng-message="pattern">{{'FERTILIZATION_PARCEL_FERTILIZATION_SOIL_VALUES_CARBON_NITRON_RATIO_UNIT_PATTERN' | translate}}</span>
                </span>
                            </div>
                        </div>
                    </div>
                </ng-form>
            </div>
            <div uib-accordion-group class="panel-default">
                <uib-accordion-heading>
          <span ng-class="{
            'text-danger': parcelFertilizationPhValuesForm.$invalid
          }">{{'FERTILIZATION_PARCEL_FERTILIZATION_PH_VALUES' | translate}}</span>
                    <span class="glyphicon glyphicon-warning-sign pull-right text-danger"
                          ng-if="parcelFertilizationPhValuesForm.$invalid"></span>
                </uib-accordion-heading>
                <ng-form name="parcelFertilizationPhValuesForm">
                    <div class="form-group"
                         ng-class="{
                 'has-error': parcelFertilizationPhValuesForm.phValue.$invalid
               }">
                        <label class="control-label soft-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_PH' | translate}}</label>
                        <div class="input-group">
                            <input class="form-control"
                                   type="number"
                                   min="0"
                                   max="14"
                                   pattern="^\d{1,6}(?:[,\.]\d{1,4})?$"
                                   ng-model="edit.fertilization.period.phValue"
                                   name="phValue"
                                   ng-disabled="(!edit.fertilization.permissions.create && edit.fertilization.create) || (!edit.fertilization.permissions.edit && !edit.fertilization.create)"/>
                            <span class="input-group-addon">{{'FERTILIZATION_PARCEL_FERTILIZATION_PH_UNIT' | translate}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group"
                                 ng-class="{
                     'has-error': parcelFertilizationPhValuesForm.targetPhValue.$invalid
                   }">
                                <label class="control-label soft-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_TARGET_PH' | translate}}</label>
                                <div class="input-group">
                                    <input class="form-control"
                                           type="number"
                                           min="0"
                                           max="14"
                                           pattern="^\d{1,6}(?:[,\.]\d{1,4})?$"
                                           ng-model="edit.fertilization.period.targetPhValue"
                                           name="targetPhValue"
                                           ng-disabled="(!edit.fertilization.permissions.create && edit.fertilization.create) || (!edit.fertilization.permissions.edit && !edit.fertilization.create)"/>
                                    <span class="input-group-addon">{{'FERTILIZATION_PARCEL_FERTILIZATION_TARGET_PH_UNIT' | translate}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group"
                                 ng-class="{
                     'has-error': parcelFertilizationPhValuesForm.phAcetate.$invalid
                   }">
                                <label class="control-label soft-label">{{'FERTILIZATION_PARCEL_FERTILIZATION_PH_ACETATE' | translate}}</label>
                                <div class="input-group">
                                    <input class="form-control"
                                           type="number"
                                           ng-model="edit.fertilization.period.phAcetate"
                                           name="phAcetate"
                                           pattern="^\d{1,6}(?:[,\.]\d{1,4})?$"
                                           ng-disabled="(!edit.fertilization.permissions.create && edit.fertilization.create) || (!edit.fertilization.permissions.edit && !edit.fertilization.create)"/>
                                    <span class="input-group-addon">{{'FERTILIZATION_PARCEL_FERTILIZATION_PH_ACETATE_UNIT' | translate}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-form>
            </div>
        </uib-accordion>
    </ng-form>
</ng-form>

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name vcMain.factory:BatchEditingHelper
   *
   * @description
   *
   */
  angular
    .module('vcMain')
    .factory('BatchEditingHelper', BatchEditingHelper);

  BatchEditingHelper.$inject = ['$translate'];

  function BatchEditingHelper($translate) {
    let BatchEditingHelperBase = {};
    BatchEditingHelperBase.getTranslatedOptions = getTranslatedOptions;
    BatchEditingHelperBase.getTranslatedCheckboxOptions = getTranslatedCheckboxOptions;
    BatchEditingHelperBase.getOptions = getOptions;

    function getTranslatedOptions(labels, translateBoth) {
      let options = [];
      labels.forEach(label => {
        options.push({label: $translate.instant(label), value: translateBoth ? $translate.instant(label) : label});
      });
      return options;
    }

    function getTranslatedCheckboxOptions() {
        let options = [];
        options.push({label: $translate.instant('YES'), value: true});
        options.push({label: $translate.instant('NO'), value: false});
        return options;
    }

    function getOptions(objects) {
      let options = [];
      objects.forEach(object => {
        options.push({label: object.label, value: object.id});
      });
      return options;
    }

    return BatchEditingHelperBase;
  }
}());

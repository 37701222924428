<sub-menu items="list.submenu.items"></sub-menu>
<main-menu></main-menu>

<div class="business-list base-layout">
    <div class="base-layout-default">
        <main-message message="list.message" fluid="'nested'"></main-message>
        <div class="table-responsive" ng-if="list.businesses.length">
            <table class="table table-hover">
                <thead>
                <th ng-click="list.sort('consecutiveNumber')">
                    #
                    <span class="glyphicon pull-right text-muted"
                          ng-if="list.sorter === 'consecutiveNumber'"
                          ng-class="{
                    'glyphicon-chevron-down': list.dir === 'asc',
                    'glyphicon-chevron-up': list.dir === 'desc'
                  }"></span>
                </th>
                <th ng-click="list.sort('label')">
                    {{'WINERY_MANAGEMENT_WINERIES_LABEL' | translate}}
                    <span class="glyphicon pull-right text-muted"
                          ng-if="list.sorter === 'label'"
                          ng-class="{
                    'glyphicon-chevron-down': list.dir === 'asc',
                    'glyphicon-chevron-up': list.dir === 'desc'
                  }"></span>
                </th>
                <th>{{'basicTerms.edit' | translate}}</th>
                <th ng-if="list.canDelete">{{'DELETE' | translate}}</th>
                </thead>
                <tbody>
                <tr ng-repeat="business in list.businesses | filter : list.filter | orderBy : list.sorter : list.dir === 'desc'">
                    <td>{{business.consecutiveNumber}}</td>
                    <td ng-if="list.hasCorrectFactsheet()" ui-sref="factsheet({id: business.id})">{{business.label}}</td>
                    <td ng-if="!list.hasCorrectFactsheet()">{{business.label}}</td>
                    <td class="shrink text-center">
                        <button class="btn btn-link"
                                ui-sref="businesses.edit({
                id: business.id
              })">
                            <span class="glyphicon glyphicon-pencil"></span>
                        </button>
                    </td>
                    <td ng-if="list.canDelete"
                        class="shrink text-center">
                        <button class="btn btn-link"
                                ng-click="$event.stopPropagation(); list.delete(business)">
                            <span class="glyphicon glyphicon-trash"></span>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

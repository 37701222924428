<div class="panel panel-primary">
  <div class="panel-heading">{{'DASHBOARD_COMMENTS' | translate}}</div>
  <table class="table table-striped table-bordered table-hover">
    <tr>
      <th>{{'DASHBOARD_COMMENT' | translate}}</th>
      <th>{{'DASHBOARD_DATE' | translate}}</th>
      <th>{{'DASHBOARD_COMMENT_USER' | translate}}</th>
    </tr>
    <tr ng-repeat="comment in commentPanel.comments">
      <td class="truncateText"><span>{{comment.description}}</span></td>
      <td>{{comment.timestamp | date:'HH:mm - dd.MM.yyyy'}}</td>
      <td>{{comment.user.firstname}} {{comment.user.lastname}}</td>
    </tr>
  </table>
</div>
<a ui-sref="comments" class="pull-right">{{'DASHBOARD_ALL_COMMENTS' | translate}}</a>
(function () {
  'use strict';

  class UsersListCtrl {
    constructor($state, $stateParams, $uibModal, UserService, CrudApi, permissions, $translate, $http) {
      let vm = this;

      // Initialize some variables
      vm.itemsPerPage = 20;
      vm.maxSize = 10;
      vm.page = $stateParams.page || 1;
      vm.sorter = $stateParams.sorter || 'firstname';
      vm.dir = $stateParams.dir || 'asc';
      vm.filter = $stateParams.filter || '';
      vm.message = $stateParams.message;
      vm.canDelete = UserService.hasPerm(permissions.BASE_CAN_DELETE_USER);
      vm.accountId = UserService.user.accountId;
      vm.jobListings = [];
      vm.hasAdvertisement = UserService.user.accountUuid === '68d24d71-7357-4791-a5ce-8b184b9da7ff';

      // Api Call
      vm.items = CrudApi.query({
        extended: true,
        active: true,
        entity: 'users',
        sortBy: vm.sorter,
        sortDir: vm.dir,
        filter: $stateParams.filter,
        page: vm.page,
        perPage: vm.itemsPerPage
      }, (data, headers, status) => {
        vm.total = headers()['x-total-count'];
        if (status === 204) {
          vm.message = 'noData';
        }
      }, () => {
        vm.message = 'loadingError';
      });

      $http.get('https://jovinbo.com/wp-json/wp/v2/job-listings/', {
          headers: {
              IGNORE_INTERCEPTOR: true
          }}).then(function (response) {
           const res = response.data.filter(e => e.status === 'publish');
           (res || []).slice(0, 6).forEach(job => {
               vm.jobListings.push({
                   link: job.link,
                   title: ('' + job.title.rendered).replace('&#8211;', ' - ')
               });
           });
      });

      // Add submenu items
      vm.submenu = {
        items: [{
          type: 'button',
          text: 'BUTTON_ADD_USERS',
          hide: !UserService.canAccessState('users.create'),
          click: () => {
            $state.go('users.create');
          }
        }, {
          type: 'search',
          model: vm.filter,
          position: 'right',
          change: (filter) => {
            $state.go($state.current.name, {
              page: 1,
              filter: filter,
              message: null
            }, {
              reload: true
            });
          }
        }]
      };

      // Pagination method
      vm.paginate = () => {
        $state.go($state.current.name, {
          page: vm.page,
          message: null
        });
      };

      // Sorting method
      vm.sort = (sorter) => {
        $state.go($state.current.name, {
          sorter: sorter,
          dir: vm.dir === 'desc' ? 'asc' : 'desc',
          message: null
        });
      };

        vm.createGPSTemplate = (user) => {
            const gpsForeground = $translate.instant('USER_GPS_PERMISSION_FOREGROUND')
            const gpsBackground = $translate.instant('USER_GPS_PERMISSION_BACKGROUND')
            const yes = $translate.instant('YES')
            const no = $translate.instant('NO')
            let template = '<div>';
            template += ('<p>' + gpsForeground + ': ' + (user.foreground ? yes : no) + '</p>')
            template += ('<p>' + gpsBackground + ': ' + (user.background ? yes : no) + '</p>')
            template += '</div>';
            return template;
        };

      // Delete Popup
      vm.delete = (item) => {
        let popup = $uibModal.open({
          controller: 'UsersDeleteCtrl',
          controllerAs: 'UsersDelete',
          templateUrl: 'users/delete/views/delete.tpl.html',
          resolve: {
            item: () => {
              return item;
            }
          }
        });
        popup.result.then(() => {
          CrudApi.delete({
            entity: 'users',
            id: item.id,
            lastModified: item.lastModified
          }, () => {
            // Calculate Which page to go after deleting record
            let pageToGo = 1,
                pageToGoCalculate = (vm.total - 1) / vm.itemsPerPage;

            if (pageToGoCalculate % 1 === 0) {
              pageToGo = vm.page - 1;
            } else {
              pageToGo = vm.page;
            }

            // stay on same page if it is not the last one
            if (vm.total / vm.itemsPerPage > vm.page) {
              pageToGo = vm.page;
            }

            $state.go($state.current.name, {
              message: 'deletingSuccess',
              page: pageToGo
            }, {
              reload: true
            });
          }, (response) => {
            if (response.status === 410) {
              vm.message = 'entityHasChanged';
            } else {
              vm.message = 'deletingError';
            }
          });
        });
      };
    }
  }

  UsersListCtrl.$inject = ['$state', '$stateParams', '$uibModal', 'UserService', 'CrudApi', 'permissions', '$translate', '$http'];

  /**
   * @ngdoc object
   * @name users.list.controller:UsersListCtrl
   *
   * @description
   *
   */
  angular
    .module('users.list')
    .controller('UsersListCtrl', UsersListCtrl);
}());

(function () {
  'use strict';

  class SettingsEditCtrl {
    constructor($stateParams, UserService, permissions, $state, CrudApi, $q, $http) {
      let vm = this,
          promises = {};

      vm.tenantConfiguration = {};

      vm.message = $stateParams.message;
      vm.canEdit = true;
      vm.hasPlantProtectionModule = UserService.hasPerm(permissions.PLANT_PROTECTION_CAN_USE);

      // Handle Ceres Api
      vm.canUseCeresApi = UserService.hasPerm(permissions.PLANT_PROTECTION_CAN_USE_CERES_API);

      promises.tenantConfiguration = CrudApi.get({
          entity: 'customers',
          subentity: 'configuration'
      }).$promise;

      $q.all(promises).then(({tenantConfiguration}) => {
        if (tenantConfiguration) {
            vm.tenantConfiguration = tenantConfiguration;
        }
      });

      // Add items to submenu
      if (vm.canEdit) {
        vm.submenu = {
          items: [{
            type: 'button',
            text: 'BUTTON_SAVE',
            click: () => {
              CrudApi.save({
                  entity: 'customers',
                  subentity: 'configuration'
              }, vm.tenantConfiguration, () => {
                  $state.go('settings', {
                      message: 'savingSuccess'
                  }, {
                      reload: true
                  }, (response) => {
                      if (response.status === 410) {
                          vm.message = 'entityHasChanged';
                      } else {
                          vm.message = 'savingError';
                      }
                  });
              });
            }
          }]
        };
      }
    }
  }

  SettingsEditCtrl.$inject = ['$stateParams', 'UserService', 'permissions', '$state', 'CrudApi', '$q', '$http'];

  /**
   * @ngdoc object
   * @name settings.controller:SettingsEditCtrl
   *
   * @description
   *
   */
  angular
    .module('settings')
    .controller('SettingsEditCtrl', SettingsEditCtrl);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name poi.factory:PoiHelper
   *
   * @description
   *
   */
  angular
    .module('poi')
    .factory('PoiHelper', PoiHelper);

  PoiHelper.$inject = ['MapHelper', 'IconHelper', '$q', 'escapeHtmlFilter', 'nl2brFilter', '$translate'];

  function PoiHelper(MapHelper, IconHelper, $q, escapeHtmlFilter, nl2brFilter, $translate) {
    let PoiHelperBase = {};
    PoiHelperBase.createIconFromPoi = (poi, disallowZoomIgnore = false) => {
      let defer = $q.defer(),
          category = '',
          name = '';
      if (angular.isObject(poi.type) && poi.type.iconCategory && poi.type.iconName) {
        category = poi.type.iconCategory;
        name = poi.type.iconName;
      }
      IconHelper.getIconDescriptor(category, name).then(descriptor => {
        let icon = PoiHelperBase.createIconFromPoiAndIconDescriptor(poi, descriptor, disallowZoomIgnore);
        defer.resolve(icon);
      }, defer.reject);
      return defer.promise;
    };
    PoiHelperBase.createIconFromPoiAndIconDescriptor = (poi, iconDescriptor, disallowZoomIgnore = false) => {
      let icon = {
        options: MapHelper.generateMarkerOptionsForPath(iconDescriptor.path, iconDescriptor.width, iconDescriptor.height, poi.color, 'transparent', {
          icon: {
            rotation: poi.rotation
          }
        }),
        infoMessage: '<h4>' + $translate.instant('POI_TYPE_CATEGORY_' + poi.type.category.toUpperCase() + '_LABEL_' + poi.type.label.toUpperCase()) + '</h4>'
      };
      if (poi.description !== '') {
        icon.infoMessage += '<p>' +
                              nl2brFilter(escapeHtmlFilter(poi.description)) +
                            '</p>';
      }
      if (angular.isDefined(poi.latitude) && angular.isDefined(poi.longitude)) {
        icon.position = {
          latitude: poi.latitude,
          longitude: poi.longitude
        };
      }
      // global pois should be ignored for zooming in general
      if (!poi.siteIds.length && !disallowZoomIgnore) {
        icon.ignoreInZoom = true;
      }
      return icon;
    };
    PoiHelperBase.getTypeCategoryTranslation = (poiType) => {
      return $translate.instant(PoiHelperBase.getTypeCategoryTranslationLabel(poiType));
    };
    PoiHelperBase.getTypeLabelTranslation = (poiType) => {
      return $translate.instant(PoiHelperBase.getTypeLabelTranslationLabel(poiType));
    };
    PoiHelperBase.getTypeCategoryTranslationLabel = (poiType) => {
      return 'POI_TYPE_CATEGORY_' + poiType.category.toUpperCase();
    };
    PoiHelperBase.getTypeLabelTranslationLabel = (poiType) => {
      return PoiHelperBase.getTypeCategoryTranslationLabel(poiType) +
              '_LABEL_' + poiType.label.toUpperCase();
    };
    return PoiHelperBase;
  }
}());

(function () {
  'use strict';

  angular
    .module('parcels.edit')
    .config(config);

  function config($stateProvider, permissions) {
    $stateProvider
      .state('parcels.edit', {
        url: '/edit/:id',
        templateUrl: 'parcels/edit/views/edit.tpl.html',
        controller: 'ParcelsEditCtrl',
        controllerAs: 'edit',
        params: {
          message: null
        }
      })
      .state('parcels.create', {
        url: '/create?businessId',
        templateUrl: 'parcels/edit/views/edit.tpl.html',
        controller: 'ParcelsEditCtrl',
        controllerAs: 'edit',
        params: {
          businessId: {
            type: 'int'
          }
        },
        data: {requiredPerms: [permissions.WINERY_MANAGEMENT_CAN_CREATE_VINEYARD]}
      });
  }
}());

<div>
    <div class="form-group"
         ng-repeat="filter in parcelsFilter.filters | filter:{show:true}:true | orderBy:'label | translate'">
        <label class="control-label">
            {{filter.label | translate}}
            <span ng-if="filter.labelPrefix"> - {{filter.labelPrefix | translate}}</span>
            <a href="#"
               ng-click="$event.preventDefault(); parcelsFilter.removeFilter(filter); parcelsFilter.updateModel()">
                <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
            </a>
        </label>
        <div ng-switch="filter.id">
            <div ng-switch-when="business" ng-init="parcelsFilter.fetchBusinesses()">
                <ui-select class="multiselect" multiple ng-model="filter.value" theme="bootstrap"
                           ng-change="parcelsFilter.updateModel()">
                    <ui-select-match placeholder="  {{'PLEASE_MAKE_CHOICE' | translate}}">
                        {{$item.label}}
                    </ui-select-match>
                    <ui-select-choices
                            repeat="business.id as business in parcelsFilter.businesses | filter: $select.search">
                        {{business.label}}
                    </ui-select-choices>
                </ui-select>
            </div>
            <div ng-switch-when="sharedBusiness" ng-init="parcelsFilter.fetchSharedBusinesses()">
                <ui-select class="multiselect" multiple ng-model="filter.value" theme="bootstrap"
                           ng-change="parcelsFilter.updateModel()">
                    <ui-select-match placeholder="  {{'PLEASE_MAKE_CHOICE' | translate}}">
                        {{$item.label}}
                    </ui-select-match>
                    <ui-select-choices
                            repeat="sharedBusiness.id as sharedBusiness in parcelsFilter.sharedBusinesses | filter: $select.search">
                        {{sharedBusiness.label}}
                    </ui-select-choices>
                </ui-select>
            </div>
            <div ng-switch-when="comment|district|region|flurNumber|label|location|note2|underlay|note1|vineTraining|note3|note4|slopeFacing|dangerCategory"
                 ng-switch-when-separator="|">
                <input class="form-control"
                       type="text"
                       ng-model="filter.value"
                       ng-change="parcelsFilter.updateModel()"/>
            </div>
            <div ng-switch-when="leased|underLeased|droughtStress|organicEligible|oidiumProne|testingGround"
                 ng-switch-when-separator="|">
                <div class="checkbox" style="margin-bottom: 15px">
                    <label hidden style="font-weight: 700">
                        <input type="checkbox"
                               ng-checked="true"
                               ng-model="filter.value"/>
                    </label>
                </div>
            </div>
            <div ng-switch-when="vine">
                <ui-select class="multiselect" multiple ng-model="filter.value" theme="bootstrap"
                           ng-change="parcelsFilter.updateModel()">
                    <ui-select-match placeholder="  {{'PLEASE_MAKE_CHOICE' | translate}}">
                        {{$item.thirdwine}}
                    </ui-select-match>
                    <ui-select-choices group-by="'grp'"
                                       repeat="wine.thirdwine as wine in parcelsFilter.wines | filter: $select.search | orderBy: ['grp', 'thirdwine']">
                        {{wine.thirdwine}}
                    </ui-select-choices>
                </ui-select>
            </div>
            <div ng-switch-when="infected">
                <select class="form-control"
                        ng-model="filter.value"
                        ng-change="parcelsFilter.updateModel()">
                    <option value="">{{'PARCELS_PARAM_INFECTED_WITH_ONONE' | translate}}</option>
                    <option>{{'PARCELS_PARAM_INFECTED_WITH_O1' | translate}}</option>
                    <option>{{'PARCELS_PARAM_INFECTED_WITH_O2' | translate}}</option>
                    <option>{{'PARCELS_PARAM_INFECTED_WITH_O3' | translate}}</option>
                    <option>{{'PARCELS_PARAM_INFECTED_WITH_O4' | translate}}</option>
                    <option>{{'PARCELS_PARAM_INFECTED_WITH_O5' | translate}}</option>
                    <option>{{'PARCELS_PARAM_INFECTED_WITH_O6' | translate}}</option>
                    <option>{{'PARCELS_PARAM_INFECTED_WITH_O7' | translate}}</option>
                </select>
            </div>
            <div ng-switch-when="area|avgStrikeLength|numberOfGrapevines|numberOfRows|laneWidth|stickDistance|bbch|slope"
                 ng-switch-when-separator="|">
                <div class="row">
                    <div class="col-xs-6">
                        <input class="form-control"
                               type="number"
                               placeholder="{{'FROM' | translate}}"
                               ng-model="filter.values.min.value"
                               ng-change="parcelsFilter.updateModel()"/>
                    </div>
                    <div class="col-xs-6">
                        <input class="form-control"
                               type="number"
                               placeholder="{{'TO' | translate}}"
                               ng-model="filter.values.max.value"
                               ng-change="parcelsFilter.updateModel()"/>
                    </div>
                </div>
            </div>
            <div ng-switch-when="clearedDate|plantedDate"
                 ng-switch-when-separator="|">
                <div class="row">
                    <div class="col-xs-6">
                        <div class="input-group">
                            <input class="form-control"
                                   placeholder="{{'FROM' | translate}}"
                                   uib-datepicker-popup="MMMM yyyy"
                                   ng-model="filter.values.min.value"
                                   ng-change="parcelsFilter.updateModel()"
                                   readonly="readonly"
                                   is-open="filter.open.from"
                                   type="text"
                                   show-button-bar="false"
                                   datepicker-options="{
                          datepickerMode: 'month',
                          minMode: 'month'
                          }"/>
                            <div class="input-group-btn">
                                <button class="btn btn-default"
                                        ng-click="filter.open.from = !filter.open.from">
                                    <span class="glyphicon glyphicon-calendar"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6">
                        <div class="input-group">
                            <input class="form-control"
                                   placeholder="{{'TO' | translate}}"
                                   uib-datepicker-popup="MMMM yyyy"
                                   ng-model="filter.values.max.value"
                                   ng-change="parcelsFilter.updateModel()"
                                   readonly="readonly"
                                   is-open="filter.open.to"
                                   type="text"
                                   show-button-bar="false"
                                   datepicker-options="{
                          datepickerMode: 'month',
                          minMode: 'month'
                          }"/>
                            <div class="input-group-btn">
                                <button class="btn btn-default"
                                        ng-click="filter.open.to = !filter.open.to">
                                    <span class="glyphicon glyphicon-calendar"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ng-switch-when="managementUnit" ng-init="parcelsFilter.fetchManagementUnits()">
                <ui-select class="multiselect" multiple ng-model="filter.value" theme="bootstrap"
                           ng-change="parcelsFilter.updateModel()">
                    <ui-select-match placeholder="  {{'PLEASE_MAKE_CHOICE' | translate}}">
                        {{$item.label}}
                    </ui-select-match>
                    <ui-select-choices
                            repeat="managementUnit.id as managementUnit in parcelsFilter.getAvailableManagementUnits($select.search)">
                        {{managementUnit.label}}
                    </ui-select-choices>
                </ui-select>
            </div>
            <div ng-switch-when="poiType" ng-init="parcelsFilter.fetchPoiTypes()">
                <ui-select class="multiselect" multiple ng-model="filter.value" theme="bootstrap"
                           ng-change="parcelsFilter.updateModel()">
                    <ui-select-match placeholder="  {{'PLEASE_MAKE_CHOICE' | translate}}">
                        {{ $item.categoryTranslated }} - {{ $item.labelTranslated }}
                    </ui-select-match>
                    <ui-select-choices
                            repeat="poiType.id as poiType in parcelsFilter.poiTypes | filter : $select.search | orderBy: ['categoryTranslated', 'labelTranslated']">
                        {{ poiType.categoryTranslated }} - {{ poiType.labelTranslated }}
                    </ui-select-choices>
                </ui-select>
            </div>
            <div ng-switch-when="costCenter" ng-init="parcelsFilter.fetchCostCenters()">
                <ui-select ng-model="filter.value" theme="bootstrap"
                           ng-change="parcelsFilter.updateModel()">
                    <ui-select-match placeholder="  {{'PLEASE_MAKE_CHOICE' | translate}}">
                        {{$select.selected.indentedLabel}}
                    </ui-select-match>
                    <ui-select-choices group-by="parcelsFilter.groupCostCenters"
                                       repeat="cc.id as cc in parcelsFilter.getAvailableCostCenters($select.search)">
                        <div ng-bind-html="cc.indentedLabel | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>
            <div ng-switch-when="nitrogenAllowance">
                <div class="row">
                    <div class="col-xs-6">
                        <input class="form-control"
                               type="number"
                               placeholder="{{'FROM' | translate}}"
                               ng-model="filter.values.min.value"
                               ng-change="parcelsFilter.updateModel()"/>
                    </div>
                    <div class="col-xs-6">
                        <input class="form-control"
                               type="number"
                               placeholder="{{'TO' | translate}}"
                               ng-model="filter.values.max.value"
                               ng-change="parcelsFilter.updateModel()"/>
                    </div>
                </div>
            </div>
            <div ng-switch-when="cultivationProgram">
                <input class="form-control"
                       type="text"
                       ng-model="filter.value"
                       ng-change="parcelsFilter.updateModel()"/>
            </div>
        </div>
    </div>
    <div class="parcel-filter-select"
         ng-if="(parcelsFilter.filters | filter:{show: false}:true).length">
        <select class="form-control"
                ng-model="parcelsFilter.newAdditionalFilter"
                ng-options="filter as ((filter.label | translate) + (filter.labelPrefix ? (' - ' + (filter.labelPrefix | translate)) : '')) for filter in parcelsFilter.filters | filter:{show: false}:true | orderBy:'label | translate'"
                ng-change="parcelsFilter.showFilter(parcelsFilter.newAdditionalFilter)">
            <option value="">{{'PARCELS_PARAM_ADD_FILTER' | translate}}</option>
        </select>
    </div>
</div>

(function () {
  'use strict';

  angular
    .module('users')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('users', {
        abstract: true,
        template: '<ui-view></ui-view>',
        url: '/users'
      });
  }
}());

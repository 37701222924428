<sub-menu items="edit.submenu.items"
          form="form"></sub-menu>
<main-menu items="edit.mainmenu.items"></main-menu>

<div class="base-layout temporary-workers-edit">
  <div class="base-layout-default">
    <main-message message="edit.message" fluid="'nested'"></main-message>
    <ng-form name="form">
      <div class="alert alert-info"
           role="alert"
           ng-if="!(edit.item.expirationDate >= edit.today)">
        {{'TEMPORARY_WORKERS_PACKAGE_EXPIRATION_HELP' | translate}}
      </div>
      <!-- NAME -->
      <div class="form-group"
           ng-class="{
             'has-error': form.name.$invalid
           }">
        <label class="control-label"
               for="name">{{'TEMPORARY_WORKER_NAME' | translate}}</label>
        <input class="form-control"
               type="text"
               name="name"
               id="name"
               ng-model="edit.item.name"
               ng-readonly="!(edit.item.expirationDate >= edit.today)" 
               required />
        <p class="help-block"
           ng-messages="form.name.$error">
          <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
        </p>
      </div>
      <!-- QUANTITY -->
      <div class="form-group"
           ng-class="{
             'has-error': form.quantity.$invalid
           }">
        <label class="control-label"
               for="name">{{'TEMPORARY_WORKER_QUANTITY' | translate}}</label>
        <input class="form-control"
               type="number"
               name="quantity"
               id="quantity"
               min="{{edit.quantity.min}}"
               max="{{edit.quantity.max}}"
               ng-model="edit.item.quantity"
               ng-readonly="edit.item.id"
               required />
        <p class="help-block"
           ng-messages="form.quantity.$error">
          <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
          <span ng-message="min">{{'FORM_HELP_NUMBER' | translate:edit.quantity}}</span>
          <span ng-message="max">{{'FORM_HELP_NUMBER' | translate:edit.quantity}}</span>
        </p>
      </div>
      <!-- MASTER USER -->
      <div class="form-group" ng-class="{
        'has-error': form.master.$invalid
      }">
        <label class="control-label">{{'MASTER_USER' | translate}}</label>
        <select class="form-control"
                ng-options="master.id as (master.lastname + ', ' + master.firstname + (!master.canDoOrders ? ' (*)' : '')) disable when !master.canDoOrders for master in edit.masters | filter : {active: true}"
                ng-model="edit.item.masterUserId"
                name="master"
                ng-readonly="!(edit.item.expirationDate >= edit.today)" 
                required>
          <option value=""
                  ng-if="!edit.item.masterUserId">{{'MAKE_CHOICE' | translate}}</option>
        </select>
        <p class="help-block">
          {{'FORM_HELP_DISABLED_USERS' | translate}}
        </p>
      </div>
      <!-- EXPIRATION DATE -->
      <div class="form-group"
           ng-class="{
             'has-error': form.expiration.$invalid
           }">
        <label class="control-label"
               for="name">{{'TEMPORARY_WORKER_EXPIRATION_DATE' | translate}}</label>
        <div>
          <div uib-datepicker
               datepicker-options="{
                 minDate: edit.today
               }" 
               class="well"
               ng-model="edit.item.expirationDate"></div>
        </div>
        <p class="help-block"
           ng-messages="form.quantity.$error">
          <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
        </p>
      </div>

      <!-- Codes -->
      <div class="form-group"
           ng-if="edit.item.id">
        <label class="control-label"
               for="name">{{'TEMPORARY_WORKERS_PACKAGE_CODES' | translate}}</label>
        <div class="form-control-static">
          <ui-view></ui-view>
        </div>
      </div>
    </ng-form>
  </div>
</div>
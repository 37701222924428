(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name orderTypes.factories:OrderTypeHelper
     *
     * @description this factory contains helper methods for working with order types
     *
     */
    angular
        .module('orderTypes')
        .factory('OrderTypeHelper', OrderTypeHelper);

    OrderTypeHelper.$inject = ['$translate'];

    function OrderTypeHelper($translate) {
        let OrderTypeHelperBase = {},
            labelTranslationKeyPrefix = 'orderType.systemType.';
        const orderTypeGroups = [
            {label: 'CANOPY_MANAGEMENT', selectable: true, permission: 'BASE.CAN_USE'},
            {label: 'SOIL_MANAGEMENT', selectable: true, permission: 'BASE.CAN_USE'},
            {label: 'PLANT_PROTECTION', selectable: false, permission: 'PLANT_PROTECTION.CAN_USE'},
            {label: 'SCOUTING', selectable: true, permission: 'BASE.CAN_USE'},
            {label: 'CLASSIFICATION', selectable: false, permission: 'BONITUR.CAN_USE'},
            {label: 'HARVEST', selectable: true, permission: 'BASE.CAN_USE'},
            {label: 'PRUNING', selectable: true, permission: 'BASE.CAN_USE'},
            {label: 'TRAINING', selectable: true, permission: 'BASE.CAN_USE'},
            {label: 'FERTILIZATION', selectable: true, permission: 'FERTILIZATION.CAN_USE'},
            {label: 'VINEYARD_REMOVAL', selectable: true, permission: 'BASE.CAN_USE'},
            {label: 'NEW_VINEYARD', selectable: true, permission: 'BASE.CAN_USE'},
            {label: 'MISCELLANEOUS', selectable: true, permission: 'BASE.CAN_USE'},
        ];
        OrderTypeHelperBase.ready = $translate.onReady;
        OrderTypeHelperBase.getLabel = getLabel;
        OrderTypeHelperBase.getTranslatedLabel = getTranslatedLabel;
        OrderTypeHelperBase.getGroupLabel = getGroupLabel;
        OrderTypeHelperBase.getTranslatedGroupLabel = getTranslatedGroupLabel;
        OrderTypeHelperBase.groupOrderTypes = groupOrderTypes;
        OrderTypeHelperBase.orderTypeGroups = orderTypeGroups;

        return OrderTypeHelperBase;

        function getLabel(orderType) {
            if (orderType.customerId !== null) {
                return orderType.label;
            }
            return getTranslatedLabel(orderType.label);
        }

        function getGroupLabel(orderType) {
            return getTranslatedGroupLabel(orderType.group);
        }

        function getTranslatedLabel(label) {
            let translationKey = labelTranslationKeyPrefix + label,
                translatedLabel = $translate.instant(translationKey);
            if (translatedLabel !== translationKey) {
                return translatedLabel;
            }
            return label;
        }

        function getTranslatedGroupLabel(groupLabel) {
            let translationKey = 'orderType.group.values.' + groupLabel,
                translatedLabel = $translate.instant(translationKey);
            if (translatedLabel !== translationKey) {
                return translatedLabel;
            }
            return groupLabel;
        }

        function groupOrderTypes(orderType) {
            return getTranslatedGroupLabel(orderType.group);
        }
    }
}());

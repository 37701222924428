(function () {
  'use strict';

  class TemporaryWorkersListCtrl {
    constructor($state, $stateParams, $uibModal, CrudApi, QrApi, FileSaver) {
      let vm = this;

      // Initialize some variables
      vm.itemsPerPage = 20;
      vm.maxSize = 10;
      vm.page = $stateParams.page || 1;
      vm.sorter = $stateParams.sorter || 'name';
      vm.dir = $stateParams.dir || 'asc';
      vm.filter = $stateParams.filter || '';
      vm.message = $stateParams.message;

      // Add submenu items
      vm.submenu = {
        items: [{
          type: 'button',
          text: 'BUTTON_ADD_TEMP_WORKER',
          click: () => {
            $state.go('temporaryWorkers.edit', {
              message: null
            });
          }
        }, {
          type: 'search',
          model: vm.filter,
          position: 'right',
          change: (filter) => {
            $state.go($state.current.name, {
              page: 1,
              filter: filter,
              message: null
            }, {
              reload: true
            });
          }
        }]
      };

      // Api Call
      vm.items = CrudApi.query({
        entity: 'temporaryworkerpackage',
        filter: $stateParams.filter,
        sortBy: vm.sorter,
        sortDir: vm.dir,
        page: vm.page,
        perPage: vm.itemsPerPage
      }, (data, headers, status) => {
        vm.total = headers()['x-total-count'];
        data.forEach((item) => {
          // TOD: For now this is working to fix the day + 1 issue but we will remove it once Florian will fix this in backend by adding Timezone Offset
          let d = new Date(item.expirationDate);
          d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
          item.expirationDate = d;

          item.expirationDate = new Date(item.expirationDate);
          item.expired = item.expirationDate < new Date();
        });
        if (status === 204) {
          vm.message = 'noData';
        }
      }, () => {
        vm.message = 'loadingError';
      });

      // Pagination method
      vm.paginate = () => {
        $state.go($state.current.name, {
          page: vm.page,
          message: null
        });
      };

      // Sorting method
      vm.sort = (sorter) => {
        $state.go($state.current.name, {
          sorter: sorter,
          dir: vm.dir === 'desc' ? 'asc' : 'desc',
          message: null
        });
      };

      // Delete Popup
      vm.delete = (item) => {
        let popup = $uibModal.open({
          controller: 'TemporaryWorkersDeleteCtrl',
          controllerAs: 'TemporaryWorkersDelete',
          templateUrl: 'temporary-workers/delete/views/delete.tpl.html',
          resolve: {
            item: () => {
              return item;
            }
          }
        });
        popup.result.then(() => {
          CrudApi.delete({
            entity: 'temporaryworkerpackage',
            id: item.id,
            lastModified: item.lastModified
          }, () => {
            // Calculate Which page to go after deleting record
            let pageToGo = 1,
                pageToGoCalculate = (vm.total - 1) / vm.itemsPerPage;

            if (pageToGoCalculate % 1 === 0) {
              pageToGo = vm.page - 1;
            } else {
              pageToGo = vm.page;
            }

            // stay on same page if it is not the last one
            if (vm.total / vm.itemsPerPage > vm.page) {
              pageToGo = vm.page;
            }

            $state.go($state.current.name, {
              message: 'deletingSuccess',
              page: pageToGo
            }, {
              reload: true
            });
          }, (response) => {
            if (response.status === 410) {
              vm.message = 'entityHasChanged';
            } else {
              vm.message = 'deletingError';
            }
          });
        });
      };

      vm.download = (item, type) => {
        QrApi
        .get({
          type: type,
          id: item.id
        }).$promise.then((data) => {
          FileSaver.saveAs(data.blob, data.filename);
        });
      };
    }
  }

  TemporaryWorkersListCtrl.$inject = ['$state', '$stateParams', '$uibModal', 'CrudApi', 'QrApi', 'FileSaver'];

  /**
   * @ngdoc object
   * @name temporaryWorkers.list.controller:TemporaryWorkersListCtrl
   *
   * @description
   *
   */
  angular
    .module('temporaryWorkers.list')
    .controller('TemporaryWorkersListCtrl', TemporaryWorkersListCtrl);
}());

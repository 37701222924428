(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name vcMain.factory:MapHelper
   *
   * @description
   *
   */
  angular
    .module('vcMain')
    .factory('MapHelper', MapHelper);

  MapHelper.$inject = ['NgMap', 'mapColors', 'userSiteStatus'];

  function MapHelper(NgMap, mapColors, userSiteStatus) {
    /* global google */
    let MapHelperBase = {};
    MapHelperBase.initMap = () => {
      let country = 'Germany',
          geocoder;

      geocoder = new google.maps.Geocoder();
      geocoder.geocode({address: country}, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          NgMap.getMap('dashboard').then((map) => {
            map.setCenter(results[0].geometry.bounds.getCenter());
            map.fitBounds(results[0].geometry.bounds);
          });
        }
      });
    };

    MapHelperBase.clearMap = () => {
      NgMap.getMap('dashboard').then(function (map) {
        if (angular.isDefined(map.markers)) {
          for (let i = 0; i < map.markers.length; i++) {
            map.markers[i].setMap(null);
          }
          map.markers = [];
        }

        if (angular.isDefined(map.temporaryWorkersMarkers)) {
          for (let i = 0; i < map.temporaryWorkersMarkers.length; i++) {
            map.temporaryWorkersMarkers[i].setMap(null);
          }
          map.temporaryWorkersMarkers = [];
        }

        if (angular.isDefined(map.vpolygons)) {
          for (let i = 0; i < map.vpolygons.length; i++) {
            map.vpolygons[i].setMap(null);
          }
          map.vpolygons = [];
        }
      });
    };

    MapHelperBase.getInitialBounds = () => {
      let points = [
          // Berlin
          {
            latitude: 52.520072,
            longitude: 13.407600
          },
          // Bremen
          {
            latitude: 53.080081,
            longitude: 8.800665
          },
          // München
          {
            latitude: 48.135596,
            longitude: 11.582654
          },
          // Karlsruhe
          {
            latitude: 49.006354,
            longitude: 8.401282
          }
      ];
      return MapHelperBase.getBounds(points);
    };

    MapHelperBase.getBounds = (points) => {
      let bounds = new google.maps.LatLngBounds();
      points.forEach((point) => {
        bounds.extend(new google.maps.LatLng(point.latitude, point.longitude));
      });
      return bounds;
    };
    MapHelperBase.simplePoints = (arrayOfPoints) => {
      return arrayOfPoints.map(MapHelperBase.simplePoint);
    };
    MapHelperBase.simplePoint = (point) => {
      return [point.latitude, point.longitude];
    };
    MapHelperBase.pointToLatLng = point => new google.maps.LatLng(point.latitude, point.longitude);
    MapHelperBase.pointsToLatLng = (arrayOfPoints) => {
      return arrayOfPoints.map(MapHelperBase.pointToLatLng);
    };
    // HELPER METHODS FOR MAP DIRECTIVES
    MapHelperBase.getVineyardColor = (siteStatus, mouseOver = false) => {
      switch (siteStatus) {
        case userSiteStatus.FINISHED:
          return mouseOver ? mapColors.greenHover : mapColors.green;
        case userSiteStatus.REDO:
          return mouseOver ? mapColors.redHover : mapColors.red;
        default:
          return mouseOver ? mapColors.blueHover : mapColors.blue;
      }
    };
    MapHelperBase.getVineyardOptions = (siteStatus, mode, mouseOver = false, optionOverrides = {}) => {
      let options = {
            strokeWeight: 2
          },
          color;

      switch (mode) {
        case 'selected':
          color = MapHelperBase.getVineyardColor(siteStatus, mouseOver);
          break;
        case 'new':
          color = mouseOver ? mapColors.blueNewParcelHover : mapColors.blueNewParcel;
          break;
        default:
          // unselected vineyards override the status color to grey
          color = mouseOver ? mapColors.greyHover : mapColors.grey;
          break;
      }
      options.strokeOpacity = 1;
      options.fillOpacity = 0.5;
      options.strokeColor = color;
      options.fillColor = color;

      return angular.merge(options, optionOverrides);
    };
    MapHelperBase.getPathFromGmapShape = (gmapShape) => {
      let resultPath = [],
          gmapPath = gmapShape.getPath();
      for (let i = 0; i < gmapPath.getLength(); i++) {
        resultPath.push({
          latitude: gmapPath.getAt(i).lat(),
          longitude: gmapPath.getAt(i).lng()
        });
      }
      return resultPath;
    };
    MapHelperBase.pointsInPolygon = (points, polygonPath, containsAll = true) => {
      let polygon = new google.maps.Polygon({paths: polygonPath.map(point => new google.maps.LatLng(point.latitude, point.longitude))}),
          all = true,
          any = false;
      for (let i = 0; i < points.length; i++) {
        const point = points[i],
            p = new google.maps.LatLng(point.latitude, point.longitude),
            contains = google.maps.geometry.poly.containsLocation(p, polygon);
        all = all && contains;
        any = any || contains;
      }
      return containsAll ? all : any;
    };
    MapHelperBase.generateMarkerOptionsForPath = (path, width, height, fillColor = 'black', strokeColor = 'white', options = {}) => {
      return angular.merge({
        icon: {
          anchor: new google.maps.Point(width / 2, height / 2),
          path: path,
          fillColor,
          fillOpacity: 1,
          scale: 1,
          strokeColor,
          strokeWeight: 1
        }
      }, options);
    };
    return MapHelperBase;
  }
}());

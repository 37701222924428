(function () {
  'use strict';

  class EditFertilizationCopyCtrl {
    constructor(period) {
      let vm = this;
      vm.period = period;
      vm.selection = [];
    }
  }

  EditFertilizationCopyCtrl.$inject = ['period'];

  /**
   * @ngdoc object
   * @name parcels.edit.controller:EditFertilizationCopyCtrl
   *
   * @description
   *
   */
  angular
    .module('parcels.edit')
    .controller('EditFertilizationCopyCtrl', EditFertilizationCopyCtrl);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name vcMain.factory:SelectionHelper
   *
   * @description this factory contains helper methods for working with (abstract) selections
   *
   */
  angular
    .module('vcMain')
    .factory('SelectionHelper', SelectionHelper);

  function SelectionHelper() {
    let SelectionHelperBase = {};
    SelectionHelperBase.changeSelection = changeSelection;

    /**
     * This method takes an existing selection and a changeset and calculates the new
     * selection in accordance with additional parameters.
     *
     * @param {Array} oldSelection the old selection that should be modified
     * @param {Array} changedSelection the changeset for the selection
     * @param {Boolean} incremental whether the changeset signifies an incremental change or a complete replacement
     * @param {Boolean} allowDeselect whether to allow reduction of the old selection by removing items that are both part of the old selection and the changeset
     * @returns {Array} the new selection
     */
    function changeSelection(oldSelection, changedSelection, incremental, allowDeselect) {
      let newSelection = [];
      if (!incremental) {
        newSelection = changedSelection.slice();
      } else {
        newSelection = oldSelection.slice();
        angular.forEach(changedSelection, selected => {
          let index = oldSelection.indexOf(selected);
          if (index > -1 && allowDeselect) {
            newSelection.splice(index, 1);
          } else if (index === -1) {
            newSelection.push(selected);
          }
        });
      }
      return newSelection;
    }

    return SelectionHelperBase;
  }
}());

(function () {
  'use strict';

  class ExportsDeleteCtrl {
    constructor(item) {
      let vm = this;
      vm.item = item;
    }
  }

  ExportsDeleteCtrl.$inject = ['item'];

  /**
   * @ngdoc object
   * @name exports.delete.controller:ExportsDeleteCtrl
   *
   * @description
   *
   */
  angular
    .module('exports.exports.delete')
    .controller('ExportsDeleteCtrl', ExportsDeleteCtrl);
}());

<div class="choseCustomerForm">
    <div class="modal-header">
        <button type="button" class="close" ng-click="$dismiss();"><span>&times;</span></button>
        <h4 class="modal-title" ng-if="impersonation.headline !== null">{{impersonation.headline | translate}}</h4>
        <h4 class="modal-title" ng-if="impersonation.headline === null">{{'ADMIN_IMPERSONATION_CHOOSE_CUSTOMER' | translate}}</h4>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div ng-class="{'input-group': impersonation.filter !== ''}">
                <input class="form-control" placeholder="{{'SEARCH' | translate}}" ng-model="impersonation.filter" ng-change="impersonation.filterCustomers()" />
                <div class="input-group-addon" ng-if="impersonation.filter !== ''" ng-click="impersonation.resetFilter()">
                    <span class="glyphicon glyphicon-remove"></span>
                </div>
            </div>
        </div>
        <p class="help-block">{{'ADMIN_IMPERSONATION_CHOOSE_CUSTOMER_EXPLANATION' | translate}}</p>
    </div>
    <div class="modal-body list-body">
        <div class="list-group" ng-if="impersonation.loaded && impersonation.filteredCustomers.length">
            <a class="list-group-item" ng-repeat="customer in impersonation.filteredCustomers" ng-click="$event.preventDefault(); $close(customer)" href="#">{{customer.label}}</a>
        </div>
        <div class="alert alert-info" role="alert" ng-if="impersonation.loaded && !impersonation.error && !impersonation.filteredCustomers.length">
            {{'ADMIN_IMPERSONATION_NO_CUSTOMERS' | translate}}
        </div>
        <div class="alert alert-danger" role="alert" ng-if="impersonation.error">
            {{'MESSAGE_DANGER_LOADING_ERROR' | translate}}
        </div>
        <div ng-if="!impersonation.loaded">
            <div class="spinner">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div>
        </div>
    </div>
</div>
(function () {
  'use strict';

  /* @ngdoc object
   * @name orderTypes
   * @description
   *
   */
  angular
    .module('orderTypes', [
      'ui.router',
      'orderTypes.delete',
      'orderTypes.edit',
      'orderTypes.list'
    ]);
}());

<sub-menu items="list.submenu.items"></sub-menu>
<main-menu></main-menu>

<div class="time-tracking-cost-centers-list base-layout">
    <div class="base-layout-default">
        <main-message message="list.message" fluid="'nested'"></main-message>

        <div class="table-responsive" ng-if="list.costCenters.length">
            <table class="table table-hover">
                <thead>
                <th>
                    {{'TIME_TRACKING_COST_CENTERS_NAME' | translate}}
                </th>
                <th class="text-center">{{'ENTITY_OWNERSHIP' | translate}}</th>
                <th class="text-center"
                    ng-if="list.canDelete">{{'DELETE' | translate}}
                </th>
                </thead>
                <tbody>
                <tr ng-repeat="costCenter in list.costCenters" ng-click="list.editClick(costCenter)">
                    <td><span class="cost-center-label"
                              ng-style="{'padding-left': (costCenter.level * 20) + 'px'}">{{costCenter.label}}</span>
                    </td>
                    <td class="text-center shrink" ng-if="list.ownEntity(costCenter.customerId)">
                        {{'OWN_ENTITY' | translate}}
                    </td>
                    <td class="text-center shrink" ng-if="!list.ownEntity(costCenter.customerId)">
                        {{'NOT_OWN_ENTITY' | translate}}
                    </td>
                    <td class="text-center shrink"
                        ng-if="list.canDelete"
                        class="shrink">
                        <button class="btn btn-link"
                                ng-click="$event.stopPropagation(); list.delete(costCenter)"
                                ng-if="costCenter.children.length === 0">
                            <span class="glyphicon glyphicon-trash"></span>
                        </button>
                        <button class="btn btn-link" ng-style="{'color':'#999999'}"
                                ng-if="costCenter.children.length !== 0"
                                tooltip-placement="left"
                                uib-tooltip="{{ 'TOOLTIP_TIME_TRACKING_COST_CENTERS_DELETE_DISABLED' | translate }}">
                            <span class="glyphicon glyphicon-info-sign"></span>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <ul uib-pagination total-items="list.total"
            ng-if="list.total > list.itemsPerPage"
            ng-model="list.page"
            items-per-page="list.itemsPerPage"
            ng-change="list.paginate()"
            direction-links="false">
    </div>
</div>

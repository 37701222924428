(function () {
  'use strict';

  /* @ngdoc object
   * @name parcels.edit
   * @description
   *
   */
  angular
    .module('parcels.edit', [
      'ui.router',
      'auth',
      'parcels.edit.orders',
      'parcels.edit.beacons',
      'angularFileUpload',
      'timeTracking',
      'dndLists'
    ]);
}());

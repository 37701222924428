(function () {
  'use strict';

  /* @ngdoc object
   * @name auth
   * @description
   *
   */
  angular
    .module('auth', [
      'ui.router',
      'auth.login',
      'auth.forgotPassword',
      'auth.registration'
    ]);
}());

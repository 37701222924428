(function () {
  'use strict';

  angular
    .module('orders.delete')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('orders.delete', {
        url: '/delete',
        templateUrl: 'parcels/edit/orders/delete/views/delete.tpl.html',
        controller: 'OrdersDeleteCtrl',
        controllerAs: 'delete'
      });
  }
}());

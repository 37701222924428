(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name constants.constant:fertilizationIngredient
   *
   * @description
   *
   */
  angular
    .module('constants')
    .constant('fertilizationIngredient', {
      NITROGEN: 2,
      PHOSPHATE: 1
    });
}());

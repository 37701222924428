(function () {
  'use strict';

  angular
    .module('temporaryWorkers.list')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('temporaryWorkers.list', {
        url: '/list?page&sorter&dir&filter',
        templateUrl: 'temporary-workers/list/views/list.tpl.html',
        controller: 'TemporaryWorkersListCtrl',
        controllerAs: 'list',
        params: {
          message: null
        }
      });
  }
}());

(function () {
  'use strict';

  class CustomersListCtrl {
    constructor(UserService, $stateParams, permissions, $state, AdminCrudApi, $uibModal, $http) {
      let vm = this;

      vm.UserService = UserService;
      vm.sorter = $stateParams.sorter;
      vm.itemsPerPage = 20;
      vm.total = 0;
      vm.filter = $stateParams.filter;
      vm.dir = $stateParams.dir;
      vm.message = $stateParams.message;
      vm.page = $stateParams.page;
      vm.canDelete = UserService.hasPerm(permissions.ADMIN_CAN_DELETE_CUSTOMER);
      vm.canSeeAll = UserService.hasPerm(permissions.ADMIN_CAN_READ_ALL_TENANTS_IN_ACCOUNT);

      vm.customerInfo = {
          total: 0,
          active: 0,
          inactive: 0
      };

      // Add submenu items
      vm.submenu = {
        items: [
          {
            type: 'button',
            text: UserService.getLocalizedLabelOrDefault('tenantAdd', 'ADMIN_BUTTON_ADD_CUSTOMER'),
            hide: !UserService.canAccessState('customers.create'),
            click: () => {
              $state.go('customers.create');
            }
          },
          {
            type: 'search',
            model: vm.filter,
            position: 'right',
            change: (filter) => {
              $state.go($state.current.name, {
                filter: filter,
                page: 1,
                message: null
              });
            }
          }
        ]
      };

        // Sorting method
        vm.sort = (sorter) => {
            let newIsActive = vm.isActive === 'desc' ? 'asc' : 'desc';
            $state.go($state.current.isActive, {
                sorter: sorter,
                isActive: newIsActive
            }, {
                notify: false
            });
            vm.sorter = sorter;
            vm.isActive = newIsActive;
        };


      vm.getCustomers = () => {
        vm.message = null;
        AdminCrudApi.query({
          entity: 'customers',
          filter: vm.filter,
          page: vm.page,
          perPage: vm.itemsPerPage
        }, (data, headers) => {
          vm.customers = data;
          vm.total = headers()['x-total-count'];
          if (!data.length) {
            vm.message = 'noData';
          }
        }, () => {
          vm.customers = [];
          vm.total = 0;
          vm.message = 'loadingError';
        });
      };

        $http.get('api/v1/admin/customers/info/').then(function (response) {
            vm.customerInfo = response.data;
        });

      vm.paginate = () => {
        $state.go($state.current.name, {
          page: vm.page,
          message: null
        }, {
          reload: true
        });
      };

      vm.getCustomers();

      // Delete Popup
      vm.delete = (item) => {
        let popup = $uibModal.open({
          controller: 'CustomersDeleteCtrl',
          controllerAs: 'delete',
          templateUrl: 'admin/customers/delete/views/delete.tpl.html',
          resolve: {
            item: () => {
              return item;
            }
          }
        });
        popup.result.then(() => {
          AdminCrudApi.delete({
            entity: 'customers',
            id: item.id,
            lastModified: item.lastModified
          }, () => {
            let page = vm.page;
            if (vm.page === Math.ceil(vm.total / vm.itemsPerPage) && vm.customers.length === 1) {
              page = Math.max(1, page - 1);
            }
            $state.go($state.current.name, {
              message: 'deletingSuccess',
              page: page
            }, {
              reload: true
            });
          }, (response) => {
            if (response.status === 410) {
              vm.message = 'entityHasChanged';
            } else if (response.status === 422) {
              vm.message = response.data.messageCode;
            } else {
              vm.message = 'deletingError';
            }
          });
        });
      };
    }
  }

  CustomersListCtrl.$inject = ['UserService', '$stateParams', 'permissions', '$state', 'AdminCrudApi', '$uibModal', '$http'];

  /**
   * @ngdoc object
   * @name admin.customers.list.controller:CustomersListCtrl
   *
   * @description
   *
   */
  angular
    .module('admin.customers.list')
    .controller('CustomersListCtrl', CustomersListCtrl);
}());

<ng-form class="form-group"
         name="additionalForm"
         style="display: block;"
         ng-controller="OrderTypeFertilizationFertilizationCtrl as additional">
    <ng-form class="form-group"
             ng-class="{
            'has-error': fertilizationArticlesForm.$invalid
          }"
             name="fertilizationArticlesForm"
             style="display: block;">
        <label class="control-label">{{'FERTILIZATION_ARTICLES' | translate}}</label>
        <ui-select multiple ng-disabled="!edit.isCreate && !edit.canEdit" ng-model="edit.additionalData.articles"
                   theme="bootstrap" style="margin-bottom: 5px;" ng-if="additional.articles"
                   on-select="additional.sanitizeSelection($item, edit.additionalData.articles)" required
                   ng-change="additional.recalculateTotalIngredientAmounts()">
            <ui-select-match placeholder="  {{'PLEASE_MAKE_CHOICE' | translate}}">
                {{$item.article.label}}
            </ui-select-match>
            <ui-select-choices
                    repeat="article in additional.getAvailableArticles($select.search, edit.additionalData.articles) | orderBy:'label'">
                {{article.label}}
            </ui-select-choices>
        </ui-select>
        <div class="alert alert-warning">{{'fertilization.areaCalculationNotice' | translate}}</div>
        <div class="row"
             style="margin-bottom: 5px;"
             ng-repeat-start="article in edit.additionalData.articles">
            <div class="col-xs-6">
                <input class="form-control"
                       type="text"
                       readonly
                       ng-model="article.article.label"/>
            </div>
            <div class="col-xs-3">
                <div class="input-group">
                    <input class="form-control"
                           type="number"
                           placeholder="{{'FERTILIZATION_ARTICLES_AMOUNT' | translate}}"
                           pattern="^\d{1,6}(?:[,\.]\d{1,4})?$"
                           ng-disabled="!edit.isCreate && !edit.canEdit"
                           required
                           ng-model="article.amount"
                           ng-change="additional.amountTotalToPerHa(article)"/>
                    <span class="input-group-addon"
                          style="min-width: 40px;">{{article.article.unit}}</span>
                </div>
            </div>
            <div class="col-xs-3">
                <div class="input-group">
                    <input class="form-control"
                           type="number"
                           placeholder="{{'FERTILIZATION_ARTICLES_AMOUNT' | translate}}"
                           pattern="^\d{1,6}(?:[,\.]\d{1,4})?$"
                           ng-disabled="!edit.isCreate && !edit.canEdit"
                           required
                           ng-model="article.amountPerHa"
                           ng-change="additional.amountPerHaToTotal(article)"/>
                    <span class="input-group-addon"
                          style="min-width: 40px;">{{article.article.unit}} / {{'FERTILIZATION_ARTICLE_UNIT_HA' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="row"
             style="margin-bottom: 5px;"
             ng-repeat="ingredient in article.article.ingredients | orderBy : 'order'"
             ng-if="ingredient.amount">
            <div class="col-xs-5 col-xs-offset-1 text-right">
                <div class="padding-input-vertical">
                    {{ ingredient.ingredient.label }}
                </div>
            </div>
            <div class="col-xs-3">
                <div class="input-group">
                    <input class="form-control"
                           type="number"
                           placeholder="{{'FERTILIZATION_ARTICLES_AMOUNT' | translate}}"
                           pattern="^\d{1,6}(?:[,\.]\d{1,4})?$"
                           ng-disabled="!edit.isCreate && !edit.canEdit"
                           ng-model="ingredient.calcAmount"
                           ng-change="additional.ingredientTotalToArticle(article, ingredient)"/>
                    <span class="input-group-addon"
                          style="min-width: 40px;">{{ ingredient.unit !== '%' ? ingredient.unit : ('FERTILIZATION_ARTICLE_UNIT_KG_SHORT' | translate)
                        }}</span>
                </div>
            </div>
            <div class="col-xs-3">
                <div class="input-group">
                    <input class="form-control"
                           type="number"
                           placeholder="{{'FERTILIZATION_ARTICLES_AMOUNT' | translate}}"
                           pattern="^\d{1,6}(?:[,\.]\d{1,4})?$"
                           ng-disabled="!edit.isCreate && !edit.canEdit"
                           ng-model="ingredient.calcAmountPerHa"
                           ng-change="additional.ingredientPerHaToArticle(article, ingredient)"/>
                    <span class="input-group-addon"
                          style="min-width: 40px;">{{ ingredient.unit !== '%' ? ingredient.unit : ('FERTILIZATION_ARTICLE_UNIT_KG_SHORT' | translate)
                        }} / {{'FERTILIZATION_ARTICLE_UNIT_HA' | translate}}</span>
                </div>
            </div>
        </div>
        <div ng-repeat-end></div>
        <p class="help-block"
           ng-messages="fertilizationArticlesForm.$error">
            <span ng-message="pattern">{{'FERTILIZATION_ARTICLES_AMOUNT_FRACTION_SIZE' | translate}}</span>
            <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
        </p>
    </ng-form>
    <div ng-if="additional.selectedVineyards.length"
         ng-class="{
         'has-warning': additional.showVineyardWarning
       }">
        <div class="row">
            <div class="col-xs-6">
                <label class="control-label">
                    {{'PARCELS' | translate}}
                </label>
            </div>
            <div class="col-xs-3 text-right">
                <label class="control-label">
                    {{'FERTILIZATION_ARTICLE_INGREDIENTS_NITROGEN' | translate}}
                </label>
            </div>
            <div class="col-xs-3 text-right">
                <label class="control-label">
                    {{'FERTILIZATION_ARTICLE_INGREDIENTS_PHOSPHATE' | translate}}
                </label>
            </div>
        </div>
        <p class="help-block" ng-if="additional.showVineyardWarning">
            {{'FERTILIZATION_ORDER_VINEYARD_CHANGED_WARNING' | translate}}
        </p>
    </div>
    <uib-accordion close-others="false" class="fertilization-data-parcels">
        <div uib-accordion-group class="panel-default"
             ng-repeat="vineyard in additional.selectedVineyards | orderBy: 'label'"
             is-disabled="vineyard.maximumNitrogenValue === null">
            <uib-accordion-heading>
                <div class="row" ng-class="{
            'text-danger': vineyard.maximumNitrogenValue !== null && (vineyard.maximumNitrogenValue - vineyard.nitrogenDeductionPending - vineyard.nitrogenDeductionFinished + additional.initialTotalIngredientAmountsPerHa[additional.ingredient.NITROGEN] - additional.totalIngredientAmountsPerHa[additional.ingredient.NITROGEN] < 0 || vineyard.maximumPhosphateValue - vineyard.phosphateDeductionPending - vineyard.phosphateDeductionFinished + additional.initialTotalIngredientAmountsPerHa[additional.ingredient.PHOSPHATE] - additional.totalIngredientAmountsPerHa[additional.ingredient.PHOSPHATE] < 0)
          }">
                    <div class="col-xs-6">
                        {{vineyard.label}}
                    </div>
                    <div class="col-xs-3 text-right" ng-if="vineyard.maximumNitrogenValue !== null">
                        {{(vineyard.maximumNitrogenValue - vineyard.nitrogenDeductionPending - vineyard.nitrogenDeductionFinished + additional.initialTotalIngredientAmountsPerHa[additional.ingredient.NITROGEN] - additional.totalIngredientAmountsPerHa[additional.ingredient.NITROGEN]) | number: 4}}
                    </div>
                    <div class="col-xs-3 text-right" ng-if="vineyard.maximumNitrogenValue !== null">
                        {{(vineyard.maximumPhosphateValue - vineyard.phosphateDeductionPending - vineyard.phosphateDeductionFinished + additional.initialTotalIngredientAmountsPerHa[additional.ingredient.PHOSPHATE] - additional.totalIngredientAmountsPerHa[additional.ingredient.PHOSPHATE]) | number: 4}}
                    </div>
                    <div class="col-xs-6 text-right" ng-if="vineyard.maximumNitrogenValue === null">
                        {{'FERTILIZATION_ORDER_VINEYARD_NO_FERTILIZATION_DATA' | translate}}
                    </div>
                </div>
            </uib-accordion-heading>
            <ul class="list-group">
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-xs-6">
                            {{'FERTILIZATION_ORDER_VINEYARD_MAXIMUM_VINEYARD' | translate}}
                        </div>
                        <div class="col-xs-3 text-right">
                            {{ vineyard.maximumNitrogenValue !== null ? (vineyard.maximumNitrogenValue | number: 4) : '-'
                            }}
                        </div>
                        <div class="col-xs-3 text-right">
                            {{ vineyard.maximumPhosphateValue !== null ? (vineyard.maximumPhosphateValue | number: 4) : '-'
                            }}
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row text-muted">
                        <div class="col-xs-6">
                            {{'FERTILIZATION_ORDER_VINEYARD_DEDUCTION_FINISHED' | translate}}
                        </div>
                        <div class="col-xs-3 text-right">
                            {{ vineyard.nitrogenDeductionFinished !== null ? ((vineyard.nitrogenDeductionFinished - (edit.order.status === additional.orderStatus.FINISHED ? additional.initialTotalIngredientAmountsPerHa[additional.ingredient.NITROGEN] : 0)) * -1 | number: 4) : '-'
                            }}
                        </div>
                        <div class="col-xs-3 text-right">
                            {{ vineyard.phosphateDeductionFinished !== null ? ((vineyard.phosphateDeductionFinished - (edit.order.status === additional.orderStatus.FINISHED ? additional.initialTotalIngredientAmountsPerHa[additional.ingredient.PHOSPHATE] : 0)) * -1 | number: 4) : '-'
                            }}
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row text-muted">
                        <div class="col-xs-6">
                            {{'FERTILIZATION_ORDER_VINEYARD_DEDUCTION_PENDING' | translate}}
                        </div>
                        <div class="col-xs-3 text-right">
                            {{ vineyard.nitrogenDeductionPending !== null ? ((vineyard.nitrogenDeductionPending - (edit.order.status !== additional.orderStatus.FINISHED ? additional.initialTotalIngredientAmountsPerHa[additional.ingredient.NITROGEN] : 0)) * -1 | number: 4) : '-'
                            }}
                        </div>
                        <div class="col-xs-3 text-right">
                            {{ vineyard.phosphateDeductionPending !== null ? ((vineyard.phosphateDeductionPending - (edit.order.status !== additional.orderStatus.FINISHED ? additional.initialTotalIngredientAmountsPerHa[additional.ingredient.PHOSPHATE] : 0)) * -1 | number: 4) : '-'
                            }}
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-xs-6">
                            {{'FERTILIZATION_ORDER_VINEYARD_DEDUCTION_CURRENT' | translate}}
                        </div>
                        <div class="col-xs-3 text-right">
                            {{ additional.totalIngredientAmountsPerHa[additional.ingredient.NITROGEN] !== undefined ? (additional.totalIngredientAmountsPerHa[additional.ingredient.NITROGEN] * -1 | number: 4) : (0 | number: 4)
                            }}
                        </div>
                        <div class="col-xs-3 text-right">
                            {{ additional.totalIngredientAmountsPerHa[additional.ingredient.PHOSPHATE] !== undefined ? (additional.totalIngredientAmountsPerHa[additional.ingredient.PHOSPHATE] * -1 | number: 4) : (0 | number: 4)
                            }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </uib-accordion>
</ng-form>

<sub-menu items="list.submenu.items" fluid="true"></sub-menu>
<main-menu></main-menu>

<div class="base-layout">
  <div class="base-layout-map">
      <div class="base-layout-map-list">
        <main-message message="list.message"
                      style="margin-top: 20px;"
                      fluid="true"
                      ng-if="list.message"></main-message>
        <div class="table-responsive" ng-if="list.items.length">
          <table class="table table-hover">
            <thead>
            <th class="text-center">{{'COPY' | translate}}</th>
            <th class="text-center hidden-xs" ng-if="list.accountId !== 1">
                {{'order.orderVisibilityMode.label' | translate}}
            </th>
            <th class="hidden-xs">{{'ORDER_ORDER_TYPE' | translate}}</th>
            <th ng-click="list.sort('label')">
              {{'ORDER_LABEL' | translate}}
              <span class="glyphicon pull-right text-muted"
                    ng-if="list.sorter === 'label'"
                    ng-class="{
                      'glyphicon-chevron-down': list.dir === 'asc',
                      'glyphicon-chevron-up': list.dir === 'desc'
                    }"></span>
            </th>
            <th class="hidden-xs">{{'ORDER_ASSIGNED_VINEYARDS' | translate}}</th>
            <th class="hidden-xs">{{'ORDER_ASSIGNED_USERS' | translate}}</th>
            <th class="hidden-xs hidden-sm"
                ng-click="list.sort('deadlineDate')">
              {{'ORDER_DEADLINE_DATE' | translate}}
              <span class="glyphicon pull-right text-muted"
                    ng-if="list.sorter === 'deadlineDate'"
                    ng-class="{
                      'glyphicon-chevron-down': list.dir === 'asc',
                      'glyphicon-chevron-up': list.dir === 'desc'
                    }"></span>
            </th>
            <th class="hidden-xs hidden-sm">{{'ORDER_PROGRESS' | translate}}</th>
            <th class="text-center">{{'DELETE' | translate}}</th>
            </thead>
            <tbody>
            <tr ng-repeat="item in list.items"
                ui-sref="orders.edit({
                 id: item.id,
                 type: 'regular'
                })"
                ng-class="{
                  'success': item.status === list.orderStatus.FINISHED,
                  'danger': item.status !== list.orderStatus.FINISHED && !item.assignedUserIds.length
                }">
              <td class="text-center">
                <button class="btn btn-link"
                        ng-click="$event.stopPropagation(); list.copy(item)"
                        ng-if="(list.orderTypes | filter:{id: item.typeId}:true)[0].canCopy">
                  <span class="glyphicon glyphicon-copy"></span>
                </button>
              </td>
              <td class="text-center hidden-xs" ng-if="list.accountId !== 1">
                  <img class="image-responsive" width="32" src="{{::list.orderVisibilityModeIcon(item)}}" alt=""/>
              </td>
              <td class="hidden-xs">{{(list.orderTypes | filter:{id:
                item.typeId}:true)[0].labelProcessed}}
              </td>
              <td>{{::item.label}}</td>
              <td class="hidden-xs">{{::list.getVineyardColumnLabel(item.siteIds)}}</td>
              <td class="hidden-xs">{{::item.assignedUserIds.length}}</td>
              <td class="hidden-xs hidden-sm">{{::item.deadlineDate | date}}</td>
              <td class="hidden-xs hidden-sm">{{::list.progressValueToDisplay(item)}}</td>
              <td class="text-center">
                <button class="btn btn-link"
                        ng-click="$event.stopPropagation(); list.delete(item)"
                        ng-if="(list.orderTypes | filter:{id: item.typeId}:true)[0].canDelete">
                  <span class="glyphicon glyphicon-trash"></span>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <ul uib-pagination
            total-items="list.total"
            ng-if="list.total > list.itemsPerPage"
            ng-model="list.page"
            items-per-page="list.itemsPerPage"
            ng-change="list.paginate()"
            boundary-links="true"
            max-size="list.maxSize"
            previous-text="&lsaquo;"
            next-text="&rsaquo;"
            first-text="&laquo;"
            last-text="&raquo;">
        </ul>
      </div>
      <div class="base-layout-map-map">
        <vc-map-display
          map-id="list.mapId"
          icon-data="list.iconData"
          vineyard-map-data="list.mapData"
          selected-vineyards="list.selectedVineyards"
          on-vineyard-click="list.onVineyardClick(vineyardId)">
        </vc-map-display>
      </div>
    </div>
  <div class="loader" ng-if="list.isLoading">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
</div>

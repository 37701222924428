(function () {
  'use strict';

  class PoiListCtrl {
    constructor($state, $stateParams, $uibModal, CrudApi, UserService, permissions, $q, CustomerMapDataService, PoiHelper, siteEntities) {
      let vm = this,
          promises = {};
      vm.mapId = 'poiListMap';
      vm.message = $stateParams.message;
      vm.itemsPerPage = 10;
      vm.page = $stateParams.page;
      vm.canCreate = UserService.canAccessState('poi.create');
      vm.canDelete = UserService.hasPerm(permissions.BASE_CAN_DELETE_POI);
      vm.rowHover = rowHover;
      vm.delete = deletePoi;
      // for pois with more than the following amount of vineyards the vineyard names won't be shown
      vm.vineyardNameCutOff = 1;
      // this value will be changed later to trigger a rezoom on the map
      vm.zoomToSelection = 'both';
      // this is used to later hide irrelevant icons on row hover
      vm.changeIconOptions = {};

      if (vm.canCreate) {
        // Add submenu items
        vm.submenu = {
          items: [{
            type: 'button',
            text: 'BUTTON_ADD_POI',
            hide: !vm.canCreate,
            click: () => {
              $state.go('poi.create');
            }
          }]
        };
      }

      promises.items = CrudApi.query({
        entity: 'poi'
      }, (data, headers, status) => {
        vm.total = headers()['x-total-count'];
        if (status === 204) {
          vm.message = 'noData';
        }
      }).$promise;

      // Fetch mapdata of all vineyards
      promises.mapData = CustomerMapDataService.getCustomerMapsData();

      // Get all vineyards so we can show vineyard names in the table
      promises.vineyards = CrudApi.query({
        entity: siteEntities
      }).$promise;

      $q.all(promises).then(({items, mapData, vineyards}) => {
        vm.vineyards = {};
        vineyards.forEach(v => vm.vineyards[v.id] = v);
        // we want to show all pois on the map so we need to get all necessary icons
        let iconPromises = {};
        items.forEach(item => {
          iconPromises[item.id] = PoiHelper.createIconFromPoi(item, true);
          item.type.labelTranslated = PoiHelper.getTypeLabelTranslation(item.type);
        });
        $q.all(iconPromises).then(iconData => {
          vm.items = items;
          vm.mapData = mapData;
          vm.iconData = iconData;
        });
      }).catch(() => {
        vm.message = 'loadingError';
      });

      function deletePoi(poi) {
        let popup = $uibModal.open({
          controller: 'PoiDeleteCtrl',
          controllerAs: 'delete',
          templateUrl: 'poi/delete/views/delete.tpl.html',
          resolve: {
            item: () => {
              return poi;
            },
            vineyards: () => {
              return vm.vineyards;
            }
          }
        });
        popup.result.then(() => {
          CrudApi.delete({
            entity: 'poi',
            id: poi.id,
            lastModified: poi.lastModified
          }, () => {
            $state.go($state.current.name, {
              message: 'deletingSuccess'
            }, {
              reload: true
            });
          }, (response) => {
            if (response.status === 410) {
              vm.message = 'entityHasChanged';
            } else {
              vm.message = 'deletingError';
            }
          });
        });
      }

      function rowHover(item, hover) {
        let itemId = null;
        vm.changeIconOptions = {};
        vm.selectedVineyards = {};
        // we reset the value here to trigger a rezoom on the map
        // if it is a global poi we don't want to include all vineyards in the zoom
        // so just zoom to the icon
        vm.zoomToSelection = hover && !item.siteIds.length ? 'icons' : 'both';
        if (hover) {
          item.siteIds.forEach(vId => vm.selectedVineyards[vId] = true);
          itemId = item.id;
        }
        angular.forEach(vm.iconData, (icon, iconId) => {
          let isHoveredIcon = itemId === null || iconId === String(itemId);
          // if the icon is hovered we want to include it in the zoom
          icon.ignoreInZoom = !isHoveredIcon;
          // and make sure that it is visible
          vm.changeIconOptions[iconId] = {
            visible: isHoveredIcon
          };
        });
      }
    }
  }

  PoiListCtrl.$inject = ['$state', '$stateParams', '$uibModal', 'CrudApi', 'UserService', 'permissions', '$q', 'CustomerMapDataService', 'PoiHelper', 'siteEntities'];

  /**
   * @ngdoc object
   * @name poi.list.controller:PoiListCtrl
   *
   * @description
   *
   */
  angular
    .module('poi.list')
    .controller('PoiListCtrl', PoiListCtrl);
}());

(function () {
    'use strict';

    class CommentsCtrl {
        constructor(
            $q,
            CrudApi,
            $stateParams,
            $state,
            $filter,
            mapColors,
            CustomerMapDataService,
            PoiHelper,
            siteEntities
        ) {
            let vm = this,
                promises = {},
                getComments;

            vm.mapId = 'comments-list-map-id';
            vm.radioModel = $stateParams.case ? $stateParams.case : $filter('translate')('FILTER_COMMENTS_RIGHT_TEXT');
            vm.polygonsObject = {};

            vm.itemsPerPage = 20;
            vm.maxSize = 10;
            vm.page = $stateParams.page || 1;
            vm.filter = $stateParams.filter || '';
            vm.sorter = $stateParams.sorter || 'timestamp';
            vm.dir = $stateParams.dir || 'desc';
            vm.commentsType = $stateParams.commentsType || '';

            vm.getMarkerColor = () => {
                return mapColors.red;
            };

            getComments = (args, radioCase) => {
                vm.filter = '';
                vm.case = radioCase;
                vm.commentsType = args;

                $state.go($state.current.name, {
                    page: 1,
                    perPage: vm.itemsPerPage,
                    filter: vm.filter,
                    sortDir: vm.dir,
                    sortBy: vm.sorter,
                    commentsType: vm.commentsType,
                    case: vm.case
                });
            };

            // Add submenu items
            vm.submenu = {
                items: [{
                    type: 'search',
                    model: vm.filter,
                    position: 'right',
                    change: (filter) => {
                        $state.go($state.current.name, {
                            page: 1,
                            filter: filter,
                            commentsType: null,
                            case: null
                        }, {
                            reload: true
                        });
                    }
                },
                    {
                        type: 'uib-btn-radio',
                        icon: 'plus',
                        position: 'right',
                        tooltip: {
                            label: {
                                left: 'TOOLTIP_DEFAULT_COMMENTS',
                                middle: 'TOOLTIP_CUSTOM_COMMENTS',
                                right: 'TOOLTIP_ALL_COMMENTS'
                            }
                        },
                        text: {
                            left: 'FILTER_COMMENTS_LEFT_TEXT',
                            middle: 'FILTER_COMMENTS_MIDDLE_TEXT',
                            right: 'FILTER_COMMENTS_RIGHT_TEXT'
                        },
                        values: {
                            left: ['default', 'Standard'],
                            middle: ['custom', 'Individuell']
                        },
                        radioModel: vm.radioModel,
                        click: (args, radioCase) => {
                            getComments(args, radioCase);
                        }
                    }]
            };

            // we don't need to wait for the global pois, we just draw them when they are loaded
            CrudApi.query({
                entity: 'poi',
                onlyGlobal: true
            }, pois => {
                let iconPromises = {};
                pois.forEach(poi => iconPromises[poi.id] = PoiHelper.createIconFromPoi(poi));
                $q.all(iconPromises).then(iconData => vm.iconData = iconData);
            });

            promises.comments = CrudApi.query({
                entity: 'comments',
                page: vm.page,
                perPage: vm.itemsPerPage,
                filter: $stateParams.filter,
                sortDir: vm.dir,
                sortBy: vm.sorter,
                commentsType: vm.commentsType
            }, (comments, headers) => {
                vm.total = headers()['x-total-count'];
            });

            promises.vineyards = CrudApi.query({
                entity: siteEntities
            }).$promise;

            // Get all businesses to resolve ids
            vm.orders = CrudApi.query({
                entity: 'orders'
            });

            // Fetch mapdata of all vineyards
            promises.mapData = CustomerMapDataService.getCustomerMapsData();

            // Resolve all promises
            vm.loaded = false;
            $q.all(promises).then((data) => {
                vm.loaded = true;
                vm.comments = data.comments;
                vm.vineyards = data.vineyards;
                vm.mapData = data.mapData;
            }, () => {
                vm.message = 'loadingError';
            });

            // Color the related vineyard or only the origin vineyard if known
            vm.rowHover = (comment, highlight) => {
                vm.selectedVineyards = {};
                vm.userData = [];
                if (highlight) {
                    if (comment.siteId !== null) {
                        vm.selectedVineyards[comment.siteId] = true;
                    } else {
                        let order = $filter('filter')(vm.orders, {id: comment.orderId})[0];
                        if (angular.isDefined(order)) {
                            angular.forEach(order.siteIds, (siteId) => {
                                vm.selectedVineyards[siteId] = true;
                            });
                        }
                    }
                }
                if (highlight && angular.isDefined(comment.latitude) &&
                    angular.isDefined(comment.longitude) &&
                    comment.latitude !== null &&
                    comment.longitude !== null) {
                    let item = [{
                        latitude: comment.latitude,
                        longitude: comment.longitude,
                        capturedDate: 1
                    }];
                    vm.userData.push(item);
                } else {
                    vm.userData = [];
                }
            };

            // Go to order upon row click
            vm.goToOrder = (orderId) => {
                let item = $filter('filter')(vm.orders, {id: orderId})[0];
                if (angular.isDefined(item)) {
                    $state.go('orders.edit', {
                        id: item.id,
                        type: 'regular',
                        v: item.siteIds
                    });
                }
            };

            vm.paginate = (page) => {
                $state.go($state.current.name, {
                    page: page || vm.page,
                    message: null
                });
            };

            // Sorting method
            vm.sort = (sorter) => {
                $state.go($state.current.name, {
                    sorter: sorter,
                    dir: vm.dir === 'desc' ? 'asc' : 'desc',
                    message: null
                });
            };
        }
    }

    CommentsCtrl.$inject = [
        '$q',
        'CrudApi',
        '$stateParams',
        '$state',
        '$filter',
        'mapColors',
        'CustomerMapDataService',
        'PoiHelper',
        'siteEntities'
    ];

    /**
     * @ngdoc object
     * @name comments.controller:CommentsCtrl
     *
     * @description
     *
     */
    angular
        .module('comments')
        .controller('CommentsCtrl', CommentsCtrl);
}());

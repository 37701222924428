(function () {
    'use strict';

    class ListCtrl {
        constructor(CrudApi, $stateParams, $state, UserService, permissions, $uibModal) {
            let vm = this;

            vm.UserService = UserService;
            vm.sorter = $stateParams.sorter;
            vm.dir = $stateParams.dir;
            vm.filter = $stateParams.filter;
            vm.message = $stateParams.message;
            vm.canDelete = UserService.hasPerm(permissions.WINERY_MANAGEMENT_CAN_DELETE_WINERY);
            vm.hasCorrectFactsheet = () => {
                let authToken = UserService.getAuthToken();
                let decodedToken = UserService.decodeToken(authToken.accessToken);
                return decodedToken.assetType === 'VINEYARD';
            };

            // Add submenu items
            vm.submenu = {
                items: [
                    {
                        type: 'button',
                        text: UserService.getLocalizedLabelOrDefault(
                            'businessAdd', 'WINERY_MANAGEMENT_WINERIES_BUTTON_ADD_WINERY'),
                        hide: !UserService.canAccessState('businesses.create'),
                        click: () => {
                            $state.go('businesses.create');
                        }
                    },
                    {
                        type: 'search',
                        model: vm.filter,
                        position: 'right',
                        change: (filter) => {
                            $state.go($state.current.name, {
                                filter: filter,
                                message: null
                            });
                        }
                    }
                ]
            };

            vm.getBusinesses = () => {
                vm.messages = {};
                CrudApi.query({
                    entity: 'businesses',
                    filter: vm.filter,
                    subentity: 'usable'
                }, (data, headers) => {
                    vm.businesses = data;
                    vm.total = headers()['x-total-count'];
                    if (!data.length) {
                        vm.message = 'noData';
                    }
                }, () => {
                    vm.businesses = [];
                    vm.total = 0;
                    vm.message = 'loadingError';
                });
            };

            // Sorting method
            vm.sort = (sorter) => {
                let newDir = vm.dir === 'desc' ? 'asc' : 'desc';
                $state.go($state.current.name, {
                    sorter: sorter,
                    dir: newDir
                }, {
                    notify: false
                });
                vm.sorter = sorter;
                vm.dir = newDir;
            };

            vm.getBusinesses();

            // Delete Popup
            vm.delete = (item) => {
                let popup = $uibModal.open({
                    controller: 'BusinessesDeleteCtrl',
                    controllerAs: 'delete',
                    templateUrl: 'business-management/businesses/delete/views/delete.tpl.html',
                    resolve: {
                        item: () => {
                            return item;
                        }
                    }
                });
                popup.result.then(() => {
                    CrudApi.delete({
                        entity: 'businesses',
                        id: item.id,
                        lastModified: item.lastModified
                    }, () => {
                        $state.go($state.current.name, {
                            message: 'deletingSuccess'
                        }, {
                            reload: true
                        });
                    }, (response) => {
                        if (response.status === 410) {
                            vm.message = 'entityHasChanged';
                        } else {
                            vm.message = 'deletingError';
                        }
                    });
                });
            };
        }
    }

    ListCtrl.$inject = ['CrudApi', '$stateParams', '$state', 'UserService', 'permissions', '$uibModal'];

    /**
     * @ngdoc object
     * @name businesses.list.controller:ListCtrl
     *
     * @description
     *
     */
    angular
        .module('businessManagement.businesses.list')
        .controller('BusinessesListCtrl', ListCtrl);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name vcMain.factory:SettingsManager
   *
   * @description a service to manage application settings backend by localStorage
   *
   */
  angular
    .module('vcMain')
    .factory('SettingsManager', SettingsManager);

  SettingsManager.$inject = ['$window'];

  function SettingsManager($window) {
    let SettingsManagerBase = {},
      settingsPrefix = 'vcMainSettings',
      settingsVersion = 'v1',
      keySeparator = ':';

    SettingsManagerBase.setValue = (key, value) => {
      let storageKey = getStorageKey(settingsVersion, key),
        storageValue = angular.toJson(value);

      $window.localStorage.setItem(storageKey, storageValue);
    };

    SettingsManagerBase.getValue = (key) => {
      let storageKey = getStorageKey(settingsVersion, key),
        storageValue = $window.localStorage.getItem(storageKey);
      if (storageValue !== null) {
        storageValue = angular.fromJson(storageValue);
      }
      return storageValue;
    };

    SettingsManagerBase.deleteValue = (key) => {
      let storageKey = getStorageKey(settingsVersion, key);
      $window.localStorage.removeItem(storageKey);
    };

    SettingsManagerBase.hasValue = (key) => {
      let storageKey = getStorageKey(settingsVersion, key),
        storageValue = $window.localStorage.getItem(storageKey);
      return storageValue !== null;
    };

    SettingsManagerBase.setObjectValue = (key, objectKey, value) => {
      let object = SettingsManagerBase.getValue(key);
      if (!angular.isObject(object)) {
        object = {};
      }
      object[objectKey] = value;
      SettingsManagerBase.setValue(key, object);
    };

    SettingsManagerBase.getObjectValue = (key, objectKey) => {
      let object = SettingsManagerBase.getValue(key);
      if (angular.isObject(object)) {
        return object[objectKey] || null;
      }
      return null;
    };

    SettingsManagerBase.deleteObjectValue = (key, objectKey) => {
      let object = SettingsManagerBase.getValue(key);
      if (angular.isObject(object)) {
        delete object[objectKey];
        SettingsManagerBase.setValue(key, object);
      }
    };

    SettingsManagerBase.hasObjectValue = (key, objectKey) => {
      let object = SettingsManagerBase.getValue(key);
      if (angular.isObject(object)) {
        return object.hasOwnProperty(objectKey);
      }
      return false;
    };

    return SettingsManagerBase;

    function getStorageKey(version, key) {
      return settingsPrefix + keySeparator + version + keySeparator + key;
    }
  }
}());

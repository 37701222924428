<form name="newFertilizationYearPresetForm">
    <div class="modal-header">
        <button type="button" class="close" ng-click="$dismiss();"><span>&times;</span></button>
        <!--TODO make translatable-->
        <h4 class="modal-title">{{'Werte aus einem existierenden Düngejahr importieren oder alle Werte neu anlegen'}}</h4>
    </div>

    <div class="modal-body text-center">
        <select class="form-control"
                ng-options="period as period.label for period in new.periods"
                ng-model="new.period"
                name="period"
                required>
            <option value="">
                <!--TODO make translatable-->
                {{'neu anlegen'}}
            </option>
        </select>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" ng-click="$dismiss();">{{'ABORT' | translate}}</button>
        <button type="button" class="btn btn-danger" ng-click="$close(new.period)">
            {{'OK' | translate}}
        </button>
    </div>
</form>

(function () {
  'use strict';

  class CustomerMapDataService {
    constructor($q, MapExtApi, UserService, CrudApi, additionalRequestOptions = {}) {
      this.$q = $q;
      this.MapExtApi = MapExtApi;
      this.UserService = UserService;
      this.CrudApi = CrudApi;

      this.additionalRequestOptions = additionalRequestOptions;
      this.lastUpdated = null;
      this.customerMapsData = null;
      this.fetchInProgress = null;
      this.requestDeferred = {};
      this.shouldRefetch = false;

      if (this.UserService.user !== null) {
        this.fetchCustomerMapsData();
      }
    }

    getCustomerMapsData() {
      return this.$getCustomerMapsData();
    }

    getCustomerMapsDataById(vineyardId, refreshCache = false) {
      if (!refreshCache) {
        return this.$getCustomerMapsData(vineyardId);
      }
      return this.CrudApi.get({
        entity: 'sites',
        subentity: 'mapsdata',
        subentityid: vineyardId
      }).$promise.then(data => {
        let setData = () => this.$setData(vineyardId, data);
        if (this.fetchInProgress !== null) {
          this.fetchInProgress.then(setData);
        } else {
          setData();
        }
        return data;
      });
    }

    invalidateCache() {
      if (this.fetchInProgress !== null) {
        // we want to make sure that the refresh flag is set even if the current fetch fails.
        // We cannot use a .finally directive here as a finally will be executed after all .then's
        // but the fetch function will provide its on .then handler that checks if another cache
        // invalidation was queued during the current fetch http request. This handler needs to
        // have access to the correct value of the shouldRefetch flag.
        this.fetchInProgress
          .then(() => this.shouldRefetch = true)
          .catch(() => this.shouldRefetch = true);
      } else {
        this.shouldRefetch = true;
      }
    }

    removeIdFromCache(vineyardId) {
      if (this.fetchInProgress !== null) {
        // see explanation in invalidateCache() why .finally is not used here
        this.fetchInProgress
          .then(() => this.$deleteData(vineyardId))
          .catch(() => this.$deleteData(vineyardId));
      } else {
        this.$deleteData(vineyardId);
      }
    }

    $getCustomerMapsData(vineyardId = null) {
      let deferred = this.$q.defer();
      if (this.customerMapsData === null || this.$shouldRefetchData() || this.fetchInProgress !== null) {
        this.$saveDeferred(vineyardId, deferred);
        // if there is a fetch in progress we don't want to trigger another one, but we want
        // to record the request for information and resolve it once the fetch is complete
        if (this.fetchInProgress === null) {
          this.fetchCustomerMapsData();
        }
      } else if (this.$hasData(vineyardId)) {
        deferred.resolve(this.$getData(vineyardId));
      } else {
        deferred.reject();
      }
      return deferred.promise;
    }

    $saveDeferred(vineyardId, deferred) {
      let objectKey = this.$getObjectKeyFromVineyardId(vineyardId);
      if (!this.requestDeferred.hasOwnProperty(objectKey)) {
        this.requestDeferred[objectKey] = [];
      }
      this.requestDeferred[objectKey].push(deferred);
    }

    $hasData(vineyardId) {
      return this.customerMapsData !== null && (vineyardId === null || this.customerMapsData.hasOwnProperty(vineyardId));
    }

    $getData(vineyardId) {
      return vineyardId === null ? this.customerMapsData : this.customerMapsData[vineyardId];
    }

    $setData(vineyardId, data) {
      this.customerMapsData[vineyardId] = data;
    }

    $deleteData(vineyardId) {
      delete this.customerMapsData[vineyardId];
    }

    $getObjectKeyFromVineyardId(vineyardId) {
      if (vineyardId === null) {
        return 'all';
      }
      return vineyardId;
    }

    $getVineyardIdFromObjectKey(objectKey) {
      if (objectKey === 'all') {
        return null;
      }
      return objectKey;
    }

    $shouldRefetchData() {
      return this.shouldRefetch;
    }

    fetchCustomerMapsData() {
      let defer = this.$q.defer();
      this.fetchInProgress = defer.promise;
      // Fetch mapdata of all vineyards
      this.MapExtApi.get(angular.extend({
        action: 'customer'
      }, this.additionalRequestOptions)).$promise.then(mapData => {
        this.customerMapsData = mapData;
        this.lastUpdated = new Date();
        this.shouldRefetch = false;
        defer.resolve();
        defer.promise.then(() => {
          // it is possible that another cach invalidation was requested in the time we took
          // to perform the http request. If this is the case we need to restart the fetch process
          // all over again. If not we can just resolve all queued promises.
          if (!this.shouldRefetch) {
            this.$resolvePromises();
          } else {
            this.fetchCustomerMapsData();
          }
        });
      })
      .catch(() => {
        console.warn('Could not fetch maps data');
        defer.reject();
        this.$resolvePromises();
      })
      .finally(() => this.fetchInProgress = null);
    }

    $resolvePromises() {
      angular.forEach(this.requestDeferred, (deferredArray, objectKey) => {
        let vineyardId = this.$getVineyardIdFromObjectKey(objectKey);
        if (this.$hasData(vineyardId)) {
          let data = this.$getData(vineyardId);
          angular.forEach(deferredArray, deferred => {
            deferred.resolve(data);
          });
        } else {
          angular.forEach(deferredArray, deferred => deferred.reject());
        }
      });
      this.requestDeferred = {};
    }
  }

  const dependencies = ['$q', 'MapExtApi', 'UserService', 'CrudApi'];

  function regularFactory($q, MapExtApi, UserService, CrudApi) {
    return new CustomerMapDataService($q, MapExtApi, UserService, CrudApi);
  }

  function temporaryFactory($q, MapExtApi, UserService, CrudApi) {
    return new CustomerMapDataService($q, MapExtApi, UserService, CrudApi, {temporaryOnly: true});
  }

  regularFactory.$inject = dependencies;
  temporaryFactory.$inject = dependencies;

  /**
   * @ngdoc service
   * @name vcMain.service:CustomerMapDataService
   *
   * @description
   *
   */
  angular
    .module('vcMain')
    .factory('CustomerMapDataService', regularFactory)
    .factory('CustomerMapDataTemporaryService', temporaryFactory);
}());

(function () {
  'use strict';

  class TimeTrackingCostCentersDeleteCtrl {
    constructor(item, CrudApi, CostCenterHelper) {
      let vm = this;
      vm.item = item;

      vm.costCenter = null;

      vm.costCenters = CrudApi.query({
        entity: 'costCenter'
      }, (data, headers) => {
        var result;
        vm.total = headers()['x-total-count'];
        if (!data.length) {
          vm.message = 'noData';
        } else {
          result = CostCenterHelper.makeFlat(data, 0, [vm.item.id]);
          CostCenterHelper.setIndentedLabel(result);

          vm.costCenters = result;
        }
      }, () => {
        vm.total = 0;
        vm.message = 'loadingError';
      });

      vm.groupCostCenters = CostCenterHelper.groupCostCenters;

      vm.getAvailableCostCenters = (search) => {
        let result = [];
        vm.costCenters.forEach((cc) => {
          if (cc.indentedLabel.match(new RegExp(search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'ig'))) {
            result.push(cc);
          }
        });
        return result;
      };
    }
  }

  TimeTrackingCostCentersDeleteCtrl.$inject = ['item', 'CrudApi', 'CostCenterHelper'];

  /**
   * @ngdoc object
   * @name costCenters.delete.controller:TimeTrackingCostCentersDeleteCtrl
   *
   * @description
   *
   */
  angular
    .module('timeTracking.costCenters.delete')
    .controller('TimeTrackingCostCentersDeleteCtrl', TimeTrackingCostCentersDeleteCtrl);
}());

(function () {
  'use strict';

  angular
    .module('edit.download')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('temporaryWorkers.edit.download', {
        url: '/download/:id?page',
        templateUrl: 'temporary-workers/edit/download/views/download.tpl.html',
        controller: 'TemporaryWorkersDownloadCtrl',
        controllerAs: 'TemporaryWorkersDownload',
        params: {
          id: null,
          message: null
        }
      });
  }
}());

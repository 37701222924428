<form name="removeModuleAlertForm">
    <div class="modal-header">
        <button type="button" class="close" ng-click="$dismiss();"><span>&times;</span></button>
        <!--TODO make translatable-->
        <h4 class="modal-title">{{'Bitte beachten:'}}</h4>
    </div>

    <div class="modal-body text-left">
        <p>
            <!--TODO make translatable-->
            {{'Das Entfernen eines Moduls an dieser Stelle hat zur Folge, dass nach dem Speichern allen dem Unternehmen zugehörigen Benutzern die entsprechenden Rechte entzogen werden! Diese können im Anschluss nur einzeln wieder hinzugefügt werden.'}}
            <br>
            <br>
            {{'Das Hinzufügen eines Moduls erlaubt hingegen die Vergabe von Rechten aus dem entsprechenden  Modul für den einzelnen Benutzer.'}}
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" ng-click="$dismiss();">{{'ABORT' | translate}}</button>
        <button type="button" class="btn btn-danger" ng-click="$close(new.period)">
            {{'OK' | translate}}
        </button>
    </div>
</form>

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dashboard.directive:dashboardTile
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="dashboard">
       <file name="index.html">
        <dashboard-tile></dashboard-tile>
       </file>
     </example>
   *
   */
  angular
    .module('dashboard')
    .directive('dashboardTile', dashboardTile);

  function dashboardTile() {
    return {
      restrict: 'E',
      scope: {
        data: '=',
        color: '=',
        toggleFunction: '=',
        temporaryWorkersToggleFunction: '=',
        users: '=',
        enabled: '<',
        showTempWorkers: '<'
      },
      templateUrl: 'dashboard/directives/dashboard-tile-directive.tpl.html',
      replace: false,
      controllerAs: 'dashboardTile',
      bindToController: true,
      controller($scope, CrudApi, $q, $interval, $filter, orderStatus, siteEntities) {
        let vm = this,
            animateBarProgress,
            progressInterval,
            progressBarInterval,
            remainingAreaInterval,
            animateRemainingArea;

        vm.$onInit = () => {
          vm.barProgress = 0;
          vm.inactive = true;
          vm.switchEnabled = false;
          vm.showTempWorkersButton = false;
          vm.orderIsActive = '';
          vm.orderStatus = orderStatus;
          vm.doneAreaStart = 0;
          vm.totalArea = vm.data.areaTotal;

          animateRemainingArea = () => {
            if(remainingAreaInterval){
              $interval.cancel(remainingAreaInterval);
              remainingAreaInterval = undefined;
            }

            const totalStepCount = 60;
            let stepCount = 0;
            remainingAreaInterval = $interval(() => {
              if(stepCount === totalStepCount){
                vm.doneAreaStart = vm.data.areaDone;
              } else {
                const fraction = stepCount / totalStepCount;
                vm.doneAreaStart = vm.data.areaDone * fraction;
              }
              stepCount++;
            }, 10, totalStepCount + 1);
          };

          animateBarProgress = (progress) => {
            if (progressBarInterval) {
              $interval.cancel(progressBarInterval);
            }
            progressBarInterval = $interval(function () {
              vm.barProgress = Math.min(progress, vm.barProgress + 1);
              if (vm.barProgress === progress) {
                $interval.cancel(progressBarInterval);
                progressBarInterval = undefined;
              }
            }, 10, 0);
          };
        };

        $scope.$watch('$destroy', () => {
          $interval.cancel(progressInterval);
          $interval.cancel(progressBarInterval);
        });

        $scope.$watch('dashboardTile.showTempWorkers', (enable) => {
          vm.showTempWorkersButton = enable;
        });

        $scope.$watch('dashboardTile.enabled', (enable) => {
          if (enable) {
            vm.switchEnabled = true;
            vm.grayscale = '';
            vm.inactive = false;
            animateBarProgress(vm.data.progress);
          } else {
            vm.grayscale = 'grayscale';
            vm.barProgress = 0;
            vm.inactive = true;
            vm.switchEnabled = false;
            // we need to cancel any potential bar interval to make sure that
            // if the user rapidly activates and deactivates a tile everything
            // still works correctly
            $interval.cancel(progressBarInterval);
          }
        });

        $scope.$watch('dashboardTile.color', () => {
          vm.borderTop = {
            'border-top-width': 4 + 'px',
            'border-top-style': 'solid',
            'border-top-color': vm.color
          };

          vm.fontColor = {
            color: vm.color
          };

          vm.switchColor = {
            background: vm.color,
            'border-color': vm.color
          };
        });

        $scope.$watch('dashboardTile.data', () => {
          vm.usersArr = [];
          vm.vineyardArr = [];

          if (vm.data.status === orderStatus.STARTED) {
            vm.orderIsActive = 'active';
          } else if (vm.data.status === orderStatus.PAUSED) {
            vm.orderIsActive = 'pause';
          } else {
            vm.orderIsActive = '';
          }

          animateRemainingArea();
          if (vm.enabled) {
            animateBarProgress(vm.data.progress);
          }

          // Get all assigned Users
          angular.forEach(vm.data.assignedUserIds, (userId) => {
            vm.usersArr.push($filter('filter')(vm.users, {id: userId})[0]);
          });

          // Get all assigned Vineyards
          for (let i = 0; i < vm.data.siteIds.length; i++) {
            if (vm.data.siteIds.length > 1) {
              vm.vineyardArr.push('one');
              break;
            } else {
              vm.vineyardArr.push(CrudApi.get({
                entity: siteEntities,
                id: vm.data.siteIds[i]
              }));
            }
          }

          if (vm.data.progress < 30) {
            vm.progressClass = 'progress-danger';
          } else if (vm.data.progress >= 30 && vm.data.progress < 70) {
            vm.progressClass = 'progress-warning';
          } else if (vm.data.progress >= 70 && vm.data.progress <= 100) {
            vm.progressClass = 'progress-success';
          }
        }, true);
      }
    };
  }
}());

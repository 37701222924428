(function () {
  'use strict';

  /* @ngdoc object
   * @name orders
   * @description
   *
   */
  angular
    .module('orders', [
      'ui.router',
      'orders.list',
      'orders.edit'
    ]);
}());

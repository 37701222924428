(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name constants.constant:mapColors
   *
   * @description
   *
   */
  angular
    .module('constants')
    .constant('mapColors', {
      blue: '#2CB5E3',
      blueHover: '#1FA8D6',
      blueNewParcel: '#6CF5FF',
      blueNewParcelHover: '#5FE8F2',
      green: '#49E32C',
      greenHover: '#3CD61F',
      red: '#E33A2C',
      redHover: '#D62D1F',
      yellow: '#E3CF2C',
      yellowHover: '#D6C21F',
      grey: '#FFFFFF',
      greyHover: '#E6E6E6'
    });
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name constants.constant:apiBasePath
   *
   * @description
   *
   */
  angular
    .module('constants')
    .constant('apiBasePath', '/api/v1/');
}());

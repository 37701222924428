(function () {
  'use strict';

  class PoiEditCtrl {
    constructor($stateParams, CrudApi, $state, $q, UserService, permissions, PoiHelper, $scope, CustomerMapDataService, $timeout, IconHelper, $log) {
      let vm = this,
          promises = {};

      vm.mapId = 'poiEditMap';
      vm.new = $stateParams.id ? false : true;

      vm.canEdit = UserService.hasPerm(permissions.BASE_CAN_EDIT_POI);
      vm.canCreate = UserService.canAccessState('poi.create');

      // Initial message
      vm.message = $stateParams.message;

      // this is used to correctly track new vineyards that the user adds or
      // removes on the poi
      vm.originalSelectedSites = {};

      // initially we disable zooming because we load vineyard geometry and edited poi
      // icon independently of global poi icons. if the global poi icons are loaded first
      // activated zoom results in zooming to the default origin (somewhere in the ocean),
      // because there are only icons and no vineyards and all global icons are ignored
      // for zooming. instead we draw the icons into the map as soon as we have them but
      // zoom only once we have vineyard geometry and the edited poi icon
      vm.zoomToSelection = false;

      // onclick listener on the map to change the item position on click
      vm.onMapClick = onMapClick;

      // whether clicking on the map will change position of poi
      vm.mapEditEnabled = vm.new;

      // helper function to get SVG path for given icon
      vm.generateSVGIconPath = IconHelper.generateSVGIconPath;

      vm.sliderOptions = {
        floor: 0,
        ceil: 360,
        disabled: !vm.new && !vm.canEdit,
        translate: value => {
          return value + '&deg;';
        },
        onChange: () => {
          vm.form.rotation.$setDirty();
        }
      };

      vm.submenu = {
        items: []
      };
      if (vm.new || vm.canEdit) {
        // Add items to submenu
        vm.submenu.items.push({
          type: 'button',
          text: 'BUTTON_SAVE',
          click: () => {
            CrudApi.save({
              id: $stateParams.id,
              entity: 'poi'
            }, vm.item, (data) => {
              $state.go('poi.edit', {
                id: data.id,
                message: 'savingSuccess'
              }, {
                reload: true
              });
            }, (response) => {
              if (response.status === 410) {
                vm.message = 'entityHasChanged';
              } else {
                vm.message = 'savingError';
              }
            });
          }
        });
      }
      if (!vm.new && vm.canCreate) {
        vm.submenu.items.push({
          type: 'button',
          text: 'BUTTON_COPY_POI',
          click: () => {
            $state.go('poi.create', {
              fromPoi: vm.item.id
            });
          },
          isDisabled: () => false
        });
      }

      vm.mainmenu = {
        items: []
      };

      if (!$stateParams.id && !$stateParams.fromPoi) {
        promises.item = {
          siteIds: [],
          rotation: 0,
          color: '#ff0000'
        };
      } else {
        // Api call
        promises.item = CrudApi.get({
          id: $stateParams.id || $stateParams.fromPoi,
          entity: 'poi'
        }).$promise;
      }

      // Fetch mapdata of all vineyards
      promises.mapData = CustomerMapDataService.getCustomerMapsData();

      // available icons
      promises.types = CrudApi.query({
        entity: 'poi',
        subentity: 'types'
      }).$promise;

      $q.all(promises).then(({item, mapData, types}) => {
        vm.mapData = mapData;
        vm.types = types;

        vm.types.forEach(t => {
          t.categoryTranslated = PoiHelper.getTypeCategoryTranslation(t);
          t.labelTranslated = PoiHelper.getTypeLabelTranslation(t);
        });

        // we don't need to wait for the global pois, we just draw them when they are loaded
        CrudApi.query({
          entity: 'poi',
          onlyGlobal: item.siteIds.length === 0,
          vId: item.siteIds
        }, pois => {
          let iconPromises = {};
          pois.forEach(poi => iconPromises[poi.id] = PoiHelper.createIconFromPoi(poi));
          $q.all(iconPromises).then(iconData => {
            // the edited icon could be one of the global pois, so we need to overwrite
            // the data we got here with potentially already existing data (e.g. the edited
            // poi icon)
            vm.iconData = angular.extend(iconData, vm.iconData);
          });
        });

        if (angular.isDefined(item.id)) {
          let buttonLabel = 'POI_NEW';
          if (!$stateParams.fromPoi) {
            buttonLabel = PoiHelper.getTypeLabelTranslation(item.type);
          } else {
            // if we use the requested item only as the basis for a new icon, we make
            // sure to remove the existing item id
            // this is important if the item is a global poi because we want to display
            // both the existing and the new poi on the map. if the new item has the same
            // id as the existing one there will be only one icon on the map instead of two
            delete item.id;
          }
          vm.mainmenu.items.push({
            label: buttonLabel,
            translate: $stateParams.fromPoi,
            state: 'poi.edit',
            params: {
              id: $stateParams.id
            }
          });
          // for existing pois we need to chose the appropriate zoom mode.
          // if it is a global poi we don't want to include all vineyards in the zoom
          // otherwise we want to include the associated vineyards
          vm.zoomToSelection = item.siteIds.length ? 'both' : 'icons';
        } else {
          vm.mainmenu.items.push({
            label: 'POI_NEW',
            state: 'poi.edit'
          });
          // we preselect one of the types for new PoIs
          if (vm.types.length) {
            item.type = vm.types[0];
          }
          // if there is no poi yet we just want to show the vineyards
          vm.zoomToSelection = true;
        }

        // we disallow zoom ignore because we want to show the poi on the map even if it
        // is global
        PoiHelper.createIconFromPoi(item, true).then(icon => {
          // if the icon has no position yet (i.e. it is new) we don't need to add it
          if (icon.position) {
            // the global pois could already be loaded so we need to copy the icon into any
            // existing data while creating a new object to trigger a redraw
            vm.iconData = angular.extend({}, vm.iconData, {
              [item.id]: icon
            });
          }
        }).catch(() => {
          $log.warn('could not create map icon from:', item);
        }).finally(() => {
          vm.item = item;
          item.color = null;
          if (vm.item.type) {
            vm.categoryOpen = {
              [vm.item.type.category]: true
            };
          }

          angular.forEach(item.siteIds, vId => vm.originalSelectedSites[vId] = true);

          $scope.$watchGroup([
            'edit.item.color',
            'edit.item.rotation',
            'edit.item.longitude',
            'edit.item.latitude',
            'edit.item.type'
          ], () => {
            // again we disallow zoom ignore
            PoiHelper.createIconFromPoi(vm.item, true).then(newIcon => {
              // in case we create a new poi the initial call to this handler will include
              // the unfinished icon data (i.e. missing position)
              // to correctly track the initial drawing of the icon on the map we want to
              // make sure that we only take the new icon into account if it has a position
              if (newIcon.position) {
                // first we need to check if the icon was already drawn on the map
                // we can check this by checking if the icon is part of the iconData object.
                // because we only add icons to this object if they have a position we
                // can be sure that it has been drawn on the map if it exists.
                let iconAlreadyDrawn = vm.iconData.hasOwnProperty(item.id);
                if (!iconAlreadyDrawn) {
                  // in case the icon is not yet drawn on the map we have to trigger
                  // an icon redraw by creating a new iconData object
                  vm.iconData = angular.extend({}, vm.iconData);
                } else {
                  // if the icon is already drawn on the map, we will just update its options
                  vm.changeIconOptions = {
                    [item.id]: newIcon
                  };
                }
                // we update the iconData.
                // in case the icon is not yet drawn on the map this data will be used
                // for drawing it on the map.
                // in case the icon is drawn on the map updating the iconData object
                // is still important in case the global pois are somehow loaded after
                // this is executed so that the redraw triggered by the load will use
                // the most up to date icon options
                vm.iconData[item.id] = newIcon;
              }
              // we need to disable map zooming in the next tick because the map is initially
              // zoomed to include everything. if we disable zooming within this tick then the map
              // will not rezoom once to just show the icon.
              $timeout(() => vm.zoomToSelection = false, 0);
            });
          });
        });
      }).catch(() => {
        vm.message = 'loadingError';
      });

      $scope.$watch('edit.item.siteIds', (newSelection) => {
        if (angular.isDefined(newSelection)) {
          vm.newSites = {};
          vm.selectedSites = {};
          newSelection.forEach(vId => {
            if (!vm.originalSelectedSites[vId]) {
              vm.newSites[vId] = true;
            } else {
              vm.selectedSites[vId] = true;
            }
          });
          // if we have a new set of selected vineyards we want to zoom the main map
          // to include those
          // this avoids the user having to find the given vineyard twice (once for selecting
          // and once for adding the actual icon via the main map)
          if (newSelection.length) {
            vm.zoomToSelection = 'both';
            $timeout(() => vm.zoomToSelection = false, 0);
          }
        }
      }, true);

      function onMapClick(event) {
        if (angular.isObject(vm.item)) {
          vm.item.latitude = event.latLng.lat();
          vm.item.longitude = event.latLng.lng();
          vm.form.latitude.$setDirty();
          vm.form.longitude.$setDirty();
        }
      }
    }
  }

  PoiEditCtrl.$inject = ['$stateParams', 'CrudApi', '$state', '$q', 'UserService', 'permissions', 'PoiHelper', '$scope', 'CustomerMapDataService', '$timeout', 'IconHelper', '$log'];

  /**
   * @ngdoc object
   * @name poi.edit.controller:PoiEditCtrl
   *
   * @description
   *
   */
  angular
    .module('poi.edit')
    .controller('PoiEditCtrl', PoiEditCtrl);
}());

<form name="deletePoiForm">
    <div class="modal-header">
        <button type="button" class="close" ng-click="$dismiss();"><span>&times;</span></button>
        <h4 class="modal-title">{{'POI_DELETE' | translate}}</h4>
    </div>
    <div class="modal-body text-center">
        <h3>{{delete.item.type.labelTranslated}}</h3>
        <h4 ng-if="delete.item.siteIds.length" title="{{ delete.fullVineyards }}">
            {{ delete.reducedVineyards }}
        </h4>
        <h4 ng-if="!delete.item.siteIds.length">
            {{ 'POI_NO_VINEYARDS' | translate }}
        </h4>
    </div>
    <div class="modal-body" ng-bind-html="'POI_DELETE_CONFIRM' | translate"></div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" ng-click="$dismiss();">{{'ABORT' | translate}}</button>
        <button type="button" class="btn btn-danger" ng-click="$close();">{{'OK' | translate}}</button>
    </div>
</form>

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name constants.constant:startState
   *
   * @description
   *
   */
  angular
    .module('constants')
    .constant('startState', 'dashboard');
}());

(function () {
    'use strict';

    class EditCtrl {
        constructor($q, UserService, $stateParams, permissions, CrudApi, $state, siteEntities) {
            let vm = this;

            vm.message = $stateParams.message;
            vm.isCreate = angular.isUndefined($stateParams.id);
            vm.canEdit = angular.isDefined($stateParams.id) && UserService.hasPerm(permissions.WINERY_MANAGEMENT_CAN_EDIT_WINERY) ||
                angular.isUndefined($stateParams.id) && UserService.hasPerm(permissions.WINERY_MANAGEMENT_CAN_CREATE_WINERY);
            vm.canCreateVineyard = UserService.canAccessState('parcels.create');
            vm.hasAssetType = function (assetType) {
                return (UserService.getAssetType() === assetType);
            };

            // Add items to submenu
            if (vm.canEdit) {
                vm.submenu = {
                    items: [{
                        type: 'button',
                        text: 'BUTTON_SAVE',
                        click: () => {
                            CrudApi.save({
                                id: $stateParams.id,
                                entity: 'businesses'
                            }, vm.business, (data) => {
                                $state.go('businesses.edit', {
                                    id: data.id,
                                    message: 'savingSuccess'
                                }, {
                                    reload: true
                                });
                            }, (response) => {
                                if (response.status === 410) {
                                    vm.message = 'entityHasChanged';
                                } else {
                                    vm.message = 'savingError';
                                }
                            });
                        }
                    }]
                };
            }

            // Initialize mainmenu items
            vm.mainmenu = {
                items: []
            };

            if (!$stateParams.id) {
                // Main menu item "new business"
                vm.mainmenu.items.push({
                    label: 'WINERY_MANAGEMENT_WINERIES_NEW',
                    state: 'businesses.create'
                });
                // Initialize toggle
                vm.business = {
                    label: null,
                    ice: null
                };
            } else {
                let promises = {};
                promises.business = CrudApi.get({
                    id: $stateParams.id,
                    entity: 'businesses'
                }, (data) => {
                    vm.business = data;
                    // Main menu item "Business XYZ"
                    vm.mainmenu.items.push({
                        label: data.label,
                        translate: false,
                        state: 'businesses.edit',
                        params: {
                            id: $stateParams.id
                        }
                    });
                });
                promises.vineyards = CrudApi.query({
                    businessId: $stateParams.id,
                    entity: siteEntities
                }, (data) => {
                    vm.vineyards = data;
                }, () => {
                    vm.vineyards = [];
                });
                $q.all(promises).then(() => {
                    // $rootScope.loading = false;
                }, () => {
                    vm.message = 'loadingError';
                });
            }
        }
    }

    EditCtrl.$inject = ['$q', 'UserService', '$stateParams', 'permissions', 'CrudApi', '$state', 'siteEntities'];

    /**
     * @ngdoc object
     * @name businessManagement.businesses.edit.controller:EditCtrl
     *
     * @description
     *
     */
    angular
        .module('businessManagement.businesses.edit')
        .controller('BusinessesEditCtrl', EditCtrl);
}());

(function () {
  'use strict';

  angular
    .module('managementUnits.edit')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('managementUnits.create', {
        url: '/create',
        templateUrl: 'management-units/edit/views/edit.tpl.html',
        controller: 'ManagementUnitEditCtrl',
        controllerAs: 'edit'
      })
      .state('managementUnits.edit', {
        url: '/edit/{id:int}',
        templateUrl: 'management-units/edit/views/edit.tpl.html',
        controller: 'ManagementUnitEditCtrl',
        controllerAs: 'edit',
        params: {
          message: null
        }
      });
  }
}());

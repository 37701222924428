<div class="panel panel-primary">
  <div class="panel-heading">{{'DASHBOARD_TODO' | translate}}</div>
  <table class="table table-striped table-bordered table-hover">
    <tr>
      <th ng-if="alarmPanel.accountId !== 1"></th>
      <th>{{'ORDERS' | translate}}</th>
      <th>{{'ORDER_DEADLINE_DATE' | translate}}</th>
      <th>{{'USERS' | translate}}</th>
    </tr>
    <tr ng-repeat="task in alarmPanel.tasks | orderBy: 'deadlineDate'">
      <td ng-if="alarmPanel.accountId !== 1">
          <img class="image-responsive" width="24" src="{{::alarmPanel.orderVisibilityModeIcon(task)}}" alt=""/>
      </td>
      <td class="truncateText"><span><a ui-sref="orders.edit({id: task.id, type: 'regular', v: task.siteIds})">{{task.label}}</a></span></td>
      <td ng-class="{'colorBg': task.isOverdue}">
          {{task.deadlineDate | date:'dd.MM.yyyy'}}
      </td>
      <td>{{task.userNames}}
        <div class="colorBg" ng-if="task.assignedUserIds.length < 1">
          {{'DASHBOARD_NO_USERS_ASSIGNED' | translate}}
        </div>
      </td>
    </tr>
  </table>
</div>
<a ui-sref="orders.list" class="pull-right">{{'DASHBOARD_ALL_ENTRIES' | translate}}</a>

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name timeTracking.filter:toHours
   *
   * @description a filter that formats a number of seconds to the form "hh:mm:ss"
   *
   * @param {Integer} input a number of seconds
   * @returns {String} a string of the form "hh:mm:ss"
   *
   */
  angular
    .module('timeTracking')
    .filter('toHours', toHours);

  function toHours() {
    return (input) => {
      if (!angular.isNumber(input)) {
        return input;
      }

      let minutes = Math.ceil(input / 60),
          hours = Math.floor(minutes / 60);
      minutes %= 60;

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };
  }
}());

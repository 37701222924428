(function () {
  'use strict';

  class ExportsListCtrl {
    constructor(CrudApi, $stateParams, $q, $state, $document, $translate, $uibModal, UserService, permissions, siteEntities) {
      let vm = this,
          promises = {};

      vm.UserService = UserService;
      vm.exports = [];
      vm.vineyards = {};
      vm.canDelete = {
        BusinessIntelligenceExportJob: UserService.hasPerm(permissions.TIME_TRACKING_CAN_USE),
        ClassificationExportJob: UserService.hasPerm(permissions.BONITUR_CAN_USE),
        PlantProtectionExportJob: UserService.hasPerm(permissions.PLANT_PROTECTION_CAN_DELETE_EXPORT),
        FertilizationPhDemandExportJob: UserService.hasPerm(permissions.FERTILIZATION_CAN_DELETE_PH_DEMAND_EXPORT),
        FertilizationNutrientComparisonExportJob: UserService.hasPerm(permissions.FERTILIZATION_CAN_DELETE_NUTRIENT_COMPARISON_EXPORT),
        FertilizationNDemandExportJob: UserService.hasPerm(permissions.FERTILIZATION_CAN_DELETE_N_DEMAND_EXPORT)
      };
      vm.canDownload = {
        BusinessIntelligenceExportJob: UserService.hasPerm(permissions.TIME_TRACKING_CAN_USE),
        ClassificationExportJob: UserService.hasPerm(permissions.BONITUR_CAN_USE),
        PlantProtectionExportJob: UserService.hasPerm(permissions.PLANT_PROTECTION_CAN_DOWNLOAD_EXPORT),
        FertilizationPhDemandExportJob: UserService.hasPerm(permissions.FERTILIZATION_CAN_DOWNLOAD_PH_DEMAND_EXPORT),
        FertilizationNutrientComparisonExportJob: UserService.hasPerm(permissions.FERTILIZATION_CAN_DOWNLOAD_NUTRIENT_COMPARISON_EXPORT),
        FertilizationNDemandExportJob: UserService.hasPerm(permissions.FERTILIZATION_CAN_DOWNLOAD_N_DEMAND_EXPORT)
      };
      vm.translatedJobs = {};
			vm.submenu = {
				items: []
			};
			vm.message = $stateParams.message;
			vm.filterJobType = $stateParams.jobType;

      vm.delete = deleteExport;
      vm.getFiles = getFiles;

      $translate.onReady(() => {
        vm.translatedJobs.BusinessIntelligenceExportJob = $translate.instant('export.types.BusinessIntelligenceExportJob');
        vm.translatedJobs.ClassificationExportJob = $translate.instant('export.types.ClassificationExportJob');
        vm.translatedJobs.PlantProtectionExportJob = $translate.instant('export.types.PlantProtectionExportJob');
        vm.translatedJobs.FertilizationNutrientComparisonExportJob = $translate.instant('export.types.FertilizationNutrientComparisonExportJob');
        vm.translatedJobs.FertilizationPhDemandExportJob = $translate.instant('export.types.FertilizationPhDemandExportJob');
        vm.translatedJobs.FertilizationNDemandExportJob = $translate.instant('export.types.FertilizationNDemandExportJob');

				vm.jobTypes = [
                    {label: vm.translatedJobs.BusinessIntelligenceExportJob, value: 'BusinessIntelligenceExportJob'},
					{label: vm.translatedJobs.ClassificationExportJob, value: 'ClassificationExportJob'},
					{label: vm.translatedJobs.PlantProtectionExportJob, value: 'PlantProtectionExportJob'},
					{label: vm.translatedJobs.FertilizationNutrientComparisonExportJob, value: 'FertilizationNutrientComparisonExportJob'},
					{label: vm.translatedJobs.FertilizationPhDemandExportJob, value: 'FertilizationPhDemandExportJob'},
					{label: vm.translatedJobs.FertilizationNDemandExportJob, value: 'FertilizationNDemandExportJob'},
				];

				vm.submenu.items.push({
					type: 'dropdownExport',
					position: 'right',
					model: vm.filterJobType,
					values: vm.jobTypes,
					addPlaceholderOption: true,
					change: (jobType) => {
						$state.go($state.current.name, {
							message: null,
							jobType: jobType
						}, {
							reload: true
						});
					}
				});
      });

      promises.vineyards = CrudApi.query({
        entity: siteEntities,
          subentity: 'usable',
      }).$promise;

      promises.exports = CrudApi.query({
        entity: 'jobs',
				jobType: vm.filterJobType
      }).$promise;

      $q.all(promises).then(({exports, vineyards}) => {
        vm.exports = exports;
        vineyards.forEach(vineyard => {
          vm.vineyards[vineyard.id] = vineyard;
        });
        vm.exports.forEach(exp => {
          getTruncatedString(exp);
        });
      }, () => {
        vm.message = 'loadingError';
      });

      function getFiles(exp, open) {
        if (open) {
          CrudApi.query({
            entity: 'jobs',
            id: exp.id,
            subentity: 'download'
          }, (data) => {
            exp.files = data;
          }, () => {
            vm.message = 'loadingError';
          });
        } else {
          exp.files = null;
        }
      }

      function deleteExport(exp) {
        let popup = $uibModal.open({
          controller: 'ExportsDeleteCtrl',
          controllerAs: 'ExportsDelete',
          templateUrl: 'exports/exports/delete/views/delete.tpl.html',
          resolve: {
            item: () => {
              return exp;
            }
          }
        });
        popup.result.then(() => {
          CrudApi.delete({
            entity: 'jobs',
            id: exp.id
          }, () => {
            $state.go($state.current.name, {
              message: 'deletingSuccess'
            }, {
              reload: true
            });
          }, () => {
            vm.message = 'deletingError';
          });
        });
      }

      function getTruncatedString(exp) {
        if (angular.isDefined(exp.vineyardIds) && exp.vineyardIds.length !== 0) {
          let str = '', index = Math.min(exp.vineyardIds.length, 3);
          for (let i = 0; i < index; i++) {
            str += vm.vineyards[exp.vineyardIds[i]].label;
            if (i !== index - 1) {
              str += ', ';
            }
          }
          if (index !== exp.vineyardIds.length) {
            str += ` ${$translate.instant('AND')} ${exp.vineyardIds.length - index} ${$translate.instant('MORE')}`;
          }
          exp.vineyards = str;
        }
      }
    }
  }

  ExportsListCtrl.$inject = ['CrudApi', '$stateParams', '$q', '$state', '$document', '$translate', '$uibModal', 'UserService', 'permissions', 'siteEntities'];

  /**
   * @ngdoc object
   * @name exports.list.controller:ExportsListCtrl
   *
   * @description
   *
   */
  angular
    .module('exports.exports.list')
    .controller('ExportsListCtrl', ExportsListCtrl);
}());

(function () {
  'use strict';

  class PlantProtectionExportsEditCtrl {
    constructor($stateParams, $state, UserService, CrudApi, moment, SettingsManager) {
      let vm = this;
      vm.UserService = UserService;
      vm.message = $stateParams.message;
      vm.export = {period: createNewPeriodObject(),
        vineyardIds: []};

      vm.moment = moment;

      vm.submenu = {
        items: [{
          type: 'button',
          text: 'BUTTON_SAVE',
          click: () => {
            vm.export.fromTime = vm.export.period.periodStartMoment.toISOString();
            vm.export.toTime = vm.export.period.periodEndMoment.toISOString();
            vm.export.jobType = 'PlantProtectionExportJob';
            vm.export.locale = SettingsManager.getValue('currentLocale');
            CrudApi.save({
              entity: 'jobs'
            }, vm.export, () => {
              $state.go('exports.list', {
                message: 'savingSuccess'
              }, {
                reload: true
              });
            }, () => {
              vm.message = 'savingError';
            });
          }
        }]
      };

      vm.mainmenu = {
        items: [{
          label: 'PLANT_PROTECTION_EXPORT_NEW',
          state: 'plantProtection.exports.edit'
        }]
      };

      vm.periodStartOptions = {
        initDate: new Date()
      };
      vm.periodEndOptions = {
        initDate: new Date()
      };
      vm.validatePeriod = () => {
        let valid = true,
            periodStart = vm.export.period.periodStartMoment,
            periodEnd = vm.export.period.periodEndMoment;
        if (periodEnd.isBefore(periodStart, 'day')) {
          valid = false;
        }
        return valid;
      };

      function createNewPeriodObject() {
        let now = moment.utc();
        return {
          periodStart: now.toDate(),
          periodEnd: now.toDate(),
          periodStartMoment: moment.utc(now),
          periodEndMoment: moment.utc(now)
        };
      }
    }
  }

  PlantProtectionExportsEditCtrl.$inject = ['$stateParams', '$state', 'UserService', 'CrudApi', 'moment', 'SettingsManager'];
  /**
   * @ngdoc object
   * @name exports.edit.controller:EditCtrl
   *
   * @description
   *
   */
  angular
    .module('plantProtection.exports.edit')
    .controller('PlantProtectionExportsEditCtrl', PlantProtectionExportsEditCtrl);
}());

<div class="parcel-selection"
     ng-class="{
       'popup': parcelsSelection.popup
     }">
  <ng-form ng-if="parcelsSelection.vineyards"
           name="parcelSelectionForm">
    <!-- EDIT: FALSE -->
    <div ng-class="{
           'panel panel-default': !parcelsSelection.popup
         }"
         ng-if="!parcelsSelection.edit">
      <ul class="list-group scrollbox">
        <li class="list-group-item"
            ng-repeat="vineyard in parcelsSelection.vineyards | filter:{selected: true}:true"
            ng-if="parcelsSelection.statusFilter === null || parcelsSelection.siteStatus[vineyard.id] === parcelsSelection.statusFilter"
            ng-class="{
              'list-group-item-primary': !parcelsSelection.siteStatus[vineyard.id] || parcelsSelection.siteStatus[vineyard.id] === parcelsSelection.userSiteStatus.NONE,
              'list-group-item-success': parcelsSelection.siteStatus[vineyard.id] === parcelsSelection.userSiteStatus.FINISHED,
              'list-group-item-danger': parcelsSelection.siteStatus[vineyard.id] === parcelsSelection.userSiteStatus.REDO
            }">
          <div class="row">
            <div class="col-xs-5">
              {{vineyard.label}}
                <span ng-if="!vineyard.isActive"> ({{'ADMIN_CUSTOMER_INFO_INACTIVE' | translate}})</span>
            </div>
            <div class="col-xs-1">
                <span
                    style="color: red"
                    popover-trigger="'mouseenter'"
                    popover-append-to-body="true"
                    popover-placement="'right'"
                    uib-popover-html="parcelsSelection.createRestrictionTemplate(parcelsSelection.restrictions.get(vineyard.uuid))"
                    class="glyphicon glyphicon-exclamation-sign"
                    ng-if="parcelsSelection.restrictions.get(vineyard.uuid)">
                </span>
            </div>
            <div class="col-xs-3">
              <a style="margin-right: 5px; cursor: pointer;"
                 ng-if="parcelsSelection.isBonitur && parcelsSelection.siteStatus[vineyard.id] === parcelsSelection.userSiteStatus.NONE"
                 ng-click="parcelsSelection.goToBonitur(vineyard)">{{'basicTerms.finish' | translate}}</a>
            </div>
            <div class="col-xs-3 text-muted text-right"
                 ng-if="parcelsSelection.showAreas">
              <span ng-if="!parcelsSelection.showAreasBrutto">{{vineyard.area | number:4}}</span>
              <span ng-if="parcelsSelection.showAreasBrutto">{{vineyard.bruttoArea | number:4}}</span>
              {{'PARCELS_PARAM_AREA_UNIT' | translate}}
            </div>
          </div>
        </li>
      </ul>
      <div ng-class="{
             'panel-footer': !parcelsSelection.popup
           }"
           ng-if="!parcelsSelection.ngDisabled">
        <div class="row">
          <div class="col-xs-2">
            <button class="btn btn-sm btn-link"
                    ng-disabled="parcelsSelection.isReadOnly"
                    ng-click="parcelsSelection.edit = !parcelsSelection.edit">
                      {{'EDIT' | translate}}
            </button>
          </div>
          <div class="col-xs-4">
            <select class="form-control"
                    ng-model="parcelsSelection.statusFilter">
              <option ng-value="null">{{'PARCELS_PARAM_USER_SITE_STATUS_NO_FILTER' | translate}}</option>
              <option ng-value="parcelsSelection.userSiteStatus.NONE">{{'PARCELS_PARAM_USER_SITE_STATUS_NONE' | translate}}</option>
              <option ng-value="parcelsSelection.userSiteStatus.REDO">{{'PARCELS_PARAM_USER_SITE_STATUS_REDO' | translate}}</option>
              <option ng-value="parcelsSelection.userSiteStatus.FINISHED">{{'PARCELS_PARAM_USER_SITE_STATUS_FINSIHED' | translate}}</option>
            </select>
          </div>
          <div class="col-xs-6"
               ng-if="parcelsSelection.showAreas">
            <div class="input-group"
                 ng-include src="'parcels/selection/directives/parcels-selection-directive.area-part.tpl.html'">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- EDIT: TRUE -->
    <div ng-class="{
           'panel panel-default': !parcelsSelection.popup
         }"
         ng-if="parcelsSelection.edit"
         ng-class="{
           'panel-default': parcelsSelection.selection.$valid,
           'panel-danger': parcelsSelection.selection.$invalid,
           'panel-info': !parcelsSelection.vineyards.length
         }">
      <ul class="list-group scrollbox"
          ng-if="parcelsSelection.vineyards.length"
          ng-hide="parcelsSelection.graphicalMode">
        <li class="list-group-item"
            ng-repeat="vineyard in parcelsSelection.vineyards"
            ng-class="{
              'list-group-item-primary': !parcelsSelection.siteStatus[vineyard.id] || parcelsSelection.siteStatus[vineyard.id] === parcelsSelection.userSiteStatus.NONE,
              'list-group-item-success': parcelsSelection.siteStatus[vineyard.id] === parcelsSelection.userSiteStatus.FINISHED,
              'list-group-item-danger': parcelsSelection.siteStatus[vineyard.id] === parcelsSelection.userSiteStatus.REDO
            }">
          <div class="row">
            <div class="col-xs-5">
              <input type="checkbox"
                     value="{{vineyard.id}}"
                     ng-model="vineyard.selected"
                     ng-change="parcelsSelection.updateSelection()"
                     ng-disabled="!vineyard.isActive"
                     style="margin-right: 15px;" />
              {{vineyard.label}}
                <span ng-if="!vineyard.isActive"> ({{'ADMIN_CUSTOMER_INFO_INACTIVE' | translate}})</span>
            </div>
            <div class="col-xs-1">
                <span
                    style="color: red"
                    popover-trigger="'mouseenter'"
                    popover-append-to-body="true"
                    popover-placement="'right'"
                    uib-popover-html="parcelsSelection.createRestrictionTemplate(parcelsSelection.restrictions.get(vineyard.uuid))"
                    class="glyphicon glyphicon-exclamation-sign"
                    ng-if="parcelsSelection.restrictions.get(vineyard.uuid)">
                </span>
            </div>
            <div class="col-xs-3 text-right">
              <a style="margin-right: 5px; cursor: pointer;"
                 ng-if="parcelsSelection.redoRight && parcelsSelection.siteStatus[vineyard.id] === parcelsSelection.userSiteStatus.FINISHED"
                 ng-click="parcelsSelection.issueRedo(vineyard)">{{'ORDER_PARCEL_STATE_REDO' | translate}}</a>
              <a style="margin-right: 5px; cursor: pointer;"
                 ng-if="parcelsSelection.isBonitur && parcelsSelection.siteStatus[vineyard.id] === parcelsSelection.userSiteStatus.NONE"
                 ng-click="parcelsSelection.goToBonitur(vineyard)">Abschließen</a>
            </div>
            <div class="col-xs-3 text-muted text-right"
                 ng-if="parcelsSelection.showAreas">
              <span ng-if="!parcelsSelection.showAreasBrutto">{{vineyard.area | number:4}}</span>
              <span ng-if="parcelsSelection.showAreasBrutto">{{vineyard.bruttoArea | number:4}}</span>
              {{'PARCELS_PARAM_AREA_UNIT' | translate}}
            </div>
          </div>
        </li>
      </ul>
      <vc-map-base map-id="'parcel-select-map-id'"
            ng-if="parcelsSelection.mapEnabled && parcelsSelection.mapShouldBeInitialized"
            ng-show="parcelsSelection.graphicalMode"
            vineyard-data="parcelsSelection.mapData"
            determine-vineyard-options="parcelsSelection.getVineyardOptions(null, parcelsSelection.selection.indexOf(vineyardId) > -1, false)"
            change-vineyard-options="parcelsSelection.changeVineyardOptions"
            on-vineyard-mouse-over="parcelsSelection.onMouseChange(vineyardId, true)"
            on-vineyard-mouse-out="parcelsSelection.onMouseChange(vineyardId, false)"
            selection-mode="true"
            on-vineyards-selected="parcelsSelection.onVineyardsSelected(vineyardIds, ctrlPressed, singleVineyard)"></vc-map-base>
      <!-- SELECT/ DESELECT ALL -->
      <div class="panel-footer"
           ng-if="parcelsSelection.vineyards.length">
        <div class="row">
          <div ng-class="{'col-xs-6': parcelsSelection.showAreas, 'col-xs-12': !parcelsSelection.showAreas}">
            <div class="btn-group" style="margin-right: 5px">
              <button type="button"
                      class="btn btn-sm btn-default"
                      ng-click="parcelsSelection.selectAll(true, parcelSelectionForm)"
                      ng-disabled="parcelsSelection.selection.$modelValue.length >= parcelsSelection.vineyards.length"
                      uib-tooltip="{{ 'SELECT_ALL' | translate }}"
                      tooltip-append-to-body="true">
                        <span class="glyphicon glyphicon-check" aria-hidden="true"></span>
              </button>
              <button type="button"
                      class="btn btn-sm btn-default"
                      ng-click="parcelsSelection.selectAll(false, parcelSelectionForm)"
                      ng-disabled="!parcelsSelection.selection.$modelValue.length"
                      uib-tooltip="{{ 'DESELECT_ALL' | translate }}"
                      tooltip-append-to-body="true">
                        <span class="glyphicon glyphicon-unchecked" aria-hidden="true"></span>
              </button>
            </div>
            <div class="btn-group">
              <button class="btn btn-default btn-sm"
                      ng-model="parcelsSelection.graphicalMode"
                      uib-btn-radio="false"
                      uib-tooltip="{{ 'PARCELS_LIST_SELECTION' | translate }}"
                      tooltip-append-to-body="true">
                        <span class="glyphicon glyphicon-list" aria-hidden="true"></span>
              </button>
              <button class="btn btn-default btn-sm"
                      ng-change="parcelsSelection.mapShouldBeInitialized = parcelsSelection.mapShouldBeInitialized || parcelsSelection.graphicalMode"
                      ng-model="parcelsSelection.graphicalMode"
                      uib-btn-radio="true"
                      uib-tooltip="{{ 'PARCELS_GRAPHICAL_SELECTION' | translate }}"
                      tooltip-append-to-body="true">
                        <span class="glyphicon glyphicon-globe" aria-hidden="true"></span>
              </button>
            </div>
          </div>
          <div class="col-xs-6"
               ng-if="parcelsSelection.showAreas">
            <div class="input-group"
                 ng-include src="'parcels/selection/directives/parcels-selection-directive.area-part.tpl.html'">
            </div>
          </div>
        </div>
      </div>

      <!-- EMPTY LIST -->
      <div class="panel-body"
           ng-if="!parcelsSelection.vineyards.length">
        <div class="alert alert-info"
           style="margin: 0;">
           {{'MESSAGE_INFO_NO_PARCEL_FILTERED' | translate}}
        </div>
      </div>
      <!-- ERRORS -->
      <div class="panel-body"
           ng-if="parcelsSelection.selection.$invalid">
        <p class="help-block"
           style="margin: 0;"
           ng-messages="parcelsSelection.selection.$error">
           <span ng-message="required">{{'FORM_HELP_REQUIRED_CHECKLIST' | translate}}</span>
           <span ng-message="empty">{{'FORM_HELP_EMPTY' | translate}}</span>
        </p>
      </div>
      <!-- FILTERS -->
      <parcels-filter class="panel-body parcel-selection-filters"
                      ng-model="parcelsSelection.filters">
      </parcels-filter>
      <div class="panel-footer text-right">
        <button type="button"
                class="btn btn-sm btn-link"
                ng-click="parcelsSelection.unfilter()">
                  {{'ORDER_EXTENDED_FILTERS_RESET' | translate}}
        </button>
        <button type="button"
                class="btn btn-sm btn-primary"
                ng-click="parcelsSelection.filter()">
                  {{'ORDER_EXTENDED_FILTERS_APPLY' | translate}}
        </button>
      </div>
    </div>
  </ng-form>
</div>

(function () {
    'use strict';

    angular
        .module('businessManagement.businesses.edit')
        .config(config);

    function config($stateProvider, permissions) {
        $stateProvider
            .state('businesses.edit', {
                url: '/edit/{id:int}',
                params: {
                    message: {
                        value: null
                    }
                },
                templateUrl: 'business-management/businesses/edit/views/edit.tpl.html',
                controller: 'BusinessesEditCtrl',
                controllerAs: 'edit'
            })
            .state('businesses.create', {
                url: '/create',
                templateUrl: 'business-management/businesses/edit/views/edit.tpl.html',
                controller: 'BusinessesEditCtrl',
                controllerAs: 'edit',
                data: {
                    requiredPerms: [
                        permissions.WINERY_MANAGEMENT_CAN_USE,
                        permissions.WINERY_MANAGEMENT_CAN_CREATE_WINERY
                    ]
                }
            });
    }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dashboard.directive:commentPanel
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="dashboard">
       <file name="index.html">
        <comment-panel></comment-panel>
       </file>
     </example>
   *
   */
  angular
    .module('dashboard')
    .directive('commentPanel', commentPanel);

  function commentPanel() {
    return {
      restrict: 'E',
      scope: {
        comments: '<'
      },
      templateUrl: 'dashboard/directives/comment-panel-directive.tpl.html',
      replace: false,
      bindToController: true,
      controllerAs: 'commentPanel',
      controller() {
      }
    };
  }
}());

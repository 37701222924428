(function () {
  'use strict';

  class TemporaryWorkersEditCtrl {
    constructor($state, $stateParams, CrudApi, ModuleService, permissions, $q) {
      let vm = this,
          promises = {};
      vm.sorter = $stateParams.sorter || 'lastname';
      vm.dir = $stateParams.dir || 'asc';

      // Serves as minimal date for the datepicker
      vm.today = new Date();

      // Initial message
      vm.message = $stateParams.message;

      // Set number boundaries for entering the quantity
      vm.quantity = {
        min: 1,
        max: 50
      };

      promises.moduleServiceInitialized = ModuleService.initialize();
      promises.masters = CrudApi.query({
        entity: 'users',
        sortBy: vm.sorter,
        sortDir: vm.dir
      }, (data, headers, status) => {
        vm.total = headers()['x-total-count'];
        if (status === 204) {
          vm.message = 'mandatoryToHaveWorkers';
        }
      }).$promise;
      if (!$stateParams.id) {
        promises.item = {
          quantity: 50,
          expirationDate: vm.today
        };
      } else {
        // Api call
        promises.item = CrudApi.get({
          id: $stateParams.id,
          entity: 'temporaryworkerpackage'
        }).$promise;
      }

      // Add items to submenu
      vm.submenu = {
        items: [{
          type: 'button',
          text: 'BUTTON_SAVE',
          click: () => {
            CrudApi.save({
              id: $stateParams.id,
              entity: 'temporaryworkerpackage'
            }, vm.item, (data) => {
              $state.go('temporaryWorkers.edit.download', {
                id: data.id,
                message: 'savingSuccess'
              });
            }, (response) => {
              if (response.status === 410) {
                vm.message = 'entityHasChanged';
              } else {
                vm.message = 'savingError';
              }
            });
          }
        }]
      };

      // Initialize mainmenu items
      vm.mainmenu = {
        items: []
      };

      $q.all(promises).then(data => {
        vm.item = data.item;
        if (angular.isDefined(vm.item.id)) {
          // TOD: For now this is working to fix the day + 1 issue but we will remove it once Florian will fix this in backend by adding Timezone Offset
          let d = new Date(vm.item.expirationDate);
          d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
          vm.item.expirationDate = d;

          // Main menu item "equipment XYZ"
          vm.mainmenu.items.push({
            label: vm.item.name,
            translate: false,
            state: 'temporaryWorkers.edit',
            params: {
              id: vm.item.id
            }
          });
        } else {
          vm.mainmenu.items.push({
            label: 'TEMPORARY_WORKER_NEW',
            state: 'temporaryWorkers.edit'
          });
        }

        vm.masters = data.masters;
        vm.masters.forEach(user => user.canDoOrders = ModuleService.userHasPermissions(user, [permissions.BASE_CAN_DO_ORDERS]));

        if (vm.masters.filter(m => !m.active).map(m => m.id).indexOf(vm.item.masterUserId) > -1) {
          // the assigned master user is deactivated
          delete vm.item.masterUserId;
        }
      }).catch(() => vm.message = 'loadingError');
    }
  }

  TemporaryWorkersEditCtrl.$inject = ['$state', '$stateParams', 'CrudApi', 'ModuleService', 'permissions', '$q'];

  /**
   * @ngdoc object
   * @name temporaryWorkers.edit.controller:TemporaryWorkersEditCtrl
   *
   * @description
   *
   */
  angular
    .module('temporaryWorkers.edit')
    .controller('TemporaryWorkersEditCtrl', TemporaryWorkersEditCtrl);
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name poi.delete
   * @description
   *
   */
  angular
    .module('poi.delete', []);
}());

<form name="deleteManagementUnitForm">
  <div class="modal-header">
    <button type="button" class="close" ng-click="$dismiss();"><span>&times;</span></button>
    <h4 class="modal-title">{{'MANAGEMENT_UNIT_DELETE' | translate}}</h4>
  </div>
  <div class="modal-body text-center">
    <h3>{{ManagementUnitDelete.item.label}}</h3>
  </div>
  <div class="modal-body" ng-bind-html="'MANAGEMENT_UNIT_DELETE_CONFIRM' | translate"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" ng-click="$dismiss();">{{'ABORT' | translate}}</button>
    <button type="button" class="btn btn-danger" ng-click="$close();">{{'OK' | translate}}</button>
  </div>
</form>
(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name parcels.selection.directive:parcelsFilter
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
     <example module="parcels.selection">
       <file name="index.html">
        <parcels-filter></parcels-filter>
       </file>
     </example>
   *
   */
  angular
    .module('parcels.selection')
    .directive('parcelsFilter', parcelsFilter);

  function parcelsFilter() {
    return {
      restrict: 'E',
      require: {
        selectedFilters: 'ngModel'
      },
      templateUrl: 'parcels/selection/directives/parcels-filter-directive.tpl.html',
      bindToController: true,
      replace: true,
      controllerAs: 'parcelsFilter',
      controller: 'ParcelsFilterCtrl'
    };
  }
}());

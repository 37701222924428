(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name vcMain.directive:mainMenu
     * @restrict EA
     * @element
     *
     * @description
     *
     * @example
     <example module="vcMain">
     <file name="index.html">
     <main-menu></main-menu>
     </file>
     </example>
     *
     */
    angular
        .module('vcMain')
        .directive('mainMenu', mainMenu);

    mainMenu.$inject = ['$translate', '$cookies', 'SettingsManager'];

    function mainMenu($translate, $cookies, SettingsManager) {
        return {
            restrict: 'E',
            scope: {
                items: '='
            },
            templateUrl: 'directives/main-menu-directive.tpl.html',
            replace: true,
            bindToController: true,
            controllerAs: 'mainMenu',
            controller: ['UserService', 'permissions', function (UserService, permissions) {
                let vm = this;
                vm.accountId = UserService.user.accountId;
                vm.customerId = UserService.user.customerId;
                vm.routeToService = UserService.routeToService;
                vm.canAccessState = UserService.canAccessState;
                vm.canAccessTimeTrackingTimeLogs = UserService.hasPerm(permissions.TIME_TRACKING_CAN_READ_OWN_TIME_LOGS) || UserService.hasPerm(permissions.TIME_TRACKING_CAN_READ_ALL_TIME_LOGS);
                vm.canUseBonitur = UserService.hasPerm(permissions.BONITUR_CAN_USE);
                vm.canUseHarvestPlanning = UserService.hasPerm(permissions.HARVEST_PLANNING_CAN_USE);
                vm.canUseStockManagement = UserService.hasPerm(permissions.STOCK_MANAGEMENT_CAN_USE);
                vm.canUseProposal = UserService.hasPerm(permissions.PROPOSAL_CAN_USE);
                vm.canUseRestrictions = UserService.hasPerm(permissions.BASE_CAN_CREATE_RESTRICTION);
                vm.canUseTimeSettings = UserService.hasPerm(permissions.TIME_TRACKING_CAN_CHANGE_SETTINGS);

                vm.customNavbarStyle = {};

                initUserflow();

                if (vm.accountId === 9) {
                    vm.customNavbarStyle = {
                        'background-color':'#575651',
                        'border-color':'#575651'
                    };
                }

                if (vm.accountId === 20) {
                    vm.customNavbarStyle = {
                        'background-color':'#81827b',
                        'border-color':'#81827b'
                    };
                }

                vm.currentLocale = $cookies.get('NG_TRANSLATE_LANG_KEY');
                vm.changeLanguage = (languageKey) => {
                    $translate.use(languageKey);
                    vm.currentLocale = languageKey;
                    SettingsManager.setValue('currentLocale', vm.currentLocale);
                    loadLocalizedLabels();
                    window.userflow.updateUser({
                        locale_code: languageKey
                    });
                };
                vm.currentLocale = window.localStorage.getItem('NG_TRANSLATE_LANG_KEY');
                SettingsManager.setValue('currentLocale', vm.currentLocale);
                loadLocalizedLabels();

                function loadLocalizedLabels() {
                    vm.bonitur = UserService.getLocalizedLabelOrNull('bonitur');
                    vm.businessLabel = UserService.getLocalizedLabelOrNull('businessLabel');
                    vm.tenantLabel = UserService.getLocalizedLabelOrNull('tenantLabel');
                }

                function initUserflow() {
                    const userLangCode = window.localStorage.getItem('NG_TRANSLATE_LANG_KEY');
                    window.userflow.identify(UserService.user.id, {
                        email: UserService.user.email,
                        locale_code: userLangCode
                    });
                    window.userflow.group(UserService.user.accountId, {
                        name: UserService.user.accountId
                    });
                    window.userflow.updateUser({
                        locale_code: userLangCode
                    });
                }
            }]
        };
    }
}());

(function () {
  'use strict';

  class TimeTrackingCostCentersListCtrl {
    constructor($state, $stateParams, $uibModal, CrudApi, UserService, permissions) {
      let vm = this;

      vm.UserService = UserService;
      vm.total = 0;
      vm.filter = $stateParams.filter;
      vm.message = $stateParams.message;
      vm.canDelete = UserService.hasPerm(permissions.TIME_TRACKING_CAN_DELETE_COST_CENTER);
      vm.costCenterDepthMap = [];
      vm.leadingWhiteSpace = '    ';
      vm.ownEntity = (customerId) => {
          return UserService.ownEntity(customerId);
      };

      // Add submenu items
      vm.submenu = {
        items: [{
          type: 'button',
          text: 'TIME_TRACKING_COST_CENTERS_NEW',
          hide: !UserService.hasPerm(permissions.TIME_TRACKING_CAN_CREATE_COST_CENTER),
          click: () => {
            $state.go('timeTracking.costCenters.create');
          }
        }]
      };

      // Fetch article types
      vm.costCenters = CrudApi.query({
        entity: 'costCenter',
        customOnly: true
      }, (data, headers) => {
        var result;
        vm.total = headers()['x-total-count'];
        if (!data.length) {
          vm.message = 'noData';
        } else {
          result = makeFlat(data, 0);

          vm.costCenters = result;
        }
      }, () => {
        vm.total = 0;
        vm.message = 'loadingError';
      });

      function makeFlat(costCenters, level) {
        let result = [];
        costCenters.sort((a, b) => a.label.localeCompare(b.label));
        costCenters.forEach(costCenter => {
          result.push(costCenter);
          costCenter.level = level;
          result.push(...makeFlat(costCenter.children, level + 1));
        });
        return result;
      }

      vm.editClick = (costCenter) => {
        $state.go('timeTracking.costCenters.edit', {id: costCenter.id});
      };

      // Delete Popup
      vm.delete = (item) => {
        let popup = $uibModal.open({
          controller: 'TimeTrackingCostCentersDeleteCtrl',
          controllerAs: 'delete',
          templateUrl: 'time-tracking/cost-centers/delete/views/delete.tpl.html',
          resolve: {
            item: () => {
              return item;
            }
          }
        });
        popup.result.then((costCenter) => {
          CrudApi.delete({
            entity: 'costCenter',
            subentityid: item.id,
            replaceWithId: costCenter.id,
            lastModified: item.lastModified
          }, () => {
            $state.go($state.current.name, {
              message: 'deletingSuccess'
            }, {
              reload: true
            });
          }, (response) => {
            if (response.status === 410) {
              vm.message = 'entityHasChanged';
            } else {
              vm.message = 'deletingError';
            }
          });
        });
      };
    }
  }

  TimeTrackingCostCentersListCtrl.$inject = ['$state', '$stateParams', '$uibModal', 'CrudApi', 'UserService', 'permissions'];

  /**
   * @ngdoc object
   * @name costCenters.list.controller:TimeTrackingCostCentersListCtrl
   *
   * @description
   *
   */
  angular
    .module('timeTracking.costCenters.list')
    .controller('TimeTrackingCostCentersListCtrl', TimeTrackingCostCentersListCtrl);
}());

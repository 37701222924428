(function () {
    'use strict';

    class ManagementUnitListCtrl {
        constructor($state, $stateParams, $uibModal, CrudApi, UserService, permissions) {
            let vm = this;
            vm.ctrlName = 'ManagementUnitListCtrl';
            vm.sorter = $stateParams.sorter || 'label';
            vm.dir = $stateParams.dir || 'asc';
            vm.filter = $stateParams.filter || '';
            vm.message = $stateParams.message;
            vm.itemsPerPage = 10;
            vm.page = $stateParams.page || 1;

            vm.canDelete = UserService.hasPerm(permissions.BASE_CAN_DELETE_EQUIPMENT_TYPE);
            vm.ownEntity = (entityOwnerId) => {
                return UserService.ownEntity(entityOwnerId);
            };
            vm.canDeleteEntity = (entityOwnerId) => {
                let isOwnedEntity = vm.ownEntity(entityOwnerId);
                let userIsAdmin = UserService.hasPerm(permissions.ADMIN_CAN_EDIT_ALL_CUSTOMER_MANAGEMENT_UNITS);
                return isOwnedEntity || userIsAdmin;
            };

            vm.items = CrudApi.query({
                entity: 'managementUnits',
                filter: $stateParams.filter,
                sortBy: vm.sorter,
                sortDir: vm.dir,
                page: vm.page,
                perPage: vm.itemsPerPage
            }, (data, headers, status) => {
                vm.total = headers()['x-total-count'];
                if (status === 204) {
                    vm.message = 'noData';
                }
            }, () => {
                vm.message = 'loadingError';
            });

            vm.submenu = {
                items: [{
                    type: 'button',
                    text: 'BUTTON_ADD_MANAGEMENT_UNIT',
                    click: () => {
                        $state.go('managementUnits.create');
                    }
                }, {
                    type: 'search',
                    model: vm.filter,
                    position: 'right',
                    change: (filter) => {
                        $state.go($state.current.name, {
                            page: 1,
                            filter: filter,
                            message: null
                        }, {
                            reload: true
                        });
                    }
                }]
            };

            vm.paginate = () => {
                $state.go($state.current.name, {
                    page: vm.page,
                    message: null
                });
            };

            vm.sort = (sorter) => {
                $state.go($state.current.name, {
                    sorter: sorter,
                    dir: vm.dir === 'desc' ? 'asc' : 'desc',
                    message: null
                });
            };

            // Delete Popup
            vm.delete = (item) => {
                let popup = $uibModal.open({
                    controller: 'ManagementUnitDeleteCtrl',
                    controllerAs: 'ManagementUnitDelete',
                    templateUrl: 'management-units/delete/views/delete.tpl.html',
                    resolve: {
                        item: () => {
                            return item;
                        }
                    }
                });
                popup.result.then(() => {
                    CrudApi.delete({
                        entity: 'managementUnits',
                        id: item.id,
                        lastModified: item.lastModified
                    }, () => {
                        $state.go($state.current.name, {
                            message: 'deletingSuccess'
                        }, {
                            reload: true
                        });
                    }, (response) => {
                        if (response.status === 410) {
                            vm.message = 'entityHasChanged';
                        } else {
                            vm.message = 'deletingError';
                        }
                    });
                });
            };
        }
    }

    ManagementUnitListCtrl.$inject = ['$state', '$stateParams', '$uibModal', 'CrudApi', 'UserService', 'permissions'];

    /**
     * @ngdoc object
     * @name managementUnits.list.controller:ListCtrl
     *
     * @description
     *
     */
    angular
        .module('managementUnits.list')
        .controller('ManagementUnitListCtrl', ManagementUnitListCtrl);
}());

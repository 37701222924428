(function () {
  'use strict';

  class ParcelsSelectionCtrl {
    constructor($scope, $filter, CrudApi, MapHelper, userSiteStatus, CustomerMapDataService, $q, SelectionHelper, siteEntities, parcelAreaDefaultUnit, $http, UserService) {
      let vm = this;

      // Restrictions
      vm.restrictions = new Map();

      // Constants for vineyard status
      vm.userSiteStatus = userSiteStatus;

      vm.$onInit = () => {
        // Edit mode on/off
        vm.edit = vm.initialEdit;

        if (angular.isUndefined(vm.graphicalMode)) {
          vm.graphicalMode = false;
        }

        // this variable controls whether the map directive has been created on the page (via
        // an ng-if). This is necessary as the map cannot be refocused while it exists in the
        // DOM but is not displayed ("display: none"). This happens if the graphical mode is not
        // set initially. When switching between text and graphical mode this variable will be
        // set to true. This creates the map and if it is visible it will be refocused correctly.
        vm.mapShouldBeInitialized = vm.graphicalMode;
        if (angular.isUndefined(vm.orderType) || angular.isUndefined(vm.assignedUser)) {
          vm.isBonitur = false;
        } else {
          let isAssigned = (vm.assignedUser.includes(UserService.user.id));
          vm.isBonitur = ((vm.orderType.label.toUpperCase() === 'BONITUR') && isAssigned);
        }
        vm.fetchVineyards();
      };

      vm.statusFilter = null;
      vm.filters = {};

      // Set filters
      vm.filter = () => {
        vm.fetchVineyards(vm.filters);
      };

      // Reset all filters
      vm.unfilter = () => {
        vm.filters = {};
        vm.fetchVineyards();
      };

      // Select or unselect all vineyards
      vm.selectAll = (selected) => {
        vm.vineyards.map((vineyard) => {
          vineyard.selected = selected && vineyard.isActive;
        });
        vm.updateSelection();
      };

       $http.get('area/api/restrictions').then(function (response) {
            response.data.forEach((restriction) => {
                restriction.areas.forEach((area) => {
                    let restrictionEntries = vm.restrictions.get(area.id);
                    if (restrictionEntries) {
                        restrictionEntries.push(...restriction.entries);
                    } else {
                        restrictionEntries = [...restriction.entries];
                    }
                    vm.restrictions.set(area.id, restrictionEntries);
                });
            });
            console.log(vm.restrictions);
        });

        vm.createRestrictionTemplate = (restrictionEntries) => {
            let template = '<ul>';
            if (restrictionEntries) {
                restrictionEntries.forEach( (entry) => {
                    template += ('<li>' + entry + '</li>');
                });
            }
            template += '</ul>';
            return template;
        };

      vm.goToBonitur = (vineyard) => {
        //Does not work for Nachbonitur
        const token = UserService.getAuthToken();
        $http.get('bonitur/loginWithToken', {headers: {'RefreshToken': token.refreshToken}}).then(function (response) {
          if(response.status === 200) {
            let param = 'surveyId=' + vm.additionalData.surveyId + '&';
            param = param + 'orderId=' + vm.orderId + '&';
            param = param + 'siteId=' + vineyard.id;
            window.location.assign('/bonitur/bonitur/createBonitur?' + param);
          }
        }, function (error) {
          console.log(error);
        });
      };

      // Update selection to be shared with the parent template
      vm.updateSelection = () => {
        let filteredVineyards = $filter('filter')(vm.vineyards, {
              selected: true
            }, true);
        vm.selectedSites = [];
        let selectedVineyardsArray = filteredVineyards.map((vineyard) => {
              vm.selectedSites.push(vineyard);
              return vineyard.id;
            });

        // If current selection does not match the directives' value rewrite the directive value
        if (vm.selection.$modelValue.sort().join(',') !== selectedVineyardsArray.sort().join(',')) {
          vm.selection.$setViewValue(selectedVineyardsArray);
        }
        vm.selection.$setValidity('required', !(vm.required && !vm.selection.$modelValue.length));
        vm.changeVineyardOptions = {};
        angular.forEach(vm.vineyards, vineyard => {
          vm.changeVineyardOptions[vineyard.id] = vm.getVineyardOptions(selectedVineyardsArray.indexOf(vineyard.id) > -1, false);
        });
      };

      vm.getVineyardOptions = (selected, mouseOver) => {
        return MapHelper.getVineyardOptions(null, selected ? 'selected' : 'unselected', mouseOver);
      };

      vm.onVineyardsSelected = (vineyardIds, ctrlPressed, singleVineyard) => {
        let selection = SelectionHelper.changeSelection(vm.selection.$viewValue, vineyardIds, ctrlPressed, singleVineyard);
        vm.vineyards.map((vineyard) => {
          vineyard.selected = selection.indexOf(vineyard.id) > -1;
        });
        vm.updateSelection();
      };

      if (parcelAreaDefaultUnit === 'brutto') {
        vm.showAreasBrutto = true;
      } else if (parcelAreaDefaultUnit === 'netto') {
        vm.showAreasBrutto = false;
      } else {
        throw new Error('ERROR parcelAreaDefaultUnit is missing or has an unknown value');
      }

      $scope.$watch('parcelsSelection.selection.$modelValue', () => {
        vm.calculateAreaSums();
      });
      vm.calculateAreaSums = () => {
        let lockStrikeLength = false;
        vm.sums = {
          brutto: 0,
          netto: 0,
          strikeLength: 0
        };
        if (vm.vineyards) {
          vm.vineyards.forEach((vineyard) => {
            vineyard.bruttoArea = 0;
            if (vineyard.plots && vineyard.plots.length) {
              vineyard.plots.forEach((plot) => {
                vineyard.bruttoArea += plot.area ? plot.area : 0;
              });
            }
            if (vm.selection.$modelValue.indexOf(vineyard.id) !== -1) {
              vm.sums.netto += vineyard.area;
              vm.sums.brutto += vineyard.bruttoArea;
              if (!lockStrikeLength) {
                if (vineyard.totalStrikeLength > 0) {
                  vm.sums.strikeLength += vineyard.totalStrikeLength;
                } else {
                  vm.sums.strikeLength = 0;
                  lockStrikeLength = true;
                }
              }
            }
          });
        }
      };

      // Fetch vineyards
      vm.fetchVineyards = (filters) => {
        let params = {
          entity: siteEntities,
          excludeFertilizationDataStart: vm.period ? vm.period.periodStart : undefined,
          excludeFertilizationDataEnd: vm.period ? vm.period.periodEnd : undefined
        };
        if (vm.isCreate) {
            params.subentity = 'usable';
        } else {
            delete params.subentity;
        }
        angular.extend(params, filters);
        CrudApi.query(params, (vineyards) => {
          // Get all vineyards that already have been selected
          let filteredVineyards = $filter('filter')(vm.vineyards, {
                selected: true
              }, true) || [],
              mapPromises = {};
          vineyards.forEach((vineyard) => {
            mapPromises[vineyard.id] = CustomerMapDataService.getCustomerMapsDataById(vineyard.id);
            vineyard.bruttoArea = 0;
            if (vineyard.plots && vineyard.plots.length) {
              vineyard.plots.forEach((plot) => {
                vineyard.bruttoSum += plot.area ? plot.area : 0;
              });
            }
            // Construct vineyard "selected" flag
            if (angular.isDefined(vm.selection.$modelValue) && vm.selection.$modelValue.length) {
              vineyard.selected = vm.selection.$modelValue.indexOf(vineyard.id) !== -1;
            }
          });
          // make sure all selected vineyards also appear on the map
          filteredVineyards.forEach((vineyard) => {
            mapPromises[vineyard.id] = CustomerMapDataService.getCustomerMapsDataById(vineyard.id);
          });
          $q.all(mapPromises).then((data) => {
            vm.mapData = data;

            // if the map is currently not displayed then the refocus will not have happenend
            // as this is a restriction of the Google Maps implementation. So what
            // we do in this case is to ensure that the map directive will be removed
            // from the page. As soon as the graphical mode is being activated the map
            // will be recreated and the refocus will happen automatically.
            if (!vm.graphicalMode) {
              vm.mapShouldBeInitialized = false;
            }
          });
          // If there have been selected vineyards before, concat.
          // Else just use the fetched vineyards
          if (filteredVineyards && filteredVineyards.length) {
            vm.vineyards = filteredVineyards.concat(vineyards.filter((vineyard) => {
              return vm.selection.$modelValue.indexOf(vineyard.id) === -1;
            }));
          } else {
            vm.vineyards = vineyards;
          }
          vm.updateSelection();
          vm.calculateAreaSums();
        });
      };

      vm.issueRedo = (vineyard) => {
        // we only save which vineyards should get REDO instead of setting the status directly in the siteStatus object of the order,
        // because we will recreate the siteStatus list of only the vineyards that should be redone before saving.
        // We do this so we don't trigger a REDO on a vineyard that the user did not explicitely request. This could happen
        // if the initial state of the vineyard that is returned from the backend was REDO already.
        vm.redoVineyards[vineyard.id] = true;
        // we make sure to create a new object here to trigger any $onChange events on directives using this scope value
        vm.siteStatus = angular.merge({}, vm.siteStatus, {
          [vineyard.id]: userSiteStatus.REDO
        });
        vm.selection.$setDirty();
      };

      vm.onMouseChange = (vineyardId, hover) => {
        vm.changeVineyardOptions = {
          [vineyardId]: vm.getVineyardOptions(vm.selection.$viewValue.indexOf(vineyardId) > -1, hover)
        };
      };
    }
  }

  ParcelsSelectionCtrl.$inject = ['$scope', '$filter', 'CrudApi', 'MapHelper', 'userSiteStatus', 'CustomerMapDataService', '$q', 'SelectionHelper', 'siteEntities', 'parcelAreaDefaultUnit', '$http', 'UserService'];

  /**
   * @ngdoc object
   * @name parcels.selection.controller:ParcelsSelectionCtrl
   *
   * @description
   *
   */
  angular
    .module('parcels.selection')
    .controller('ParcelsSelectionCtrl', ParcelsSelectionCtrl);
}());

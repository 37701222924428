<select class="form-control"
        ng-model="parcelsSelection.showAreasBrutto"
        ng-change="parcelsSelection.effectiveArea()">
    <option ng-value="false">{{'PARCELS_PARAM_AREA_NETTO' | translate}}</option>
    <option ng-value="true">{{'PARCELS_PARAM_AREA_BRUTTO' | translate}}</option>
</select>
<span class="input-group-addon">
    <span ng-if="!parcelsSelection.showAreasBrutto">{{parcelsSelection.sums.netto | number:4}}</span>
    <span ng-if="parcelsSelection.showAreasBrutto">{{parcelsSelection.sums.brutto | number:4}}</span>
    {{'PARCELS_PARAM_AREA_UNIT' | translate}}
</span>
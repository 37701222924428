<ng-form style="display: block; margin: 0;"
         class="form-group edit-fertilization-copy"
         ng-class="{
           'has-error': copyFertilizationYearForm.$invalid
         }"
         name="copyFertilizationYearForm">
  <div class="modal-header">
    <button type="button" class="close" ng-click="$dismiss();"><span>&times;</span></button>
    <h4 class="modal-title">{{'FERTILIZATION_PARCEL_FERTILIZATION_YEAR_COPY' | translate}}</h4>
  </div>
  <div class="modal-body">
    <div class="alert alert-info">{{('FERTILIZATION_PARCEL_FERTILIZATION_YEAR_COPY_INFO' | translate:{start: (copy.period.periodStart|date), end: (copy.period.periodEnd|date)})}}</div>
  </div>
  <parcels-selection ng-model="copy.selection"
                     map-enabled="true"
                     initial-edit="true"
                     popup="true"
                     period="copy.period"
                     sums="false"
                     required></parcels-selection>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" ng-click="$dismiss();">{{'ABORT' | translate}}</button>
    <button type="button" class="btn btn-default" ng-disabled="copyFertilizationYearForm.$invalid" ng-click="$close(copy.selection);">{{'OK' | translate}}</button>
  </div>
</ng-form>
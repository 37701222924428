<div class="form-group">
    <table class="table table-bordered">
        <tr ng-repeat="comment in edit.comments | orderBy:'-timestamp'" class="text-muted">
            <td class="shrink">{{comment.timestamp | date}}</td>
            <td class="shrink">{{comment.timestamp | date:'HH:mm'}}</td>
            <td style="color: {{comment.color}}" class="shrink">
                {{edit.getUserName(edit.users, comment.userId, 'lastname')}},
                {{edit.getUserName(edit.users, comment.userId, 'firstname')}}
            </td>
            <td class="shrink" ng-if="!comment.commentType">-</td>
            <td class="shrink" ng-if="comment.commentType">{{comment.commentType | translate}}</td>
            <td class="truncateText"><span>{{comment.description}}</span></td>
        </tr>
    </table>
</div>

(function () {
  'use strict';

  angular
    .module('vcMain')
    .config(config);

  function config($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name fertilization.nutrientComparisonExports
   * @description
   *
   */
  angular
    .module('fertilization.nutrientComparisonExports', [
      'ui.router',
      'fertilization.nutrientComparisonExports.edit'
    ]);
}());

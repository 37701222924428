(function () {
  'use strict';

  /* @ngdoc object
   * @name users
   * @description
   *
   */
  angular
    .module('users', [
      'ui.router',
      'users.edit',
      'users.list',
      'users.delete'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('orderTypes.list')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('orderTypes.list', {
        url: '/list',
        templateUrl: 'order-types/list/views/list.tpl.html',
        controller: 'OrderTypesListCtrl',
        controllerAs: 'list',
        params: {
          message: {
            value: null
          }
        }
      });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name home
   * @description
   *
   */
  angular
    .module('businessManagement.businesses', [
      'ui.router',
      'businessManagement.businesses.edit',
      'businessManagement.businesses.delete',
      'businessManagement.businesses.list',
      'businesses.factsheet'
    ]);
}());

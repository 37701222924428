(function () {
    'use strict';

    class OrderTypePlantProtectionCtrl {
        constructor($scope, $q, $filter, CrudApi) {
            let vm = this,
                promises = {};

            vm.totalWidth = 0;
            vm.totalCount = 0;
            vm.averageAisleWidth = 0;
            vm.numberOfLines = 2;
            vm.effectiveWaterAmount = 0;

            // Articles
            promises.articles = CrudApi.query({
                entity: 'plantProtection',
                subentity: 'articles'
            }).$promise;

            promises.tenantConfiguration = CrudApi.get({
                entity: 'customers',
                subentity: 'configuration'
            }).$promise;
            // Execute promises
            $q.all(promises).then((data) => {
                vm.articles = data.articles;
                vm.tenantConfiguration = data.tenantConfiguration;

                vm.sanitizeSelection = (item, selection) => {
                    let selectionItem = $filter('filter')(selection, {
                        id: item.id
                    })[0];
                    selection.splice(selection.indexOf(selectionItem), 1);
                    selection.push({
                        article: selectionItem,
                        useBaseExpenditure: true
                    });
                };

                $scope.$watch('edit.selectedSites', () => {
                    vm.totalCount = 0;
                    $scope.edit.selectedSites.forEach((site) => {
                        if (site.laneWidth) {
                            vm.totalWidth += site.laneWidth;
                            vm.totalCount++;
                        }
                    });
                    if (vm.totalCount !== 0) {
                        vm.averageAisleWidth = parseFloat((vm.totalWidth / vm.totalCount).toFixed(2));
                        if ($scope.edit.additionalData.workingArea && vm.averageAisleWidth !== 0) {
                            vm.numberOfLines = Math.round($scope.edit.additionalData.workingArea / vm.averageAisleWidth);
                        }
                    } else {
                        vm.numberOfLines = 2; // this should be a common default
                        vm.averageAisleWidth = parseFloat(
                            ($scope.edit.additionalData.workingArea / vm.numberOfLines).toFixed(2)
                        );
                    }
                    vm.plannedAmountOfWaterChanged();
                });

                $scope.$watch('edit.additionalData.articles', (selection) => {
                    let firstFromSelection = selection && selection.length ? selection[0] : null;
                    const oldMode = vm.mode;
                    if (firstFromSelection) {
                        let firstFromSelectionType = firstFromSelection.article ? firstFromSelection.article.type.label : firstFromSelection.type.label;
                        if (firstFromSelectionType === 'HERBICIDE') {
                            vm.mode = 'HERBICIDE';
                            vm.articles.forEach((article) => {
                                article.disabled = article.type.label !== 'HERBICIDE';
                            });
                            if (oldMode && oldMode !== vm.mode) {
                                $scope.edit.additionalData.areaMethod = 'gross';
                                $scope.edit.additionalData.calculationMethod = 'ground';
                            }
                        } else {
                            vm.mode = 'REGULAR';
                            vm.articles.forEach((article) => {
                                article.disabled = article.type.label === 'HERBICIDE';
                            });
                            if (oldMode && oldMode !== vm.mode) {
                                $scope.edit.additionalData.areaMethod = 'gross';
                                $scope.edit.additionalData.calculationMethod = 'ground';
                            }
                        }
                        if (vm.averageAisleWidth > 0) {
                            $scope.edit.additionalData.workingArea = vm.numberOfLines * vm.averageAisleWidth;
                        }
                    } else {
                        vm.mode = 'NONE';
                        vm.articles.forEach((article) => {
                            article.disabled = false;
                        });
                        delete $scope.edit.additionalData.areaMethod;
                        delete $scope.edit.additionalData.calculationMethod;
                    }
                    vm.plannedAmountOfWaterChanged();
                });

                vm.getAvailableArticles = (search, selection) => {
                    let result = [];
                    vm.articles.forEach((article) => {
                        let articleInList = $filter('filter')(selection, {
                            article: {
                                id: article.id
                            }
                        }, true) || [];
                        if (article.label.match(new RegExp(search), 'ig') && !articleInList.length) {
                            result.push(article);
                        }
                    });
                    return result;
                };

                vm.getBruttoAreaOfSite = (site) => {
                    let bruttoArea = 0;
                    if (site.plots && site.plots.length) {
                        site.plots.forEach((plot) => {
                            bruttoArea += plot.area ? plot.area : 0;
                        });
                    }
                    // The steep slope will be considered for areas, not for article amount.
                    if (vm.useSteep(site)) {
                        bruttoArea = bruttoArea * 1.25;
                    }
                    return bruttoArea;
                };

                vm.getTreatedAreaOfSite = (site) => {
                    return vm.getBruttoAreaOfSite (site) * $scope.edit.additionalData.sprayBandwidth / vm.averageAisleWidth;
                };

                vm.getNettoAreaOfSite = (site) => {
                    return site.area ? site.area : 0;
                };

                vm.workingLinesChange = () => {
                    $scope.edit.additionalData.workingArea = vm.numberOfLines * vm.averageAisleWidth;
                };
                // Calculate average aisle width
                vm.workingAreaChange = () => {
                    vm.numberOfLines = ($scope.edit.additionalData.workingArea / vm.averageAisleWidth).toFixed(0);
                };

                vm.getTotalStrikeLengthOfSite = (site) => {
                    return site.totalStrikeLength ? site.totalStrikeLength : 0;
                };

                vm.plannedAmountOfWaterChanged = () => {
                    let plannedAmountOfWater = $scope.edit.additionalData.plannedAmountOfWater;
                    const effectivePart = vm.calcTotalEffectiveArea() / vm.calcTotalArea();
                    console.log(plannedAmountOfWater);
                    console.log(vm.calcTotalArea());
                    console.log(vm.averageAisleWidth);
                    console.log(effectivePart);
                    vm.effectiveWaterAmount = plannedAmountOfWater * effectivePart;
                };

                vm.effectiveWaterAmountChanged = () => {
                    const effectivePart = vm.calcTotalEffectiveArea() / vm.calcTotalArea();
                    $scope.edit.additionalData.plannedAmountOfWater = vm.effectiveWaterAmount / effectivePart;
                };

                vm.calcTotalEffectiveArea = () => {
                    let totalArea = 0;
                    $scope.edit.selectedSites.forEach(site => {
                        totalArea += vm.calcEffectiveArea(site);
                    });
                    return totalArea;
                };

                vm.calcTotalArea = () => {
                    let totalArea = 0;
                    $scope.edit.selectedSites.forEach(site => {
                        if ($scope.edit.additionalData.areaMethod === 'gross') {
                            totalArea += vm.getBruttoAreaOfSite(site);
                        } else if ($scope.edit.additionalData.areaMethod === 'net') {
                            totalArea += vm.getNettoAreaOfSite(site);
                        }
                    });
                    return totalArea;
                };

                vm.calcTotalPlannedAmountOfWater = () => {
                    let totalPlannedAmountOfWater = 0;
                    $scope.edit.selectedSites.forEach((site) => {
                        totalPlannedAmountOfWater += vm.calcPlannedAmountOfWater(site);
                    });
                    return totalPlannedAmountOfWater;
                };

                vm.calcTotalAmountOfArticle = (article) => {
                    let totalAmountOfArticle = 0;
                    $scope.edit.selectedSites.forEach((site) => {
                        totalAmountOfArticle += vm.calcAmountOfArticle(article, site);
                    });
                    return totalAmountOfArticle;
                };

                vm.calcPlannedAmountOfWater = (site) => {
                    let plannedAmountOfWater = 0;
                    if (vm.mode === 'REGULAR') {
                        if ($scope.edit.additionalData.calculationMethod === 'ground') {
                            if ($scope.edit.additionalData.areaMethod === 'gross') {
                                plannedAmountOfWater = $scope.edit.additionalData.plannedAmountOfWater * vm.getBruttoAreaOfSite(site);
                            } else if ($scope.edit.additionalData.areaMethod === 'net') {
                                plannedAmountOfWater = $scope.edit.additionalData.plannedAmountOfWater * vm.getNettoAreaOfSite(site);
                            }
                        } else if ($scope.edit.additionalData.calculationMethod === 'lwf') {
                            if ($scope.edit.additionalData.areaMethod === 'gross') {
                                plannedAmountOfWater = $scope.edit.additionalData.plannedAmountOfWater *
                                    (vm.getBruttoAreaOfSite(site) / $scope.edit.additionalData.workingArea) *
                                    $scope.edit.additionalData.foliageHeight * 2;
                            } else if ($scope.edit.additionalData.areaMethod === 'net') {
                                plannedAmountOfWater = $scope.edit.additionalData.plannedAmountOfWater *
                                    ((vm.getTotalStrikeLengthOfSite(site) * $scope.edit.additionalData.foliageHeight * 2) / 10000);
                            }
                        }
                    } else if (vm.mode === 'HERBICIDE') {
                        if ($scope.edit.additionalData.areaMethod === 'gross') {
                            plannedAmountOfWater = $scope.edit.additionalData.plannedAmountOfWater *
                                vm.getTreatedAreaOfSite(site);
                        } else if ($scope.edit.additionalData.areaMethod === 'net') {
                            plannedAmountOfWater = ($scope.edit.additionalData.plannedAmountOfWater *
                                vm.getTotalStrikeLengthOfSite(site) *
                                $scope.edit.additionalData.sprayBandwidth) / 10000;
                        }
                    }
                    return plannedAmountOfWater;
                };

                vm.calcEffectiveArea = (site) => {
                    let effectiveArea = 0;
                    if (vm.mode === 'REGULAR') {
                        if ($scope.edit.additionalData.calculationMethod === 'ground') {
                            if ($scope.edit.additionalData.areaMethod === 'gross') {
                                effectiveArea = vm.getBruttoAreaOfSite(site);
                            } else if ($scope.edit.additionalData.areaMethod === 'net') {
                                effectiveArea = vm.getNettoAreaOfSite(site);
                            }
                        } else if ($scope.edit.additionalData.calculationMethod === 'lwf') {
                            if ($scope.edit.additionalData.areaMethod === 'gross') {
                                effectiveArea = (vm.getBruttoAreaOfSite(site) / $scope.edit.additionalData.workingArea) *
                                    $scope.edit.additionalData.foliageHeight * 2;
                            } else if ($scope.edit.additionalData.areaMethod === 'net') {
                                effectiveArea = ((vm.getTotalStrikeLengthOfSite(site) * $scope.edit.additionalData.foliageHeight * 2) / 10000);
                            }
                        }
                    } else if (vm.mode === 'HERBICIDE') {
                        if ($scope.edit.additionalData.areaMethod === 'gross') {
                            effectiveArea = vm.getTreatedAreaOfSite(site);
                        } else if ($scope.edit.additionalData.areaMethod === 'net') {
                            effectiveArea = (vm.getTotalStrikeLengthOfSite(site) * $scope.edit.additionalData.sprayBandwidth) / 10000;
                        }
                    }
                    return effectiveArea;
                };

                vm.calcAmountOfArticle = (article, site) => {
                    let amountOfArticle = 0;
                    let basicExpenditure = 1;
                    if (!article.article || !site) {
                        return amountOfArticle;
                    }
                    if (article.useBaseExpenditure) {
                        basicExpenditure = $scope.edit.additionalData.baseExpenditure;
                    }
                    if (vm.mode === 'REGULAR') {
                        if ($scope.edit.additionalData.calculationMethod === 'ground') {
                            if ($scope.edit.additionalData.areaMethod === 'gross') {
                                amountOfArticle = vm.getBruttoAreaOfSite(site) *
                                    article.amountPerArea * article.article.normalizationFactor *
                                    basicExpenditure;
                            } else if ($scope.edit.additionalData.areaMethod === 'net') {
                                amountOfArticle = vm.getNettoAreaOfSite(site) *
                                    article.amountPerArea * article.article.normalizationFactor *
                                    basicExpenditure;
                            }
                        } else if ($scope.edit.additionalData.calculationMethod === 'lwf') {
                            if ($scope.edit.additionalData.areaMethod === 'gross') {
                                amountOfArticle = (vm.getBruttoAreaOfSite(site) / $scope.edit.additionalData.workingArea) *
                                    $scope.edit.additionalData.foliageHeight * 2 *
                                    article.amountPerArea * article.article.normalizationFactor *
                                    basicExpenditure;
                            } else if ($scope.edit.additionalData.areaMethod === 'net') {
                                amountOfArticle = ((vm.getTotalStrikeLengthOfSite(site) * $scope.edit.additionalData.foliageHeight * 2) / 10000) *
                                    article.amountPerArea * article.article.normalizationFactor *
                                    basicExpenditure;
                            }
                        }
                    } else if (vm.mode === 'HERBICIDE') {
                        if ($scope.edit.additionalData.areaMethod === 'gross') {
                            amountOfArticle = vm.getTreatedAreaOfSite(site) * article.amountPerArea *
                                basicExpenditure * article.article.normalizationFactor;
                        } else if ($scope.edit.additionalData.areaMethod === 'net') {
                            amountOfArticle = (vm.getTotalStrikeLengthOfSite(site) * $scope.edit.additionalData.sprayBandwidth *
                                article.amountPerArea * article.article.normalizationFactor *
                                basicExpenditure) / 10000;
                        }
                    }
                    return amountOfArticle;
                };

                vm.calcMixtureAmount = (article) => {
                    let mixtureAmount;
                    let baseExpenditure = 1;

                    if (article.useBaseExpenditure) {
                        baseExpenditure = $scope.edit.additionalData.baseExpenditure;
                    }

                    mixtureAmount = article.amountPerArea *
                        article.article.normalizationFactor *
                        baseExpenditure *
                        ($scope.edit.additionalData.mixtureAmount / $scope.edit.additionalData.plannedAmountOfWater);
                    return mixtureAmount;
                };

                vm.useSteep = (site) => {
                    return (vm.tenantConfiguration.useSteepForPlantProtection && site.steep === true);
                };

                vm.hasSteep = () => {
                    return !!vm.tenantConfiguration.useSteepForPlantProtection;
                };

            });
        }
    }

    OrderTypePlantProtectionCtrl.$inject = ['$scope', '$q', '$filter', 'CrudApi'];

    /**
     * @ngdoc object
     * @name orders.edit.controller:OrderTypePlantProtectionCtrl
     *
     * @description
     *
     */
    angular
        .module('orders.edit')
        .controller('OrderTypePlantProtectionCtrl', OrderTypePlantProtectionCtrl);
}());

<form name="editBeaconForm">
    <div class="modal-header">
        <button type="button" class="close" ng-click="$dismiss();"><span>&times;</span></button>
        <h4 class="modal-title">{{ (BeaconsEdit.newBeacon ? 'WINERY_MANAGEMENT_PARCELS_MAP_ADD_BEACON' : 'WINERY_MANAGEMENT_PARCELS_MAP_EDIT_BEACON') | translate}}</h4>
    </div>
    <div class="modal-body">
        <div class="form-group">
        <label class="control-label">{{'WINERY_MANAGEMENT_PARCELS_MAP_BEACON_ID' | translate}}</label>
        <input class="form-control" type="text" ng-model="BeaconsEdit.beacon" name="beacon" required />
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" ng-click="$dismiss();">{{'ABORT' | translate}}</button>
        <button type="button" class="btn btn-success" ng-disabled="addBeaconForm.$invalid" ng-click="$close(BeaconsEdit.beacon);">{{'OK' | translate}}</button>
    </div>
</form>

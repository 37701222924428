<sub-menu items="list.submenu.items" ng-if="list.canCreate"></sub-menu>
<main-menu></main-menu>

<ng-form name="form"
         class="base-layout order-types-list">
    <div class="base-layout-default">
        <main-message message="list.message" fluid="'nested'"></main-message>
        <div class="table-responsive" ng-if="list.items.length">
            <table class="table table-hover">
                <thead>
                <th>
                    {{'ORDER_ORDER_TYPE' | translate}}
                </th>
                <th>{{'orderType.group.label' | translate}}</th>
                <th class="text-center">{{'ENTITY_OWNERSHIP' | translate}}</th>
                <th class="text-center"
                    ng-if="list.canDelete">{{'DELETE' | translate}}
                </th>
                </thead>
                <tbody>
                <tr ng-repeat="item in list.items | orderBy : ['groupPosition', 'label']"
                    ui-sref="orderTypes.edit({id:item.id})">
                    <td>{{item.label}}</td>
                    <td>{{('orderType.group.values.' + item.group) | translate}}</td>
                    <td class="text-center shrink" ng-if="list.ownEntity(item.customerId)">
                        {{'OWN_ENTITY' | translate}}
                    </td>
                    <td class="text-center shrink" ng-if="!list.ownEntity(item.customerId)">
                        {{'NOT_OWN_ENTITY' | translate}}
                    </td>
                    <td class="text-center shrink"
                        ng-if="list.canDelete">
                        <button class="btn btn-link"
                                ng-click="$event.stopPropagation(); list.delete(item)"
                                ng-disabled="!list.canDeleteEntity(item.customerId)">
                            <span class="glyphicon glyphicon-trash"></span>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-form>

(function () {
  'use strict';

  /* @ngdoc object
   * @name fertilization.nDemandExports
   * @description
   *
   */
  angular
    .module('fertilization.nDemandExports', [
      'ui.router',
      'fertilization.nDemandExports.edit'
    ]);
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name temporaryWorkers.delete
   * @description
   *
   */
  angular
    .module('temporaryWorkers.delete', [
      'ui.router'
    ]);
}());

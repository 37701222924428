(function () {
  'use strict';

  class TemporaryWorkersDownloadCtrl {
    constructor($state, $stateParams, $rootScope, CrudApi, QrApi, FileSaver) {
      let vm = this;

      // TOD: Its a hack to reset the submenu item, need to work on the is overall
      $rootScope.subMenu = true;

      // Initialize some variables
      vm.itemsPerPage = 3;
      vm.maxSize = 10;
      vm.page = $stateParams.page || 1;
      vm.message = $stateParams.message;

      vm.packageWorkers = CrudApi.query({
        packageId: $stateParams.id,
        entity: 'temporaryworkerpackage',
        subentity: 'workers',
        page: vm.page,
        perPage: vm.itemsPerPage
      }, (data, headers, status) => {
        vm.total = headers()['x-total-count'];
        if (status === 204) {
          vm.message = 'noData';
        }
      }, () => {
        vm.message = 'loadingError';
      });

      // Pagination method
      vm.paginate = () => {
        $state.go($state.current.name, {
          page: vm.page,
          message: null
        });
      };

      // Download Method
      vm.download = (cItem, type) => {
        QrApi
        .get({
          type: type,
          id: cItem.id
        }).$promise.then((data) => {
          FileSaver.saveAs(data.blob, data.filename);

          // var fileURL = URL.createObjectURL(data.response);
          // var a         = document.createElement('a');
          // a.href        = fileURL;
          // a.target      = '_blank';
          // a.download    = 'test.zip';
          // document.body.appendChild(a);
          // a.click();
        });
      };
    }
  }

  TemporaryWorkersDownloadCtrl.$inject = ['$state', '$stateParams', '$rootScope', 'CrudApi', 'QrApi', 'FileSaver'];

  /**
   * @ngdoc object
   * @name edit.download.controller:TemporaryWorkersDownloadCtrl
   *
   * @description
   *
   */
  angular
    .module('edit.download')
    .controller('TemporaryWorkersDownloadCtrl', TemporaryWorkersDownloadCtrl);
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name plantProtection
   * @description
   *
   */
  angular
    .module('plantProtection', [
      'ui.router',
      'plantProtection.exports'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name businessManagement.businesses.constant:businessMainUrl
   *
   * @description
   *
   */
  angular
    .module('businessManagement.businesses')
    .constant('businessMainUrl', '/businesses');
}());

<td ng-if="list.showCheckboxes && list.hasAssetType('VINEYARD')">
    <input ng-if="!item.isShared" type="checkbox" ng-model="::item.isChecked" ng-change="list.selectItem()" ng-click="$event.stopPropagation()">
</td>
<td>{{::item.label}}</td>
<td class="hidden-xs hidden-sm" ng-if="list.hasAssetType('VINEYARD')">{{::item.district}}</td>
<td class="hidden-xs hidden-sm" ng-if="list.hasAssetType('VINEYARD')">{{::list.getPlotLabels(item.plots)}}</td>
<td class="hidden-xs" ng-if="list.hasAssetType('VINEYARD')">{{::item.wineThird}}</td>
<td class="hidden-xs hidden-sm">{{::item.businessLabel}}</td>
<td class="text-center">
  <button class="btn btn-link" ng-if="list.canDelete && !item.isShared"
          ng-click="$event.stopPropagation(); list.delete(item)">
    <span class="glyphicon glyphicon-trash"></span>
  </button>
</td>

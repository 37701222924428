(function () {
    'use strict';

    angular
        .module('plantProtection')
        .config(config);

    function config($stateProvider, permissions) {
        $stateProvider
            .state('plantProtection', {
                abstract: true,
                url: '/plant-protection',
                template: '<ui-view />',
                data: {
                    requiredPerms: [permissions.PLANT_PROTECTION_CAN_USE]
                }
            });
    }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name vcMain.directive:batchEdit
   * @restrict E
   * @element
   *
   * @description This directive lists edittable properties so the user can set them for multiple entities at once.
   *
   * @example
      <example module="
      ">
        <file name="index.html">
          <batchEdit></batchEdit>
        </file>
      </example>
   *
   */
  angular
    .module('vcMain')
    .directive('batchEdit', batchEdit);

  batchEdit.$inject = ['$rootScope', '$window', 'CrudApi', '$translate', 'WineTypeInfo', '$timeout'];

  function batchEdit($rootScope, $window, CrudApi, $translate, WineTypeInfo, $timeout) {
    return {
      restrict: 'E',
      scope: {
        entities: '<',
        entityName: '<',
        fieldDescriptors: '<',
        canDelete: '<',
        currentStep: '=?',
        onStepChanged: '&'
      },
      templateUrl: 'directives/batch-edit-directive.tpl.html',
      controllerAs: 'batchEdit',
      bindToController: true,
      controller() {
        let vm = this;

        vm.progress = 0;
        vm.successfulApiCalls = 0;
        vm.errorneousEntities = [];
        vm.descriptorIndex = {};

        vm.isDate = angular.isDate;

        vm.$onInit = () => {
          vm.currentStep = 0;
          vm.fieldDescriptors = angular.copy(vm.fieldDescriptors);
          vm.fieldDescriptors.forEach(descriptor => {
            if (descriptor.type === 'slider') {
              // This is necessary because we cannot determine if the value truly changed otherwise.
              descriptor.reference = {value: descriptor.options.floor, readableValue: descriptor.options.floor};

              descriptor.options.onChange = () => {
                descriptor.value = {value: descriptor.reference.value, readableValue: descriptor.reference.value};
              };
            } else if (descriptor.type === 'wineSelection') {
              vm.wineKeys = {};
              WineTypeInfo.whenReady().then(() => {
                vm.translateValue = (value, level) => {
                  vm.wineKeys[level] = WineTypeInfo.getWineKey(value);
                };
              });
            }
          });

          vm.descriptorIndex = vm.buildIndex(vm.fieldDescriptors);
        };

        vm.buildIndex = (descriptors) => {
          let index = {};
          descriptors.forEach(descriptor => {
            index[descriptor.fieldname] = descriptor.label;
          });
          return index;
        };

        vm.changeStep = (step) => {
          vm.currentStep = step;
          if (angular.isFunction(vm.onStepChanged)) {
            vm.onStepChanged({step: step});
          }
        };

        vm.continueClicked = () => {
          vm.changeStep(vm.currentStep + 1);
        };

        vm.renderSlider = () => {
          // This is needed whenever a slider is on tabs
          $timeout(function () {
            $rootScope.$broadcast('rzSliderForceRender');
          });
        };

        vm.confirmClicked = () => {
          vm.changeStep(vm.currentStep + 1);
          let entities = angular.copy(vm.entities, []);
          if (vm.selectedTab === 0) {
            vm.fieldDescriptors.forEach(descriptor => {
              if (angular.isDefined(descriptor.value) && angular.isDefined(descriptor.value.value) ||
              // descriptor.value.value is always null for wineThird, so we need to check this explicitly.
              // It is sufficient to check only wineThird because the user is forced to enter all three values if he wants to edit vine.
              descriptor.fieldname === 'wineThird' && angular.isDefined(descriptor.value) && angular.isDefined(descriptor.value.wineThird)) {
                entities.forEach(entity => {
                  if (descriptor.fieldname === 'wineThird') {
                    entity.wineFirst = descriptor.value.wineFirst.value;
                    entity.wineSecond = descriptor.value.wineSecond.value;
                    entity.wineThird = descriptor.value.wineThird.value;
                  } else {
                    entity[descriptor.fieldname] = descriptor.value.value;
                  }
                });
              }
            });
          }
          vm.makeApiCalls(entities);
        };

        vm.makeApiCalls = (copiedEntities) => {
          let length = Math.min(10, copiedEntities.length),
              calls = vm.successfulApiCalls + length,
              entities = copiedEntities.splice(0, length);
          entities.forEach(entity => {
            if (vm.selectedTab === 0) {
              CrudApi.save({
                id: entity.id,
                entity: 'sites',
              }, entity, () => {
                vm.onFinishedApiCall(calls, copiedEntities);
              }, () => {
                vm.onFinishedApiCall(calls, copiedEntities, entity);
              });
            } else {
              CrudApi.delete({
                entity: vm.entityName,
                id: entity.id,
                lastModified: entity.lastModified
              }, entity, () => {
                vm.onFinishedApiCall(calls, copiedEntities);
              }, () => {
                vm.onFinishedApiCall(calls, copiedEntities, entity);
              });
            }
          });
        };

        // entity is undefined if the call was successful.
        vm.onFinishedApiCall = (calls, copiedEntities, entity) => {
          if (angular.isDefined(entity)) {
            vm.errorneousEntities.push(entity);
          }
          vm.updateProgress();
          if (vm.successfulApiCalls === calls && vm.progress !== 100) {
            vm.makeApiCalls(copiedEntities);
          }
        };

        vm.updateProgress = () => {
          vm.successfulApiCalls += 1;
          vm.progress = vm.successfulApiCalls / vm.entities.length * 100;
          if (vm.progress === 100) {
            vm.changeStep(vm.currentStep + 1);
          }
        };

        vm.cancelClicked = () => {
          vm.changeStep(0);
        };
      },
      link(scope, element, attrs, controller) {
        $window.onbeforeunload = checkOnBeforeUnload;

        scope.$on('$destroy', () => {
          $window.onbeforeunload = undefined;
        });

        // This prevents the user from using the back button or any other navigation point.
        scope.$on('$stateChangeStart', function (event) {
          if (controller.currentStep === 3 && controller.progress !== 100) {
            let message = $translate.instant('BATCH_EDITING_LEAVE_PAGE_MESSAGE');
            if (!$window.confirm(message)) {
              event.preventDefault();
            }
          }
        });

        // This prevents the user from closing or refreshing the tab.
        function checkOnBeforeUnload(event) {
          if (controller.currentStep === 3 && controller.progress !== 100) {
            let message = $translate.instant('BATCH_EDITING_LEAVE_PAGE_MESSAGE');
            if (typeof event === 'undefined') {
              event = $window.event;
            }
            if (event) {
              event.returnValue = message;
            }
            return message;
          }
        }
      }
    };
  }
}());

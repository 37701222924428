(function () {
  'use strict';

  angular
    .module('vcMain')
    .config(config);

  function config($httpProvider) {
    $httpProvider.interceptors.push(['$q', '$rootScope', ($q, $rootScope) => {
      return {
        request: (cfg) => {
          if (!cfg.ignoreSpinner) {
            $rootScope.activeRequests = valOrZero($rootScope.activeRequests) + 1;
          }
          return cfg;
        },
        response: (response) => {
          if (!response.config.ignoreSpinner) {
            $rootScope.activeRequests = substractWithFloor($rootScope.activeRequests, 1, 0);
          }
          return response;
        },
        responseError: (response) => {
          if (!response.config.ignoreSpinner) {
            $rootScope.activeRequests = substractWithFloor($rootScope.activeRequests, 1, 0);
          }
          return $q.reject(response);
        }
      };
    }]);

    function substractWithFloor(value, substract, floor) {
      return Math.max(valOrZero(value) - substract, floor);
    }

    function valOrZero(value) {
      return value || 0;
    }
  }
}());

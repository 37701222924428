(function () {
  'use strict';

  /* @ngdoc object
   * @name timeTracking.costCenters
   * @description
   *
   */
  angular
    .module('timeTracking.costCenters', [
      'ui.router',
      'timeTracking.costCenters.list',
      'timeTracking.costCenters.edit',
      'timeTracking.costCenters.delete'
    ]);
}());

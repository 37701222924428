<ng-form class="form-group" ng-class="{'has-error': lessorForm.$invalid}" name="lessorForm" style="display: block;">
    <label class="control-label">{{'PARCELS_PARAM_OWNERSHIP' | translate}}</label>
    <ui-select multiple ng-model="edit.item.lessors" theme="bootstrap" tagging="edit.newLessor" style="margin-bottom: 5px;" ng-disabled="!edit.fullEditAvailable || edit.isShared">
        <ui-select-match placeholder="  {{'PLEASE_MAKE_CHOICE' | translate}}">
            {{$item.label}}
        </ui-select-match>
        <ui-select-choices repeat="lessor in edit.getAvailableLessors($select.search)">
            {{lessor.label}}
        </ui-select-choices>
    </ui-select>
    <div class="row"
         style="margin-bottom: 5px;"
         ng-repeat="lessor in edit.item.lessors | orderBy : 'label'">
        <div class="col-xs-9">
            <input class="form-control"
                   type="text"
                   placeholder="{{'PARCELS_PARAM_OWNERSHIP' | translate}}"
                   readonly
                   ng-model="lessor.label" />
        </div>
        <div class="col-xs-3">
            <div class="input-group">
                <input class="form-control"
                       type="number"
                       placeholder="{{'PARCELS_PARAM_PERCENT' | translate}}"
                       ng-readonly="!edit.fullEditAvailable || edit.isShared"
                       pattern="^\d{1,6}(?:[,\.]\d{1,4})?$"
                       ng-model="lessor.part"
                       required />
                <span class="input-group-addon">{{'measurementUnit.PERCENT' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="row"
         style="margin-bottom: 5px;"
         ng-if="edit.item.lessors.length > 1">
        <div class="col-xs-3 col-xs-offset-9 text-right">
            <div class="input-group">
                <input class="form-control"
                       type="number"
                       ng-disabled="true"
                       ng-value="edit.lessorSum().toFixed(4)" />
                <span class="input-group-addon">{{'measurementUnit.PERCENT' | translate}}</span>
            </div>
        </div>
    </div>
    <p class="help-block"
       ng-messages="flurForm.$error">
        <span ng-message="pattern">{{'PARCELS_PARAM_PERCENT_SIZE' | translate}}</span>
        <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
    </p>
</ng-form>

<div class="checkbox" style="margin-bottom: 15px">
    <label for="leased" style="font-weight: 700">
        <input  id="leased"
                type="checkbox"
                ng-disabled="!edit.fullEditAvailable || edit.isShared"
                ng-model="edit.item.leased" />
        {{'site.leased.label' | translate}}
    </label>
</div>
<div class="checkbox" style="margin-bottom: 15px">
    <label for="underLeased" style="font-weight: 700">
        <input id="underLeased"
               type="checkbox"
               ng-disabled="!edit.fullEditAvailable || edit.isShared"
               ng-model="edit.item.underLeased" />
        {{'site.underLeased.label' | translate}}
    </label>
</div>
<div class="checkbox" style="margin-bottom: 15px">
    <label for="geldpacht" style="font-weight: 700">
        <input id="geldpacht"
               type="checkbox"
               ng-disabled="!edit.fullEditAvailable || edit.isShared"
               ng-model="edit.item.geldpacht" />
        {{'site.geldpacht.label' | translate}}
    </label>
</div>

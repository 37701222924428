<sub-menu items="edit.submenu.items"
form="form" ng-if="edit.canEdit"></sub-menu>
<main-menu items="edit.mainmenu.items"></main-menu>

<div class="base-layout">
  <div class="base-layout-default">
    <main-message message="edit.message" fluid="'nested'"></main-message>
    <ng-form name="form"
             class="businesses-edit"
             autocomplete="off">
      <!-- LABEL -->
      <div class="form-group"
           ng-class="{
             'has-error': form.label.$invalid
           }">
        <label class="control-label"
               for="model">{{'WINERY_MANAGEMENT_WINERIES_LABEL' | translate}}</label>
        <input class="form-control"
               type="text"
               name="label"
               id="label"
               ng-model="edit.business.label"
               required
               ng-readonly="!edit.canEdit" />
        <p class="help-block"
           ng-messages="form.label.$error">
          <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
        </p>
      </div>

      <!-- ICE -->
      <div class="form-group"
           ng-class="{
             'has-error': form.ice.$invalid
           }">
        <label class="control-label"
               for="model">{{'WINERY_MANAGEMENT_WINERIES_ICE' | translate}}</label>
        <input class="form-control"
               type="text"
               name="ice"
               id="ice"
               ng-model="edit.business.ice"
               required
               ng-readonly="!edit.canEdit" />
        <p class="help-block"
           ng-messages="form.ice.$error">
          <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
        </p>
      </div>

      <!-- Vineyards -->
      <div class="form-group" ng-if="!edit.isCreate">
        <a class="pull-right" ui-sref="parcels.create({businessId: edit.business.id})" ng-if="edit.canCreateVineyard">{{'WINERY_MANAGEMENT_PARCELS_NEW' | translate}}</a>
        <label class="control-label">{{'WINERY_MANAGEMENT_WINERIES_VINEYARDS' | translate}}</label>
        <ul class="list-group well" ng-if="edit.vineyards.length">
            <li class="list-group-item" ng-repeat="vineyard in edit.vineyards"
                ui-sref="parcels.edit({id: vineyard.id})">
                <span>{{vineyard.label}}</span>
            </li>
        </ul>
        <div class="alert alert-info" ng-if="!edit.vineyards.length">{{'WINERY_MANAGEMENT_WINERIES_NO_VINEYARDS' | translate}}</div>
      </div>
    </ng-form>
  </div>
</div>

(function () {
  'use strict';

  /* @ngdoc object
   * @name managementUnits
   * @description
   *
   */
  angular
    .module('managementUnits', [
      'ui.router',
      'managementUnits.delete',
      'managementUnits.list',
      'managementUnits.edit'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name vcMain.directive:vcmap
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="vcMain">
       <file name="index.html">
        <vcmap></vcmap>
       </file>
     </example>
   *
   */
  angular
    .module('vcMain')
    .directive('vcmap', vcmap);

  function vcmap() {
    return {
      restrict: 'E',
      scope: {
        vcMapData: '=',
        vcMapDrawingData: '='
      },
      templateUrl: 'directives/vcmap-directive.tpl.html',
      replace: false,
      controllerAs: 'vcmap',
      controller($scope, NgMap, MapHelper, tileColors, $state, $filter, $timeout, userSiteStatus) {
        /* global google */
        /* eslint-disable no-loop-func */
        /* jshint loopfunc:true */
        let vm = this;

        MapHelper.initMap();

        $scope.$watch('vcMapData', function (newValue) {
          if (newValue) {
            if (!angular.equals({}, $scope.vcMapDrawingData)) {
              // Clear Users and Polygons
              MapHelper.clearMap();
              mapDrawing($scope.vcMapDrawingData, $scope.vcMapData.activatedOrdersVineyardsInfo, $scope.vcMapData.orderIndexObject);
            }
          }
        }, true);

        function mapDrawing(data, statusData, tileIndex) {
          let outlines = [],
              forBound = [],
              bounds,
              outlineToPush,
              info = {};

          angular.forEach(data, (vineyard, id) => {
            if (vineyard.outline) {
              info = getDrawingInfo(id, tileIndex);
              if (info !== null) {
                // Creating one array of points objects to set bound properly
                angular.forEach(vineyard.outline.path, (value) => {
                  forBound.push(value);
                });

                outlineToPush = MapHelper.simplePoints(vineyard.outline.path);
                // outlines.push(MapHelper.simplePoints(vineyard.outline.path));

                outlines.push({
                  id: id,
                  out: outlineToPush,
                  color: info.color,
                  draw: info.draw,
                  isTemporary: vineyard.isTemporary
                });
              }
            }
          });

          // Adding User Marker to the bounding values as well
          angular.forEach($scope.vcMapData.userProgressObject, function (user, orderid) {
            if ($scope.vcMapData.activatedOrdersArr.indexOf(parseInt(orderid, 10)) !== -1) {
              angular.forEach(user, (userPath) => {
                forBound.push({latitude: userPath.lat, longitude: userPath.lon, altitude: null});
              });
            }
          });

          // Adding Temp Worker Markers to the bounding values as well
          angular.forEach($scope.vcMapData.temporaryWorkersObject, function (worker, orderid) {
            if ($scope.vcMapData.activatedOrdersArr.indexOf(parseInt(orderid, 10)) !== -1) {
              angular.forEach(worker, (workerPath) => {
                if (workerPath.visible) {
                  forBound.push({latitude: workerPath.lat, longitude: workerPath.lon, altitude: null});
                }
              });
            }
          });

          // Setting bounds according to all points of all vineyards
          bounds = MapHelper.getBounds(forBound);

          NgMap.getMap().then((map) => {
            map.setCenter(bounds.getCenter());
            map.fitBounds(bounds);
          });

          vm.map = {
            outlines: outlines
          };

          // Draw Vineyard Polygons
          NgMap.getMap().then((map) => {
            angular.forEach(vm.map, function (outlinesArray) {
              for (let i = 0; i < outlinesArray.length; i++) {
                if (outlinesArray[i].draw) {
                  let path = [],
                      vPolygon = null;
                  for (let j = 0; j < outlinesArray[i].out.length; j++) {
                    path.push({lat: outlinesArray[i].out[j][0], lng: outlinesArray[i].out[j][1]});
                  }

                  vPolygon = new google.maps.Polygon({
                    paths: path,
                    strokeColor: outlinesArray[i].color,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: outlinesArray[i].color,
                    fillOpacity: 0.9
                  });

                  vPolygon.setMap(map);
                  vPolygon.vid = outlinesArray[i].id;

                  if (!outlinesArray[i].isTemporary) {
                    google.maps.event.addListener(vPolygon, 'click', function () {
                      goToState(vPolygon.vid, 'vineyard');
                    });
                  }

                  if (angular.isUndefined(map.vpolygons)) {
                    map.vpolygons = [];
                  }
                  map.vpolygons.push(vPolygon);
                }
              }
            });

            // Draw master users
            drawMarkers($scope.vcMapData.userProgressObject, true, map, tileIndex);

            // Draw temporary workers
            drawMarkers($scope.vcMapData.temporaryWorkersObject, false, map, tileIndex);

            // Couldn't figure out the exact issue which is cause this timing issue
            // in case of rendering custom markers. So for now progragmatically sliding the map
            // to show all the custom markers
            $timeout(function () {
              NgMap.getMap(vm.mapId).then((gmap) => {
                let cnt = gmap.getCenter();
                cnt.e += 0.000001;
                gmap.panTo(cnt);
                cnt.e -= 0.000001;
                gmap.panTo(cnt);
              });
            }, 500);
          });
        }

        function drawMarkers(pointsArray, masterUser, map, tileIndex) {
          angular.forEach(pointsArray, function (user, orderid) {
            if ($scope.vcMapData.activatedOrdersArr.indexOf(parseInt(orderid, 10)) !== -1) {
              angular.forEach(user, function (userPath, wid) {
                if (userPath.visible) {
                  let marker = new google.maps.Marker({
                    map: map,
                    position: new google.maps.LatLng(userPath.lat, userPath.lon),
                    icon: {
                      path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
                      fillColor: tileColors[tileIndex[orderid]],
                      fillOpacity: 0.9,
                      strokeColor: '#000',
                      strokeWeight: 1,
                      scale: masterUser ? 1 : 0.5
                    }
                  });
                  // Append click handler only if needed as in case of master users
                  if (masterUser) {
                    marker.wid = wid;

                    google.maps.event.addListener(marker, 'click', function () {
                      goToState(marker.wid, 'user');
                    });
                  }

                  if (angular.isUndefined(map.markers)) {
                    map.markers = [];
                  }
                  map.markers.push(marker);
                }
              });
            }
          });
        }

        function getDrawingInfo(vineyardId, tileIndex) {
          // Check If needs to be drawn or not
          let orderList = [],
              color = '',
              draw = true;
          // get all orders in which this vineyard exist
          angular.forEach($scope.vcMapData.activatedOrdersVineyardsInfo, function (value, orderId) {
            if (value.indexOf(parseInt(vineyardId, 10)) !== -1) {
              orderList.push(orderId);
            }
          });

          // Decide color to draw it in
          if (orderList.length > 0) {
            draw = getDrawStatus(parseInt(vineyardId, 10), orderList, tileIndex);
            color = getColorInfo(parseInt(vineyardId, 10), orderList, tileIndex, draw[1]);
            return {color: color, draw: draw[0]};
          }
          return null;
        }

        function getColorInfo(vineyardId, existInOrders, tileIndex, vineyardBelongsTo) {
          let colorToReturn = '';
          // Decide color to draw it in
          if (existInOrders.length === 1) {
            colorToReturn = tileColors[tileIndex[existInOrders[0]]];
            return colorToReturn;
          } else if (existInOrders.length > 1) {
            if (angular.isDefined(vineyardBelongsTo[vineyardId])) {
              colorToReturn = tileColors[tileIndex[vineyardBelongsTo[vineyardId]]];
              return colorToReturn;
            }
            let priorityIndex = 12,
                colorToReturnIndex;
            colorToReturnIndex = priorityIndex;
            for (let i = 0; i < existInOrders.length; i++) {
              colorToReturn = tileColors[tileIndex[existInOrders[i]]];
              priorityIndex = tileColors.indexOf(colorToReturn);

              if (colorToReturnIndex > priorityIndex) {
                colorToReturnIndex = priorityIndex;
              }
            }
            colorToReturn = tileColors[colorToReturnIndex];
            return colorToReturn;
          }
        }

        function getDrawStatus(vineyardId, existInOrders, tileIndex) {
          if (existInOrders.length === 1) {
            // Decide draw status in case there is only one active order containing this vineyard
            let order = $filter('filter')($scope.vcMapData.ordersArr, {id: existInOrders[0]})[0],
                isFinished = isVineyardFinished(vineyardId, order);
            return [!isFinished, {}];
          } else if (existInOrders.length > 1) {
            // In case there are multiple active order containing this vineyard
            let priorityBasedSortedList;
            priorityBasedSortedList = Object.keys(tileIndex).sort(function (a, b) {
              return tileIndex[a] - tileIndex[b];
            });

            for (let i = 0; i < priorityBasedSortedList.length; i++) {
              let order = $filter('filter')($scope.vcMapData.ordersArr, {id: priorityBasedSortedList[i]})[0];
              if (existInOrders.indexOf(order.id.toString()) !== -1) {
                let isFinished = isVineyardFinished(vineyardId, order);
                if (!isFinished) {
                  return [true, {[vineyardId]: order.id}];
                }
              }
            }

            return [false, {}];
          }
        }

        function isVineyardFinished(vineyardId, order) {
          let isFinished = true;
          if (order.siteStatus !== null) {
            let siteStatusObject = null;
            for (let i = 0; i < order.siteStatus.length; i++) {
              if (order.siteStatus[i].id === vineyardId) {
                siteStatusObject = order.siteStatus[i];
                break;
              }
            }
            if (siteStatusObject !== null) {
              if (order.finishStrategy === 'any') {
                isFinished = siteStatusObject.userStatus.some(us => us.status === userSiteStatus.FINISHED);
              }
            }
          }
          return isFinished;
        }

        function goToState(id, casee) {
          let state = '';
          switch (casee) {
            case 'vineyard':
              state = 'parcels.edit.orders.list';
              break;
            case 'user':
              state = 'users.edit';
              break;
            default:
              state = 'dashboard';
              break;
          }
          $state.go(state, {
            id: id
          });
        }
      }
    };
  }
}());

<form name="deleteTimeTrackingCostCenterForm">
  <div class="modal-header">
      <button type="button" class="close" ng-click="$dismiss();"><span>&times;</span></button>
      <h4 class="modal-title">{{'TIME_TRACKING_COST_CENTERS_DELETE' | translate}}</h4>
  </div>
  <div class="modal-body text-center">
      <h3>{{delete.item.label}}</h3>
  </div>
  <div class="modal-body" ng-bind-html="'TIME_TRACKING_COST_CENTERS_CONFIRM' | translate"></div>
  <div class="form-group modal-body" ng-class="{
        'has-error': deleteTimeTrackingCostCenterForm.costCenter.$invalid
    }">
    <ui-select ng-required="true" name="costCenter" ng-model="delete.costCenter" theme="bootstrap" style="margin-bottom: 5px;">
        <ui-select-match placeholder="{{'PLEASE_MAKE_CHOICE' | translate}}" >
          {{$select.selected.indentedLabel}}
        </ui-select-match>
        <ui-select-choices group-by="delete.groupCostCenters" repeat="cc as cc in delete.getAvailableCostCenters($select.search)">
          <div ng-bind-html="cc.indentedLabel | highlight: $select.search"></div>
        </ui-select-choices>
    </ui-select>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-default" ng-click="$dismiss();">{{'ABORT' | translate}}</button>
      <button type="button" class="btn btn-danger" ng-disabled="deleteTimeTrackingCostCenterForm.$invalid" ng-click="$close(delete.costCenter);">{{'OK' | translate}}</button>
  </div>
</form>
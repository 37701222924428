(function () {
  'use strict';

  angular
    .module('temporaryWorkers.delete')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('temporaryWorkers.delete', {
        url: '/delete',
        templateUrl: 'temporary-workers/delete/views/delete.tpl.html',
        controller: 'DeleteCtrl',
        controllerAs: 'delete'
      });
  }
}());

(function () {
  'use strict';

  class ServiceRoutingCtrl {
    constructor($state, $window, UserService, $http) {
      let vm = this;
      vm.UserService = UserService;

      vm.routeToServiceWithLogin = (entryPoint) => {
        const token = UserService.getAuthToken();
        $http.get('bonitur/loginWithToken', {headers: {'RefreshToken': token.refreshToken}}).then(function (response) {
          if(response.status === 200) {
            window.location.assign(entryPoint + 'list');
          }
        }, function (error) {
          console.log(error);
        });
      };

      vm.routeToService = (entryPoint) => {
          window.location.assign(entryPoint);
      };
    }
  }

  ServiceRoutingCtrl.$inject = ['$state', '$window', 'UserService', '$http'];

  /**
   * @ngdoc object
   * @name vcMain.controllers:ServiceRoutingCtrl
   *
   * @description
   *
   */
  angular
    .module('vcMain')
    .controller('ServiceRoutingCtrl', ServiceRoutingCtrl);
}());

<div class="form-group" ng-controller="ServiceRoutingCtrl as serviceRouting">
    <label class="control-label">{{(edit.boniturLabel ? edit.boniturLabel : 'classification.label') | translate}}</label>
    <table class="table table-bordered table-hover" ng-if="edit.classifications.length > 0">
        <thead>
        <tr>
            <th class="">{{'classification.survey' | translate}}</th>
            <th class="">{{'classification.effect' | translate}}</th>
            <th class="">{{'classification.state.label' | translate}}</th>
            <th class="">{{'classification.updated' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr ng-repeat="classification in edit.classifications" ng-click="serviceRouting.routeToService('/classification/' + classification.id)">
            <td class="">{{classification.survey}}</td>
            <td class="">{{classification.effect}}</td>
            <td class="">{{'classification.state.values.' + classification.state | translate}}</td>
            <td class="">{{classification.updated | amDateFormat: 'DD.MM.yyyy'}}</td>
        </tr>
        </tbody>
    </table>
</div>
<div id="bonitur-list">
  <div class="spinner" style="margin: auto;text-align: center;top: 50px;">
    <img src="https://gifimage.net/wp-content/uploads/2017/08/spinner-gif-13.gif"
         style="width: 60px; height: 60px;">
  </div>
</div>

(function () {
  'use strict';

  /* @ngdoc object
   * @name exports
   * @description
   *
   */
  angular
    .module('exports', [
      'ui.router',
      'exports.exports'
    ]);
}());

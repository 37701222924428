(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name vcMain.factory:IconHelper
   *
   * @description a helper service that enables interaction with Material Design icons available in the application
   *
   */
  angular
    .module('vcMain')
    .factory('IconHelper', IconHelper);

  IconHelper.$inject = ['$http', '$q'];

  function IconHelper($http, $q) {
    let IconHelperBase = {};

    IconHelperBase.generateSVGIconPath = generateSVGIconPath;
    IconHelperBase.getIconDescriptor = getIconDescriptor;

    /**
     * Generates the relative path to the SVG file of the specified Material Design icon.
     * Available icons can be found {@link https://material.io/tools/icons/?style=baseline|here}.
     *
     * @param {string} category the Material Design icon category
     * @param {string} icon the Material Design icon name
     * @returns {string} the relative path to the icon file
     */
    function generateSVGIconPath(category, icon) {
      return 'vendor/material-design-icons/' + category.toLowerCase() + '/svg/production/ic_' + icon.toLowerCase() + '_48px.svg';
    }

    /**
     * Generates the descriptor of the specified Material Design icon. Available icons can
     * be found {@link https://material.io/tools/icons/?style=baseline|here}.
     *
     * @param {string} category the Material Design icon category
     * @param {string} icon the Material Design icon name
     * @returns {Promise} a promise which resolves to the icon descriptor; the descriptor
     *                    contains width, height and the SVG path of the icon
     */
    function getIconDescriptor(category, icon) {
      let defer = $q.defer(),
          iconPath = generateSVGIconPath(category, icon);

      // get the icon using the path - we enable caching so that we won't download the same icon
      // over and over again
      $http({
        method: 'GET',
        url: iconPath,
        headers: {
          IGNORE_INTERCEPTOR: true
        },
        cache: true
      }).then(response => {
        // parse the SVG XML
        let svg = angular.element(response.data),
            paths = svg.find('path'),
            descriptor = {};

        if (angular.isUndefined(svg.attr('width')) || angular.isUndefined(svg.attr('height'))) {
          throw new Error('SVG has no width or height attribute');
        }

        descriptor.width = parseInt(svg.attr('width'), 10);
        descriptor.height = parseInt(svg.attr('height'), 10);

        // if the SVG has more then one path we just primitively combine them into a single
        // one by appending the path specifications.
        // in practice this seems to work well enough for now.
        angular.forEach(paths, path => {
          let pathElm = angular.element(path);
          if (angular.isDefined(pathElm.attr('d'))) {
            descriptor.path = (angular.isString(descriptor.path) ? descriptor.path + ' ' : '') + pathElm.attr('d');
          }
        });

        if (angular.isUndefined(descriptor.path)) {
          throw new Error('Could not extract path from SVG');
        }

        defer.resolve(descriptor);
      }, defer.reject);

      return defer.promise;
    }

    return IconHelperBase;
  }
}());

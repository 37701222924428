(function () {
    'use strict';

    class OrderTypesEditCtrl {
        constructor($stateParams, CrudApi, $state, $q, UserService, permissions, OrderTypeHelper) {
            let vm = this,
                promises = {};

            vm.new = !$stateParams.id;

            // Rights
            vm.canEdit = UserService.hasPerm(permissions.BASE_CAN_EDIT_ORDERTYPE);
            vm.canEditVisibility = UserService.hasPerm(permissions.ADMIN_CAN_EDIT_VISIBILITY_MODE);

            // Initial message
            vm.message = $stateParams.message;
            vm.orderTypeGroups = [];

            OrderTypeHelper.ready().then(() => {
                let orderTypeGroups = OrderTypeHelper.orderTypeGroups;
                orderTypeGroups = orderTypeGroups
                    .filter(group => group.selectable)
                    .filter(group => group.permission === 'BASE.CAN_USE' || UserService.hasPerm(group.permission))
                orderTypeGroups.forEach(group => {
                    group.labelProcessed = OrderTypeHelper.getTranslatedGroupLabel(group.label)
                });
                console.log(orderTypeGroups);
                vm.orderTypeGroups = orderTypeGroups;
            });

            // Add items to submenu
            vm.submenu = {
                items: [{
                    type: 'button',
                    text: 'BUTTON_SAVE',
                    click: () => {
                        doSave((data) => {
                            $state.go('orderTypes.edit', {
                                id: data.id,
                                message: 'savingSuccess'
                            }, {
                                reload: true
                            });
                        });
                    }
                }]
            };

            vm.mainmenu = {
                items: []
            };

            vm.doSave = doSave;

            promises.items = CrudApi.query({
                entity: 'orderTypes',
                customOnly: true
            }).$promise;

            if (!$stateParams.id) {
                promises.item = {
                    position: 0,
                    visibilityMode: 'TENANT'
                };
            } else {
                // Api call
                promises.item = CrudApi.get({
                    id: $stateParams.id,
                    entity: 'orderTypes'
                }).$promise;
            }

            $q.all(promises).then(({item, items}) => {
                vm.items = items.filter(t => t.id !== item.id);
                vm.item = item;

                if (angular.isDefined(item.id)) {
                    vm.currentPosition = item.position;
                    if (item.customerId === null) {
                        // the user tried to edit a system type
                        $state.go('orderTypes.list');
                    }

                    vm.mainmenu.items.push({
                        label: item.label,
                        translate: false,
                        state: 'orderTypes.edit',
                        params: {
                            id: $stateParams.id
                        }
                    });
                } else {
                    vm.currentPosition = Infinity;
                    vm.mainmenu.items.push({
                        label: 'ORDER_TYPE_NEW',
                        state: 'orderTypes.edit'
                    });
                }
            }).catch(() => {
                vm.message = 'loadingError';
            });

            function doSave(successCallback) {
                CrudApi.save({
                    id: $stateParams.id,
                    entity: 'orderTypes'
                }, vm.item, successCallback, (response) => {
                    if (response.status === 409) {
                        vm.message = 'alreadyExists';
                    } else if (response.status === 410) {
                        vm.message = 'entityHasChanged';
                    } else {
                        vm.message = 'savingError';
                    }
                });
            }
        }
    }

    OrderTypesEditCtrl.$inject = ['$stateParams', 'CrudApi', '$state', '$q', 'UserService', 'permissions', 'OrderTypeHelper'];

    /**
     * @ngdoc object
     * @name orderTypes.edit.controller:OrderTypesEditCtrl
     *
     * @description
     *
     */
    angular
        .module('orderTypes.edit')
        .controller('OrderTypesEditCtrl', OrderTypesEditCtrl);
}());

<sub-menu items="list.submenu.items"></sub-menu>
<main-menu></main-menu>

<div class="customer-list base-layout">
  <div class="base-layout-default">
    <main-message message="list.message" fluid="'nested'"></main-message>
    <p ng-if="list.canSeeAll">
        <span>{{'ADMIN_CUSTOMER_INFO_TOTAL' | translate}}: {{list.customerInfo.total}} / </span>
        <span>{{'ADMIN_CUSTOMER_INFO_ACTIVE' | translate}}: {{list.customerInfo.active}} / </span>
        <span>{{'ADMIN_CUSTOMER_INFO_INACTIVE' | translate}}: {{list.customerInfo.inactive}}</span>
    </p>
    <div class="table-responsive" ng-if="list.customers.length">
      <table class="table table-hover">
        <thead>
          <th>#</th>
          <th>{{'ADMIN_CUSTOMERS_LABEL' | translate}} </th>
          <th ng-click="list.sort('isActive')">{{'basicTerms.status' | translate}}
              <span class="glyphicon pull-right text-muted"
                    ng-if="list.sorter === 'isActive'"
                    ng-class="{
                    'glyphicon-chevron-down': list.dir === 'asc',
                    'glyphicon-chevron-up': list.dir === 'desc'
                  }"></span></th>
          <th ng-if="list.canDelete">{{'DELETE' | translate}}</th>
        </thead>
        <tbody>
          <tr ng-repeat="customer in list.customers"
              ui-sref="customers.edit({
                id: customer.id
              })">
            <td>{{customer.consecutiveNumber}}</td>
            <td>{{customer.label}}</td>
            <td ng-if="customer.isActive">{{'ADMIN_CUSTOMER_INFO_ACTIVE' | translate}}</td>
            <td ng-if="!customer.isActive">{{'ADMIN_CUSTOMER_INFO_INACTIVE' | translate}}</td>
            <td ng-if="list.canDelete"
                class="shrink text-center">
              <button class="btn btn-link"
                      ng-click="$event.stopPropagation(); list.delete(customer)">
                <span class="glyphicon glyphicon-trash"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ul uib-pagination total-items="list.total"
      ng-if="list.total > list.itemsPerPage"
      ng-model="list.page"
      items-per-page="list.itemsPerPage"
      ng-change="list.paginate()"
      direction-links="false">
  </div>
</div>

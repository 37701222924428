(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name timeTracking.directive:infiniteScroll
   * @restrict A
   * @element
   *
   * @description This directive calls the specified expression whenever the end of its host element scrolls into view
   *
   * @example
     <element module="timeTracking" infinite-scroll="loadMore()">
     </element>
   *
   */
  angular
    .module('timeTracking')
    .directive('infiniteScroll', infiniteScroll);

  infiniteScroll.$inject = ['$window', '$rootScope', '$timeout'];

  function infiniteScroll($window, $rootScope, $timeout) {
    return {
      restrict: 'A',
      scope: {
        infiniteScroll: '&',
        infiniteScrollHorizontal: '='
      },
      link: function ($scope, $el) {
        let lastElementSize = null;
        $el.bind('scroll', handler);

        $scope.$on('$destroy', () => {
          $el.unbind('scroll', handler);
        });

        handler();

        function handler(event) {
          let shouldCall = false,
              currentElementSize;

          if (!$scope.infiniteScrollHorizontal) {
            currentElementSize = $el[0].scrollHeight;
            shouldCall = $window.innerHeight + $el[0].scrollTop >= currentElementSize;
          } else {
            currentElementSize = $el[0].scrollWidth;
            shouldCall = $window.innerWidth + $el[0].scrollLeft >= currentElementSize;
          }

          if (shouldCall) {
            /* eslint "angular/ng_no_private_call": 0 */
            if ($scope.$$phase || $rootScope.$$phase) {
              $scope.infiniteScroll();
            } else {
              $scope.$apply($scope.infiniteScroll);
            }
          }

          // when initializing the content we want to make sure that we either have enough
          // elements to be able to scroll the element or if there are not enough to show
          // all of them. to achieve this we might need to call the "load more" expression
          // more than once. so in case we need to load more and the last time we did the
          // element size changed we queue up another execution of the handler function
          // until we are big enough or nothing changes anymore.
          if (!event && shouldCall && (lastElementSize === null || lastElementSize !== currentElementSize)) {
            lastElementSize = currentElementSize;
            $timeout(() => handler(), 0);
          }
        }
      }
    };
  }
}());

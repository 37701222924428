<ng-form class="form-group order-plant-protection"
         name="plantProtectionForm"
         style="display: block;"
         ng-controller="OrderTypePlantProtectionCtrl as additional">
  <div class="alert alert-warning">{{'PLANT_PROTECTION_WARNING' | translate}}</div>

  <!-- ARTICLES -->
  <ng-form class="form-group"
           ng-class="{
             'has-error': plantProtectionArticlesForm.$invalid
           }"
           name="plantProtectionArticlesForm"
           style="display: block;">
    <label class="control-label">{{'PLANT_PROTECTION_ARTICLES' | translate}}</label>
    <ui-select multiple
               ng-disabled="!edit.isCreate && !edit.canEdit"
               ng-model="edit.additionalData.articles"
               theme="bootstrap"
               style="margin-bottom: 5px;"
               ng-if="additional.articles"
               on-select="additional.sanitizeSelection($item, edit.additionalData.articles)"
               required>
      <ui-select-match placeholder="  {{'PLEASE_MAKE_CHOICE' | translate}}">
        {{$item.article.label}}
      </ui-select-match>
      <ui-select-choices repeat="article in additional.getAvailableArticles($select.search, edit.additionalData.articles) | orderBy:'label'"
                         ui-disable-choice="article.disabled">
        {{article.label}}
      </ui-select-choices>
    </ui-select>
    <div class="row"
         style="margin-bottom: 5px;"
         ng-repeat="article in edit.additionalData.articles">
      <div class="col-xs-7">
        <input class="form-control"
               type="text"
               readonly
               ng-model="article.article.label" />
      </div>
        <div class="col-xs-2">
            <input class="vc-checkbox"
                   name="basic_effort"
                   id="basic_effort"
                   title="Basisaufwand berücksichtigen."
                   type="checkbox"
                   ng-disabled="!edit.isCreate && !edit.canEdit"
                   ng-readonly="!edit.canEdit"
                   ng-model="article.useBaseExpenditure"
            />
            <span class="ng-binding" />{{'ARTICLE_BASIC_EFFORT' | translate}}<span/>
      </div>

      <div class="col-xs-3">
        <div class="input-group">
          <input class="form-control"
                 type="number"
                 min="0"
                 ng-disabled="!edit.isCreate && !edit.canEdit"
                 pattern="^\d{1,6}(?:[,\.]\d{1,4})?$"
                 required
                 ng-model="article.amountPerArea" />
          <span class="input-group-addon"
                style="min-width: 40px;">{{article.article.unit}} / {{'PLANT_PROTECTION_ARTICLE_UNIT_HA' | translate}}</span>
        </div>
      </div>
    </div>
    <span class="help-block"
          ng-if="additional.mode === 'HERBICIDE'">
      {{'PLANT_PROTECTION_ARTICLES_HERBICIDES' | translate}}
    </span>
    <span class="help-block"
          ng-if="additional.mode === 'REGULAR'">
      {{'PLANT_PROTECTION_ARTICLES_NON_HERBICIDES' | translate}}
    </span>
  </ng-form>
  <uib-accordion ng-if="plantProtectionArticlesForm.$valid"
                 close-others="false">
    <div uib-accordion-group
         class="panel-default">
      <uib-accordion-heading>
        <span ng-class="{
          'text-danger': plantProtectionAreaMethodForm.$invalid
        }">{{'PLANT_PROTECTION_AREA_METHOD' | translate}}</span>
        <span class="glyphicon glyphicon-warning-sign pull-right text-danger"
              ng-if="plantProtectionAreaMethodForm.$invalid"></span>
      </uib-accordion-heading>
      <ng-form name="plantProtectionAreaMethodForm">
        <!-- CALCULATION METHOD -->
        <div class="form-group"
             ng-class="{
               'has-error': plantProtectionAreaMethodForm.calculationMethod.$invalid
             }">
          <label class="control-label">{{'PLANT_PROTECTION_CALCULATION_METHOD' | translate}}</label>
          <select class="form-control"
                  name="calculationMethod"
                  ng-model="edit.additionalData.calculationMethod"
                  ng-disabled="!edit.isCreate && !edit.canEdit"
                  required>
            <option value=""
                    ng-if="!edit.additionalData.calculationMethod">{{'PLEASE_MAKE_CHOICE' | translate}}</option>
            <option value="ground"
                    ng-if="additional.mode !== 'NONE'">{{'PLANT_PROTECTION_CALCULATION_METHOD_GROUND' | translate}}</option>
            <option value="lwf"
                    ng-if="additional.mode !== 'NONE' && additional.mode !== 'HERBICIDE'">{{'PLANT_PROTECTION_CALCULATION_METHOD_LWF' | translate}}</option>
          </select>
        </div>
        <!-- AREA METHOD -->
        <div class="form-group"
             ng-if="plantProtectionAreaMethodForm.calculationMethod.$valid && additional.mode && edit.additionalData.calculationMethod && edit.areaSums"
             ng-class="{
               'has-error': plantProtectionAreaMethodForm.areaMethod.$invalid
             }">
          <label class="control-label">{{'PLANT_PROTECTION_AREA_METHOD' | translate}}</label>
          <select class="form-control"
                  name="areaMethod"
                  ng-model="edit.additionalData.areaMethod"
                  ng-disabled="!edit.isCreate && !edit.canEdit"
                  required>
            <option value=""
                    ng-if="!edit.additionalData.areaMethod">{{'PLEASE_MAKE_CHOICE' | translate}}</option>
            <option value="gross"
                    ng-if="!plantProtectionAreaMethodForm.calculationMethod.$invalid">
              {{'PLANT_PROTECTION_AREA_METHOD_BRUTTO' | translate}}
            </option>
            <option value="net"
                    ng-if="!plantProtectionAreaMethodForm.calculationMethod.$invalid &&
                           !(additional.mode === 'REGULAR' && edit.additionalData.calculationMethod === 'lwf' && !edit.areaSums.strikeLength) &&
                           !(additional.mode === 'HERBICIDE' && edit.areaSums && !edit.areaSums.strikeLength)">
              {{'PLANT_PROTECTION_AREA_METHOD_NETTO' | translate}}
            </option>
          </select>
          <span class="help-block"
                ng-if="plantProtectionAreaMethodForm.calculationMethod.$valid">
            <span ng-class="{
              'strong': edit.additionalData.areaMethod === 'gross'
            }">
              {{'PLANT_PROTECTION_AREA_METHOD_BRUTTO' | translate}}<br />
              <ul ng-if="additional.mode === 'REGULAR' && edit.additionalData.calculationMethod === 'ground'">
                <li>{{'PLANT_PROTECTION_PARCELS_BRUTTO' | translate}}</li>
                <li>= {{edit.areaSums.brutto | number:4}}{{'PLANT_PROTECTION_ARTICLE_UNIT_HA' | translate}}</li>
              </ul>
              <ul ng-if="additional.mode === 'REGULAR' && edit.additionalData.calculationMethod === 'lwf'">
                <li ng-if="!edit.additionalData.workingArea">{{'PLANT_PROTECTION_AREA_METHOD_BRUTTO_MISSING_WORKING_AREA' | translate}}</li>
                <li ng-if="!edit.additionalData.foliageHeight">{{'PLANT_PROTECTION_AREA_METHOD_BRUTTO_MISSING_FOLIAGE_HEIGHT' | translate}}</li>
                <li ng-if="edit.additionalData.workingArea && edit.additionalData.foliageHeight">({{'PLANT_PROTECTION_PARCELS_BRUTTO' | translate}} / {{'PLANT_PROTECTION_WORKING_AREA' | translate}}) * {{'PLANT_PROTECTION_FOLIAGE_HEIGHT' | translate}} * 2 {{'PLANT_PROTECTION_FOLIAGE_SIDES' | translate}}</li>
                <li ng-if="edit.additionalData.workingArea && edit.additionalData.foliageHeight">({{edit.areaSums.brutto}}{{'PLANT_PROTECTION_ARTICLE_UNIT_HA' | translate}} / {{edit.additionalData.workingArea}}{{'PLANT_PROTECTION_WORKING_AREA_UNIT' | translate}}) * {{edit.additionalData.foliageHeight}}{{'PLANT_PROTECTION_FOLIAGE_HEIGHT_UNIT' | translate}} * 2 {{'PLANT_PROTECTION_FOLIAGE_SIDES' | translate}}</li>
                <li ng-if="edit.additionalData.workingArea && edit.additionalData.foliageHeight">= {{(edit.areaSums.brutto / edit.additionalData.workingArea) * edit.additionalData.foliageHeight * 2| number:4}}{{'PLANT_PROTECTION_ARTICLE_UNIT_HA' | translate}}</li>
              </ul>
              <ul ng-if="additional.mode === 'HERBICIDE'">
                <li ng-if="!edit.additionalData.workingArea">{{'PLANT_PROTECTION_AREA_METHOD_BRUTTO_MISSING_WORKING_AREA' | translate}}</li>
                <li ng-if="!edit.additionalData.sprayBandwidth">{{'PLANT_PROTECTION_AREA_METHOD_BRUTTO_MISSING_SPRAY_BAND_WIDTH' | translate}}</li>
                <li ng-if="edit.additionalData.workingArea && edit.additionalData.sprayBandwidth">({{'PLANT_PROTECTION_PARCELS_BRUTTO' | translate}} * {{'PLANT_PROTECTION_SPRAY_BAND_WIDTH' | translate}}) / {{'plantProtection.averageAisleWidth' | translate}}</li>
                <li ng-if="edit.additionalData.workingArea && edit.additionalData.sprayBandwidth">({{edit.areaSums.brutto | number:4}}{{'PLANT_PROTECTION_ARTICLE_UNIT_HA' | translate}} * {{edit.additionalData.sprayBandwidth}}{{'PLANT_PROTECTION_SPRAY_BAND_WIDTH_UNIT' | translate}}) / {{ additional.averageAisleWidth}}{{'PLANT_PROTECTION_WORKING_AREA_UNIT' | translate}}</li>
                <li ng-if="edit.additionalData.workingArea && edit.additionalData.sprayBandwidth">= {{(edit.areaSums.brutto * edit.additionalData.sprayBandwidth) / additional.averageAisleWidth | number:4}}{{'PLANT_PROTECTION_ARTICLE_UNIT_HA' | translate}}</li>
              </ul>
            </span>
            <span ng-class="{
              'strong': edit.additionalData.areaMethod === 'net'
            }">
              {{'PLANT_PROTECTION_AREA_METHOD_NETTO' | translate}}<br />
              <ul ng-if="additional.mode === 'REGULAR' && edit.additionalData.calculationMethod === 'ground'">
                <li>{{'PLANT_PROTECTION_PARCELS_NETTO' | translate}}</li>
                <li>= {{edit.areaSums.netto | number:4}}{{'PLANT_PROTECTION_ARTICLE_UNIT_HA' | translate}}</li>
              </ul>
              <ul ng-if="additional.mode === 'REGULAR' && edit.additionalData.calculationMethod === 'lwf'">
                <li ng-if="!edit.areaSums.strikeLength">{{'PLANT_PROTECTION_AREA_METHOD_NETTO_MISSING_STRIKE_LENGTH_SUM' | translate}}</li>
                <li ng-if="!edit.additionalData.foliageHeight">{{'PLANT_PROTECTION_AREA_METHOD_NETTO_MISSING_FOLIAGE_HEIGHT' | translate}}</li>
                <li ng-if="edit.areaSums.strikeLength && edit.additionalData.foliageHeight">{{'PLANT_PROTECTION_PARCELS_STRIKE_LENGTH' | translate}} * {{'PLANT_PROTECTION_FOLIAGE_HEIGHT' | translate}} * 2 {{'PLANT_PROTECTION_FOLIAGE_SIDES' | translate}}</li>
                <li ng-if="edit.areaSums.strikeLength && edit.additionalData.foliageHeight">{{edit.areaSums.strikeLength | number:4}}{{'PLANT_PROTECTION_PARCELS_STRIKE_LENGTH_UNIT' | translate}} * {{edit.additionalData.foliageHeight}}{{'PLANT_PROTECTION_FOLIAGE_HEIGHT_UNIT' | translate}} * 2 {{'PLANT_PROTECTION_FOLIAGE_SIDES' | translate}}</li>
                <li ng-if="edit.areaSums.strikeLength && edit.additionalData.foliageHeight">= {{(edit.areaSums.strikeLength * edit.additionalData.foliageHeight * 2) / 10000 | number:4}}{{'PLANT_PROTECTION_ARTICLE_UNIT_HA' | translate}}</li>
              </ul>
              <ul ng-if="additional.mode === 'HERBICIDE'">
                <li ng-if="!edit.areaSums.strikeLength">{{'PLANT_PROTECTION_AREA_METHOD_NETTO_MISSING_STRIKE_LENGTH_SUM' | translate}}</li>
                <li ng-if="!edit.additionalData.sprayBandwidth">{{'PLANT_PROTECTION_AREA_METHOD_NETTO_MISSING_SPRAY_BAND_WIDTH' | translate}}</li>
                <li ng-if="edit.areaSums.strikeLength && edit.additionalData.sprayBandwidth">{{'PLANT_PROTECTION_PARCELS_STRIKE_LENGTH' | translate}} * {{'PLANT_PROTECTION_SPRAY_BAND_WIDTH' | translate}}</li>
                <li ng-if="edit.areaSums.strikeLength && edit.additionalData.sprayBandwidth">{{edit.areaSums.strikeLength | number:4}}{{'PLANT_PROTECTION_PARCELS_STRIKE_LENGTH_UNIT' | translate}} * {{edit.additionalData.sprayBandwidth}}{{'PLANT_PROTECTION_SPRAY_BAND_WIDTH_UNIT' | translate}}</li>
                <li ng-if="edit.areaSums.strikeLength && edit.additionalData.sprayBandwidth">= {{(edit.areaSums.strikeLength * edit.additionalData.sprayBandwidth) / 10000 | number:4}}{{'PLANT_PROTECTION_ARTICLE_UNIT_HA' | translate}}</li>
              </ul>
            </span>
          </span>
        </div>
        <!-- WORKING AREA -->
        <div class="form-group"
             ng-class="{
               'has-error': plantProtectionAreaMethodForm.workingArea.$invalid
             }">
            <div class="row"
                 style="margin-bottom: 5px;">
                <div class="col-xs-4">
                    <label class="control-label">{{'plantProtection.numberOfLines' | translate}}</label>
                </div>
                <div class="col-xs-4">
                    <label class="control-label">{{'plantProtection.averageAisleWidth' | translate}}</label>
                </div>
                <div class="col-xs-4">
                    <label class="control-label">{{'PLANT_PROTECTION_WORKING_AREA' | translate}}</label>
                </div>
            </div>
            <div class="row"
                 style="margin-bottom: 5px;">
                <div class="col-xs-4">
                    <input class="form-control"
                           name="workingLines"
                           type="number"
                           ng-model="additional.numberOfLines"
                           ng-change="additional.workingLinesChange()"
                           ng-disabled="!edit.isCreate && !edit.canEdit"
                           ng-readonly="!edit.canEdit"
                           required/>
                </div>
                <div class="col-xs-4">
                    <div class="input-group">
                        <input class="form-control"
                               name="averageAisleWidth"
                               type="number"
                               ng-model="additional.averageAisleWidth"
                               ng-disabled="true"
                               ng-readonly="true"
                               required/>
                        <span class="input-group-addon">{{'PLANT_PROTECTION_WORKING_AREA_UNIT' | translate}}</span>
                    </div>
                </div>
                <div class="col-xs-4">
                    <div class="input-group">
                        <input class="form-control"
                               name="workingArea"
                               type="number"
                               min="0"
                               ng-change="additional.workingAreaChange()"
                               ng-model="edit.additionalData.workingArea"
                               ng-disabled="!edit.isCreate && !edit.canEdit"
                               required/>
                        <span class="input-group-addon">{{'PLANT_PROTECTION_WORKING_AREA_UNIT' | translate}}</span>
                    </div>
                </div>
            </div>
            <div class="alert alert-warning"
                ng-if="additional.totalCount !== edit.additionalData.selectedSites.length "
                ng-bind-html="'plantProtection.missingAisleWidth' | translate"/>
            </div>
        </div>
        <!-- SPRAY BAND WIDTH -->
        <div class="form-group"
             ng-if="additional.mode === 'HERBICIDE'"
             ng-class="{
               'has-error': plantProtectionAreaMethodForm.sprayBandwidth.$invalid
             }">
          <label class="control-label">{{'PLANT_PROTECTION_SPRAY_BAND_WIDTH' | translate}}</label>
          <div class="input-group">
            <input class="form-control"
                   name="sprayBandwidth"
                   type="number"
                   min="0"
                   step="0.1"
                   ng-model="edit.additionalData.sprayBandwidth"
                   ng-disabled="!edit.isCreate && !edit.canEdit"
                   required />
            <span class="input-group-addon">{{'PLANT_PROTECTION_SPRAY_BAND_WIDTH_UNIT' | translate}}</span>
          </div>
        </div>
        <!-- FOLIAGE HEIGHT -->
        <div class="form-group"
             ng-if="edit.additionalData.calculationMethod === 'lwf'"
             ng-class="{
               'has-error': plantProtectionAreaMethodForm.foliageHeight.$invalid
             }">
          <label class="control-label">{{'PLANT_PROTECTION_FOLIAGE_HEIGHT' | translate}}</label>
          <div class="input-group">
            <input class="form-control"
                   name="foliageHeight"
                   type="number"
                   min="0"
                   ng-model="edit.additionalData.foliageHeight"
                   ng-disabled="!edit.isCreate && !edit.canEdit"
                   required />
            <span class="input-group-addon">{{'PLANT_PROTECTION_FOLIAGE_HEIGHT_UNIT' | translate}}</span>
          </div>
        </div>
      </ng-form>
    </div>
    <div uib-accordion-group
         class="panel-default">
      <uib-accordion-heading>
        <span ng-class="{
          'text-danger': plantProtectionMixtureAmountForm.$invalid
        }">{{'PLANT_PROTECTION_MIXTURE_AMOUNT' | translate}}</span>
        <span class="glyphicon glyphicon-warning-sign pull-right text-danger"
              ng-if="plantProtectionMixtureAmountForm.$invalid"></span>
      </uib-accordion-heading>
      <ng-form name="plantProtectionMixtureAmountForm">
        <!-- PLANNED AMOUNT OF WATER -->
        <div class="form-group"
             ng-class="{
               'has-error': plantProtectionMixtureAmountForm.plannedAmountOfWater.$invalid
             }">
          <label class="control-label">{{'PLANT_PROTECTION_PLANNED_AMOUNT_OF_WATER' | translate}}</label>
          <div class="input-group">
            <input class="form-control"
                   name="plannedAmountOfWater"
                   type="number"
                   min="0"
                   ng-model="edit.additionalData.plannedAmountOfWater"
                   ng-disabled="!edit.isCreate && !edit.canEdit"
                   ng-change="additional.plannedAmountOfWaterChanged()"
                   required />
            <span class="input-group-addon">{{'PLANT_PROTECTION_PLANNED_AMOUNT_OF_WATER_UNIT' | translate}}</span>
          </div>
        </div>

          <!-- Effective AMOUNT OF WATER -->
          <div class="form-group"
               ng-class="{
               'has-error': plantProtectionMixtureAmountForm.effectiveAmountOfWater.$invalid
             }">
              <label class="control-label">{{'order.plantProtection.effectiveWaterPlanedPerHa' | translate}}</label>
              <div class="input-group">
                  <input class="form-control"
                         name="effectiveAmountOfWater"
                         type="number"
                         min="0"
                         ng-model="additional.effectiveWaterAmount"
                         ng-disabled="!edit.isCreate && !edit.canEdit"
                         ng-change="additional.effectiveWaterAmountChanged()"
                         required />
                  <span class="input-group-addon">{{'PLANT_PROTECTION_PLANNED_AMOUNT_OF_WATER_UNIT' | translate}}</span>
              </div>
          </div>

        <!-- BASE EXPENDITURE -->
        <div class="form-group"
             ng-class="{
               'has-error': plantProtectionMixtureAmountForm.baseExpenditure.$invalid
             }">
          <label class="control-label">{{'PLANT_PROTECTION_BASE_EXPENDITURE' | translate}}</label>
          <div class="input-group">
            <input class="form-control"
                   name="baseExpenditure"
                   type="number"
                   min="0"
                   ng-model="edit.additionalData.baseExpenditure"
                   ng-disabled="!edit.isCreate && !edit.canEdit"
                   required />
            <span class="input-group-addon">{{'PLANT_PROTECTION_BASE_EXPENDITURE_UNIT' | translate}}</span>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label">{{'PLANT_PROTECTION_MIXTURE_AMOUNT_TOTAL' | translate}}</label>
          <div class="alert alert-info"
               ng-if="plantProtectionForm.$invalid">
            {{'PLANT_PROTECTION_MIXTURE_AMOUNT_HELP' | translate}}
          </div>
          <div class="form-control-static"
               ng-if="plantProtectionForm.$valid">
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <tr>
                  <th>{{'PLANT_PROTECTION_WATER' | translate}}</th>
                  <td class="text-right">
                    {{additional.calcTotalPlannedAmountOfWater() | number:4}} {{'PLANT_PROTECTION_MIXTURE_AMOUNT_UNIT' | translate}}
                  </td>
                </tr>
                <tr ng-repeat="article in edit.additionalData.articles">
                  <th>{{article.article.label}}</th>
                  <td class="text-right">
                    {{additional.calcTotalAmountOfArticle(article) | number:4}}
                    <span ng-if="article.article.unit === 'l'">{{'PLANT_PROTECTION_ARTICLE_UNIT_L_SHORT' | translate}}</span>
                    <span ng-if="article.article.unit !== 'l'">{{'PLANT_PROTECTION_ARTICLE_UNIT_KG_SHORT' | translate}}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="form-group">
              <label class="control-label">{{'PLANT_PROTECTION_MIXTURE_AMOUNT_SITE' | translate}}</label>
              <div class="alert alert-info"
                   ng-if="plantProtectionForm.$invalid">
                  {{'PLANT_PROTECTION_MIXTURE_AMOUNT_HELP' | translate}}
              </div>
              <div class="form-control-static"
                   ng-if="plantProtectionForm.$valid">
                  <uib-accordion close-others="false">
                      <div uib-accordion-group class="panel-default" ng-repeat="site in edit.selectedSites">
                          <uib-accordion-heading>
                              <span class="accordion-heading-size">{{site.label}}</span>
                              <span class="accordion-heading-size" ng-if="additional.useSteep(site)">(+25% {{'site.steep.label' | translate}})</span>
                          </uib-accordion-heading>
                          <div class="table-responsive">
                              <table class="table table-bordered table-striped no-margin-bottom">
                                  <tr>
                                      <th>{{'PLANT_PROTECTION_WATER' | translate}}</th>
                                      <td class="text-right">
                                          {{additional.calcPlannedAmountOfWater(site) | number:4}} {{'PLANT_PROTECTION_MIXTURE_AMOUNT_UNIT' | translate}}
                                      </td>
                                  </tr>
                                  <tr ng-repeat="article in edit.additionalData.articles">
                                      <th>{{article.article.label}}</th>
                                      <td class="text-right">
                                          {{additional.calcAmountOfArticle(article, site) | number:4}}
                                          <span ng-if="article.article.unit === 'l'">{{'PLANT_PROTECTION_ARTICLE_UNIT_L_SHORT' | translate}}</span>
                                          <span ng-if="article.article.unit !== 'l'">{{'PLANT_PROTECTION_ARTICLE_UNIT_KG_SHORT' | translate}}</span>
                                      </td>
                                  </tr>
                              </table>
                          </div>
                      </div>
                  </uib-accordion>
              </div>
          </div>
        <!-- CALCULATOR -->
        <div class="well"
             ng-if="edit.additionalData.plannedAmountOfWater && edit.additionalData.baseExpenditure">
          <div class="form-group">
            <label class="control-label">{{'PLANT_PROTECTION_MIXTURE_AMOUNT_CALCULATOR' | translate}}</label>
            <div class="input-group">
              <input class="form-control"
                     name="mixtureAmount"
                     type="number"
                     min="0"
                     ng-model="edit.additionalData.mixtureAmount"
                     ng-disabled="!edit.isCreate && !edit.canEdit" />
              <span class="input-group-addon">{{'PLANT_PROTECTION_MIXTURE_AMOUNT_UNIT' | translate}}</span>
            </div>
          </div>
          <table class="table table-bordered table-striped"
                 ng-if="edit.additionalData.mixtureAmount">
            <tr>
                <th>{{'PLANT_PROTECTION_ARTICLES' | translate}}</th>
                <th class="text-right">{{'PLANT_PROTECTION_MIXTURE_AMOUNT' | translate}}</th>
                <th ng-if="additional.hasSteep()" class="text-right">{{'PLANT_PROTECTION_MIXTURE_AMOUNT' | translate}} (+25% {{'site.steep.label' | translate}})</th>
            </tr>
            <tr ng-repeat="article in edit.additionalData.articles">
              <td>{{article.article.label}}</td>
              <td class="text-right">
                {{additional.calcMixtureAmount(article) | number:4}}
                <span ng-if="article.article.unit === 'l'">{{'PLANT_PROTECTION_ARTICLE_UNIT_L_SHORT' | translate}}</span>
                <span ng-if="article.article.unit !== 'l'">{{'PLANT_PROTECTION_ARTICLE_UNIT_KG_SHORT' | translate}}</span>
              </td>
              <td ng-if="additional.hasSteep()" class="text-right">
                  {{additional.calcMixtureAmount(article) * 1.25 | number:4}}
                  <span ng-if="article.article.unit === 'l'">{{'PLANT_PROTECTION_ARTICLE_UNIT_L_SHORT' | translate}}</span>
                  <span ng-if="article.article.unit !== 'l'">{{'PLANT_PROTECTION_ARTICLE_UNIT_KG_SHORT' | translate}}</span>
              </td>
            </tr>
          </table>
        </div>
      </ng-form>
    </div>
    <div uib-accordion-group
         class="panel-default">
      <uib-accordion-heading>
        <span ng-class="{
          'text-danger': plantProtectionSprayForm.$invalid
        }">{{'PLANT_PROTECTION_SPRAY_OUTPUT' | translate}}</span>
        <span class="glyphicon glyphicon-warning-sign pull-right text-danger"
              ng-if="plantProtectionSprayForm.$invalid"></span>
      </uib-accordion-heading>
      <ng-form name="plantProtectionSprayForm">
        <!-- SPEED -->
        <div class="form-group"
             ng-class="{
               'has-error': plantProtectionSprayForm.speed.$invalid
             }">
          <label class="control-label">{{'PLANT_PROTECTION_SPEED' | translate}}</label>
          <div class="input-group">
            <input class="form-control"
                   name="speed"
                   type="number"
                   min="0"
                   ng-model="edit.additionalData.speed"
                   ng-disabled="!edit.isCreate && !edit.canEdit"
                   required />
            <span class="input-group-addon">{{'PLANT_PROTECTION_SPEED_UNIT' | translate}}</span>
          </div>
        </div>
        <!-- OPEN JETS COUNT -->
        <div class="form-group"
             ng-class="{
               'has-error': plantProtectionSprayForm.openJetsCount.$invalid
             }">
          <label class="control-label">{{'PLANT_PROTECTION_OPEN_JETS_COUNT' | translate}}</label>
          <input class="form-control"
                 name="openJetsCount"
                 type="number"
                 min="1"
                 ng-model="edit.additionalData.openJetsCount"
                 ng-disabled="!edit.isCreate && !edit.canEdit"
                 required />
        </div>
        <!-- JET PRESSURE -->
        <div class="form-group"
             ng-class="{
               'has-error': plantProtectionSprayForm.jetPressure.$invalid
             }">
          <label class="control-label">{{'PLANT_PROTECTION_SPRAY_PRESSURE_AMOUNT' | translate}}</label>
          <div class="input-group">
            <input class="form-control"
                   name="jetPressure"
                   type="number"
                   min="0"
                   ng-model="edit.additionalData.jetPressure"
                   ng-disabled="!edit.isCreate && !edit.canEdit" />
            <span class="input-group-addon">{{'PLANT_PROTECTION_SPRAY_PRESSURE_AMOUNT_UNIT' | translate}}</span>
          </div>
        </div>
        <!-- OUTPUT -->
        <div class="form-group"
             ng-if="edit.additionalData.plannedAmountOfWater && edit.additionalData.speed && edit.additionalData.workingArea && edit.additionalData.openJetsCount">
          <label class="control-label">{{'PLANT_PROTECTION_SPRAY_OUTPUT' | translate}}</label>
          <div class="input-group-static">
            {{(additional.effectiveWaterAmount * edit.additionalData.speed * edit.additionalData.workingArea) / (edit.additionalData.openJetsCount * 600) | number:4}} {{'PLANT_PROTECTION_SPRAY_OUTPUT_UNIT' | translate}}
          </div>
        </div>
        <!-- OUTPUT TOTAL -->
        <div class="form-group"
             ng-if="edit.additionalData.plannedAmountOfWater && edit.additionalData.speed && edit.additionalData.workingArea && edit.additionalData.openJetsCount">
          <label class="control-label">{{'PLANT_PROTECTION_SPRAY_OUTPUT_TOTAL' | translate}}</label>
          <div class="input-group-static">
            {{(additional.effectiveWaterAmount * edit.additionalData.speed * edit.additionalData.workingArea) / 600 | number:4}} {{'PLANT_PROTECTION_SPRAY_OUTPUT_UNIT' | translate}}
          </div>
        </div>
      </ng-form>
    </div>
  </uib-accordion>
</ng-form>

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name vcMain.factory:CrudApi
   *
   * @description
   *
   */
  angular
    .module('vcMain')
    .factory('CrudApi', CrudApi);

  function CrudApi($resource, apiBasePath) {
    let resource = createResource();
    resource.newInstance = (actions) => createResource(actions);
    return resource;

    function createResource(actions) {
      return $resource(apiBasePath + ':entity/:id/:subentity/:subentityid/:command', {}, actions);
    }
  }

  CrudApi.$inject = ['$resource', 'apiBasePath'];
}());

<div ng-if="!edit.isCreate && (edit.type === 'regular' || edit.type === 'finished') && edit.additionalData !== null"
     ng-switch="(edit.orderTypes | filter:{id: edit.order.typeId}:true)[0].group">
  <div ng-switch-when="FERTILIZATION">
     <ng-include src="'orders/edit/views/order-types/fertilization-fertilization.tpl.html'"></ng-include>
  </div>
  <div ng-switch-when="PLANT_PROTECTION">
     <ng-include src="'orders/edit/views/order-types/plant-protection.tpl.html'"></ng-include>
  </div>
    <div ng-switch-default>
        <ng-include src="'orders/edit/views/order-types/material.tpl.html'"></ng-include>
    </div>
</div>

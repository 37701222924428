(function () {
  'use strict';

  angular
    .module('settings')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('settings', {
        url: '/settings',
        templateUrl: 'settings/views/edit.tpl.html',
        controller: 'SettingsEditCtrl',
        controllerAs: 'edit',
        params: {
          message: null
        }
      });
  }
}());

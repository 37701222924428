(function () {
  'use strict';

  angular
    .module('parcels')
    .config(config);

  function config($stateProvider, siteEntities) {
    $stateProvider
      .state('parcels', {
        abstract: true,
        template: '<ui-view />',
        url: '/' + siteEntities
      });
  }
}());

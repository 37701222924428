<div class="form-group">
    <label class="control-label" ng-if="!edit.domainWachau">{{'PARCELS_PARAM_DISTRICT' | translate}}</label>
    <label class="control-label" ng-if="edit.domainWachau">Ortsteil</label>
    <input class="form-control"
            type="text"
            ng-maxlength="255"
            ng-readonly="!edit.fullEditAvailable || edit.isShared"
            ng-model="edit.item.district" />
    <p class="help-block"
       ng-messages="form.district.$error">
        <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
    </p>
</div>

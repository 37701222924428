(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name factories.factory:CheckTokenApi
   *
   * @description
   *
   */
  angular
    .module('vcMain')
    .factory('CheckTokenApi', CheckTokenApi);

  function CheckTokenApi($resource, apiBasePath) {
    return $resource(apiBasePath + 'auth/checktoken', {}, {
      query: {
        method: 'GET',
        cache: false,
        isArray: false
      }
    });
  }

  CheckTokenApi.$inject = ['$resource', 'apiBasePath'];
}());

(function () {
  'use strict';

  angular
    .module('vcMain')
    .config(config);

  config.$inject = ['$translateProvider'];

  function config($translateProvider) {
    // translations are not stored in this project but in global i18n project.
    // watch.js can be set to not passing requests to external host for displaying raw keys.
    // "StaticFilesLoader" tries to get files by full url like 'https://workshop.vineyard-cloud.com/i18n/de.json'
    $translateProvider.useStaticFilesLoader({
      files: [{
        prefix: 'i18n/',
        suffix: '.json'
      }]
    });
    // Set default language, this will also be fallback
    $translateProvider.preferredLanguage('de');
    $translateProvider.fallbackLanguage('de');
    // Set sanitize strategy
    $translateProvider.useSanitizeValueStrategy('sanitizeParameters');
    // store selection as localStorage
    $translateProvider.useLocalStorage();
  }
}());

<div class="regular-orders-list">
    <span class="btn btn-link" ng-click="list.loadData()">
        {{'TOOLTIP_LOAD_DATA' | translate }}
    </span>
   <a class="btn btn-link"
    ui-sref="orders.create({
      v: list.vineyardId,
      type: 'regular'
    })">
    {{'TOOLTIP_ADD_ORDER' | translate }}
  </a>
  <div class="table-responsive" ng-if="list.items.length">
    <table class="table table-bordered table-hover">
      <thead>
        <th ng-click="list.sort('label')">
          {{'ORDER_LABEL' | translate}}
          <span class="glyphicon pull-right text-muted"
                ng-if="list.sorter === 'label'"
                ng-class="{
                  'glyphicon-chevron-down': list.dir === 'asc',
                  'glyphicon-chevron-up': list.dir === 'desc'
                }"></span>
        </th>
        <th ng-click="list.sort('deadlineDate')">
          {{'ORDER_DEADLINE_DATE' | translate}}
          <span class="glyphicon pull-right text-muted"
                ng-if="list.sorter === 'deadlineDate'"
                ng-class="{
                  'glyphicon-chevron-down': list.dir === 'asc',
                  'glyphicon-chevron-up': list.dir === 'desc'
                }"></span>
        </th>
        <th>{{'ORDER_ASSIGNED_USERS' | translate}}</th>
        <th>{{'ORDER_ASSIGNED_EQUIPMENTS' | translate}}</th>
        <th>{{'ORDER_STARTED' | translate}}</th>
        <th>{{'ORDER_ENDED' | translate}}</th>
        <th>{{'DELETE' | translate}}</th>
      </thead>
      <tbody>
        <tr ng-repeat="item in list.items"
            ui-sref="orders.edit({
             id: item.id,
             type: 'regular',
             v: item.siteIds
            })"
            ng-class="{
              'success': item.status === list.orderStatus.FINISHED
            }">
          <td>{{item.label}}</td>
          <td>{{item.deadlineDate | date}}</td>
          <td>{{item.assignedUserIds.length}}</td>
          <td>{{item.assignedEquipmentIds.length}}</td>
          <td>
            <span class="glyphicon" ng-class="{
              'glyphicon-ok': item.status !== list.orderStatus.NONE || item.progress !== 0,
              'glyphicon-remove': item.status === list.orderStatus.NONE && item.progress === 0
            }"></span>
          </td>
          <td>
            <span class="glyphicon" ng-class="{
              'glyphicon-ok': item.status === list.orderStatus.FINISHED,
              'glyphicon-remove': item.status !== list.orderStatus.FINISHED
            }"></span>
          </td>
          <td>
            <button class="btn btn-link"
                    ng-click="$event.stopPropagation(); list.delete(item)">
              <span class="glyphicon glyphicon-trash"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ul uib-pagination
      total-items="list.total"
      ng-if="list.total > list.itemsPerPage"
      ng-model="list.page"
      items-per-page="list.itemsPerPage"
      ng-change="list.paginate()"
      boundary-links="true"
      max-size="list.maxSize"
      previous-text="&lsaquo;"
      next-text="&rsaquo;"
      first-text="&laquo;"
      last-text="&raquo;"></ul>
</div>

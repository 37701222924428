(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name vcMain.directive:vcMapEdit
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="vcMain">
       <file name="index.html">
        <vc-map-edit></vc-map-edit>
       </file>
     </example>
   *
   */
  angular
    .module('vcMain')
    .directive('vcMapEdit', vcMapEdit);

  function vcMapEdit() {
    return {
      restrict: 'E',
      scope: {
        mapId: '<',
        triggerZoom: '<',
        drawingManagerOptions: '<',
        polygons: '<',
        polylines: '<',
        markers: '<',
        drawingManagerEvents: '<',
        objectEvents: '<'
      },
      templateUrl: 'directives/vc-map-edit-directive.tpl.html',
      replace: false,
      transclude: true,
      bindToController: true,
      controllerAs: 'vcMapEdit',
      controller: ['$q', 'MapHelper', 'NgMap', '$rootScope', function ($q, MapHelper, NgMap, $rootScope, $timeout) {
        let vm = this,
            objectEventTypes = ['click', 'mousedown', 'pathChanged'],
            defaultDrawingManagerOptions = {
              drawingControlOptions: {position: google.maps.ControlPosition.TOP_CENTER}
            },
            drawingManager = null,
            polygons = [],
            polylines = [],
            markers = [],
            mapReady = $q.defer();

        // we set the initial zoom to "germany" as soon as the map is ready
        mapReady.promise.then((map) => {
          let bounds = MapHelper.getInitialBounds(),
              cnt;

          map.fitBounds(bounds);
          cnt = map.getCenter();
          cnt.e += 0.000001;
          map.panTo(cnt);
          cnt.e -= 0.000001;
          map.panTo(cnt);
        });

        vm.$onInit = () => {
          NgMap.getMap(vm.mapId).then((map) => {
            google.maps.event.addListenerOnce(map, 'tilesloaded', () => mapReady.resolve(map));
            // it is possible that the "tilesloaded" event fired before the event handler
            // above was registered. to make sure that the map is still able to zoom
            // we resolve the mapReady promise after a second of waiting at the latest
            $timeout(() => {
              mapReady.resolve(map);
            }, 1000);
          });
        };

        vm.$onChanges = (changesObj) => {
          NgMap.getMap(vm.mapId).then((map) => {
            /* global google */
            if (changesObj.triggerZoom && angular.isArray(vm.polygons) && angular.isArray(vm.polylines) && angular.isArray(vm.markers) && (vm.polygons.length || vm.polylines.length || vm.markers.length)) {
              // Wrapping this into mapReady solves the problem of change triggering before init
              mapReady.promise.then(() => {
                let bounds = new google.maps.LatLngBounds(),
                    cnt;
                vm.polygons.concat(vm.polylines).forEach(poly => {
                  poly.path.forEach(point => extendBound(bounds, point));
                });
                vm.markers.forEach(marker => extendBound(bounds, marker.position));
                if (!bounds.isEmpty()) {
                  map.fitBounds(bounds);
                  cnt = map.getCenter();
                  cnt.e += 0.000001;
                  map.panTo(cnt);
                  cnt.e -= 0.000001;
                  map.panTo(cnt);
                }
              });
            }

            if (changesObj.polygons && angular.isArray(changesObj.polygons.currentValue)) {
              createObjects(
                map,
                'polygon',
                vm.polygons,
                (polygonOptions, existingObj) => {
                  polygonOptions.paths = MapHelper.pointsToLatLng(polygonOptions.path);
                  delete polygonOptions.path;
                  if (existingObj !== null) {
                    if (!compareObjectToOptions(existingObj, polygonOptions)) {
                      existingObj.setOptions(polygonOptions);
                    }
                    return existingObj;
                  }
                  return new google.maps.Polygon(polygonOptions);
                },
                polygons,
                objectEventTypes
              );
            }

            if (changesObj.polylines && angular.isArray(changesObj.polylines.currentValue)) {
              createObjects(
                map,
                'polyline',
                vm.polylines,
                (polylineOptions, existingObj) => {
                  polylineOptions.path = MapHelper.pointsToLatLng(polylineOptions.path);
                  if (existingObj) {
                    if (!compareObjectToOptions(existingObj, polylineOptions)) {
                      existingObj.setOptions(polylineOptions);
                    }
                    return existingObj;
                  }
                  return new google.maps.Polyline(polylineOptions);
                },
                polylines,
                objectEventTypes
              );
            }

            if (changesObj.markers && angular.isArray(changesObj.markers.currentValue)) {
              createObjects(
                map,
                'marker',
                vm.markers,
                (markerOptions, existingObj) => {
                  markerOptions.position = MapHelper.pointToLatLng(markerOptions.position);
                  if (existingObj) {
                    if (!compareObjectToOptions(existingObj, markerOptions)) {
                      existingObj.setOptions(markerOptions);
                    }
                    return existingObj;
                  }
                  return new google.maps.Marker(markerOptions);
                },
                markers,
                objectEventTypes
              );
            }

            if (angular.isDefined(changesObj.drawingManagerOptions) && changesObj.drawingManagerOptions.currentValue) {
              if (drawingManager !== null) {
                clearObjects([drawingManager]);
              }
              drawingManager = createDrawingManager(map, vm.drawingManagerOptions);
            }
          });
        };

        function compareObjectToOptions(object, options) {
          let equal = true,
              keys = Object.keys(options);
          for (let i = 0; i < keys.length && equal; i++) {
            const key = keys[i],
                value = options[key],
                otherValue = object.get(key);
            if (key === 'path' || key === 'paths') {
              equal = comparePaths(value, getPathFromPoly(object));
            } else if (key === 'position') {
              equal = comparePoints(value, object.getPosition());
            } else {
              equal = value === otherValue;
            }
          }
          return equal;
        }

        function comparePaths(pathArray, pathMVC) {
          let equal = angular.isArray(pathArray) && pathMVC instanceof google.maps.MVCArray && pathArray.length === pathMVC.getLength();
          for (let i = 0; i < pathArray.length && equal; i++) {
            const point = pathArray[i],
                otherPoint = pathMVC.getAt(i);
            equal = comparePoints(point, otherPoint);
          }
          return equal;
        }

        function comparePoints(point1, point2) {
          return point1 instanceof google.maps.LatLng && point2 instanceof google.maps.LatLng && point1.equals(point2);
        }

        function createObjects(map, objectType, sourceList, constructor, objectArray, eventTypes) {
          let descriptors = clearObjects(objectArray, sourceList);
          descriptors.forEach(descriptor => {
            let object = constructor(angular.copy(descriptor.sourceRef), descriptor.object);
            if (descriptor.object === null) {
              // we created a new object
              object.setMap(map);
            }
            descriptor.listeners = registerEventHandlers(eventTypes, object, objectType, descriptor.sourceRef);
            descriptor.object = object;
            objectArray.push(descriptor);
          });
        }

        function createDrawingManager(map, drawingOptions, defaultOptions = defaultDrawingManagerOptions) {
          let dManager = new google.maps.drawing.DrawingManager(angular.merge({}, defaultOptions, drawingOptions)),
              listeners = [];
          dManager.setDrawingMode(null);
          dManager.setMap(map);
          listeners.push(google.maps.event.addListener(dManager, 'overlaycomplete', (e) => {
            $rootScope.$apply(() => onMapOverlayCompleted(e));
          }));
          return {object: dManager, listeners: listeners, sourceRef: drawingOptions};
        }

        function registerEventHandlers(eventTypes, object, objectType, element) {
          let listeners = [];
          angular.forEach(eventTypes, (eventType) => {
            let expandedEvents = [],
                eventTarget,
                path = null;
            if (eventType === 'pathChanged') {
              path = getPathFromPoly(object);
              if (path !== null) {
                expandedEvents = ['insert_at', 'remove_at', 'set_at'];
                eventTarget = path;
              }
            } else {
              expandedEvents = [eventType];
              eventTarget = object;
            }
            angular.forEach(expandedEvents, (et) => {
              listeners.push(google.maps.event.addListener(eventTarget, et, (e) => {
                $rootScope.$apply(() => handleEvent(e, objectType, eventType, element, object));
              }));
            });
          });
          return listeners;
        }

        function getPathFromPoly(poly) {
          let path = null;
          if (poly instanceof google.maps.Polygon) {
            path = poly.getPath();
          } else if (poly instanceof google.maps.Polyline) {
            path = poly.getPath();
          }
          return path;
        }

        function clearObjects(objects, sourceList = []) {
          let result = [],
              srcCopy = sourceList.slice();
          while (objects.length > 0) {
            const obj = objects.pop(),
                idx = srcCopy.indexOf(obj.sourceRef);
            if (idx === -1) {
              obj.object.setMap(null);
            } else {
              result.push(obj);
              srcCopy.splice(idx, 1);
            }
            // because the path might change, we remove all existing listeners.
            // they will be reapplied later after a potential existing object
            // has been updated
            angular.forEach(obj.listeners, listener => listener.remove());
          }
          // the remaining sources have no corresponding object on the map yet
          srcCopy.forEach(src => result.push({sourceRef: src, object: null}));
          return result;
        }

        function handleEvent(ev, objectType, eventType, element, object) {
          if (angular.isObject(vm.objectEvents) && angular.isObject(vm.objectEvents[objectType]) && angular.isFunction(vm.objectEvents[objectType][eventType])) {
            vm.objectEvents[objectType][eventType](element, object, ev);
          }
        }

        function onMapOverlayCompleted(e) {
          // We expect the user to do something with the element that was drawn instead of just
          // showing it on the map
          e.overlay.setMap(null);

          if (angular.isObject(vm.drawingManagerEvents)) {
            let eventName = e.type + 'Completed';
            if (angular.isFunction(vm.drawingManagerEvents[eventName])) {
              vm.drawingManagerEvents[eventName](e.overlay);
            }
          }
        }

        function extendBound(bounds, point) {
          const latlng = new google.maps.LatLng(point.latitude, point.longitude);
          bounds.extend(latlng);
        }
      }]
    };
  }
}());

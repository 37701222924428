(function () {
  'use strict';

  angular
    .module('vcMain')
    .run(run);

  function run($rootScope) {
    let keepUntilAppCloses = $rootScope.$on('$stateChangeStart', () => {
      $rootScope.subMenu = false;
    });
    // Get rid of the event listener when the application closes
    $rootScope.$on('$destroy', () => {
      keepUntilAppCloses();
    });
  }
  run.$inject = ['$rootScope'];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name vcMain
   * @description
   *
   */
  angular
    .module('vcMain', [
      'constants',
      'ngAria',
      'ngCookies',
      'ngResource',
      'ngMessages',
      'ngSanitize',
      'ngMap',
      'ui.router',
      'ui.bootstrap',
      'pascalprecht.translate',
      'angular-jwt',
      'auth',
      'dashboard',
      'users',
      'parcels',
      'settings',
      'temporaryWorkers',
      'ngFileSaver',
      'rzModule',
      'orders',
      'fertilization',
      'plantProtection',
      'ui.validate',
      'dashboard',
      'uiSwitch',
      'comments',
      'ui.select',
      'angular.filter',
      'businessManagement',
      'admin',
      'managementUnits',
      'angularMoment',
      'orderTypes',
      'poi',
      'timeTracking',
      'exports',
      'angulartics',
      'angulartics.piwik'
    ]);
}());

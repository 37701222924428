(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name parcels.selection.directive:parcelsSelection
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="parcels.selection">
       <file name="index.html">
        <parcels-selection></parcels-selection>
       </file>
     </example>
   *
   */
  angular
    .module('parcels.selection')
    .directive('parcelsSelection', parcelsSelection);

  function parcelsSelection() {
    return {
      restrict: 'E',
      scope: {
        isCreate: '=',
        initialEdit: '=',
        redoRight: '=',
        assignedUser: '=',
        additionalData: '=',
        orderId: '=',
        orderType: '=',
        redoVineyards: '=',
        required: '@',
        siteStatus: '=siteStatus',
        strategy: '=vineyardStrategy',
        ngDisabled: '=',
        popup: '=',
        period: '=',
        showAreas: '=',
        sums: '=',
        selectedSites : '=',
        mapEnabled: '<',
        graphicalMode: '=?',
        isReadOnly: '='
      },
      require: {
        selection: 'ngModel'
      },
      templateUrl: 'parcels/selection/directives/parcels-selection-directive.tpl.html',
      replace: true,
      bindToController: true,
      controllerAs: 'parcelsSelection',
      controller: 'ParcelsSelectionCtrl'
    };
  }
}());

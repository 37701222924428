<sub-menu items="edit.submenu.items" form="form" ng-if="edit.canEdit"></sub-menu>
<main-menu items="edit.mainmenu.items"></main-menu>

<div class="base-layout">
  <div class="base-layout-default">
    <main-message message="edit.message" fluid="'nested'"></main-message>
    <ng-form name="form">
      <div class="form-group" ng-class="{
        'has-error': form.label.$invalid
      }">
        <label class="control-label">{{'TIME_TRACKING_COST_CENTERS_NAME' | translate}}</label>
        <input class="form-control"
              type="text"
              ng-model="edit.costCenter.label"
              name="label"
              required
              ng-readonly="!edit.canEdit" />
      </div>
      <div class="form-group">
        <label class="control-label">{{'TIME_TRACKING_COST_CENTERS_PARENT_NODE' | translate}}</label>
        <select class="form-control"
                ng-options="costCenter.id as costCenter.indentedLabel for costCenter in edit.costCenters"
                ng-model="edit.costCenter.parentId"
                name="parentCostCenter"
                ng-disabled="!edit.canEdit" >
          <option value="">{{'TIME_TRACKING_COST_CENTERS_NO_PARENT_NODE' | translate}}</option>
        </select>
      </div>

      <!-- VISIBILITY MODE -->
      <div class="form-group"
           ng-if="edit.canEditVisibility"
           ng-class="{'has-error': formBase.costCenterVisibilityMode.$invalid}">
        <label class="control-label" for="costCenterVisibilityMode">{{'costCenter.costCenterVisibilityMode.label' | translate}}</label>
        <select class="form-control"
                id="costCenterVisibilityMode"
                name="visibilityMode"
                ng-model="edit.costCenter.visibilityMode"
                ng-disabled="!edit.isCreate && !edit.canEdit"
                required>
          <option value="TENANT">{{'costCenter.costCenterVisibilityMode.values.TENANT.label' | translate}}</option>
          <option value="ACCOUNT">{{'costCenter.costCenterVisibilityMode.values.ACCOUNT.label' | translate}}</option>
        </select>
        <p class="help-block" ng-messages="formBase.visibilityMode.$error">
          <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
        </p>
        <p class="help-block">
          <span>{{'costCenter.costCenterVisibilityMode.values.' + edit.costCenter.visibilityMode + '.help' | translate}}</span>
        </p>
      </div>
    </ng-form>
  </div>
</div>

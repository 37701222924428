(function () {
  'use strict';

  /* @ngdoc object
   * @name businessManagement
   * @description
   *
   */
  angular
    .module('businessManagement', [
      'ui.router',
      'businessManagement.businesses'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name vcMain.directive:vcMapDisplay
   * @restrict E
   * @element
   *
   * @description this directive defines default behaviour for displaying a map with vineyards and user information
   *
   * @example
     <example module="vcMain">
       <file name="index.html">
        <vc-map-display></vc-map-display>
       </file>
     </example>
   *
   */
  angular
    .module('vcMain')
    .directive('vcMapDisplay', vcMapDisplay);

  function vcMapDisplay() {
    return {
      restrict: 'E',
      scope: {
        mapId: '<',
        selectedVineyards: '<',
        newVineyards: '<',
        zoomToSelection: '<?',
        vineyardMapData: '<',
        iconData: '<?',
        hideIconControl: '<?',
        hideIcons: '=?',
        siteStatusData: '<?',
        users: '<?',
        userPathData: '<?',
        tempWorkerPathData: '<?',
        userColorData: '<?',
        onVineyardClickExternal: '&?onVineyardClick',
        onVineyardMouseOut: '&?',
        onVineyardMouseOver: '&?',
        onMapClick: '<?',
        changeIconOptions: '<?'
      },
      templateUrl: 'directives/vc-map-display-directive.tpl.html',
      replace: false,
      transclude: true,
      bindToController: true,
      controllerAs: 'vcMapDisplay',
      controller(userSiteStatus, MapHelper, $state, $stateParams, $scope, SettingsManager) {
        let vm = this,
            defaultUserColor = '#000000',
            hideIconStorageKey = 'mapHideIcon';

        vm.$onInit = () => {
          vm.getUserColor = getUserColor;
          vm.getVineyardOptions = getVineyardOptions;
          vm.onVineyardClick = onVineyardClick;
          vm.onVineyardMouseChange = onVineyardMouseChange;
          vm.onIconClick = onIconClick;
          vm.emptyIcons = {};
          // if we have no concrete value for hideIcons we want to set the default value from
          // the settings if it exists
          if (angular.isUndefined(vm.hideIcons) && SettingsManager.hasObjectValue(hideIconStorageKey, vm.mapId)) {
            vm.hideIcons = SettingsManager.getObjectValue(hideIconStorageKey, vm.mapId);
          }
        };

        vm.$onChanges = (changesObj) => {
          if (changesObj.siteStatusData && changesObj.siteStatusData.currentValue) {
            updateVineyardOptions();
          }

          if (changesObj.selectedVineyards && changesObj.selectedVineyards.currentValue) {
            // if the parent directive does not provide a value for zoomToSelection we default to "true"
            vm.zoomMode = calculateZoomMode(vm.zoomToSelection, [changesObj.selectedVineyards.currentValue, vm.newVineyards], [vm.iconData]);
            updateVineyardOptions();
          }

          if (changesObj.newVineyards && changesObj.newVineyards.currentValue) {
            vm.zoomMode = calculateZoomMode(vm.zoomToSelection, [changesObj.newVineyards.currentValue, vm.selectedVineyards], [vm.iconData]);
            updateVineyardOptions();
          }

          if (changesObj.iconData && changesObj.iconData.currentValue) {
            vm.zoomMode = calculateZoomMode(vm.zoomToSelection, [vm.newVineyards, vm.selectedVineyards], [changesObj.iconData.currentValue]);
            updateVineyardOptions();
          }

          if (changesObj.zoomToSelection) {
            vm.zoomMode = calculateZoomMode(changesObj.zoomToSelection.currentValue, [vm.newVineyards, vm.selectedVineyards], [vm.iconData]);
          }
        };

        // changed values for hideIcons should be persisted in the settings
        $scope.$watch('vcMapDisplay.hideIcons', hideIcons => {
          if (angular.isDefined(hideIcons) && hideIcons) {
            SettingsManager.setObjectValue(hideIconStorageKey, vm.mapId, hideIcons);
          } else {
            SettingsManager.deleteObjectValue(hideIconStorageKey, vm.mapId);
          }
        });

        function onVineyardClick(vineyardId) {
          let parcelEdit = 'parcels.edit',
              parcelOrderList = parcelEdit + '.orders.list',
              currentId = parseInt($stateParams.id, 10);
          if (angular.isFunction(vm.onVineyardClickExternal)) {
            vm.onVineyardClickExternal({vineyardId: vineyardId});
          } else if (!$state.includes(parcelEdit) || currentId !== vineyardId) {
            $state.go(parcelOrderList, {
              id: vineyardId
            });
          }
        }

        function onVineyardMouseChange(vineyardId, hover) {
          vm.changeVineyardOptions = {
            [vineyardId]: getVineyardOptions(vineyardId, hover)
          };
          if (hover && angular.isFunction(vm.onVineyardMouseOver)) {
            vm.onVineyardMouseOver({vineyardId: vineyardId});
          } else if (!hover && angular.isFunction(vm.onVineyardMouseOut)) {
            vm.onVineyardMouseOut({vineyardId: vineyardId});
          }
        }

        function onIconClick(iconId) {
          let poiEdit = 'poi.edit',
              currentId = parseInt($stateParams.id, 10);
          if (!$state.includes(poiEdit) || currentId !== iconId) {
            $state.go(poiEdit, {
              id: iconId
            });
          }
        }

        function calculateZoomMode(zoomToSelection, vineyards, icons) {
          // zooming is completely disabled
          if (angular.isDefined(zoomToSelection) && !zoomToSelection) {
            return false;
          }
          let zoomMode = {
                vineyards: {},
                icons: {}
              },
              allIgnoredInZoom = {},
              sources,
              targets;

          if (zoomToSelection === 'both') {
            sources = [icons, vineyards];
            targets = ['icons', 'vineyards'];
          } else if (zoomToSelection === 'icons') {
            sources = [icons];
            targets = ['icons'];
          } else {
            sources = [vineyards];
            targets = ['vineyards'];
          }

          for (let i = 0; i < sources.length; i++) {
            const source = sources[i];
            // if there are no elements to ignore we cannot ignore all elements per definition
            // this is done to handle the case of vineyards without an explicit selection
            // that should then show all vineyards
            allIgnoredInZoom[targets[i]] = source.some(hasElements);
            for (let j = 0; j < source.length; j++) {
              const sourceElement = source[j];
              for (const id in sourceElement) {
                if (sourceElement.hasOwnProperty(id)) {
                  const val = sourceElement[id];
                  // we ignore the element for zooming if the appropriate attribute is set
                  if (val && !val.ignoreInZoom) {
                    zoomMode[targets[i]][id] = true;
                    // there is at least one element which should not be ignored in the zoom
                    allIgnoredInZoom[targets[i]] = false;
                  }
                }
              }
            }
          }

          // if there are no specific objects we want to zoom to, we want to show everything
          // for the chosen zoom mode (i.e. if we should show vineyards we show all vineyards
          // but not all icons) - but only if not all element candidates were explicitely
          // ignored for zooming
          targets.forEach(k => {
            if (!hasElements(zoomMode[k]) && !allIgnoredInZoom[k]) {
              zoomMode[k] = true;
            }
          });

          return zoomMode;
        }

        function hasElements(s) {
          return s && Object.keys(s).length > 0;
        }

        function updateVineyardOptions() {
          let changeVineyardOptions = {};
          angular.forEach(vm.vineyardMapData, (_, vineyardId) => {
            changeVineyardOptions[vineyardId] = getVineyardOptions(vineyardId);
          });
          vm.changeVineyardOptions = changeVineyardOptions;
        }

        function getUserColor(userId) {
          if (angular.isDefined(vm.userColorData) && angular.isDefined(vm.userColorData[userId])) {
            return vm.userColorData[userId];
          } else if (angular.isFunction(vm.userColorData)) {
            return vm.userColorData(userId);
          }
          return defaultUserColor;
        }

        function getVineyardOptions(vineyardId, mouseOver = false) {
          let siteStatus = vm.siteStatusData && vm.siteStatusData[vineyardId] ? vm.siteStatusData[vineyardId] : userSiteStatus.NONE,
              mode = 'unselected';

          if (angular.isObject(vm.selectedVineyards) && vm.selectedVineyards[vineyardId]) {
            mode = 'selected';
          } else if (angular.isObject(vm.newVineyards) && vm.newVineyards[vineyardId]) {
            mode = 'new';
          }

          return MapHelper.getVineyardOptions(siteStatus, mode, mouseOver);
        }
      }
    };
  }
}());

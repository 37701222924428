(function () {
  'use strict';

  angular
    .module('orders')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('orders', {
        abstract: true,
        url: '/orders',
        template: '<ui-view />'
      });
  }
}());

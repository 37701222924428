(function () {
  'use strict';

  angular
    .module('exports.exports.list')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('exports.list', {
        url: '/list?sorter&dir&filter',
        templateUrl: 'exports/exports/list/views/list.tpl.html',
        controller: 'ExportsListCtrl',
        controllerAs: 'list',
        params: {
          message: {
            value: null
          },
					jobType: undefined
        }
      });
  }
}());

<div class="form-group" ng-if="!edit.domainWachau">
  <label class="control-label">{{'PARCELS_PARAM_STICKDISTANCE' | translate}}</label>
  <div>
    <rzslider rz-slider-model="edit.item.stickDistance"
              rz-slider-options="edit.sliderOptions.stickDistance">
    </rzslider>
  </div>
</div>
<div ng-if="edit.domainWachau" class="form-group">
    <label class="control-label">{{'PARCELS_PARAM_STICKDISTANCE' | translate}}</label>
    <input class="form-control"
           ng-readonly="!edit.fullEditAvailable || edit.isShared"
           type="number"
           ng-model="edit.item.stickDistance"/>
</div>

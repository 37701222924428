(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name vcMain.directive:subMenu
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="vcMain">
       <file name="index.html">
        <sub-menu></sub-menu>
       </file>
     </example>
   *
   */
  angular
    .module('vcMain')
    .directive('subMenu', subMenu);

  function subMenu($rootScope, $uibModal) {
    return {
      restrict: 'E',
      scope: {
        items: '=',
        form: '=',
        fluid: '=',
        controller: '=',
        templateUrl: '='
      },
      templateUrl: 'directives/sub-menu-directive.tpl.html',
      replace: true,
      bindToController: true,
      controllerAs: 'subMenu',
      controller() {
        let vm = this;
        $rootScope.subMenu = true;

        vm.openFilter = function (item) {
          let popup = $uibModal.open({
            controller: item.controller,
            controllerAs: 'filter',
            templateUrl: item.templateUrl,
            resolve: item.resolve
          });
          popup.result.then(item.onResult);
        };
      }
    };
  }

  subMenu.$inject = ['$rootScope', '$uibModal'];
}());

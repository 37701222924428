(function () {
  'use strict';

  angular
    .module('timeTracking')
    .config(config);

  function config($stateProvider, permissions) {
    $stateProvider
      .state('timeTracking', {
        abstract: true,
        url: '/time-tracking',
        template: '<ui-view />',
        data: {
          requiredPerms: [permissions.TIME_TRACKING_CAN_USE]
        }
      });
  }
}());

(function () {
  'use strict';

  angular
    .module('managementUnits.list')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('managementUnits.list', {
        url: '/list?page&sorter&dir&filter',
        templateUrl: 'management-units/list/views/list.tpl.html',
        controller: 'ManagementUnitListCtrl',
        controllerAs: 'list',
        params: {
          message: null
        }
      });
  }
}());

(function () {
    'use strict';

    class EditFertilizationNewCtrl {
        constructor(periods, period) {
            let vm = this;
            vm.periods = periods;
            vm.period = period;
        }
    }

    EditFertilizationNewCtrl.$inject = ['periods', 'period'];

    /**
     * @ngdoc object
     * @name parcels.edit.controller:EditFertilizationNewCtrl
     *
     * @description
     *
     */
    angular
        .module('parcels.edit')
        .controller('EditFertilizationNewCtrl', EditFertilizationNewCtrl);
}());

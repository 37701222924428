(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name vcMain.factory:AdminCrudApi
   *
   * @description
   *
   */
  angular
    .module('vcMain')
    .factory('AdminCrudApi', AdminCrudApi);

  function AdminCrudApi($resource, apiBasePath) {
    return $resource(apiBasePath + 'admin/:entity/:id' + '/:subentity');
  }

  AdminCrudApi.$inject = ['$resource', 'apiBasePath'];
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name vcMain.directive:mainMessage
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="vcMain">
       <file name="index.html">
        <main-message></main-message>
       </file>
     </example>
   *
   */
  angular
    .module('vcMain')
    .directive('mainMessage', mainMessage);

  function mainMessage() {
    return {
      restrict: 'E',
      scope: {
        message: '=',
        fluid: '='
      },
      templateUrl: 'directives/main-message-directive.tpl.html',
      replace: true,
      bindToController: true,
      controllerAs: 'mainMessage',
      controller($scope) {
        let vm = this;
        vm.messages = {
          noData: false,
          loadingError: false,
          deletingSuccess: false,
          deletingError: false,
          savingError: false,
          savingSuccess: false,
          mandatoryToHaveWorkers: false,
          invalidPolyLine: false,
          errorUpload: false,
          errorKmlUnevenPoints: false,
          emailAlreadyExists: false,
          copySuccess: false,
          copyError: false,
          alreadyExists: false,
          orderTypesDependantOrders: false,
          overlappingTimeLogError: false,
          equipmentOfTypeExisting: false,
          entityHasChanged: false,
          orderDeletingErrorFollowUp: false,
          tenantDeletingErrorIsCurrent: false
        };
        $scope.$watch('mainMessage.message', (msg) => {
          angular.forEach(vm.messages, (value, key) => {
            vm.messages[key] = false;
          });
          if (msg && vm.messages.hasOwnProperty(msg)) {
            vm.messages[msg] = true;
          }
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name constants.constant:userSiteStatus
   *
   * @description
   *
   */
  angular
    .module('constants')
    .constant('userSiteStatus', {
      NONE: 0,
      FINISHED: 1,
      REDO: 2
    });
}());

(function () {
  'use strict';

  angular
    .module('businessManagement.businesses')
    .config(config);

  function config($stateProvider, permissions, businessMainUrl) {
    $stateProvider
      .state('businesses', {
        abstract: true,
        url: businessMainUrl,
        template: '<ui-view />',
        data: {requiredPerms: [permissions.WINERY_MANAGEMENT_CAN_USE]}
      });
  }
}());

<ng-map center="49.2484327,8.0600613"
        map-type-id="hybrid"
        id="dashboard"
        styles="[{
            featureType: 'poi',
            stylers: [{
                visibility: 'off'
            }]
        }, {
            featureType: 'transit',
            stylers: [{
                visibility: 'off'
            }]
        }]">
</ng-map>

<sub-menu items="edit.submenu.items" form="form"></sub-menu>
<main-menu></main-menu>

<div class="settings-edit base-layout">
    <div class="base-layout-default">
        <main-message message="edit.message" fluid="'nested'"></main-message>
        <ng-form name="agrirouterForm">
            <div class="form-group">
                <a class="btn btn-primary navbar-btn"
                   href="/agrirouter/">
                    {{ 'mainMenu.agrirouter' | translate }}
                </a>
            </div>
            <hr />
        </ng-form>
        <ng-form name="ceresForm" ng-if="edit.canUseCeresApi">
            <div class="form-group">
                <a class="btn btn-primary navbar-btn"
                   href="/farmunited/">
                    {{ 'mainMenu.farmunited' | translate }}
                </a>
            </div>
            <hr />
        </ng-form>
        <ng-form name="form">
            <div class="form-group" ng-class="{
                'has-error': form.businessPeriodStartDay.$invalid || form.businessPeriodStartMonth.$invalid
            }">
                <label class="control-label">{{'ADMIN_CUSTOMERS_BUSINESSPERIODSTART' | translate}}</label>
                <div style="display: flex">
                    <div style="display: flex; margin-right: 1rem">
                        <div style="display: flex; align-items: center">
                            <label class="custom-label">{{'DAY' | translate}}</label>
                        </div>
                        <input style="width: 65px" class="form-control" type="number" ng-model="edit.tenantConfiguration.businessPeriodStart.day" name="businessPeriodStartDay" required min="1" max="31" ng-readonly="!edit.canEdit" />
                    </div>
                    <div style="display: flex;">
                        <div style="display: flex; align-items: center">
                            <label class="custom-label">{{'MONTH' | translate}}</label>
                        </div>
                        <input style="width: 65px" class="form-control" type="number" ng-model="edit.tenantConfiguration.businessPeriodStart.month" name="businessPeriodStartMonth" required min="1" max="12" ng-readonly="!edit.canEdit" />
                    </div>
                </div>
            </div>
            <hr />
            <div class="form-group" ng-if="edit.hasPlantProtectionModule">
                <label class="control-label">{{'basicTerms.edit' | translate}}</label>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" ng-model="edit.tenantConfiguration.useSteepForPlantProtection"
                               ng-disabled="!edit.canEdit">{{'site.steepConsideration.label' | translate}}
                    </label>
                </div>
            </div>
        </ng-form>
    </div>
</div>

<!-- ORDER TYPE -->
<div class="form-group" ng-class="{'has-error': formBase.orderType.$invalid}">
    <label class="control-label" for="orderType">{{'ORDER_ORDER_TYPE' | translate}}</label>
    <div ng-class="{'input-group': edit.isCreate && edit.canCreateOrderTypes}">
        <ui-select required
                   id="orderType"
                   name="orderType"
                   ng-disabled="!edit.isCreate && !edit.canEditType()"
                   ng-model="edit.order.typeId"
                   ng-change="edit.changeOrderType()">
            <ui-select-match placeholder="{{'PLEASE_MAKE_CHOICE' | translate}}">{{$select.selected.labelProcessed}}
            </ui-select-match>
            <ui-select-choices group-by="edit.groupOrderTypes" repeat="ot.id as ot in edit.orderTypes | filter: edit.filterOrderType | orderBy:['groupPosition', 'groupLabel', 'position', 'labelProcessed']" ui-disable-choice="ot.disabled">
                <div ng-bind-html="ot.labelProcessed | highlight: $select.search"></div>
            </ui-select-choices>
        </ui-select>
        <a href="#"
           class="input-group-addon"
           ng-if="edit.isCreate && edit.canCreateOrderTypes"
           ng-click="$event.preventDefault(); edit.createNewOrderType()">
            <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>
        </a>
    </div>
    <p class="help-block"
       ng-messages="formBase.orderType.$error">
        <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
    </p>
    <div class="alert alert-info"
         ng-if="edit.order.typeId && (edit.orderTypes | filter:{id: edit.order.typeId}:true)[0].group.indexOf('FERTILIZATION') !== -1">
        {{'ORDER_ORDER_TYPE_FERTILIZATION_ORDER_WARNING' | translate}}
    </div>
</div>
<!-- LABEL -->
<div class="form-group"
     ng-class="{
       'has-error': formBase.label.$invalid
     }">
    <label class="control-label"
           for="label">{{'ORDER_LABEL' | translate}}</label>
    <input class="form-control"
           type="text"
           name="label"
           id="label"
           ng-model="edit.order.label"
           ng-disabled="(!edit.isCreate && !edit.canEdit) || !edit.canEditReadOnlyOrder()"
           required/>
    <p class="help-block"
       ng-messages="formBase.label.$error">
        <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
    </p>
</div>
<!-- DESCRIPTION -->
<div class="form-group"
     ng-class="{
       'has-error': formBase.description.$invalid
     }">
    <label class="control-label"
           for="description">{{'ORDER_DESCRIPTION' | translate}}</label>
    <textarea class="form-control"
              name="description"
              id="description"
              ng-model="edit.order.description"
              ng-maxlength="255"
              ng-disabled="(!edit.isCreate && !edit.canEdit) || !edit.canEditReadOnlyOrder()"></textarea>
    <p class="help-block"
       ng-messages="formBase.description.$error">
        <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
    </p>
</div>
<!-- UPDATE STRATEGY WHEN ADDING SITES TO MANAGEMENT UNITS -->
<div class="form-group"
     ng-class="{
        'has-error': formBase.managementUnitUpdateStrategy.$invalid
      }">
    <label class="control-label"
           for="managementUnitUpdateStrategy">{{'order.managementUnitUpdateStrategy.label' | translate}}</label>
    <select class="form-control"
            id="managementUnitUpdateStrategy"
            name="managementUnitUpdateStrategy"
            ng-model="edit.order.managementUnitUpdateStrategy"
            ng-disabled="(!edit.isCreate && !edit.canEdit) || !edit.canEditReadOnlyOrder()"
            required>
        <option value="KEEP_FINISHED">{{'order.managementUnitUpdateStrategy.values.KEEP_FINISHED.label' | translate}}</option>
        <option value="REOPEN">{{'order.managementUnitUpdateStrategy.values.REOPEN.label' | translate}}</option>
    </select>
    <p class="help-block">
        <span>{{'order.managementUnitUpdateStrategy.values.' + edit.order.managementUnitUpdateStrategy + '.hint' | translate}}</span>
    </p>
</div>
<!-- DEADLINE -->
<div class="form-group"
     ng-if="edit.type === 'regular' || edit.type === 'finished'"
     ng-class="{
       'has-error': formBase.deadline.$invalid
     }">
    <label class="control-label">{{'ORDER_DEADLINE_DATE' | translate}}</label>
    <div class="input-group">
        <input class="form-control"
               id="deadline"
               name="deadline"
               uib-datepicker-popup="dd.MM.yyyy"
               ng-model="edit.order.deadlineDate"
               readonly="readonly"
               is-open="edit.order.deadlineDateOpen"
               type="text"
               show-button-bar="false"
               required
               ng-disabled="!edit.canEditReadOnlyOrder()"/>
        <div class="input-group-btn">
            <button class="btn btn-default"
                    ng-disabled="!edit.isCreate && !edit.canEdit"
                    ng-click="edit.order.deadlineDateOpen = !edit.order.deadlineDateOpen">
                <span class="glyphicon glyphicon-calendar"></span>
            </button>
        </div>
    </div>
    <p class="help-block"
       ng-messages="formBase.deadline.$error">
        <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
    </p>
</div>
<!-- VISIBILITY MODE -->
<div ng-if="edit.canEditVisibility && !edit.isCyclic" class="form-group"
     ng-class="{'has-error': formBase.orderVisibilityMode.$invalid}">
    <label class="control-label" for="orderVisibilityMode">{{'order.orderVisibilityMode.label' | translate}}</label>
    <select class="form-control"
            id="orderVisibilityMode"
            name="orderVisibilityMode"
            ng-model="edit.order.orderVisibilityMode"
            ng-disabled="(!edit.isCreate && !edit.canEdit) || !edit.canEditReadOnlyOrder()"
            required>
        <option value="TENANT">{{'order.orderVisibilityMode.values.TENANT.label' | translate}}</option>
        <option value="ACCOUNT_READ_ONLY">{{'order.orderVisibilityMode.values.ACCOUNT_READ_ONLY.label' | translate}}
        </option>
        <option value="ACCOUNT">{{'order.orderVisibilityMode.values.ACCOUNT.label' | translate}}</option>
    </select>
    <p class="help-block" ng-messages="formBase.orderVisibilityMode.$error">
        <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
    </p>
    <p class="help-block">
        <span>{{'order.orderVisibilityMode.values.' + edit.order.orderVisibilityMode + '.help' | translate}}</span>
    </p>
</div>

<!-- FollowUp Order -->
<div ng-if="!edit.isCreate" class="form-group"
     ng-class="{'has-error': formBase.followUpOrder.$invalid}">
    <label class="control-label" for="followUpOrder">{{'order.workflow.followUp.label' | translate}}</label>
    <ui-select theme="bootstrap"
            id="followUpOrder"
            name="followUpOrder"
            ng-model="edit.order.workflow.followUpOrder"
            ng-change="edit.addFollowUpOrder()"
            ng-disabled="(!edit.canEdit) || !edit.canEditReadOnlyOrder()">
        <ui-select-match allow-clear="true" placeholder="{{'PLEASE_MAKE_CHOICE' | translate}}">{{$select.selected.label}}
        </ui-select-match>
        <ui-select-choices repeat="order.id as order in edit.orders">
            <div ng-bind-html="order.label | highlight: $select.search"></div>
        </ui-select-choices>
    </ui-select>
    <p class="help-block">
        <span>{{'order.workflow.followUp.help' | translate}}</span>
    </p>
</div>

(function () {
  'use strict';

  class CustomersDeleteCtrl {
    constructor(item) {
      let vm = this;
      vm.item = item;
    }
  }

  CustomersDeleteCtrl.$inject = ['item'];

  /**
   * @ngdoc object
   * @name admin.customers.delete.controller:CustomersDeleteCtrl
   *
   * @description
   *
   */
  angular
    .module('admin.customers.delete')
    .controller('CustomersDeleteCtrl', CustomersDeleteCtrl);
}());

(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name auth.factory:UserService
     *
     * @description
     *
     */
    angular
        .module('auth')
        .factory('UserService', UserService);

    function UserService($state, SettingsManager, jwtHelper, $log) {
        let user = null,
            permissionSet = null,
            settingsKey = 'auth',
            UserServiceBase = {
                get user() {
                    return user;
                },
                set user(u) {
                    user = u;
                    permissionSet = u !== null ? new Set(u.permissions) : null;
                },
                hasPerm: (perm) => {
                    return permissionSet !== null && permissionSet.has(perm);
                },
                hasAllPerms: (perms) => {
                    let containsAll = true;
                    for (let index = 0; index < perms.length && containsAll; index++) {
                        const perm = perms[index];
                        containsAll = UserServiceBase.hasPerm(perm);
                    }
                    return containsAll;
                },
                canAccessState: (stateName) => {
                    let state = $state.get(stateName),
                        stateExists = state !== null;
                    if (stateExists && angular.isObject(state.data) && angular.isArray(state.data.requiredPerms)) {
                        return UserServiceBase.hasAllPerms(state.data.requiredPerms);
                    }
                    return stateExists;
                },
                getAssetType: () => {
                    return UserServiceBase.user.assetType;
                },
                getAssetTypeAsClass: () => {
                    let assetType = UserServiceBase.user.assetType.toString();
                    assetType = assetType.toLowerCase();
                    return assetType.charAt(0).toUpperCase() + assetType.slice(1);
                },
                startImpersonation: (impersonationToken) => {
                    let token = UserServiceBase.getAuthToken();
                    // We don't start a new impersonation if one is already active, otherwise we would
                    // switch to this impersonation if we were to try and stop the new one which
                    // results in an unstoppable impersonation.
                    if (token.impersonationOriginalToken === null) {
                        storeAuthToken(impersonationToken, token.refreshToken, token.accessToken);
                        UserServiceBase.reloadUser();
                    }
                },
                stopImpersonation: () => {
                    let token = UserServiceBase.getAuthToken();
                    if (token.impersonationOriginalToken !== null) {
                        storeAuthToken(token.impersonationOriginalToken, token.refreshToken);
                        UserServiceBase.reloadUser();
                    }
                },
                isImpersonating: () => {
                    return user !== null && user.impersonatorCustomerId !== null;
                },
                reloadUser: () => {
                    let vcMain = UserServiceBase.getAuthToken(), u, translatedStrings;
                    if (vcMain) {
                        u = decodeToken(vcMain.accessToken);
                        translatedStrings = UserServiceBase.getTranslatedStrings();
                    } else {
                        u = null;
                        translatedStrings = null;
                    }
                    UserServiceBase.user = u;
                    UserServiceBase.translatedStrings = translatedStrings;
                },
                validateToken: (accessToken, permissionList) => {
                    let u = decodeToken(accessToken);
                    return u !== null && permissionList.every(perm => u.permissions.indexOf(perm) > -1);
                },
                storeAuthToken: (accessToken, refreshToken) => {
                    storeAuthToken(accessToken, refreshToken);
                    UserServiceBase.reloadUser();
                },
                getAuthToken: () => {
                    return SettingsManager.getValue(settingsKey);
                },
                getVcToken: () => {
                    return decodeToken(SettingsManager.getValue(settingsKey).accessToken);
                },
                clearAuthToken: () => {
                    SettingsManager.deleteValue(settingsKey);
                    UserServiceBase.reloadUser();
                },
                decodeToken: (token) => {
                    return decodeToken(token);
                },
                ownEntity: (customerId) => {
                    let decodedToken = decodeToken(UserServiceBase.getAuthToken().accessToken);
                    return customerId === decodedToken.customerId;
                },
                storeSessionConfiguration: (data) => {
                    SettingsManager.setValue('translatedStrings', {
                        bonitur: data.bonitur,
                        businessLabel: data.businessLabel,
                        businessSingular: data.businessSingular,
                        businessPlural: data.businessPlural,
                        businessAdd: data.businessAdd,
                        businessSelect: data.businessSelect,
                        tenantLabel: data.tenantLabel,
                        tenantSingular: data.tenantSingular,
                        tenantPlural: data.tenantPlural,
                        tenantAdd: data.tenantAdd,
                        tenantSelect: data.tenantSelect,
                        cultivationProgram: data.cultivationProgram,
                    });
                    UserServiceBase.reloadUser();
                },
                getTranslatedStrings: () => {
                    return SettingsManager.getValue('translatedStrings');
                },
                getLocalizedLabelOrNull(label) {
                    const translatedStrings = this.getTranslatedStrings();
                    const currentLocale = SettingsManager.getValue('currentLocale');
                    if (translatedStrings && translatedStrings[label] && currentLocale) {
                        let translatedString = translatedStrings[label];
                        if (translatedString[currentLocale]) {
                            return translatedString[currentLocale];
                        }
                    }
                    return null;
                },
                getLocalizedLabelOrDefault(label, defaultValue) {
                    let translatedStringOrNull = this.getLocalizedLabelOrNull(label);
                    if (translatedStringOrNull !== null) {
                        return translatedStringOrNull;
                    }
                    return defaultValue;
                }
            };

        function decodeToken(token) {
            let jwt;
            try {
                jwt = jwtHelper.decodeToken(token);
            } catch (err) {
                $log.error('Error decoding access token:', err);
                return null;
            }
            return {
                email: jwt.email,
                assetType: jwt.assetType,
                id: jwt.userId,
                userUuid: jwt.userUuid,
                customerId: jwt.customerId,
                customerUuid: jwt.customerUuid,
                customerLabel: jwt.customerLabel,
                impersonatorCustomerId: jwt.impersonatorCustomerId || null,
                permissions: jwt.permission,
                token: token,
                accountId: jwt.accountId,
                accountUuid: jwt.accountUuid
            };
        }

        function storeAuthToken(accessToken, refreshToken, impersonationOriginalToken = null) {
            SettingsManager.setValue(settingsKey, {
                accessToken: accessToken,
                refreshToken: refreshToken,
                impersonationOriginalToken: impersonationOriginalToken
            });
        }

        return UserServiceBase;
    }

    UserService.$inject = ['$state', 'SettingsManager', 'jwtHelper', '$log'];
}());

(function () {
    'use strict';

    class CustomersEditCtrl {
        constructor($stateParams, UserService, permissions, AdminCrudApi, $state, CrudApi, $q, ModuleService, $filter, $uibModal) {
            let vm = this,
                promises = {};
            vm.domainWachau = UserService.getVcToken().accountUuid === '7f1d4f34-a92d-4b8e-906b-60046d14cbfb';
            vm.message = $stateParams.message;
            vm.canEdit = angular.isDefined($stateParams.id) && UserService.hasPerm(permissions.ADMIN_CAN_EDIT_CUSTOMER) ||
                angular.isUndefined($stateParams.id) && UserService.hasPerm(permissions.ADMIN_CAN_CREATE_CUSTOMER);
            vm.removeModuleAlert = () => {
                $uibModal.open({
                    controller: 'EditRemoveModuleAlertCtrl',
                    templateUrl: 'admin/customers/edit/views/edit-remove-module-alert.tpl.html',
                    resolve: {}
                });
            };
            vm.birthdayOpen = false;
            vm.entryDateOpen = false;
            vm.terminationDate = false;

            vm.log = () => {
                console.log(vm.customer);
            };

            // Add items to submenu
            if (vm.canEdit) {
                vm.submenu = {
                    items: [{
                        type: 'button',
                        text: 'BUTTON_SAVE',
                        click: () => {
                            vm.customer.birthday = $filter('date')(vm.customer.birthday, 'yyyy-MM-dd');
                            vm.customer.modules = vm.modules.filter(mod => mod.selected).map(mod => mod.label);
                            AdminCrudApi.save({
                                id: $stateParams.id,
                                entity: 'customers'
                            }, vm.customer, (data) => {
                                $state.go('customers.edit', {
                                    id: data.id,
                                    message: 'savingSuccess'
                                }, {
                                    reload: true
                                });
                            }, (response) => {
                                if (response.status === 410) {
                                    vm.message = 'entityHasChanged';
                                } else {
                                    vm.message = 'savingError';
                                }
                            });
                        }
                    }]
                };
            }

            // Initialize mainmenu items
            vm.mainmenu = {
                items: []
            };

            promises.moduleServiceInitialized = ModuleService.initialize();

            if ($stateParams.id) {
                promises.customer = AdminCrudApi.get({
                    id: $stateParams.id,
                    entity: 'customers'
                }).$promise;
            } else {
                promises.customer = null;
            }

            $q.all(promises).then(({customer}) => {
                if (customer !== null) {
                    vm.mainmenu.items.push({
                        label: customer.label,
                        translate: false,
                        state: 'customers.edit',
                        params: {
                            id: $stateParams.id
                        }
                    });
                    vm.customer = customer;
                    vm.customer.homepage = vm.customer.homepage ? vm.customer.homepage : '';
                    vm.customer.additionalTitle = vm.customer.additionalTitle ? vm.customer.additionalTitle : '';
                    vm.customer.businessCreditAccount = vm.customer.businessCreditAccount ? vm.customer.businessCreditAccount: '';
                    vm.customer.debitorsAccount = vm.customer.debitorsAccount ? vm.customer.debitorsAccount : '';
                    vm.customer.loanAccountGroup2 = vm.customer.loanAccountGroup2 ? vm.customer.loanAccountGroup2 : '';
                    vm.customer.loanAccountGroup3= vm.customer.loanAccountGroup3? vm.customer.loanAccountGroup3 : '';
                    vm.customer.entryDate = vm.customer.entryDate ? new Date(vm.customer.entryDate) : null;
                    vm.customer.terminationDate = vm.customer.terminationDate ? new Date(vm.customer.terminationDate) : null;
                    vm.customer.birthday = vm.customer.birthday ? new Date(vm.customer.birthday) : null;
                } else {
                    // Main menu item "new customer"
                    vm.mainmenu.items.push({
                        label: 'ADMIN_CUSTOMERS_NEW',
                        state: 'customers.create'
                    });
                    vm.customer = {};
                }
                vm.modules = ModuleService.getSortedTranslatedModules();
                vm.modules.forEach(mod => {
                    mod.selected = vm.customer.modules && vm.customer.modules.indexOf(mod.label) !== -1;
                });
            }, () => {
                vm.message = 'loadingError';
            });
        }
    }

    CustomersEditCtrl.$inject = ['$stateParams', 'UserService', 'permissions', 'AdminCrudApi', '$state', 'CrudApi', '$q', 'ModuleService', '$filter', '$uibModal'];

    /**
     * @ngdoc object
     * @name admin.customers.edit.controller:CustomersEditCtrl
     *
     * @description
     *
     */
    angular
        .module('admin.customers.edit')
        .controller('CustomersEditCtrl', CustomersEditCtrl);
}());

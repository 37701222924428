<main-menu></main-menu>

<div class="base-layout">
  <div class="base-layout-fluid dashboard">
    <main-message message="dashboard.message" fluid="true"></main-message>
    <div class="row">
      <div class="col-md-2"
           ng-repeat="order in dashboard.ordersArr | limitTo: dashboard.quantity track by order.id">
        <dashboard-tile data="order"
                        color="dashboard.getTileColor(order)"
                        users="dashboard.users"
                        temporary-workers-toggle-function="dashboard.toggleTempWorkers"
                        toggle-function="dashboard.activateTile"
                        enabled="dashboard.isTileActivated(order)"
                        show-temp-workers="dashboard.showTemporaryWorkers(order)">
        </dashboard-tile>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="btn btn-default"
             ng-click="dashboard.loadMore()"
             ng-if="dashboard.quantity <= 6">
          {{'DASHBOARD_SHOW_MORE_TASKS' | translate}}
        </div>
        <div class="btn btn-default go-to-all-tasks"
             ng-click="dashboard.goToAllTasks()"
             ng-if="dashboard.quantity > 6">
          {{'DASHBOARD_SHOW_ALL_TASKS' | translate}}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2>{{'DASHBOARD_USERS_WITHOUT_ORDERS' | translate}}</h2>
        <div class="progress">
            <div class="progress-bar progress-bar-success worker-inactive-bar"
                role="progressbar"
                aria-valuenow="80"
                aria-valuemin="0"
                aria-valuemax="100"
                ng-style="dashboard.userBarWidth"
                popover-title="{{'DASHBOARD_USERS_WITHOUT_ORDERS' | translate}}"
                uib-popover-template="dashboard.dynamicPopover.templateUrl"
                popover-trigger="'outsideClick'">
              &nbsp;
            </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <vcmap vc-map-data="dashboard.vcMapData" vc-map-drawing-data="dashboard.vineyardsMapDataBuffer"></vcmap>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <comment-panel comments="dashboard.commentsArr"></comment-panel>
      </div>
      <div class="col-md-6">
        <alarm-panel tasks="dashboard.ordersToDo"></alarm-panel>
      </div>
    </div>
    <div class="loader" ng-if="dashboard.isLoading">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
    </div>
  </div>
</div>
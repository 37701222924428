<th ng-if="list.showCheckboxes && list.hasAssetType('VINEYARD')">
    <input type="checkbox" ng-model="list.allItemsChecked" ng-change="list.selectAllItems()"
           ng-click="$event.stopPropagation()">
</th>
<th ng-disabled="!list.showCheckboxes" ng-click="list.showCheckboxes && list.sort('label')"
    ng-class="{'cursor-text': !list.showCheckboxes}">
    {{'PARCEL_LABEL' | translate}}
    <span class="glyphicon pull-right text-muted"
          ng-if="list.showCheckboxes && list.sorter === 'label'"
          ng-class="{
          'glyphicon-chevron-down': list.dir === 'asc',
          'glyphicon-chevron-up': list.dir === 'desc'
        }"></span>
</th>
<th class="hidden-xs hidden-sm" ng-if="list.hasAssetType('VINEYARD') && !list.domainWachau"
    ng-click="list.showCheckboxes && list.sort('district')" ng-class="{'cursor-text': !list.showCheckboxes}">
    {{'PARCELS_PARAM_DISTRICT' | translate}}
    <span class="glyphicon pull-right text-muted"
          ng-if="list.showCheckboxes && list.sorter === 'district'"
          ng-class="{
          'glyphicon-chevron-down': list.dir === 'asc',
          'glyphicon-chevron-up': list.dir === 'desc'
        }"></span>
</th>
<th class="hidden-xs hidden-sm" ng-if="list.hasAssetType('VINEYARD') && list.domainWachau"
    ng-click="list.showCheckboxes && list.sort('district')" ng-class="{'cursor-text': !list.showCheckboxes}">
    Ortsteil
    <span class="glyphicon pull-right text-muted"
          ng-if="list.showCheckboxes && list.sorter === 'district'"
          ng-class="{
          'glyphicon-chevron-down': list.dir === 'asc',
          'glyphicon-chevron-up': list.dir === 'desc'
        }"></span>
</th>
<th class="hidden-xs hidden-sm" ng-if="list.hasAssetType('VINEYARD')">{{'PARCEL_FLURNUMBER' | translate}}</th>
<th class="hidden-xs" ng-if="list.hasAssetType('VINEYARD')" ng-click="list.showCheckboxes && list.sort('wineThird')"
    ng-class="{'cursor-text': !list.showCheckboxes}">
    {{'PARCEL_VINE' | translate}}
    <span class="glyphicon pull-right text-muted"
          ng-if="list.showCheckboxes && list.sorter === 'wineThird'"
          ng-class="{
          'glyphicon-chevron-down': list.dir === 'asc',
          'glyphicon-chevron-up': list.dir === 'desc'
        }"></span>
</th>
<th class="hidden-xs hidden-sm" ng-if="list.businessSingular === null && !list.domainWachau">{{'PARCEL_WINERY' | translate}}</th>
<th class="hidden-xs hidden-sm" ng-if="list.businessSingular === null && list.domainWachau">Winzer</th>
<th class="hidden-xs hidden-sm" ng-if="list.businessSingular !== null && !list.domainWachau">{{ list.businessSingular }}</th>
<th class="hidden-xs hidden-sm" ng-if="list.businessSingular !== null && list.domainWachau">Winzer</th>
<th class="text-center" ng-if="list.canDelete">{{'DELETE' | translate}}</th>

<sub-menu items="comments.submenu.items" fluid="true"></sub-menu>
<main-menu></main-menu>

<ng-form name="form" class="base-layout comments">
  <div class="base-layout-map">
    <div class="base-layout-map-content">
      <div class="table-responsive" ng-cloak="true">
        <table class="table table-hover"
               ng-if="comments.comments.length">
          <thead>
            <th ng-click="comments.sort('description')">
              {{'COMMENTS_LIST_COMMENT_HEADING' | translate}}
              <span class="glyphicon pull-right text-muted"
                    ng-if="comments.sorter === 'description'"
                    ng-class="{
                      'glyphicon-chevron-down': comments.dir === 'asc',
                      'glyphicon-chevron-up': comments.dir === 'desc'
                    }">
              </span>
            </th>
            <th>
              {{'COMMENTS_LIST_TASK_HEADING' | translate}}
            </th>
            <th>
              {{'COMMENTS_LIST_VINEYARD_HEADING' | translate}}
            </th>
          </thead>
          <tbody>
            <tr ng-repeat="comment in comments.comments track by $index"
                ng-mouseenter="comments.rowHover(comment, true)"
                ng-mouseleave="comments.rowHover(comment, false)"
                ng-click="comments.goToOrder(comment.orderId)">
              <td ng-if="!comment.description">
                -
              </td>
              <td ng-if="comment.description" class="truncateText">
                <span>{{comment.description}}</span>
              </td>
              <td>
                {{comment.orderTitle}}
              </td>
              <td ng-if="!comment.siteId">
                -
              </td>
              <td ng-if="comment.siteId">
                {{(comments.vineyards | filter:{ id: comment.siteId })[0].label}}
              </td>
            </tr>
          </tbody>
        </table>
        <ul uib-pagination
            total-items="comments.total"
            ng-if="comments.total > comments.itemsPerPage"
            ng-model="comments.page"
            items-per-page="comments.itemsPerPage"
            ng-change="comments.paginate()"
            max-size="comments.maxSize"
            boundary-links="true"
            previous-text="&lsaquo;"
            next-text="&rsaquo;"
            first-text="&laquo;"
            last-text="&raquo;">
        </ul>
        <div class="alert alert-info"
             ng-if="!comments.comments.length">
          {{'MESSAGE_INFO_NO_DATA' | translate}}
        </div>
      </div>
    </div>
    <div class="base-layout-map-map">
        <vc-map-display
            map-id="comments.mapId"
            icon-data="comments.iconData"
            vineyard-map-data="comments.mapData"
            selected-vineyards="comments.selectedVineyards"
            user-path-data="comments.userData"
            user-color-data="comments.getMarkerColor"></vc-map-display>
    </div><!-- end row -->
  </div><!-- end container-fluid -->
  <div class="loader" ng-if="!comments.loaded">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
  </div>
</div><!-- end #comments -->

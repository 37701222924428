(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name parcels.factory:ParcelStatus
   *
   * @description
   *
   */
  angular
    .module('parcels')
    .factory('ParcelStatus', ParcelStatus);

  function ParcelStatus(mapColors, userSiteStatus) {
    let ParcelStatusBase = {};
    ParcelStatusBase.constructStatusObject = (statusData, finishStrategy) => {
      let siteStatus = {};
      statusData.forEach((status) => {
        if (status.userStatus && status.userStatus.length) {
          if (finishStrategy === 'any' && status.userStatus.some((item) => {
            return item.status === userSiteStatus.FINISHED;
          })) {
            siteStatus[status.id] = userSiteStatus.FINISHED;
          } else if (finishStrategy === 'fixed' && status.userStatus.some((item) => {
            return item.status === userSiteStatus.FINISHED;
          })) {
            siteStatus[status.id] = userSiteStatus.FINISHED;
          } else if (status.userStatus.some((item) => {
            return item.status === userSiteStatus.REDO;
          })) {
            siteStatus[status.id] = userSiteStatus.REDO;
          } else {
            siteStatus[status.id] = userSiteStatus.NONE;
          }
        } else {
          siteStatus[status.id] = userSiteStatus.NONE;
        }
      });
      return siteStatus;
    };
    return ParcelStatusBase;
  }

  ParcelStatus.$inject = ['mapColors', 'userSiteStatus'];
}());

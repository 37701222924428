(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name timeTracking.directive:formElement
   * @restrict A
   * @element
   *
   * @description This directive adds a getElement function to the controller of a form to get
   * the underlying form element
   *
   * @example
     <form module="timeTracking" form-element>
     </form>
   *
   */
  angular
    .module('timeTracking')
    .directive('formElement', formElement);

  function formElement() {
    return {
      restrict: 'A',
      require: 'form',
      link: function ($scope, $el, $attr, $form) {
        $form.getElement = () => $el[0];
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name constants.constant:colorConstants
   *
   * @description
   *
   */
  angular
    .module('constants')
    .constant('colorConstants', [
      '#e5001f',
      '#180059',
      '#6236d9',
      '#c2cc33',
      '#ff0066',
      '#00ff00',
      '#00997a',
      '#ffaa00',
      '#e639ac',
      '#ff6600'
    ]);
}());

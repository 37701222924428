<form name="deleteImageForm">
    <div class="modal-header">
        <button type="button" class="close" ng-click="$dismiss();"><span>&times;</span></button>
        <h4 class="modal-title">{{'ORDER_IMAGE_DELETE' | translate}}</h4>
    </div>
    <div class="modal-body text-center">
        <img ng-src="{{delete.item.downloadURL}}" style="width: 100%">
        <h3>{{delete.item.filename}}</h3>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" ng-click="$dismiss();">{{'ABORT' | translate}}</button>
        <button type="button" class="btn btn-danger" ng-click="$close();">{{'OK' | translate}}</button>
    </div>
</form>

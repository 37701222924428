(function () {
  'use strict';

  /* @ngdoc object
   * @name edit.orders
   * @description
   *
   */
  angular
    .module('parcels.edit.orders', [
      'ui.router',
      'parcels.edit.orders.list',
      'orders.cyclic',
      'orders.delete'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('fertilization.phDemandExports.edit')
    .config(config);

  function config($stateProvider, permissions) {
    $stateProvider
      .state('fertilization.phDemandExports.edit', {
        url: '/edit',
        templateUrl: 'fertilization/ph-demand-exports/edit/views/edit.tpl.html',
        controller: 'FertilizationPhDemandExportsEditCtrl',
        controllerAs: 'edit',
        params: {
          message: {
            value: null
          }
        },
        data: {requiredPerms: [
          permissions.FERTILIZATION_CAN_CREATE_PH_DEMAND_EXPORT
        ]}
      });
  }
}());

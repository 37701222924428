<div class="tile-content {{dashboardTile.borderClass}}"
     ng-style="dashboardTile.borderTop">
  <div class="order-state">
    <span ng-class="dashboardTile.orderIsActive"></span>
  </div>
  <switch ng-model="dashboardTile.switchEnabled"
          ng-change="dashboardTile.toggleFunction(dashboardTile.data, dashboardTile.switchEnabled)"
          ng-style="dashboardTile.switchEnabled ? dashboardTile.switchColor: false">
  </switch>
  <div class="tile-header">
    <p tooltip-placement="bottom"
         uib-tooltip="{{dashboardTile.data.description}}"
         class="tile-heading"
         ui-sref="orders.edit({
           id: dashboardTile.data.id,
           type: 'regular',
           v: dashboardTile.data.siteIds
          })">
      {{dashboardTile.data.label | uppercase}}
    </p>
  </div>
  <div class="tile-readable-progress">
    <div class="tile-readable-progress-inner"
         ng-class="dashboardTile.inactive ? 'inactive' : ' '">
      {{dashboardTile.doneAreaStart | number : 2}} / {{dashboardTile.totalArea | number : 2}} ha
    </div>
  </div>
  <div class="{{dashboardTile.grayscale}}">
    <div class="{{dashboardTile.progressClass}}"
         ng-style="{ width: dashboardTile.progress > '100' ? '100%' : dashboardTile.barProgress + '%' }">
         <div ng-if="dashboardTile.data.status === dashboardTile.orderStatus.PAUSED"
              class="paused">
          {{'DASHBOARD_TILE_PAUSED' | translate}}
        </div>
    </div>
    <div class="tile-information"
         ng-class="dashboardTile.inactive ? 'inactive' : ' '">
      <p ng-if="dashboardTile.data.assignedUserIds.length > 1">
        {{dashboardTile.data.assignedUserIds.length}} {{'DASHBOARD_TILE_USERS' | translate}}
      </p>
      <p ng-if="dashboardTile.data.assignedUserIds.length === 1"
         tooltip-placement="bottom"
         uib-tooltip="{{dashboardTile.usersArr[0].firstname + ' ' + dashboardTile.usersArr[0].lastname}}">
        {{dashboardTile.usersArr[0].firstname + ' ' + dashboardTile.usersArr[0].lastname}}
      </p>
      <p ng-if="dashboardTile.data.assignedUserIds.length < 1">
        {{'DASHBOARD_TILE_NO_USERS' | translate}}
      </p>
      <div ng-repeat="vineyard in dashboardTile.vineyardArr track by $index">
        <p ng-if="dashboardTile.data.siteIds.length > 1"
           class="tile-vineyards"
           tooltip-placement="bottom"
           uib-tooltip="{{dashboardTile.data.siteIds.length}} {{'PARCELS' | translate}}">
          {{dashboardTile.data.siteIds.length}} {{'PARCELS' | translate}}
        </p>
        <p ng-if="dashboardTile.data.siteIds.length === 1"
           class="tile-vineyards"
           tooltip-placement="bottom"
           uib-tooltip="{{vineyard.label}}">
          {{vineyard.label}}
        </p>
      </div>
    </div>
    <div class="show-temp-workers"
         ng-class="dashboardTile.inactive ? 'inactive' : ' '">
      <span tooltip-placement="bottom"
            uib-tooltip="{{'DASHBOARD_SHOW_TEMPORARY_MITARBEITER' | translate}}">
         {{'DASHBOARD_SHOW_TEMPORARY_MITARBEITER' | translate}}
      </span>
      <div class="vc-checkbox">
        <label>
          <input
          type="checkbox"
          ng-model="dashboardTile.showTempWorkersButton"
          ng-change="dashboardTile.temporaryWorkersToggleFunction(dashboardTile.data, dashboardTile.showTempWorkersButton)"
          ng-disabled="dashboardTile.inactive">
          <span class="cr">
            <i class="cr-icon glyphicon glyphicon-ok"
               ng-style="dashboardTile.fontColor"></i>
          </span>
        </label>
      </div>
    </div>
  </div>
</div>

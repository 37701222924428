<form name="filterParcelListForm" class="filter-parcel-list">
    <div class="modal-header">
        <button type="button" class="close" ng-click="$dismiss();"><span>&times;</span></button>
        <h4 class="modal-title">{{'PARCELS_LIST_FILTER' | translate}}</h4>
    </div>
    <parcels-filter class="modal-body" ng-model="filter.filters"></parcels-filter>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" ng-click="$dismiss();">{{'ABORT' | translate}}</button>
        <button type="button" class="btn btn-success" ng-click="$close(filter.filters);">{{'OK' | translate}}</button>
    </div>
</form>

(function () {
  'use strict';

  class CyclicOrdersListCtrl {
    constructor($scope, $state, $stateParams, $rootScope, CrudApi, $uibModal) {
      let vm = this;

      // TOD: Its a hack, as we don't have enough time to rework all this
      // This has been done to select the proper tab in Aufgaben
      // Upon pressing browser back button
      // All its doing is setting the parent variable to current tab
      $scope.edit.active = 1;

      // Id of vineyard, use to create new orders
      vm.vineyardId = $stateParams.id;

      // TOD: Its a hack to reset the submenu item, need to work on the is overall
      $rootScope.subMenu = true;

      // Initialize some variables
      vm.itemsPerPage = 5;
      vm.maxSize = 10;
      vm.page = $stateParams.page || 1;
      vm.sorter = $stateParams.sorter || 'firstDeadlineDate';
      vm.dir = $stateParams.dir || 'desc';
      vm.message = $stateParams.message;

      vm.items = [];
      vm.total = [];

      vm.loadData = () => {
          // Api call for fetching the vineyard's map data
          vm.items = CrudApi.query({
              entity: 'cyclicorders',
              siteId: $stateParams.id,
              sortBy: vm.sorter,
              sortDir: vm.dir,
              perPage: vm.itemsPerPage,
              page: vm.page
          }, (data, headers, status) => {
              vm.total = headers()['x-total-count'];
              if (status === 204) {
                  vm.message = 'noData';
              }
          });
      };

      // Pagination method
      vm.paginate = () => {
        $state.go($state.current.name, {
          page: vm.page
        });
      };

      // Sorting method
      vm.sort = (sorter) => {
        $state.go($state.current.name, {
          sorter: sorter,
          dir: vm.dir === 'desc' ? 'asc' : 'desc',
          message: null
        });
      };

      // Delete Popup
      vm.delete = (item) => {
        let popup = $uibModal.open({
          controller: 'OrdersDeleteCtrl',
          controllerAs: 'delete',
          templateUrl: 'parcels/edit/orders/delete/views/delete.tpl.html',
          resolve: {
            item: () => {
              return item;
            },
            type: () => {
              return 'cyclic';
            }
          }
        });
        popup.result.then(() => {
          CrudApi.delete({
            entity: 'cyclicorders',
            id: item.id
          }, () => {
            // TOD: Its a hack, need to discuss this with Robert. Setting message on parent level
            $scope.edit.message = 'deletingSuccess';

            // Calculate Which page to go after deleting record
            let pageToGo = 1,
                pageToGoCalculate = (vm.total - 1) / vm.itemsPerPage;

            if (pageToGoCalculate % 1 === 0) {
              pageToGo = vm.page - 1;
            } else {
              pageToGo = vm.page;
            }

            // stay on same page if it is not the last one
            if (vm.total / vm.itemsPerPage > vm.page) {
              pageToGo = vm.page;
            }

            vm.page = pageToGo;

            vm.items = CrudApi.query({
              entity: 'cyclicorders',
              siteId: $stateParams.id,
              sortBy: vm.sorter,
              sortDir: vm.dir,
              perPage: vm.itemsPerPage,
              page: pageToGo
            }, (data, headers, status) => {
              vm.total = headers()['x-total-count'];
              if (status === 204) {
                vm.message = 'noData';
              }

              // To set the proper page in url
              $state.go($state.current.name, {
                page: vm.page
              });
            });
          }, () => {
            // TOD: Its a hack, need to discuss this with Robert. Setting message on parent level
            $scope.edit.message = 'deletingError';
          });
        });
      };
    }
  }

  CyclicOrdersListCtrl.$inject = ['$scope', '$state', '$stateParams', '$rootScope', 'CrudApi', '$uibModal'];

  /**
   * @ngdoc object
   * @name orders.cyclic.controller:CyclicOrdersListCtrl
   *
   * @description
   *
   */
  angular
    .module('orders.cyclic')
    .controller('CyclicOrdersListCtrl', CyclicOrdersListCtrl);
}());

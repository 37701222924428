<form name="deleteEquipmentForm">
  <div class="modal-header">
    <button type="button" class="close" ng-click="$dismiss();"><span>&times;</span></button>
    <h4 class="modal-title">{{'USER_DELETE' | translate}}</h4>
  </div>
  <div class="modal-body text-center">
    <h3>{{UsersDelete.item.lastname}}, {{UsersDelete.item.firstname}}</h3>
    <h4>{{UsersDelete.item.email}}</h4>
  </div>
  <div class="modal-body" ng-bind-html="'USER_DELETE_CONFIRM' | translate"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" ng-click="$dismiss();">{{'ABORT' | translate}}</button>
    <button type="button" class="btn btn-danger" ng-click="$close();">{{'OK' | translate}}</button>
  </div>
</form>
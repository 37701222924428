(function () {
  'use strict';

  angular
    .module('orders.cyclic')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('parcels.edit.orders.cyclic', {
        url: '/cyclic?page&sorter&dir',
        templateUrl: 'parcels/edit/orders/cyclic/views/cyclic.tpl.html',
        controller: 'CyclicOrdersListCtrl',
        controllerAs: 'cyclic',
        params: {
          message: null
        }
      });
  }
}());

(function () {
  'use strict';

  class OrderTypeBoniturCtrl {
    constructor($scope, $q, $filter, $http, CrudApi) {
      let vm = this;
      let promises = {};
      let vineyardParamsExp = '';
      vm.selected = undefined;
      vm.surveyId = undefined;
      vm.vineyards = [];
      vm.siteIds = [];
      vm.selectedNachbonitur = [];

      if(!$scope.edit.additionalData.siteDataList) {
        $scope.edit.additionalData.siteDataList = [];
      }

      if($scope.edit.order.siteIds) {
        vm.siteIds = $scope.edit.order.siteIds;
        vineyardParamsExp = '?vineyard=';
        for (const element of vm.siteIds) {
          vineyardParamsExp += element + ',';
        }
      }

      //Get All Vineyards
      promises.vineyards = CrudApi.query({
        entity: 'vineyards'
      }).$promise;

      //get All Surveys
      $http.get('/bonitur/api/v1/survey').then(function(response) {
        vm.surveys = response.data;
        vm.selected = vm.surveys.filter(i => i.id === vm.surveyId)[0].label;
      });

      //Filter only Vineyards for the order
      $q.all(promises).then((data) => {
        for (const siteId of this.siteIds) {
          let vineyard = data.vineyards.find(x => x.id === siteId);
          if(vineyard) {
            vm.vineyards.push(vineyard);
          }
        }
        //Get all Bonitur with the State "Nachbonitur erf.
        $http.get('bonitur/api/v1/bonitur/needNachbonitur' + vineyardParamsExp).then(function(response) {
          vm.boniturs = response.data;
          for(const bonitur of response.data) {
            let site = vm.vineyards.find(s => s.id === bonitur.site.legacyId);
            let element = {
              boniturId: bonitur.id,
              boniturLabel: bonitur.name,
              siteId: site.id,
              siteLabel: site.label
            };
            vm.selectedNachbonitur.push(element);
            $scope.edit.additionalData.siteDataList.push({boniturId: element.boniturId, siteId: element.siteId});
          }
        });
        console.log(vm.selectedNachbonitur);
        console.log($scope.edit.additionalData.siteDataList);
      });

      //Get already set SurveyId
      $scope.$watch('edit.additionalData.surveyId', (surveyId) => {
        vm.surveyId = surveyId;
      });

      $scope.$watch('edit.order.siteIds', (siteIds) => {
        vm.siteIds = siteIds;
        let params = '?vineyard=';
        for (const element of siteIds) {
          params += element + ',';
        }
      });

      vm.showSelected = (item) => {
        vm.selected = item.label;
      };

      vm.updateSelection = (nachbonitur, $event) => {
        let checkbox = angular.element($event.target)[0];
        if(checkbox.checked) {
          let arrElement = {
            boniturId: nachbonitur.boniturId,
            siteId: nachbonitur.siteId
          };
          $scope.edit.additionalData.siteDataList.push(arrElement);
        } else {
          for(let i = 0; i < $scope.edit.additionalData.siteDataList.length; i++) {
            let arrElement = $scope.edit.additionalData.siteDataList[i];
            if(arrElement.boniturId === nachbonitur.boniturId) {
              $scope.edit.additionalData.siteDataList.splice(i, 1);
            }
          }
        }
        console.log($scope.edit.additionalData.siteDataList);
      };
    }
  }

  OrderTypeBoniturCtrl.$inject = ['$scope', '$q', '$filter', '$http', 'CrudApi'];

  /**
   * @ngdoc object
   * @name orders.edit.controller:OrderTypeBoniturCtrl
   *
   * @description
   *
   */
  angular
    .module('orders.edit')
    .controller('OrderTypeBoniturCtrl', OrderTypeBoniturCtrl);
}());

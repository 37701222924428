(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name vcMain.factory:QrApi
   *
   * @description
   *
   */
  angular
    .module('vcMain')
    .factory('QrApi', QrApi);

  function QrApi($resource, apiBasePath, Blob) {
    return $resource(apiBasePath + 'generate/:type/:id', {},
      {
        get: {
          method: 'GET',
          responseType: 'arraybuffer',
          transformResponse: (data, headers) => {
            return {
              blob: new Blob([data], {type: 'octet/stream'}),
              filename: headers()['content-disposition'].split('=')[1].split('"')[1]
            };
          }
        }
      });
  }

  QrApi.$inject = ['$resource', 'apiBasePath', 'Blob'];
}());

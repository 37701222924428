(function () {
  'use strict';

  /* @ngdoc object
   * @name orders.edit
   * @description
   *
   */
  angular
    .module('orders.edit', [
      'ui.router',
      'angular.filter',
      'ui.validate'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('fertilization.nDemandExports')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('fertilization.nDemandExports', {
        abstract: true,
        url: '/n-demand-exports',
        template: '<ui-view />'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name timeTracking.factories:CostCenterHelper
   *
   * @description this factory contains helper methods for working with cost centers
   *
   */
  angular
    .module('timeTracking')
    .factory('CostCenterHelper', CostCenterHelper);

  CostCenterHelper.$inject = ['$translate'];

  function CostCenterHelper($translate) {
    let CostCenterHelperBase = {},
        labelTranslationKeyPrefix = 'TIME_TRACKING_COST_CENTERS_LABEL_';
    CostCenterHelperBase.getLabel = getLabel;
    CostCenterHelperBase.getTranslatedLabel = getTranslatedLabel;
    CostCenterHelperBase.makeFlat = makeFlat;
    CostCenterHelperBase.setIndentedLabel = setIndentedLabel;
    CostCenterHelperBase.groupCostCenters = groupCostCenters;

    return CostCenterHelperBase;

    function getLabel(costCenter) {
      if (costCenter.customerId !== null) {
        return costCenter.label;
      }
      return getTranslatedLabel(costCenter.label);
    }

    function getTranslatedLabel(label) {
      let translationKey = labelTranslationKeyPrefix + label,
          translatedLabel = $translate.instant(translationKey);
      if (translatedLabel !== translationKey) {
        return translatedLabel;
      }
      return label;
    }

    function makeFlat(costCenters, level, excludeCostCenterIds) {
      let result = [];
      costCenters.forEach(c => c.label = getLabel(c));
      costCenters.sort((a, b) => {
        // we always want to show system cost centers last in list
        if(isCustom(a) && (isShared(b) || isSystem(b))) {
          return -1;
        } else if(isShared(a) && isCustom(b)) {
          return 1;
        } else if(isShared(a) && isSystem(b)) {
          return -1;
        } else if(isSystem(a) && (isCustom(b) || isShared(b))) {
          return 1;
        } else {
          return a.label.localeCompare(b.label);
        }
      });
      costCenters.forEach(costCenter => {
        if (!excludeCostCenterIds || excludeCostCenterIds.indexOf(costCenter.id) < 0) {
          result.push(costCenter);
          costCenter.level = level;
          result.push(...makeFlat(costCenter.children, level + 1, excludeCostCenterIds));
        }
      });
      return result;
    }

    function isSystem(costCenter) {
      return costCenter.costCenterVisibilityMode !== 'ACCOUNT' && !costCenter.customerId;
    }

    function isCustom(costCenter) {
      return costCenter.costCenterVisibilityMode !== 'ACCOUNT' && costCenter.customerId;
    }

    function isShared(costCenter) {
      return costCenter.costCenterVisibilityMode === 'ACCOUNT';
    }

    function setIndentedLabel(costCentersFlat) {
      costCentersFlat.forEach(costCenter => {
        let prefix = '-'.repeat(costCenter.level);
        if (prefix.length) {
          prefix += ' ';
        }
        costCenter.indentedLabel = prefix.concat(costCenter.label);
      });
    }

    function groupCostCenters(costCenter) {
      let costCenterGroup;
      if (costCenter.costCenterVisibilityMode === 'ACCOUNT') {
        costCenterGroup = $translate.instant('TIME_TRACKING_COST_CENTERS_SHARED');
      } else if (costCenter.customerId) {
        costCenterGroup = $translate.instant('TIME_TRACKING_COST_CENTERS_CUSTOM');
      } else {
        costCenterGroup = $translate.instant('TIME_TRACKING_COST_CENTERS_SYSTEM');
      }
      return costCenterGroup;
    }
  }
}());

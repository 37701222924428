(function () {
  'use strict';

  class ImpersonationPopupCtrl {
    constructor(AdminCrudApi, UserService, filterFilter) {
      let vm = this;
      vm.loaded = false;
      vm.error = false;
      vm.customers = [];
      vm.filter = '';
      vm.filteredCustomers = [];
      vm.filterCustomers = filterCustomers;
      vm.resetFilter = resetFilter;
      vm.headline = UserService.getLocalizedLabelOrNull('tenantSelect');

      AdminCrudApi.query({
        entity: 'impersonation',
        subentity: 'usable'
      }, (data) => {
        vm.customers = data.filter(customer => customer.id !== UserService.user.customerId);
        filterCustomers();
        vm.loaded = true;
      }, () => {
        vm.loaded = true;
        vm.error = true;
      });

      function resetFilter() {
        vm.filter = '';
        filterCustomers();
      }

      function filterCustomers() {
        vm.filteredCustomers = filterFilter(vm.customers, {label: vm.filter});
      }
    }
  }

  ImpersonationPopupCtrl.$inject = ['AdminCrudApi', 'UserService', 'filterFilter'];

  /**
   * @ngdoc object
   * @name auth.impersonation.controller:ImpersonationPopupCtrl
   *
   * @description
   *
   */
  angular
    .module('auth.impersonation')
    .controller('ImpersonationPopupCtrl', ImpersonationPopupCtrl);
}());

(function () {
    'use strict';


    class ParcelsEditCtrl {
        constructor($http, $scope, $timeout, $q, $state, $stateParams, $translate, AdminCrudApi, CrudApi, $filter, WineTypeInfo, CustomerMapDataService, MapHelper, filterFilter, $uibModal, mapColors, FileUploader, apiBasePath, UserService, permissions, moment, PoiHelper, siteEntities, CostCenterHelper) {
            let vm = this,
                promises = {},
                savePromises = [];
            vm.mapId = 'parcel-edit-map-id';
            vm.mapIdEdit = 'parcel-edit-map-edit-id';
            vm.createNewParcel = angular.isUndefined($stateParams.id);
            vm.fullEditAvailable = !vm.createNewParcel && UserService.hasPerm(permissions.WINERY_MANAGEMENT_CAN_EDIT_VINEYARD) || vm.createNewParcel && UserService.hasPerm(permissions.WINERY_MANAGEMENT_CAN_CREATE_VINEYARD);
            vm.hasFertilizationModule = UserService.hasPerm(permissions.FERTILIZATION_CAN_USE);
            vm.hasTimeTrackingModule = UserService.hasPerm(permissions.TIME_TRACKING_CAN_USE);
            vm.hasBoniturModule = UserService.hasPerm(permissions.BONITUR_CAN_USE);
            vm.hasShapeConverterModule = UserService.hasPerm(permissions.SHAPE_CONVERTER_CAN_USE);
            vm.businessLabel = UserService.getLocalizedLabelOrNull('businessSingular');
            vm.hasHarvestPlanningModule = UserService.hasPerm(permissions.HARVEST_PLANNING_CAN_USE);
            vm.ownCustomerUuid = UserService.user.customerUuid;
            vm.accountId = UserService.user.accountId; //GwfAccountId = 9
            vm.cultivationProgramLabel = UserService.getLocalizedLabelOrNull('cultivationProgram');
            vm.boniturLabel = UserService.getLocalizedLabelOrNull('bonitur');
            vm.isShared = false;
            vm.dateIgnore = false;
            vm.domainWachau = UserService.getVcToken().accountUuid === '7f1d4f34-a92d-4b8e-906b-60046d14cbfb'
            vm.visibleLaneWidth = 0;

            vm.updateHiddenField = () => {
                vm.item.laneWidth = vm.visibleLaneWidth / 100;
            };

            vm.hasAssetType = function (assetType) {
                return (UserService.getAssetType() === assetType);
            };

            vm.mapEditModeEnabled = false;
            vm.mapEditMode = null;
            vm.maxBeaconsPerVineyard = 4;
            vm.polygons = [];
            vm.polylines = [];
            vm.beacons = [];
            vm.polygonOptions = MapHelper.getVineyardOptions(null, 'selected', false, {editable: true});
            vm.selectedVineyards = {
                [$stateParams.id]: true
            };

            vm.ourHeaders = {
                selected: null,
                lists:
                    {
                        'VCHeadersUnselected': [
                            {db: 'wine_first', name: 'PARCELS_PARAM_WINE_FIRST', value: 'null'},
                            {db: 'wine_second', name: 'PARCELS_PARAM_WINE_SECOND', value: 'null'},
                            {db: 'wine_third', name: 'PARCELS_PARAM_WINE_THIRD', value: 'null'},
                            {db: 'stick_distance', name: 'PARCELS_PARAM_STICKDISTANCE', value: 'null'},
                            {db: 'wine_location', name: 'PARCELS_PARAM_WINELOCATION', value: 'null'},
                            {db: 'district', name: 'PARCELS_PARAM_DISTRICT', value: 'null'},
                            {db: 'planted_date', name: 'PARCELS_PARAM_PLANTEDDATE', value: 'null'},
                            {db: 'yield_year', name: 'PARCELS_PARAM_YIELD_YEAR', value: 'null'},
                            {db: 'cleared_date', name: 'PARCELS_PARAM_CLEAREDDATE', value: 'null'},
                            {db: 'lane_width', name: 'PARCELS_PARAM_LANE_WIDTH', value: 'null'},
                            {db: 'bbch', name: 'PARCELS_PARAM_BBCH', value: 'null'},
                            {db: 'note_1', name: 'PARCELS_PARAM_NOTE1', value: 'null'},
                            {db: 'note_2', name: 'PARCELS_PARAM_NOTE2', value: 'null'},
                            {db: 'management_unit_id', name: 'MANAGEMENT_UNIT', value: 'null'},
                            {db: 'costCenterId', name: 'PARCELS_PARAM_COST_CENTER', value: 'null'},
                            {db: 'avg_strike_length', name: 'PARCELS_PARAM_AVG_STRIKE_LENGTH', value: 'null'},
                            {db: 'gradient', name: 'PARCELS_PARAM_GRADIENT', value: 'null'},
                            {db: 'number_of_grapevines', name: 'PARCELS_PARAM_NUMBER_OF_GRAPEVINES', value: 'null'},
                            {db: 'infested_with', name: 'PARCELS_PARAM_INFECTED_WITH', value: 'null'},
                            {db: 'infested_severity', name: 'PARCELS_PARAM_INFECTED_SEVERITY', value: 'null'},
                            {db: 'underlay', name: 'PARCELS_PARAM_UNDERLAY', value: 'null'},
                            {db: 'cultivation_program', name: 'PARCELS_PARAM_CULTIVATION_PROGRAM', value: 'null'},
                            {db: 'clone', name: 'PARCELS_PARAM_CLONE', value: 'null'},
                            {db: 'maximum_nitrogen_value', name: 'maximum_nitrogen_value', value: 'null'},
                            {db: 'nitrogen_deduction_pending', name: 'nitrogen_deduction_pending', value: 'null'},
                            {db: 'nitrogen_deduction_finished', name: 'nitrogen_deduction_finished', value: 'null'},
                            {db: 'phosphate_deduction_finished', name: 'phosphate_deduction_finished', value: 'null'},
                            {db: 'maximum_phosphate_value', name: 'maximum_phosphate_value', value: 'null'},
                            {db: 'phosphate_deduction_pending', name: 'phosphate_deduction_pending', value: 'null'},
                            {db: 'avg_strike_length', name: 'avg_strike_length', value: 'null'},
                            {db: 'total_strike_length', name: 'total_strike_length', value: 'null'},
                            {db: 'entity_discriminator', name: 'entity_discriminator', value: 'null'},
                            {db: 'number_of_rows', name: 'number_of_rows', value: 'null'},
                        ],
                        'VCHeadersSelected': [
                            {db: 'label', name: 'PARCEL_LABEL', value: 'null'},
                            {db: 'area', name: 'PARCELS_PARAM_AREA', value: 'null'},
                            {db: 'flurnumber', name: 'PARCEL_FLURNUMBER', value: 'null'},
                            {db: 'flik', name: 'PARCELS_PARAM_FLIK', value: 'null'},
                        ]
                    }
            };

            // add cultivationProgram options dependent from account
            vm.cultivationProgram = [];

            vm.clone = [];

            vm.shapeCsvLists = {
                'csvKey': [],
                'shapeKey': [],
                'vineyardKey': []
            };

            vm.uploadHeaders = {
                selected: null,
                lists:
                    {
                        'ShapeHeadersSelected': [],
                        'ShapeHeadersUnselected': []
                    }
            };

            vm.shapeUpload = {
                'filenames': [],
                'resultList': [],
                'selectionList': [],
                'businessId': '',
                'number': '',
                'label': '',
                'area': '',
                'flik': '',
                'rebe': '',
                'outcomeMessage': '',
                'outcomeHeader': ''
            };

            // To mark proper sub tab in Aufgaben tab as active
            vm.active = $state.current.name === 'parcels.edit.orders.cyclic' ? 1 : 0;

            // Initial message
            vm.message = $stateParams.message;

            // To handle key names for parent level keys
            vm.keys = {};

            vm.contractors = [];
            vm.restrictions = [];
            vm.classifications = [];

            // Scope functions needed for the edit map functionality
            vm.enableMapEditMode = enableMapEditMode;
            vm.getBeaconsFromColumns = getBeaconsFromColumns;
            vm.getSelectedPolyline = getSelectedPolyline;
            vm.getSelectedPoint = getSelectedPoint;
            vm.resetMap = resetMap;
            vm.canDrawPolylines = canDrawPolylines;
            vm.deleteRow = deleteRow;
            vm.deleteBeacon = deleteBeacon;
            vm.editBeacon = editBeacon;
            vm.addBeacon = addBeacon;

            vm.newPlot = (item) => {
                // if a plot with this exact label already exists, use that
                let existingPlot = $filter('filter')(vm.item.plots, {label: item}, true);
                if (existingPlot.length) {
                    return existingPlot[0];
                }
                // If the label would be a dupe, do not add it
                if (vm.availablePlotsIndex.hasOwnProperty(item)) {
                    return vm.availablePlotsIndex[item];
                }
                return {
                    label: item
                };
            };

            WineTypeInfo.whenReady().then(() => {
                vm.allWines = WineTypeInfo.getAllWines();

                // To manage the keys to display child level wines
                vm.translateValue = (value, level) => {
                    vm.keys[level] = WineTypeInfo.getWineKey(value);
                };
            });

            vm.plotSum = () => {
                let a = vm.item.plots.reduce((total, item) => {
                    return total + (item.area || 0);
                }, 0);
                return a;
            };

            vm.lessorSum = () => {
                let a = vm.item.lessors.reduce((total, item) => {
                    return total + (item.part || 0);
                }, 0);
                return a;
            };

            vm.validateYieldYear = () => {
                    if (vm.item.plantedDate != null) {
                        const newDate = new Date(Date.now());
                        newDate.setFullYear(vm.item.plantedDate.getFullYear() + 3);
                        vm.item.yieldYear = newDate;
                    }
                };

            vm.sliderOptions = {
                bbch: {
                    disabled: !vm.fullEditAvailable,
                    floor: 0,
                    ceil: 97,
                    step: 1,
                    onChange: () => {
                        if (vm.sliderOptions.bbch.reference !== vm.item.bbch) {
                            $scope.form.$setDirty();
                        }
                        vm.sliderOptions.bbch.reference = vm.item.bbch;
                    }
                },
                stickDistance: {
                    disabled: !vm.fullEditAvailable,
                    floor: 50,
                    ceil: 200,
                    step: 1,
                    onChange: () => {
                        if (vm.sliderOptions.stickDistance.reference !== vm.item.stickDistance) {
                            $scope.form.$setDirty();
                        }
                        vm.sliderOptions.stickDistance.reference = vm.item.stickDistance;
                    }
                }
            };

            // Add items to submenu
            vm.submenu = {
                items: [{
                    type: 'button',
                    text: 'BUTTON_SAVE',
                    click: () => {
                        if (savePromises.length === 0) {
                            vm.item.plantedDate = $filter('date')(vm.item.plantedDate, 'yyyy-MM-dd');
                            vm.item.clearedDate = $filter('date')(vm.item.clearedDate, 'yyyy-MM-dd');
                            if(vm.item.laneWidth) {
                                vm.item.laneWidth = vm.visibleLaneWidth / 100;
                            }
                            if (vm.item.managementUnitId) {
                                vm.item.managementUnitId = vm.item.managementUnitId.id;
                            }
                            if (vm.item.managementUnitId === undefined || vm.item.managementUnitId === null) { // if the management unit is to be removed
                                vm.item.managementUnitId = 0;
                            }
                            if (vm.createNewParcel) {
                                savePromises.push(CrudApi.save({
                                        entity: siteEntities
                                    }, vm.item, (data) => {
                                        CrudApi.save({
                                            entity: 'sites',
                                            subentity: 'mapsdata',
                                            subentityid: data.id
                                        }, {
                                            outline: vm.polygons.length ? vm.polygons[0] : {},
                                            columns: vm.polylines
                                        }).$promise
                                            .then(() => {
                                                $state.go('parcels.edit.orders.list', {
                                                    id: data.id,
                                                    message: 'savingSuccess'
                                                });
                                            })
                                            .catch(() => vm.message = 'savingError')
                                            .finally(() => savePromises = []);
                                    }).$promise
                                        .catch(() => {
                                            vm.message = 'savingError';
                                            savePromises = [];
                                        })
                                );
                            } else {
                                savePromises.push(CrudApi.save({
                                    id: $stateParams.id,
                                    entity: siteEntities
                                }, vm.item).$promise);
                                if (vm.mapEditModeEnabled) {
                                    savePromises.push(CrudApi.save({
                                        entity: 'sites',
                                        subentity: 'mapsdata',
                                        subentityid: $stateParams.id
                                    }, {
                                        outline: vm.polygons.length ? vm.polygons[0] : {},
                                        columns: vm.polylines
                                    }).$promise);
                                }
                                if (UserService.hasPerm(permissions.FERTILIZATION_CAN_USE) && (vm.fertilization.permissions.create && vm.fertilization.create || vm.fertilization.permissions.edit && !vm.fertilization.create) && vm.fertilization.period && !angular.equals(vm.fertilization.period, {})) {
                                    let fertilizationData = angular.copy(vm.fertilization.period);
                                    fertilizationData.periodStart = vm.fertilization.period.periodStartMoment.toISOString();
                                    fertilizationData.periodEnd = vm.fertilization.period.periodEndMoment.toISOString();
                                    fertilizationData.soilAnalysisYear = vm.fertilization.period.soilAnalysisYearMoment.toISOString();
                                    savePromises.push(CrudApi.save({
                                        entity: siteEntities,
                                        id: $stateParams.id,
                                        subentity: 'fertilizationData',
                                        subentityid: vm.fertilization.period && vm.fertilization.period.id ? vm.fertilization.period.id : undefined
                                    }, fertilizationData).$promise);
                                }
                            }
                            $q.all(savePromises).then(([vineyardData]) => {
                                console.log(vm.hasShapeConverterModule);
                                $state.go($state.current.name, {
                                    id: vineyardData.id,
                                    message: 'savingSuccess'
                                }, {
                                    reload: true
                                });
                            }).catch((response) => {
                                let message = 'savingError';
                                if (response.status === 410) {
                                    message = 'entityHasChanged';
                                }

                                $state.go($state.current.name, {
                                    id: $stateParams.id,
                                    message: message
                                }, {
                                    reload: true
                                });
                            }).finally(() => savePromises = []);
                        }
                        vm.dateIgnore = false;
                    }
                }]
            };

            // Initialize mainmenu items
            vm.mainmenu = {
                items: []
            };

            if (vm.createNewParcel) {
                promises.item = {
                    siteType: UserService.getAssetTypeAsClass()
                };
                if (UserService.getAssetType() === 'VINEYARD') {
                    promises.item.plots = [];
                }
                promises.item.lessors = [];
                promises.allMapData = {};
                promises.vineyardMapData = {};
                promises.iconData = {};
                vm.mainmenu = {
                    items: [{
                        label: 'WINERY_MANAGEMENT_PARCELS_NEW',
                        state: 'parcels.create'
                    }]
                };
                vm.mapEditModeEnabled = true;
                if (angular.isDefined($stateParams.businessId)) {
                    promises.item.businessId = $stateParams.businessId;
                }
            } else {
                // Api call for fetching the vineyard data
                promises.item = CrudApi.get({
                    id: $stateParams.id,
                    entity: siteEntities
                }, (data) => {
                    if (data.wineFirst) {
                        vm.translateValue(data.wineFirst, 'wineFirst');
                    }
                    if (data.wineSecond) {
                        vm.translateValue(data.wineSecond, 'wineSecond');
                    }
                    data.plantedDate = data.plantedDate ? new Date(data.plantedDate) : null;
                    data.yieldYear = data.yieldYear ? new Date(data.yieldYear) : null;
                    data.clearedDate = data.clearedDate ? new Date(data.clearedDate) : null;
                    data.stickDistance = data.stickDistance || 50;
                    data.bbch = data.bbch || 0;
                    vm.isShared = data.isShared;
                    console.log('IsShared ->' + vm.isShared);
                    if (vm.isShared === true) {
                        vm.mapEditModeEnabled = false;
                        vm.sliderOptions.stickDistance.disabled = true;
                        vm.sliderOptions.bbch.disabled = true;
                    }
                    vm.mainmenu = {
                        items: [{
                            label: data.label,
                            translate: false,
                            state: 'parcels.edit',
                            params: {
                                id: $stateParams.id
                            }
                        }]
                    };
                    $http.get('area/api/restrictions?areaId=' + data.uuid).then(function (response) {
                        vm.restrictions = response.data;
                        vm.restrictions = vm.restrictions
                            .sort((a, b) => (a.lastModified.localeCompare(b.lastModified) * -1));
                    });
                    $http.get('classification/api/subject/' + data.uuid + '/classifications/info').then(function (response) {
                        vm.classifications = response.data;
                        console.log(vm.classifications);
                    });
                }).$promise;

                // get map data for all vineyards to draw into the map
                promises.allMapData = CustomerMapDataService.getCustomerMapsData();
                // but we want to refresh only the current vineyard in case its map data was changed
                promises.vineyardMapData = CustomerMapDataService.getCustomerMapsDataById($stateParams.id, true);

                // get pois relevant for this vineyard
                promises.iconData = getPoiIconData();

                // Fertilization handling
                if (UserService.hasPerm(permissions.FERTILIZATION_CAN_USE)) {
                    vm.fertilization = {
                        create: false,
                        period: createNewPeriodObject(),
                        permissions: {
                            create: UserService.hasPerm(permissions.FERTILIZATION_CAN_CREATE_FERTILIZATION_VINEYARD_DATA),
                            edit: UserService.hasPerm(permissions.FERTILIZATION_CAN_EDIT_FERTILIZATION_VINEYARD_DATA),
                            delete: UserService.hasPerm(permissions.FERTILIZATION_CAN_DELETE_FERTILIZATION_VINEYARD_DATA)
                        }
                    };

                    promises.fertilizationPerdiods = CrudApi.query({
                        entity: siteEntities,
                        id: $stateParams.id,
                        subentity: 'fertilizationData'
                    }, (data) => {
                        data.forEach((item) => {
                            // Form item label for dropdown
                            item.label = $filter('date')(item.periodStart) + ' - ' + $filter('date')(item.periodEnd);
                            // Convert dates
                            item.periodStartMoment = moment.utc(item.periodStart);
                            item.periodEndMoment = moment.utc(item.periodEnd);
                            item.soilAnalysisYearMoment = moment.utc(item.soilAnalysisYear);
                            item.periodStart = item.periodStartMoment.toDate();
                            item.periodEnd = item.periodEndMoment.toDate();
                            item.soilAnalysisYear = item.soilAnalysisYearMoment.toDate();
                        });
                    }).$promise;

                    if (vm.hasBoniturModule) {
                        const token = UserService.getAuthToken();
                        $http.get('bonitur/loginWithToken', {headers: {'RefreshToken': token.refreshToken}}).then(function () {
                            let httpGetPartialUiConfig = {
                                headers: {
                                    'Accept': 'text/html'
                                },
                            };
                            $http.get('/bonitur/api/v1/bonitur/bySite/' + $stateParams.id, httpGetPartialUiConfig).then(function successCallback(response) {
                                // TODO replace this by a more responsive solution. e.g. $http returns a promise, maybe we can add this to promises.
                                setTimeout(function () {
                                    var boniturListElement = angular.element(window.document)[0].getElementById('bonitur-list');
                                    boniturListElement.innerHTML = response.data;
                                }, 5000);
                            }, function errorCallback(response) {
                                var boniturListElement = angular.element(window.document)[0].getElementById('bonitur-list');
                                boniturListElement.innerHTML = '<span>ERROR ' + response.status + ': ' + response.statusText;
                                console.log(response.status + ' - ' + response.statusText);
                            });
                        });
                    }

                    vm.validatePeriod = () => {
                        let valid = true,
                            periodStart = vm.fertilization.period.periodStartMoment,
                            periodEnd = vm.fertilization.period.periodEndMoment;
                        if (vm.fertilization && vm.fertilization.periods) {
                            vm.fertilization.periods.forEach((period) => {
                                // we check all failure conditions (even those that should be avoided by the disabled days in the datepicker),
                                // because we have to make sure we validate everything when creating a new fertilization datum
                                if ( period.id !== vm.fertilization.period.id && (
                                    period.periodEndMoment.isSameOrBefore(periodEnd, 'day') && period.periodStartMoment.isSameOrAfter(periodStart, 'day') ||
                                    period.periodStartMoment.isSameOrBefore(periodStart, 'day') && period.periodEndMoment.isSameOrAfter(periodStart, 'day') ||
                                    period.periodStartMoment.isSameOrBefore(periodEnd, 'day') && period.periodEndMoment.isSameOrAfter(periodEnd, 'day')
                                )) {
                                    valid = false;
                                }
                            });
                        }
                        return valid;
                    };

                    vm.periodStartOptions = {
                        initDate: new Date(),
                        dateDisabled: (data) => {
                            let disabled = false,
                                dateMoment = moment.utc(data.date);
                            // we only want to disable anything if we are in day granularity to make navigating in the datepicker popup easier
                            if (data.mode !== 'day') {
                                return false;
                            }
                            // Disabled all days that are on known periods
                            if (vm.fertilization && vm.fertilization.periods && vm.fertilization.periods.length) {
                                vm.fertilization.periods.forEach((period) => {
                                    if (period.id !== vm.fertilization.period.id && dateMoment.isBetween(period.periodStartMoment, period.periodEndMoment, 'day', '[]')) {
                                        disabled = true;
                                    }
                                });
                            }
                            // Disable so that startdate cannot be higher then endate
                            if (vm.fertilization && vm.fertilization.period && dateMoment.isAfter(vm.fertilization.period.periodEndMoment, 'day')) {
                                disabled = true;
                            }
                            return disabled;
                        }
                    };
                    vm.periodEndOptions = {
                        initDate: new Date(),
                        dateDisabled: (data) => {
                            let disabled = false,
                                dateMoment = moment.utc(data.date);
                            // we only want to disable anything if we are in day granularity to make navigating in the datepicker popup easier
                            if (data.mode !== 'day') {
                                return false;
                            }
                            // Disabled all days that are on known periods
                            if (vm.fertilization && vm.fertilization.periods && vm.fertilization.periods.length) {
                                vm.fertilization.periods.forEach((period) => {
                                    if (period.id !== vm.fertilization.period.id && dateMoment.isBetween(period.periodStartMoment, period.periodEndMoment, 'day', '[]')) {
                                        disabled = true;
                                    }
                                });
                            }
                            // Disable so that enddate cannot be lower then startdate
                            if (vm.fertilization && vm.fertilization.period && dateMoment.isBefore(vm.fertilization.period.periodStartMoment, 'day')) {
                                disabled = true;
                            }
                            return disabled;
                        }
                    };


                    vm.soilAnalysisYearOptions = {
                        datepickerMode: 'year',
                        minMode: 'year'
                    };
                    vm.newFertilizationPeriod = () => {
                        $translate('FERTILIZATION_PARCEL_FERTILIZATION_YEAR_NEW_LABEL').then((newlabel) => {
                            const label = newlabel
                            let newFertilizationPeriod
                            let formIsReady
                                let popup = $uibModal.open({
                                    controller: 'EditFertilizationNewCtrl',
                                    controllerAs: 'new',
                                    templateUrl: 'parcels/edit/views/edit-fertilization-new.tpl.html',
                                    resolve: {
                                        periods: () => {
                                            return vm.fertilization.periods;
                                        },
                                        period: () => {
                                            return vm.fertilization.period;
                                        }
                                    }
                                });
                                formIsReady = popup.result.then(()=> {
                                    vm.dateIgnore = true;
                                    if(popup.result.$$state.value === undefined){
                                        newFertilizationPeriod = createNewPeriodObject();
                                        newFertilizationPeriod.label = label;
                                    } else {
                                        newFertilizationPeriod = createNewPeriodObject();
                                        newFertilizationPeriod = popup.result.$$state.value;
                                        newFertilizationPeriod.periodEnd = null;
                                        newFertilizationPeriod.periodEndMoment= null;
                                        newFertilizationPeriod.periodStart= null;
                                        newFertilizationPeriod.periodStartMoment = null;
                                        newFertilizationPeriod.id = null;
                                        //TODO make translatable
                                        newFertilizationPeriod.label =`- Neues Düngejahr - (verwendet importierte Werte von ${popup.result.$$state.value.label})`;
                                    }
                                })
                                formIsReady.then(()=>{
                                vm.fertilization.periods.push(newFertilizationPeriod);
                                vm.fertilization.period = newFertilizationPeriod;
                                vm.fertilization.create = true;
                                }
                            );
                        });
                    };

                  /*
                  //Old version (no popup, no preselection)
                  vm.newFertilizationPeriod = () => {
                        $translate('FERTILIZATION_PARCEL_FERTILIZATION_YEAR_NEW_LABEL').then((newlabel) => {
                            // Initialize dates to prevent Today - UTC Bug
                            let newFertilizationPeriod = createNewPeriodObject();
                            newFertilizationPeriod.label = newlabel;
                            vm.fertilization.periods.push(newFertilizationPeriod);
                            vm.fertilization.period = newFertilizationPeriod;
                            vm.fertilization.create = true;
                        })
                    }*/

                    vm.isEmpty = (obj) => {
                        return Object.keys(obj).length === 0;
                    };

                    vm.copyFertilizationPeriod = () => {
                        let popup = $uibModal.open({
                            controller: 'EditFertilizationCopyCtrl',
                            controllerAs: 'copy',
                            templateUrl: 'parcels/edit/views/edit-fertilization-copy.tpl.html',
                            resolve: {
                                period: () => {
                                    return vm.fertilization.period;
                                }
                            }
                        });
                        popup.result.then((ids) => {
                            CrudApi.save({
                                entity: siteEntities,
                                id: $stateParams.id,
                                subentity: 'fertilizationData',
                                subentityid: vm.fertilization.period.id,
                                command: 'copy'
                            }, ids, () => {
                                $state.go($state.current.name, {
                                    message: 'copySuccess'
                                }, {
                                    reload: true
                                });
                            }, () => {
                                vm.message = 'copyError';
                            });
                        });
                    };

                    vm.newFertilizationPeriodCancel = () => {
                        vm.fertilization.periods.pop();
                        vm.fertilization.period = vm.fertilization.periods.length ? vm.fertilization.periods[0] : {};
                        vm.fertilization.create = false;
                    };

                    // Delete Popup
                    vm.deleteFertilizationPeriod = () => {
                        let popup = $uibModal.open({
                            controller: 'EditFertilizationDeleteCtrl',
                            controllerAs: 'delete',
                            templateUrl: 'parcels/edit/views/edit-fertilization-delete.tpl.html',
                            resolve: {
                                item: () => {
                                    return vm.fertilization.period;
                                }
                            }
                        });
                        popup.result.then(() => {
                            CrudApi.delete({
                                entity: siteEntities,
                                id: $stateParams.id,
                                subentity: 'fertilizationData',
                                subentityid: vm.fertilization.period.id
                            }, () => {
                                $state.go($state.current.name, {
                                    message: 'deletingSuccess'
                                }, {
                                    reload: true
                                });
                            }, () => {
                                vm.message = 'deletingError';
                            });
                        });
                    };
                }
            }

            // Fetch contractors
            promises.contractors = CrudApi.query({
                entity: 'customers',
                subentity: 'contractors'
            }).$promise;

            promises.businesses = CrudApi.query({
                entity: 'businesses',
                subentity: 'forSelection'
            }).$promise;

            promises.managementUnits = CrudApi.query({
                entity: 'managementUnits'
            }).$promise;

            if (vm.hasTimeTrackingModule) {
                promises.costCenters = CrudApi.query({
                    entity: 'costCenter',
                    customOnly: true
                }).$promise;
            } else {
                promises.costCenters = [];
            }

            if (UserService.getAssetType() === 'VINEYARD') {
                promises.plots = CrudApi.query({
                    entity: 'plots'
                }).$promise;
            }

            promises.lessors = AdminCrudApi.query({
                entity: 'customers',
                subentity: 'selection'
            }).$promise;

            // Launch all calls
            $q.all(promises).then((data) => {
                vm.item = data.item;
                if (data.fertilizationPerdiods) {
                    vm.fertilization.periods = data.fertilizationPerdiods;
                    vm.fertilization.period = data.fertilizationPerdiods.length ? data.fertilizationPerdiods[data.fertilizationPerdiods.length-1] : {};
                }
                // we take the map data of all vineyards
                vm.mapData = data.allMapData;
                // and update the map data of the current vineyards
                vm.mapData[$stateParams.id] = data.vineyardMapData;
                vm.businesses = data.businesses;
                vm.contractors = data.contractors;
                vm.managementUnits = data.managementUnits;
                vm.iconData = data.iconData;
                vm.costCenters = CostCenterHelper.makeFlat(data.costCenters, 0);
                CostCenterHelper.setIndentedLabel(vm.costCenters);

                let map = {},
                    managementUnits = $filter('filter')(data.managementUnits, {id: vm.item.managementUnitId});

                if (angular.isDefined(vm.item.managementUnitId) && managementUnits.length > 0) {
                    vm.item.managementUnitId = managementUnits[0];
                }

                if (vm.item.laneWidth) {
                    console.log(vm.item.laneWidth);
                    vm.visibleLaneWidth = vm.item.laneWidth * 100;
                }

                if (data.item.plots) {
                    // because the select box identifies plots using object identity we need to make
                    // sure those plot objects that are already associated with the vineyard are part
                    // of the availablePlots
                    data.item.plots.forEach(plot => map[plot.id] = plot);
                    vm.availablePlots = data.plots.map(plot => map.hasOwnProperty(plot.id) ? map[plot.id] : plot);
                    vm.availablePlotsIndex = {};
                    vm.availablePlots.forEach(p => vm.availablePlotsIndex[p.label] = p);
                    // We need custom tailored filter logic here, as "!" would not work otherwise.
                    // "!" is reserved for negation in standard angular filters
                    vm.getAvailablePlots = (search) => {
                        let result = [];
                        vm.availablePlots.forEach((plot) => {
                            if (plot.label.match(new RegExp(search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'ig'))) {
                                result.push(plot);
                            }
                        });
                        return result;
                    };
                }

                if (data.item.lessors) {
                    data.item.lessors.forEach(lessor => map[lessor.id] = lessor);
                    vm.availableLessors = data.lessors.map(lessor => map.hasOwnProperty(lessor.id) ? map[lessor.id] : {tenant:lessor.id, label:lessor.label, part:null});
                    vm.availableLessorsIndex = {};
                    vm.availableLessors.forEach(p => vm.availableLessorsIndex[p.label] = p);
                    // We need custom tailored filter logic here, as "!" would not work otherwise.
                    // "!" is reserved for negation in standard angular filters
                    vm.getAvailableLessors = (search) => {
                        let result = [];
                        vm.availableLessors.forEach((lessor) => {
                            if (lessor.label.match(new RegExp(search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'ig'))) {
                                result.push(lessor);
                            }
                        });
                        return result;
                    };
                }

                if (UserService.getVcToken().accountUuid === 'edd2c849-adea-44a1-9d0d-4e9ab4302e55') { // GWF
                    vm.cultivationProgram = [
                        {value: '00 - Basis', label: '00 - Basis'},
                        {value: '01 - Basis BB', label: '01 - Basis BB'},
                        {value: '02 - Klasse 2', label: '02 - Klasse 2'},
                        {value: '03 - Klasse 3', label: '03 - Klasse 3'},
                        {value: '04 - keine Beerntung', label: '04 - keine Beerntung'},
                        {value: '05 - Silvaner-Projekt', label: '05 - Silvaner-Projekt'},
                        {value: '06 - GWF-Extra WW', label: '06 - GWF-Extra WW'},
                        {value: '07 - Ökoanbau', label: '07 - Ökoanbau'},
                        {value: '08 - Premium', label: '08 - Premium'},
                        {value: '09 - GWF-Rebensart', label: '09 - GWF-Rebensart'},
                        {value: '10 - Junge Frank´n', label: '10 - Junge Frank´n'},
                        {value: '11 - GWF-Extra RW', label: '11 - GWF-Extra RW'},
                        {value: '12 - Sommerzeit', label: '12 - Sommerzeit'},
                        {value: '13 - Orts- u. Lagenweine', label: '13 - Orts- u. Lagenweine'},
                        {value: '20 - Minimalschnitt', label: '20 - Minimalschnitt'},
                        {value: '21 - Basis LFL weiß', label: '21 - Basis LFL weiß'},
                        {value: '22 - Basis LFL rot', label: '21 - Basis LFL rot'},
                        {value: '33 - ohne g.U.', label: '33 - ohne g.U.'},
                        {value: '61 - Bremser', label: '61 - Bremser'},
                        {value: '62 - Basis', label: '62 - Basis'},
                        {value: '63 - Basis', label: '63 - Basis'},
                        {value: '64 - Basis', label: '64 - Basis'},
                        {value: '65 - Basis', label: '65 - Basis'},
                        {value: '66 - Basis', label: '66 - Basis'},
                        {value: '77 - Öko GWF-Extra WW', label: '77 - Öko GWF-Extra WW'},
                        {value: '78 - Öko GWF-Extra RW', label: '78 - Öko GWF-Extra RW'},
                        {value: '79 - Klassik Öko', label: '79 - Klassik Öko'},
                        {value: '98 - Sektprogramm', label: '98 - Sektprogramm'},
                        {value: '99 - Franken Klassik', label: '99 - Franken Klassik'}
                    ];
                } else if (UserService.getVcToken().accountUuid === '7f1d4f34-a92d-4b8e-906b-60046d14cbfb') { // Domäne Wachau
                    vm.cultivationProgram = [
                        {value: '100 - Müller-Thurgau', label: '100 - Müller-Thurgau'},
                        {value: '104 - MüllerThurgau Beerenauslese', label: '104 - MüllerThurgau Beerenauslese'},
                        {value: '105 - MüllerThurgau sort.gefault', label: '105 - MüllerThurgau sort.gefault'},
                        {value: '106 - MTH Weitenberg', label: '106 - MTH Weitenberg'},
                        {value: '110 - MüllerThurgau FreieFläche', label: '110 - MüllerThurgau FreieFläche'},
                        {value: '120 - MüllerThg.Beerenausl.Fr.Fläche', label: '120 - MüllerThg.Beerenausl.Fr.Fläche'},
                        {value: '180 - Müller-Thurgau BIO', label: '180 - Müller-Thurgau BIO'},
                        {value: '202 - St.Laurent', label: '202 - St.Laurent'},
                        {value: '205 - St.Laurent Freie Fläche', label: '205 - St.Laurent Freie Fläche'},
                        {value: '206 - Merlot', label: '206 - Merlot'},
                        {value: '210 - Merlot Freie Fläche', label: '210 - Merlot Freie Fläche'},
                        {value: '211 - Blauer Zweigelt', label: '211 - Blauer Zweigelt'},
                        {value: '215 - Zweigelt Freie Fläche', label: '215 - Zweigelt Freie Fläche'},
                        {value: '217 - Blauer Portugieser', label: '217 - Blauer Portugieser'},
                        {value: '220 - Blauburgunder (Pinot Noir)', label: '220 - Blauburgunder (Pinot Noir)'},
                        {value: '221 - Blauburger', label: '221 - Blauburger'},
                        {value: '225 - Cabernet Sauvignon', label: '225 - Cabernet Sauvignon'},
                        {value: '280 - Zweigelt BIO', label: '280 - Zweigelt BIO'},
                        {value: '281 - Bl.Portugieser BIO', label: '281 - Bl.Portugieser BIO'},
                        {value: '282 - St.Lautent BIO', label: '282 - St.Lautent BIO'},
                        {value: '283 - Blauburgunder BIO (Pinot Noir)', label: '283 - Blauburgunder BIO (Pinot Noir)'},
                        {value: '284 - Blauburger BIO', label: '284 - Blauburger BIO'},
                        {value: '285 - Cabernet Sauvignon BIO', label: '285 - Cabernet Sauvignon BIO'},
                        {value: '300 - Grüner Veltliner', label: '300 - Grüner Veltliner'},
                        {value: '301 - Grüner Veltliner Durchlese', label: '301 - Grüner Veltliner Durchlese'},
                        {value: '304 - GV Beerenauslese', label: '304 - GV Beerenauslese'},
                        {value: '310 - GV Freie Fläche', label: '310 - GV Freie Fläche'},
                        {value: '311 - GV Trockenstress', label: '311 - GV Trockenstress'},
                        {value: '312 - GV Vorderseiber', label: '312 - GV Vorderseiber'},
                        {value: '314 - GV Achleiten', label: '314 - GV Achleiten'},
                        {value: '315 - GV Achleiten Vorlese', label: '315 - GV Achleiten Vorlese'},
                        {value: '318 - GV Loibenberg', label: '318 - GV Loibenberg'},
                        {value: '320 - GV Loibenberg Vorlese', label: '320 - GV Loibenberg Vorlese'},
                        {value: '321 - GV Kellerberg', label: '321 - GV Kellerberg'},
                        {value: '323 - GV Kellerberg Vorlese', label: '323 - GV Kellerberg Vorlese'},
                        {value: '324 - GV Liebenberg', label: '324 - GV Liebenberg'},
                        {value: '325 - GV Liebenberg Vorlese', label: '325 - GV Liebenberg Vorlese'},
                        {value: '327 - GV Hochrain', label: '327 - GV Hochrain'},
                        {value: '328 - GV Hochrain Freie Fläche', label: '328 - GV Hochrain Freie Fläche'},
                        {value: '330 - GV Kollmitz', label: '330 - GV Kollmitz'},
                        {value: '331 - GV Kollmütz', label: '331 - GV Kollmütz'},
                        {value: '332 - GV Kollmitz Vorlese', label: '332 - GV Kollmitz Vorlese'},
                        {value: '333 - GV Axpoint', label: '333 - GV Axpoint'},
                        {value: '334 - GV Lichtenstainerin', label: '334 - GV Lichtenstainerin'},
                        {value: '335 - GV Kaiserberg', label: '335 - GV Kaiserberg'},
                        {value: '336 - GV Pichlpoint', label: '336 - GV Pichlpoint'},
                        {value: '337 - GV Axpoint Vorlese', label: '337 - GV Axpoint Vorlese'},
                        {value: '339 - GV Kaiserberg Vorlese', label: '339 - GV Kaiserberg Vorlese'},
                        {value: '340 - GV Pichlpoint Vorlese', label: '340 - GV Pichlpoint Vorlese'},
                        {value: '341 - GV Traunthal', label: '341 - GV Traunthal'},
                        {value: '342 - GV Schön Smaragd', label: '342 - GV Schön Smaragd'},
                        {value: '343 - GV Kirnberg', label: '343 - GV Kirnberg'},
                        {value: '344 - GV Kreuzberg', label: '344 - GV Kreuzberg'},
                        {value: '345 - GV Trenning', label: '345 - GV Trenning'},
                        {value: '346 - GV Setzberg', label: '346 - GV Setzberg'},
                        {value: '347 - GV Kirnberg Smaragd', label: '347 - GV Kirnberg Smaragd'},
                        {value: '348 - GS uralt', label: '348 - GS uralt'},
                        {value: '349 - GV Frauenweingärten', label: '349 - GV Frauenweingärten'},
                        {value: '350 - GV Stiegelstal', label: '350 - GV Stiegelstal'},
                        {value: '351 - GV Kirnberg Vorlese', label: '351 - GV Kirnberg Vorlese'},
                        {value: '352 - GV Liebenberg BIO', label: '352 - GV Liebenberg BIO'},
                        {value: '353 - GV Liebenberg Vorlese BIO', label: '353 - GV Liebenberg Vorlese BIO'},
                        {value: '354 - GV Hochrain BIO', label: '354 - GV Hochrain BIO'},
                        {value: '355 - GV Hochrain sort.rein BIO', label: '355 - GV Hochrain sort.rein BIO'},
                        {value: '356 - GV Hochrain Vorlese BIO', label: '356 - GV Hochrain Vorlese BIO'},
                        {value: '357 - GV Kollmütz BIO', label: '357 - GV Kollmütz BIO'},
                        {value: '358 - GV Kollmütz Vorlese BIO', label: '358 - GV Kollmütz Vorlese BIO'},
                        {value: '359 - GV Pichlpoint BIO', label: '359 - GV Pichlpoint BIO'},
                        {value: '360 - GV Pichlpoint Vorlese BIO', label: '360 - GV Pichlpoint Vorlese BIO'},
                        {value: '361 - GV Kreuzberg BIO', label: '361 - GV Kreuzberg BIO'},
                        {value: '362 - GV Kreuzberg Vorlese BIO', label: '362 - GV Kreuzberg Vorlese BIO'},
                        {value: '363 - GV Traunthal BIO', label: '363 - GV Traunthal BIO'},
                        {value: '364 - GV Schön Smaragd BIO', label: '364 - GV Schön Smaragd BIO'},
                        {value: '365 - GV Loibenberg BIO', label: '365 - GV Loibenberg BIO'},
                        {value: '366 - GV Kollmitz BIO', label: '366 - GV Kollmitz BIO'},
                        {value: '367 - GV Kaiserberg BIO', label: '367 - GV Kaiserberg BIO'},
                        {value: '368 - GV Kirnberg BIO', label: '368 - GV Kirnberg BIO'},
                        {value: '369 - GV Axpoint BIO', label: '369 - GV Axpoint BIO'},
                        {value: '370 - GV Kirnberg BIO Vorlese', label: '370 - GV Kirnberg BIO Vorlese'},
                        {value: '371 - Gemischter Satz Uralt BIO', label: '371 - Gemischter Satz Uralt BIO'},
                        {value: '372 - GV Vorderseiber BIO', label: '372 - GV Vorderseiber BIO'},
                        {value: '373 - GV Achleiten BIO', label: '373 - GV Achleiten BIO'},
                        {value: '380 - GV BIO', label: '380 - GV BIO'},
                        {value: '391 - GV Kollmütz Freie Fläche', label: '391 - GV Kollmütz Freie Fläche'},
                        {value: '393 - GV Pichlpoint Freie Fläche', label: '393 - GV Pichlpoint Freie Fläche'},
                        {value: '400 - Neuburger', label: '400 - Neuburger'},
                        {value: '403 - Neuburger Setzberg', label: '403 - Neuburger Setzberg'},
                        {value: '404 - Neub.Spitzer Graben Somm.Edit.', label: '404 - Neub.Spitzer Graben Somm.Edit.'},
                        {value: '405 - Neuburger Beerenauslese', label: '405 - Neuburger Beerenauslese'},
                        {value: '410 - Neuburger Freie Fläche', label: '410 - Neuburger Freie Fläche'},
                        {value: '480 - Neuburger BIO', label: '480 - Neuburger BIO'},
                        {value: '481 - Neuburger Spitzer Graben BIO', label: '481 - Neuburger Spitzer Graben BIO'},
                        {value: '500 - Riesling', label: '500 - Riesling'},
                        {value: '502 - Riesling sort gefault', label: '502 - Riesling sort gefault'},
                        {value: '503 - Riesling gefault Freie Fl.', label: '503 - Riesling gefault Freie Fl.'},
                        {value: '504 - Riesling Beerenauslese', label: '504 - Riesling Beerenauslese'},
                        {value: '505 - Riesl. Kellerberg BA', label: '505 - Riesl. Kellerberg BA'},
                        {value: '508 - Riesl.EL Bodentrauben', label: '508 - Riesl.EL Bodentrauben'},
                        {value: '509 - Riesl.Achleiten BA', label: '509 - Riesl.Achleiten BA'},
                        {value: '510 - Riesling Freie Fläche', label: '510 - Riesling Freie Fläche'},
                        {value: '511 - Riesling Trockenstress', label: '511 - Riesling Trockenstress'},
                        {value: '515 - Riesling Singerriedl', label: '515 - Riesling Singerriedl'},
                        {value: '516 - Riesling Singerr.gefault', label: '516 - Riesling Singerr.gefault'},
                        {value: '517 - Riesl.Singerr.Vorlese', label: '517 - Riesl.Singerr.Vorlese'},
                        {value: '518 - Riesling 1000-Eimerberg', label: '518 - Riesling 1000-Eimerberg'},
                        {value: '519 - Riesling 1000-Eimerb.gefault', label: '519 - Riesling 1000-Eimerb.gefault'},
                        {value: '520 - Riesl.1000-Eim.Vorlese', label: '520 - Riesl.1000-Eim.Vorlese'},
                        {value: '521 - Riesling Achleiten', label: '521 - Riesling Achleiten'},
                        {value: '522 - Riesl.Achl.gefault sort.', label: '522 - Riesl.Achl.gefault sort.'},
                        {value: '523 - Riesl.Achleiten Vorlese', label: '523 - Riesl.Achleiten Vorlese'},
                        {value: '524 - Riesl.Kellerberg', label: '524 - Riesl.Kellerberg'},
                        {value: '525 - Riesl.Kellerb.gefault sort.', label: '525 - Riesl.Kellerb.gefault sort.'},
                        {value: '526 - Riesl.Kellerb.Vorlese', label: '526 - Riesl.Kellerb.Vorlese'},
                        {value: '527 - Riesling Loibenberg', label: '527 - Riesling Loibenberg'},
                        {value: '528 - Riesl.Loibenb.gefault sort.', label: '528 - Riesl.Loibenb.gefault sort.'},
                        {value: '529 - Riesl.Loibenb.Vorlese', label: '529 - Riesl.Loibenb.Vorlese'},
                        {value: '530 - Riesling Weitenberg', label: '530 - Riesling Weitenberg'},
                        {value: '531 - Riesling Kreuzberg', label: '531 - Riesling Kreuzberg'},
                        {value: '533 - Riesl.Setzberg', label: '533 - Riesl.Setzberg'},
                        {value: '534 - Riesl.Setzberg gefault sort.', label: '534 - Riesl.Setzberg gefault sort.'},
                        {value: '535 - Riesl.Setzberg Vorlese', label: '535 - Riesl.Setzberg Vorlese'},
                        {value: '536 - Riesling Bruck', label: '536 - Riesling Bruck'},
                        {value: '537 - Riesl.Bruck Vorlese', label: '537 - Riesl.Bruck Vorlese'},
                        {value: '538 - Riesl.Bruck sort gefault', label: '538 - Riesl.Bruck sort gefault'},
                        {value: '539 - Riesling Steinriegl', label: '539 - Riesling Steinriegl'},
                        {value: '540 - Riesl.Steinriegl gefault', label: '540 - Riesl.Steinriegl gefault'},
                        {value: '541 - Riesling Trenning', label: '541 - Riesling Trenning'},
                        {value: '542 - Riesling Seiber', label: '542 - Riesling Seiber'},
                        {value: '543 - Riesling Ritzling', label: '543 - Riesling Ritzling'},
                        {value: '544 - Riesl.Loibenberg BA', label: '544 - Riesl.Loibenberg BA'},
                        {value: '545 - Riesl Kellerb.TBA', label: '545 - Riesl Kellerb.TBA'},
                        {value: '546 - Riesling Brandstatt Smaragd', label: '546 - Riesling Brandstatt Smaragd'},
                        {value: '547 - Riesling Brandstatt gefault', label: '547 - Riesling Brandstatt gefault'},
                        {value: '548 - Riesling Trenning gefault', label: '548 - Riesling Trenning gefault'},
                        {value: '550 - Riesing BIO', label: '550 - Riesing BIO'},
                        {value: '551 - Riesling 1000-Eimerberg BIO', label: '551 - Riesling 1000-Eimerberg BIO'},
                        {value: '552 - Riesling 1000-E. sort. Gefault BIO', label: '552 - Riesling 1000-E. sort. Gefault BIO'},
                        {value: '553 - Riesling 1000-E. Vorlese BIO', label: '553 - Riesling 1000-E. Vorlese BIO'},
                        {value: '554 - Riesling Kellerberg BIO', label: '554 - Riesling Kellerberg BIO'},
                        {value: '555 - Riesling Kellerberg sort. Gefault BIO', label: '555 - Riesling Kellerberg sort. Gefault BIO'},
                        {value: '556 - Riesling Kellerberg Vorlese BIO', label: '556 - Riesling Kellerberg Vorlese BIO'},
                        {value: '557 - Riesling Loibenberg BIO', label: '557 - Riesling Loibenberg BIO'},
                        {value: '558 - Riesling Loibenberg sort. Gefault BIO', label: '558 - Riesling Loibenberg sort. Gefault BIO'},
                        {value: '559 - Riesling Loibenberg Vorlese BIO', label: '559 - Riesling Loibenberg Vorlese BIO'},
                        {value: '560 - Riesling Trenning BIO', label: '560 - Riesling Trenning BIO'},
                        {value: '561 - Riesling Brandstatt Smaragd BIO', label: '561 - Riesling Brandstatt Smaragd BIO'},
                        {value: '562 - Riesling Brandstatt Smaragd BIO gefault', label: '562 - Riesling Brandstatt Smaragd BIO gefault'},
                        {value: '563 - Riesling Trenning BIO gefault', label: '563 - Riesling Trenning BIO gefault'},
                        {value: '564 - Riesling Steinriegl BIO', label: '564 - Riesling Steinriegl BIO'},
                        {value: '570 - Riesling gefault BIO', label: '570 - Riesling gefault BIO'},
                        {value: '571 - Riesling Bruck BIO', label: '571 - Riesling Bruck BIO'},
                        {value: '572 - Riesling Singerriedel BIO', label: '572 - Riesling Singerriedel BIO'},
                        {value: '573 - Riesling Achleiten BIO', label: '573 - Riesling Achleiten BIO'},
                        {value: '578 - Riesl. Singerriedl BA', label: '578 - Riesl. Singerriedl BA'},
                        {value: '590 - Riesl.Loibenberg Freie Fläche', label: '590 - Riesl.Loibenberg Freie Fläche'},
                        {value: '591 - Riesl. Loibenberg gefault Fr.F', label: '591 - Riesl. Loibenberg gefault Fr.F'},
                        {value: '592 - Riesl.sort.rein Freie Fläche', label: '592 - Riesl.sort.rein Freie Fläche'},
                        {value: '600 - Muskateller', label: '600 - Muskateller'},
                        {value: '602 - Muskateller sort.gefault', label: '602 - Muskateller sort.gefault'},
                        {value: '604 - Muskateller Beerenauslese', label: '604 - Muskateller Beerenauslese'},
                        {value: '610 - Muskateller Freie Fläche', label: '610 - Muskateller Freie Fläche'},
                        {value: '630 - Muskateller BIO', label: '630 - Muskateller BIO'},
                        {value: '631 - Muskateller Beerenauslese BIO', label: '631 - Muskateller Beerenauslese BIO'},
                        {value: '650 - Traminer', label: '650 - Traminer'},
                        {value: '651 - Traminer Beerenauslese', label: '651 - Traminer Beerenauslese'},
                        {value: '680 - Traminer BIO', label: '680 - Traminer BIO'},
                        {value: '681 - Traminer Beerenauslese BIO', label: '681 - Traminer Beerenauslese BIO'},
                        {value: '700 - Weißburgunder', label: '700 - Weißburgunder'},
                        {value: '704 - Weißburg.Beerenauslese', label: '704 - Weißburg.Beerenauslese'},
                        {value: '710 - Weißburg.Freie Fläche', label: '710 - Weißburg.Freie Fläche'},
                        {value: '780 - Weißburg.BIO', label: '780 - Weißburg.BIO'},
                        {value: '800 - Frühroter Veltliner', label: '800 - Frühroter Veltliner'},
                        {value: '804 - Frühroter Veltl.Beerenauslese', label: '804 - Frühroter Veltl.Beerenauslese'},
                        {value: '810 - Frühroter Veltl.Freie Fläche', label: '810 - Frühroter Veltl.Freie Fläche'},
                        {value: '830 - Frünr.Veltl.BIO', label: '830 - Frünr.Veltl.BIO'},
                        {value: '850 - Sauvignon Blanc', label: '850 - Sauvignon Blanc'},
                        {value: '880 - Sauvign.Blanc BIO', label: '880 - Sauvign.Blanc BIO'},
                        {value: '899 - Frühroter Veltl.Tafelwein', label: '899 - Frühroter Veltl.Tafelwein'},
                        {value: '900 - Chardonnay', label: '900 - Chardonnay'},
                        {value: '904 - Chardonnay BA', label: '904 - Chardonnay BA'},
                        {value: '970 - Chard.BIO', label: '970 - Chard.BIO'},
                    ];
                } else if (UserService.getVcToken().accountUuid === '218b8b34-411d-4a40-a057-edcf66db9121') {
                    vm.cultivationProgram = [
                        {value: 'Riesling Kalkstein', label: 'Riesling Kalkstein'},
                        {value: 'Scheurebe Urmeer Löss', label: 'Scheurebe Urmeer Löss'}
                    ];
                }

                // We need custom tailored filter logic here, as "!" would not work otherwise.
                // "!" is reserved for negation in standard angular filters
                vm.getAvailableManagementUnits = (search) => {
                    let result = [];
                    vm.managementUnits.forEach((mu) => {
                        if (mu.label.match(new RegExp(search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'ig'))) {
                            result.push(mu);
                        }
                    });
                    return result;
                };

                vm.removeSelectedManagementUnit = () => {
                    vm.item.managementUnitId = null;
                };

                vm.getAvailableCostCenters = (search) => {
                    let result = [];
                    vm.costCenters.forEach((cc) => {
                        if (cc.indentedLabel.match(new RegExp(search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'ig'))) {
                            result.push(cc);
                        }
                    });
                    return result;
                };
            }).catch(() => {
                vm.message = 'loadingError';
            });

            vm.groupCostCenters = CostCenterHelper.groupCostCenters;

            // Initialize the uploader
            vm.uploader = new FileUploader({
                autoUpload: true,
                url: apiBasePath + 'sites/uploadmapsdata',
                onBeforeUploadItem: (item) => {
                    // set the auth header
                    item.headers = {
                        auth: UserService.user.token
                    };
                    // Remove error message if it was previously on and user uploads correct KML afterwards
                    vm.message = null;
                },
                onCompleteItem: (item, mapData, status) => {
                    if (status === 200) {
                        vm.mapEditMode = 'manual';
                        // change the triggerZoom value to force the map to rezoom
                        vm.triggerZoom = !vm.triggerZoom;
                        initializeGeometry(mapData);
                    } else if (status === 409) {
                        vm.message = 'errorKmlUnevenPoints';
                    } else {
                        vm.message = 'errorUpload';
                    }
                },
                filters: [{
                    name: 'kml',
                    fn: (item) => {
                        return item.name.toLowerCase().match(/\.kml/) !== null;
                    }
                }]
            });

            vm.shapeFileNames = [];

            // Initialize the uploader
            vm.multiUploader = new FileUploader({
                url: apiBasePath + 'shapeConverter/uploadFiles',
                onBeforeUploadItem: (item) => {
                    // set the auth header
                    item.headers = {
                        auth: UserService.user.token
                    };
                    // Remove error message if it was previously on and user uploads correct KML afterwards
                    vm.message = null;
                },
                filters: [{
                    name: 'shape Filter',
                    fn: (item) => {
                        return item.name.toLowerCase().match(/\.(shp|prj|cpg|qix|shx|dbf|csv)/) !== null;
                    }
                }],
                onSuccessItem: (item, response) => {
                    console.log(response);
                    let j;
                    for (j = 0; j < response.length; j++) {
                        vm.shapeFileNames.push(String(response[j]));
                    }
                },
                onCompleteAll: () => {
                    console.log(vm.shapeFileNames.toString());
                    $http.post(apiBasePath + 'shapeConverter/getFileUploadHeaders', JSON.stringify({stringList: vm.shapeFileNames})).then(function (newResponse) {
                        for (let i = 0; i < newResponse.data.length; i = i + 2) {
                            vm.uploadHeaders.lists.ShapeHeadersUnselected.push({
                                label: newResponse.data[i],
                                value: newResponse.data[i + 1]
                            });
                        }
                        console.log(vm.uploadHeaders.lists.ShapeHeadersUnselected.toString());
                    });
                }
            });

            vm.deleteFiles = function () {
                $http.post(apiBasePath + 'shapeConverter/clearCachedFilenamesList',
                    JSON.stringify({
                        stringList: vm.shapeUpload.filenames
                    }))
                    .then(function (newResponse) {
                        console.log(newResponse);
                        if (newResponse.status === 204) {
                            vm.shapeMultiUploader.clearQueue();
                            vm.shapeUpload = {
                                'filenames': [],
                                'resultList': [],
                                'selectionList': [],
                                'businessId': '',
                                'number': '',
                                'label': '',
                                'area': '',
                                'flik': '',
                                'rebe': '',
                                'outcomeMessage': '',
                                'outcomeHeader': ''
                            };
                        }
                    });
            };

            // Initialize the uploader
            vm.shapeMultiUploader = new FileUploader({
                url: apiBasePath + 'shapeConverter/uploadFiles',
                onBeforeUploadItem: (item) => {
                    // set the auth header
                    item.headers = {
                        auth: UserService.user.token
                    };
                    // Remove error message if it was previously on and user uploads correct KML afterwards
                    vm.message = null;
                },
                filters: [{
                    name: 'shape Filter',
                    fn: (item) => {
                        return item.name.toLowerCase().match(/\.(shp|prj|cpg|qix|shx|dbf|csv)/) !== null;
                    }
                }],
                onSuccessItem: (item, response) => {
                    console.log(response);
                    let j;
                    for (j = 0; j < response.length; j++) {
                        vm.shapeUpload.filenames.push(String(response[j]));
                    }
                },
                onCompleteAll: () => {
                    console.log(vm.shapeUpload.filenames.toString());
                    $http.post(apiBasePath + 'shapeConverter/getFileUploadHeaders', JSON.stringify({stringList: vm.shapeUpload.filenames})).then(function (newResponse) {
                        for (let i = 0; i < newResponse.data.length; i = i + 2) {
                            vm.shapeUpload.selectionList.push({
                                key: newResponse.data[i],
                                value: newResponse.data[i] + ':\t' + newResponse.data[i + 1]
                            });
                        }
                        console.log(vm.shapeUpload.selectionList.toString());
                    });
                }
            });


            // Events that would be fired when the user draws on the google map
            vm.drawingManagerEvents = {
                polylineCompleted: (polyline) => {
                    let path = MapHelper.getPathFromGmapShape(polyline);
                    // Prevent lines to be added outside of the polygon
                    if (!MapHelper.pointsInPolygon(path, vm.polygons[0].path)) {
                        vm.message = 'invalidPolyLine';
                        // Prevent lines with less than 1 point to be added
                    } else if (path.length > 1) {
                        vm.polylines.push(angular.merge({}, vm.polygonOptions, {
                            path: path
                        }));
                        // Set the polygon to not editable
                        vm.polygons[0].editable = false;
                        triggerUpdate();
                        vm.message = null;
                    }
                    // Set the form to be dirty, so the user can save again
                    $scope.form.$setDirty();
                },
                polygonCompleted: function (polygon) {
                    let path = MapHelper.getPathFromGmapShape(polygon);
                    // Prevent polygons with less than 2 points to be added
                    if (path.length > 2) {
                        vm.polygons.push(angular.merge({}, vm.polygonOptions, {
                            path: path
                        }));
                    }
                    triggerUpdate();
                    // Set the form to be dirty, so the user can save again
                    $scope.form.$setDirty();
                    vm.message = null;
                }
            };

            // Events that will be fired when the user interacts with map objects
            vm.objectEvents = {
                polyline: {
                    click: (polyline, model, args) => {
                        // Only react to the click when the user clicks a vertex
                        if (angular.isDefined(args.vertex)) {
                            deselectAll();
                            let point = polyline.path[args.vertex];
                            if (point.beacon) {
                                point.selected = true;
                            } else {
                                // Select the clicked polyline
                                polyline.clickedPoint = point;
                                polyline.selected = true;
                            }
                            triggerUpdate();
                        }
                    },
                    pathChanged: (polyline, model, changed) => {
                        let path = MapHelper.getPathFromGmapShape(model),
                            allBeaconsConsistend = ensureBeaconConsistency(polyline.path, path);
                        deselectAll();
                        if (allBeaconsConsistend && MapHelper.pointsInPolygon(path, vm.polygons[0].path)) {
                            polyline.path = path;
                            // Select the clicked polyline
                            polyline.clickedPoint = path[changed];
                            polyline.selected = true;
                            vm.message = null;
                        } else {
                            vm.message = 'invalidPolyLine';
                        }
                        triggerUpdate();
                        $scope.form.$setDirty();
                    }
                },
                marker: {
                    click: (marker) => {
                        deselectAll();
                        // Select the clicked beacon
                        marker.correspondingPoint.selected = true;
                        triggerUpdate();
                    }
                },
                polygon: {
                    pathChanged: (polygon, model) => {
                        let path = MapHelper.getPathFromGmapShape(model);
                        polygon.path = path;
                        triggerUpdate();
                        $scope.form.$setDirty();
                    }
                }
            };
            vm.removeItemToUpload = function (item) {
                const index = vm.shapeFileNames.indexOf(item);
                if (index > -1) {
                    vm.shapeFileNames.splice(index, 1);
                }
                item.remove();
            };
            vm.convertsuccess = [];
            vm.convertShapes = function () {
                $http.post(apiBasePath + 'shapeConverter/convert',
                    JSON.stringify({
                        vcHeadersSelected: vm.ourHeaders.lists.VCHeadersSelected.map(d => d.db),
                        shapeHeadersSelected: vm.uploadHeaders.lists.ShapeHeadersSelected.map(d => d.label),
                        keysCsv: vm.shapeCsvLists.csvKey.map(d => d.label),
                        keysShp: vm.shapeCsvLists.shapeKey.map(d => d.label),
                        keysVineyard: vm.shapeCsvLists.vineyardKey.map(d => d.label),
                        businessId: vm.item.businessId,
                        fileNames: {stringList: vm.shapeFileNames}
                    })).then(function (newResponse) {
                    console.log(newResponse.data.length);
                    vm.shapeFileNames = [];
                    for (let i = 0; i < newResponse.data.length; i++) {
                        vm.convertsuccess.push({label: newResponse.data[i]});
                    }
                });
            };

            vm.shapeUploadDo = function () {
                $http({
                    method: 'POST',
                    url: apiBasePath + 'shapeConverter/shapeUploadConvert',
                    data: JSON.stringify({
                        businessId: vm.shapeUpload.businessId,
                        plotAreaKey: vm.shapeUpload.area,
                        flurnumberKey: vm.shapeUpload.number,
                        labelKey: vm.shapeUpload.label,
                        flikKey: vm.shapeUpload.flik,
                        rebeKey: vm.shapeUpload.rebe,
                        fileNames: {stringList: vm.shapeUpload.filenames}
                    }),
                    responseType: 'json'

                }).then(function (newResponse) {
                    console.log(newResponse);
                    let value = newResponse.data[0];
                    if (value === 'Success') {
                        vm.shapeUpload.outcomeHeader = 'BATCH_EDITING_SUCCESS_VINEYARDS';
                    } else {
                        vm.shapeUpload.outcomeHeader = 'BATCH_EDITING_ERROR_VINEYARDS';
                        vm.shapeUpload.outcomeMessage = value;
                    }
                });
            };
            vm.showResultingParcels = function () {
                CustomerMapDataService.invalidateCache();
                $state.go('parcels.list', {
                    'business': vm.shapeUpload.businessId
                }, {reload: true});
            };

            function enableMapEditMode() {
                if (angular.isDefined(vm.mapData)) {
                    vm.mapEditModeEnabled = true;
                    vm.mapEditMode = 'manual';
                    vm.triggerZoom = !vm.triggerZoom;
                    initializeGeometry(vm.mapData[$stateParams.id]);
                }
            }

            function initializeGeometry(mapData) {
                // Intialize Shape arrays
                if (angular.isDefined(mapData)) {
                    if (angular.isDefined(mapData.outline) && angular.isDefined(mapData.outline.path) && mapData.outline.path.length) {
                        vm.polygons = [angular.merge({}, mapData.outline, vm.polygonOptions)];
                        vm.polylines = angular.isDefined(mapData.columns) ? mapData.columns.map((col) => angular.merge({}, col, vm.polygonOptions)) : [];
                    }
                    triggerUpdate();
                }
            }

            function resetMap() {
                vm.polylines = [];
                vm.polygons = [];
                vm.mapEditMode = null;
                vm.message = null;
                triggerUpdate();
            }

            function deleteRow(polyline) {
                vm.polylines.splice(vm.polylines.indexOf(polyline), 1);
                deselectAll();
                triggerUpdate();
                $scope.form.$setDirty();
            }

            function deleteBeacon(point) {
                let popup = createBeaconModal(point.beacon, 'delete');
                popup.result.then(() => {
                    point.beacon = null;
                    deselectAll();
                    triggerUpdate();
                    $scope.form.$setDirty();
                });
            }

            function editBeacon(point) {
                let popup = createBeaconModal(point.beacon);
                popup.result.then(result => {
                    point.beacon = result;
                    triggerUpdate();
                    $scope.form.$setDirty();
                });
            }

            function addBeacon(polyline) {
                if (polyline.clickedPoint) {
                    let popup = createBeaconModal(polyline.clickedPoint.beacon);
                    popup.result.then(result => {
                        deselectAll();
                        polyline.clickedPoint.beacon = result;
                        polyline.clickedPoint.selected = true;
                        triggerUpdate();
                        $scope.form.$setDirty();
                    });
                }
            }

            function triggerUpdate() {
                $timeout(() => {
                    vm.polygons = vm.polygons.slice();
                    vm.polylines = vm.polylines.slice();
                    vm.beacons = getBeaconsFromColumns(vm.polylines);
                    // correctly set the editable status of the polygons
                    vm.polygons.forEach(polygon => polygon.editable = canEditPolygons());
                    vm.polylines.forEach(polyline => {
                        if (polyline.selected) {
                            polyline.strokeColor = mapColors.yellow;
                        } else {
                            polyline.strokeColor = vm.polygonOptions.strokeColor;
                        }
                    });
                }, 0);
            }

            function getBeaconsFromColumns(columns) {
                /* global google */
                let beacons = [];
                if (angular.isArray(columns)) {
                    columns.forEach(column => {
                        column.path.forEach((point) => {
                            if (point.beacon) {
                                beacons.push({
                                    id: point.beacon,
                                    position: {
                                        latitude: point.latitude,
                                        longitude: point.longitude
                                    },
                                    animation: point.selected ? google.maps.Animation.BOUNCE : null,
                                    correspondingPoint: point
                                });
                            }
                        });
                    });
                }
                return beacons;
            }

            function ensureBeaconConsistency(oldPath, newPath) {
                let allBeaconsExist = true;
                for (let i = 0; i < oldPath.length && allBeaconsExist; i++) {
                    const point = oldPath[i];
                    if (point.beacon) {
                        let pointFound = false;
                        for (let j = 0; j < newPath.length && !pointFound; j++) {
                            const newPoint = newPath[j];
                            if (point.latitude === newPoint.latitude && point.longitude === newPoint.longitude) {
                                pointFound = true;
                                newPoint.beacon = point.beacon;
                            }
                        }
                        allBeaconsExist = pointFound;
                    }
                }
                return allBeaconsExist;
            }

            function getSelectedPolyline() {
                let selected = filterFilter(vm.polylines, {selected: true}, true);
                if (selected.length === 1) {
                    return selected[0];
                }
                return null;
            }

            function getSelectedPoint() {
                for (let i = 0; i < vm.polylines.length; i++) {
                    const polyline = vm.polylines[i];
                    let selected = filterFilter(polyline.path, {selected: true}, true);
                    if (selected.length === 1) {
                        return selected[0];
                    }
                }
                return null;
            }

            function canEditPolygons() {
                // Polygons are editable if there are no lines
                return vm.polylines.length ? false : true;
            }

            function canDrawPolylines() {
                return !!vm.polygons.length;
            }

            function deselectAll() {
                vm.polylines.forEach((pl) => {
                    delete pl.selected;
                    // deselect points
                    pl.path.forEach(point => {
                        delete point.selected;
                    });
                });
            }

            function createBeaconModal(beacon, operation = 'edit') {
                let popup = $uibModal.open({
                    controller: 'BeaconsEditCtrl',
                    controllerAs: 'Beacons' + operation.charAt(0).toUpperCase() + operation.slice(1).toLowerCase(),
                    templateUrl: 'parcels/edit/beacons/views/beacons-' + operation.toLowerCase() + '.tpl.html',
                    resolve: {
                        beacon: () => {
                            return beacon;
                        }
                    }
                });
                return popup;
            }

            function createNewPeriodObject() {
                let now = moment.utc();
                return {
                    periodStart: now.toDate(),
                    periodEnd: now.toDate(),
                    periodStartMoment: moment.utc(now),
                    periodEndMoment: moment.utc(now)
                };
            }

            function getPoiIconData() {
                let defer = $q.defer();
                CrudApi.query({
                    entity: 'poi',
                    vId: $stateParams.id
                }, pois => {
                    let iconPromises = {};
                    pois.forEach(poi => {
                        iconPromises[poi.id] = PoiHelper.createIconFromPoi(poi);
                    });
                    $q.all(iconPromises).then(defer.resolve, defer.reject);
                }, defer.reject);
                return defer.promise;
            }

            vm.renderSlider = () => {
                // This is needed whenever a slider is on tabs
                $timeout(function () {
                    $scope.$broadcast('rzSliderForceRender');
                });
            };

            vm.sliderLabel = (value) => {
                return value + 'cm';
            };

            vm.majorWineRegion = [
                'Abtswinder Schild',
                'Frickenhäuser Markgraf Babenbg',
                'Iphöfer Burgweg',
                'Rödelseer Schloßberg',
                'Thüngersheimer Ravensburg',
                'Ipsheimer Burgberg',
                'Karlstadter Roßtal',
                'Volkacher Kirchberg',
                'Donnersdorfer Zabelstein',
                'Großostheimer Heiligenthal',
                'Donnersdorfer Zabelstein',
                'Kitzinger Hofrat',
                'Randersackerer Ewig Leben',
                'Zeiler Kapellenberg',
                'Eibelstadter Teufelstor',
                'Weigenh.Frankenb.Schloßst.',
                'Oberschwarzacher Steige',
                'Ipp Frankenberger Schloßstück',
                'Dettelbacher Honigberg',
                'Wertheimer Tauberklinge',
                'Hammelburger Burg',
                'Würzburger Marienberg',
                'Sommerhäuser Oelspiel',
                'Winzer an der Mainschleife',
                'ohne Großlage Tauberfranken',
                'ohne Großlage Franken'
            ];

            vm.wineArea = [
                'Abtswind; Greuth; Castell',
                'Astheim',
                'Bullenheim',
                'Dertingen',
                'Eisenheim; Wipfeld',
                'Frankenwinheim',
                'Frickenhausen',
                'Gerolzhofen',
                'Großlangheim',
                'Großostheim',
                'Hammelburg',
                'Hüttenheim',
                'Iphofen',
                'Ippesheim',
                'Kitzingen; Sulzfeld',
                'Königheim',
                'Külsheim',
                'Lindach',
                'Markt Einersheim',
                'Oberschüpf',
                'Randersacker; Eibelstadt',
                'Retzstadt',
                'Rödelsee',
                'Seinsheim',
                'Sickershausen',
                'Stammheim',
                'Stetten',
                'Sugenheim',
                'Tauberrettersheim',
                'Untermain',
                'Volkach',
                'WWK Lese 1',
                'WWK Lese 2',
                'Zeil'];

            vm.placeOfLoading = [
                '01 - Dertingen',
                '02 - Erlenbach',
                '03 - Großostheim',
                '04 - Hammelburg',
                '05 - Homburg',
                '06 - Iphofen',
                '07 - Ipsheim',
                '08 - Ipsheim/Weimersheim',
                '09 - Kitzingen',
                '10 - Königheim',
                '11 - Külsheim',
                '12 - Oberschüpf',
                '13 - Ramsthal',
                '14 - Randersacker',
                '15 - Retzstadt',
                '16 - Stetten',
                '17 - Tauberrettersheim',
                '18 - Volkach',
                '19 - Weimersheim',
                '20 - Wipfeld',
                '21 - Zeil'
            ];

            vm.loadingPoint = [
                '01 - Festplatz',
                '02 - Halle Bäuerleinsberg',
                '03 - Gilbert',
                '04 - Fella',
                '05 - Kelterstation',
                '06 - Verkaufsstelle',
                '07 - (Leer)',
                '08 - (Leer)',
                '09 - (Leer)',
                '10 - Halle Honikel',
                '11 - Bauhof',
                '12 - Oehm',
                '13 - Kirche',
                '14 - Sixt Bernhard',
                '15 - Kelterstation',
                '16 - Blatterspiel',
                '17 - Halle Winkler',
                '18 - Festplatz',
                '19 - Kelterstation',
                '20 - Verkaufsstelle',
                '21 - (Leer)',
                '22 - Kelterstation',
                '23 - Keine Fläche'
            ];

            vm.filteredLoadingPoint = vm.loadingPoint;

            vm.getLoadingPointsForPlaceOfLoading = () => {
                //console.log('Filter');
                const places = vm.placeOfLoading.find(place => place.value === vm.item.placeOfLoading);
                //console.log('places'+ places);
                vm.filteredLoadingPoint = vm.loadingPoint.filter(point => point.id === places.id);
            };
        }
    }

    ParcelsEditCtrl.$inject = ['$http', '$scope', '$timeout', '$q', '$state', '$stateParams', '$translate', 'AdminCrudApi', 'CrudApi', '$filter', 'WineTypeInfo', 'CustomerMapDataService', 'MapHelper', 'filterFilter', '$uibModal', 'mapColors', 'FileUploader', 'apiBasePath', 'UserService', 'permissions', 'moment', 'PoiHelper', 'siteEntities', 'CostCenterHelper'];

    /**
     * @ngdoc object
     * @name parcels.edit.controller:ParcelsEditCtrl
     *
     * @description
     *
     */
    angular
        .module('parcels.edit')
        .controller('ParcelsEditCtrl', ParcelsEditCtrl);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name vcMain.factory:TimeHelper
   *
   * @description this factory contains helper methods for working with stupid timezones
   *
   */
  angular
    .module('vcMain')
    .factory('TimeHelper', TimeHelper);

  TimeHelper.$inject = ['moment'];

  function TimeHelper(moment) {
    let TimeHelperBase = {};
    TimeHelperBase.toLocalDate = toLocalDate;
    TimeHelperBase.localDateToTimezone = localDateToTimezone;

    /**
     * This function takes a UTC timestamp and returns a javascript date object that represents
     * the given time in the given timezone.
     *
     * @param {*} timestamp UTC representation of the time
     * @param {*} timezone the timezone to convert the UTC time into
     * @returns {Date} a javascript date object of the UTC timestamp in the given time
     */
    function toLocalDate(timestamp, timezone) {
      // parse the timestamp as a datetime in the given timezone
      let momentDate = moment(timestamp).tz(timezone);
      // create a new Date object that represents the local time in the browser timezone
      return new Date(momentDate.format('YYYY-MM-DDTHH:mm:ss'));
    }

    /**
     * This function takes a javascript date object representing a local time in the given timezone
     * and converts it into a UTC date object.
     *
     * @param {*} date the date object to convert
     * @param {*} timezone the timezone of the local time
     * @returns {Date} the UTC date object
     */
    function localDateToTimezone(date, timezone) {
      let localDate = moment(date).local(),
          inTimezone = moment.tz(localDate.format('YYYY-MM-DD HH:mm:ss'), timezone);
      return inTimezone.toDate();
    }

    return TimeHelperBase;
  }
}());

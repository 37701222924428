(function () {
  'use strict';

  /* @ngdoc object
   * @name poi
   * @description
   *
   */
  angular
    .module('poi', [
      'ui.router',
      'poi.list',
      'poi.edit',
      'poi.delete'
    ]);
}());

(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name auth.constant:permissions
     *
     * @description
     *
     */
    angular
        .module('auth')
        .constant('permissions', {
            BASE_CAN_USE: 'BASE.CAN_USE',
            BASE_CAN_CREATE_USER: 'BASE.CAN_CREATE_USER',
            BASE_CAN_DELETE_USER: 'BASE.CAN_DELETE_USER',
            BASE_CAN_EDIT_USER: 'BASE.CAN_EDIT_USER',
            BASE_CAN_DO_MANAGEMENT: 'BASE.CAN_DO_MANAGEMENT',
            BASE_CAN_DO_ORDERS: 'BASE.CAN_DO_ORDERS',
            BASE_CAN_ASSIGN_USER_TO_ORDER: 'BASE.CAN_ASSIGN_USER_TO_ORDER',
            BASE_CAN_MARK_VINEYARD_AS_REDO: 'BASE.CAN_MARK_VINEYARD_AS_REDO',
            BASE_CAN_CREATE_ORDERTYPE: 'BASE.CAN_CREATE_ORDERTYPE',
            BASE_CAN_DELETE_ORDERTYPE: 'BASE.CAN_DELETE_ORDERTYPE',
            BASE_CAN_EDIT_ORDERTYPE: 'BASE.CAN_EDIT_ORDERTYPE',
            BASE_CAN_CREATE_POI: 'BASE.CAN_CREATE_POI',
            BASE_CAN_DELETE_POI: 'BASE.CAN_DELETE_POI',
            BASE_CAN_EDIT_POI: 'BASE.CAN_EDIT_POI',
            BASE_CAN_CREATE_EQUIPMENT_TYPE: 'BASE.CAN_CREATE_EQUIPMENT_TYPE',
            BASE_CAN_DELETE_EQUIPMENT_TYPE: 'BASE.CAN_DELETE_EQUIPMENT_TYPE',
            BASE_CAN_EDIT_EQUIPMENT_TYPE: 'BASE.CAN_EDIT_EQUIPMENT_TYPE',
            BASE_CAN_UPLOAD_FILE: 'BASE.CAN_UPLOAD_FILE',
            BASE_CAN_DOWNLOAD_FILE: 'BASE.CAN_DOWNLOAD_FILE',
            BASE_CAN_DELETE_FILE: 'BASE.CAN_DELETE_FILE',
            BASE_CAN_CREATE_TEMPORARY_SITE: 'BASE.CAN_CREATE_TEMPORARY_SITE',
            BASE_CAN_CREATE_FINISHED_ORDER: 'BASE.CAN_CREATE_FINISHED_ORDER',
            BASE_CAN_CREATE_RESTRICTION: 'BASE.CAN_CREATE_RESTRICTION',
            BASE_CAN_READ_GPS_PROFILE: 'BASE.CAN_READ_GPS_PROFILE',
            BASE_CAN_READ_COSTSPERHOUR: 'BASE.CAN_READ_COSTSPERHOUR',
            WINERY_MANAGEMENT_CAN_USE: 'WINERY_MANAGEMENT.CAN_USE',
            WINERY_MANAGEMENT_CAN_CREATE_WINERY: 'WINERY_MANAGEMENT.CAN_CREATE_WINERY',
            WINERY_MANAGEMENT_CAN_DELETE_WINERY: 'WINERY_MANAGEMENT.CAN_DELETE_WINERY',
            WINERY_MANAGEMENT_CAN_EDIT_WINERY: 'WINERY_MANAGEMENT.CAN_EDIT_WINERY',
            WINERY_MANAGEMENT_CAN_CREATE_VINEYARD: 'WINERY_MANAGEMENT.CAN_CREATE_VINEYARD',
            WINERY_MANAGEMENT_CAN_DELETE_VINEYARD: 'WINERY_MANAGEMENT.CAN_DELETE_VINEYARD',
            WINERY_MANAGEMENT_CAN_EDIT_VINEYARD: 'WINERY_MANAGEMENT.CAN_EDIT_VINEYARD',
            ADMIN_CAN_USE: 'ADMIN.CAN_USE',
            ADMIN_CAN_READ_ALL_TENANTS_IN_ACCOUNT: 'ADMIN.CAN_READ_ALL_TENANTS_IN_ACCOUNT',
            ADMIN_CAN_CREATE_CUSTOMER: 'ADMIN.CAN_CREATE_CUSTOMER',
            ADMIN_CAN_DELETE_CUSTOMER: 'ADMIN.CAN_DELETE_CUSTOMER',
            ADMIN_CAN_EDIT_CUSTOMER: 'ADMIN.CAN_EDIT_CUSTOMER',
            ADMIN_CAN_EDIT_VISIBILITY_MODE: 'ADMIN.CAN_EDIT_VISIBILITY_MODE',
            ADMIN_CAN_EDIT_ALL_CUSTOMER_EQUIPMENTS: 'ADMIN.CAN_EDIT_ALL_CUSTOMER_EQUIPMENTS',
            ADMIN_CAN_EDIT_ALL_CUSTOMER_EQUIPMENT_TYPES: 'ADMIN.CAN_EDIT_ALL_CUSTOMER_EQUIPMENT_TYPES',
            ADMIN_CAN_EDIT_ALL_CUSTOMER_FERTILIZATION_ARTICLES: 'ADMIN.CAN_EDIT_ALL_CUSTOMER_FERTILIZATION_ARTICLES',
            ADMIN_CAN_EDIT_ALL_CUSTOMER_MANAGEMENT_UNITS: 'ADMIN.CAN_EDIT_ALL_CUSTOMER_MANAGEMENT_UNITS',
            ADMIN_CAN_EDIT_ALL_CUSTOMER_ORDERS: 'ADMIN.CAN_EDIT_ALL_CUSTOMER_ORDERS',
            ADMIN_CAN_EDIT_ALL_CUSTOMER_ORDER_TYPES: 'ADMIN.CAN_EDIT_ALL_CUSTOMER_ORDER_TYPES',
            ADMIN_CAN_EDIT_ALL_CUSTOMER_PLANT_PROTECTION_ARTICLES: 'ADMIN.CAN_EDIT_ALL_CUSTOMER_PLANT_PROTECTION_ARTICLES',
            ADMIN_CAN_EDIT_ALL_CUSTOMER_VINEYARDS: 'ADMIN.CAN_EDIT_ALL_CUSTOMER_VINEYARDS',
            ADMIN_CAN_EDIT_BONITUR: 'ADMIN.CAN_EDIT_BONITUR',
            ADMIN_CAN_IMPERSONATE: 'ADMIN.CAN_IMPERSONATE',
            ADMIN_CAN_BE_REGIONAL_MANAGER: 'ADMIN.CAN_BE_REGIONAL_MANAGER',
            BONITUR_CAN_USE: 'BONITUR.CAN_USE',
            BONITUR_CAN_MANAGE: 'BONITUR.CAN_MANAGE',
            SHAPE_CONVERTER_CAN_USE: 'SHAPE_CONVERTER.CAN_USE',
            FERTILIZATION_CAN_USE: 'FERTILIZATION.CAN_USE',
            FERTILIZATION_CAN_CREATE_FERTILIZATION: 'FERTILIZATION.CAN_CREATE_FERTILIZATION',
            FERTILIZATION_CAN_EDIT_FERTILIZATION: 'FERTILIZATION.CAN_EDIT_FERTILIZATION',
            FERTILIZATION_CAN_DELETE_FERTILIZATION: 'FERTILIZATION.CAN_DELETE_FERTILIZATION',
            FERTILIZATION_CAN_CREATE_ARTICLE: 'FERTILIZATION.CAN_CREATE_ARTICLE',
            FERTILIZATION_CAN_EDIT_ARTICLE: 'FERTILIZATION.CAN_EDIT_ARTICLE',
            FERTILIZATION_CAN_DELETE_ARTICLE: 'FERTILIZATION.CAN_DELETE_ARTICLE',
            FERTILIZATION_CAN_CREATE_FERTILIZATION_VINEYARD_DATA: 'FERTILIZATION.CAN_CREATE_FERTILIZATION_VINEYARD_DATA',
            FERTILIZATION_CAN_DELETE_FERTILIZATION_VINEYARD_DATA: 'FERTILIZATION.CAN_DELETE_FERTILIZATION_VINEYARD_DATA',
            FERTILIZATION_CAN_EDIT_FERTILIZATION_VINEYARD_DATA: 'FERTILIZATION.CAN_EDIT_FERTILIZATION_VINEYARD_DATA',
            FERTILIZATION_CAN_CREATE_NUTRIENT_COMPARISON_EXPORT: 'FERTILIZATION.CAN_CREATE_NUTRIENT_COMPARISON_EXPORT',
            FERTILIZATION_CAN_DOWNLOAD_NUTRIENT_COMPARISON_EXPORT: 'FERTILIZATION.CAN_DOWNLOAD_NUTRIENT_COMPARISON_EXPORT',
            FERTILIZATION_CAN_DELETE_NUTRIENT_COMPARISON_EXPORT: 'FERTILIZATION.CAN_DELETE_NUTRIENT_COMPARISON_EXPORT',
            FERTILIZATION_CAN_CREATE_PH_DEMAND_EXPORT: 'FERTILIZATION.CAN_CREATE_PH_DEMAND_EXPORT',
            FERTILIZATION_CAN_DOWNLOAD_PH_DEMAND_EXPORT: 'FERTILIZATION.CAN_DOWNLOAD_PH_DEMAND_EXPORT',
            FERTILIZATION_CAN_DELETE_PH_DEMAND_EXPORT: 'FERTILIZATION.CAN_DELETE_PH_DEMAND_EXPORT',
            FERTILIZATION_CAN_CREATE_N_DEMAND_EXPORT: 'FERTILIZATION.CAN_CREATE_N_DEMAND_EXPORT',
            FERTILIZATION_CAN_DOWNLOAD_N_DEMAND_EXPORT: 'FERTILIZATION.CAN_DOWNLOAD_N_DEMAND_EXPORT',
            FERTILIZATION_CAN_DELETE_N_DEMAND_EXPORT: 'FERTILIZATION.CAN_DELETE_N_DEMAND_EXPORT',
            HARVEST_PLANNING_CAN_USE: 'HARVEST_PLANNING.CAN_USE',
            HARVEST_PLANNING_CAN_MANAGE: 'HARVEST_PLANNING.CAN_MANAGE',
            PATHTIME_CAN_USE: 'PATHTIME.CAN_USE',
            PATHTIME_CAN_MANAGE: 'PATHTIME.CAN_MANAGE',
            PLANT_PROTECTION_CAN_USE: 'PLANT_PROTECTION.CAN_USE',
            PLANT_PROTECTION_CAN_CREATE_ARTICLE: 'PLANT_PROTECTION.CAN_CREATE_ARTICLE',
            PLANT_PROTECTION_CAN_DELETE_ARTICLE: 'PLANT_PROTECTION.CAN_DELETE_ARTICLE',
            PLANT_PROTECTION_CAN_EDIT_ARTICLE: 'PLANT_PROTECTION.CAN_EDIT_ARTICLE',
            PLANT_PROTECTION_CAN_CREATE_EXPORT: 'PLANT_PROTECTION.CAN_CREATE_EXPORT',
            PLANT_PROTECTION_CAN_DELETE_EXPORT: 'PLANT_PROTECTION.CAN_DELETE_EXPORT',
            PLANT_PROTECTION_CAN_DOWNLOAD_EXPORT: 'PLANT_PROTECTION.CAN_DOWNLOAD_EXPORT',
            PLANT_PROTECTION_CAN_USE_CERES_API: 'PLANT_PROTECTION.CAN_USE_CERES_API',
            PROPOSAL_CAN_USE: 'PROPOSAL.CAN_USE',
            TIME_TRACKING_CAN_USE: 'TIME_TRACKING.CAN_USE',
            TIME_TRACKING_CAN_CREATE_COST_CENTER: 'TIME_TRACKING.CAN_CREATE_COST_CENTER',
            TIME_TRACKING_CAN_DELETE_COST_CENTER: 'TIME_TRACKING.CAN_DELETE_COST_CENTER',
            TIME_TRACKING_CAN_EDIT_COST_CENTER: 'TIME_TRACKING.CAN_EDIT_COST_CENTER',
            TIME_TRACKING_CAN_CHANGE_SETTINGS: 'TIME_TRACKING.CAN_CHANGE_SETTINGS',
            TIME_TRACKING_CAN_READ_OWN_TIME_LOGS: 'TIME_TRACKING.CAN_READ_OWN_TIME_LOGS',
            TIME_TRACKING_CAN_EDIT_OWN_TIME_LOGS: 'TIME_TRACKING.CAN_EDIT_OWN_TIME_LOGS',
            TIME_TRACKING_CAN_READ_ALL_TIME_LOGS: 'TIME_TRACKING.CAN_READ_ALL_TIME_LOGS',
            TIME_TRACKING_CAN_EDIT_ALL_TIME_LOGS: 'TIME_TRACKING.CAN_EDIT_ALL_TIME_LOGS',
            STOCK_MANAGEMENT_CAN_USE: 'STOCK_MANAGEMENT.CAN_USE'
        });
}());

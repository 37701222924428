(function () {
  'use strict';

  class BeaconsDeleteCtrl {
    constructor(beacon) {
      let vm = this;
      vm.beacon = beacon;
    }
  }

  BeaconsDeleteCtrl.$inject = ['beacon'];

  /**
   * @ngdoc object
   * @name parcels.edit.beacons.controller:BeaconsDeleteCtrl
   *
   * @description
   *
   */
  angular
    .module('parcels.edit.beacons')
    .controller('BeaconsDeleteCtrl', BeaconsDeleteCtrl);
}());

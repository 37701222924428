/* globals google */
(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name vcMain.directive:vcMapBase
   * @restrict E
   * @element
   *
   * @description This directive draws a map with vineyards and (temporary workers).
   *
   * @example
      <example module="vcMain">
        <file name="index.html">
          <vc-map-base></vc-map-base>
        </file>
      </example>
   *
   */
  angular
    .module('vcMain')
    .directive('vcMapBase', vcMapBase);

  vcMapBase.$inject = ['$document', '$sanitize'];

  function vcMapBase($document, $sanitize) {
    return {
      restrict: 'E',
      scope: {
        mapId: '<',
        vineyardData: '<',
        icons: '<?',
        users: '<?',
        userData: '<',
        tempWorkerData: '<',
        changeVineyardOptions: '<',
        changeIconOptions: '<',
        selectionMode: '<',
        zoomMode: '<?',
        determineVineyardOptions: '&?',
        determineUserColor: '&?',
        onVineyardClick: '&?',
        onVineyardMouseOut: '&?',
        onVineyardMouseOver: '&?',
        onVineyardsSelected: '&?',
        onIconClick: '&?',
        // we don't call this "onMapClick" because attribute parsing of ngMap will break
        // of an attribute starting with "on..." is not using an expression attribute
        // (e.g. "&")
        enableMapClick: '<?'
      },
      templateUrl: 'directives/vc-map-base-directive.tpl.html',
      replace: true,
      transclude: true,
      controllerAs: 'vcMapBase',
      bindToController: true,
      controller(NgMap, $filter, $rootScope, MapHelper, $log, $q, $timeout) {
        let vm = this,
            defaultDrawingManagerOptions = {
              drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ['circle', 'polygon', 'rectangle']
              }
            },
            overrideOverlayOptions = {},
            pointIndex,
            vineyardElementMapping = {},
            vineyardElements = [],
            iconElementMapping = {},
            iconElements = [],
            userElements = [],
            tempWorkerElements = [],
            drawingManager = null,
            ctrlPressed = false,
            mapReady = $q.defer(),
            mapClickListener = null;

        vm.setCtrlPressed = setCtrlPressed;
        // we set the initial zoom to "germany" as soon as the map is ready
        mapReady.promise.then((map) => {
          let bounds = MapHelper.getInitialBounds(),
              cnt;

          map.fitBounds(bounds);
          cnt = map.getCenter();
          cnt.e += 0.000001;
          map.panTo(cnt);
          cnt.e -= 0.000001;
          map.panTo(cnt);
          console.log(vm.scope.vineyardData);
        });

        vm.mapInitialized = (map) => {
          // apparently there are problems if the map is panned or zoomed while the tiles
          // are not yet loaded (the pan/zoom will not be executed)
          // so here we are resolving our map ready promise when the tiles have been loaded.
          // this promise will later be used by the onchange callback to wait for the map
          // before attempting to pan/zoom.
          // other functions also use this promise even though they might need to wait for
          // up to one second when the map is being initialized. We accept that because
          // a race condition is possible where NgMap.getMap operates on stale map references
          // until the map initialization is completed. This results in the wrong map
          // instance being used for operations which is obviously undesireable.
          google.maps.event.addListenerOnce(map, 'tilesloaded', () => mapReady.resolve(map));
          // it is possible that the "tilesloaded" event fired before the event handler
          // above was registered. to make sure that the map is still able to zoom
          // we resolve the mapReady promise after a second of waiting at the latest
          $timeout(() => {
            mapReady.resolve(map);
          }, 1000);
        };

        vm.$onDestroy = () => {
          clearElements(vineyardElements);
          clearElements(userElements);
          clearElements(tempWorkerElements);
          clearElements(iconElements);
          if (mapClickListener !== null) {
            mapClickListener.remove();
          }
        };

        /* eslint complexity: [2, 18] */
        vm.$onChanges = (changesObj) => {
          let iconsAlreadyDrawn = false;

          if (changesObj.vineyardData && changesObj.vineyardData.currentValue) {
            clearElements(vineyardElements);
            drawVineyards(changesObj.vineyardData.currentValue);
            vineyardElementMapping = createElementsMapping(vineyardElements, 'vineyardId');
            // it is possible that the icon attribute was populated before the
            // respective vineyardData was. To correctly draw the icons in this case
            // we need to draw them here now
            if (angular.isDefined(vm.icons)) {
              clearElements(iconElements);
              drawIcons(vm.icons);
              iconElementMapping = createElementsMapping(iconElements, 'iconId');
              iconsAlreadyDrawn = true;
            }
          }

          if (changesObj.userData && changesObj.userData.currentValue) {
            clearElements(userElements);
            drawUsers(changesObj.userData.currentValue);
          }

          if (changesObj.tempWorkerData && changesObj.tempWorkerData.currentValue) {
            clearElements(tempWorkerElements);
            drawTempWorkers(changesObj.tempWorkerData.currentValue);
          }

          // we only draw the icons if they were not already drawn above
          if (changesObj.icons && changesObj.icons.currentValue && !iconsAlreadyDrawn) {
            clearElements(iconElements);
            drawIcons(changesObj.icons.currentValue);
            iconElementMapping = createElementsMapping(iconElements, 'iconId');
          }

          if (changesObj.changeVineyardOptions && angular.isObject(changesObj.changeVineyardOptions.currentValue)) {
            angular.forEach(changesObj.changeVineyardOptions.currentValue, (options, vineyardId) => {
              angular.forEach(vineyardElementMapping[vineyardId], element => {
                setElementOptions(element.element, options);
              });
            });
          }

          if (changesObj.changeIconOptions && angular.isObject(changesObj.changeIconOptions.currentValue)) {
            angular.forEach(changesObj.changeIconOptions.currentValue, (options, iconId) => {
              angular.forEach(iconElementMapping[iconId], element => {
                let iconOptions = getIconOptions(options);
                // iconOptions is null if there was no valid position data specified in
                // options.
                // in this case we assume the passed options object is just incremental
                // changes to certain attributes that we can apply directly without
                // error
                setElementOptions(element.element, iconOptions !== null ? iconOptions : options);
              });
            });
          }

          // either the selection mode changed or the vineyard data changed, so we might need to updated our index
          if (changesObj.selectionMode || changesObj.vineyardData) {
            if (drawingManager !== null) {
              clearElements([drawingManager]);
            }
            if (vm.selectionMode && angular.isDefined(vm.vineyardData)) {
              pointIndex = buildPointIndex(vm.vineyardData);
              drawingManager = createDrawingManager(pointIndex, vm.drawingManagerOptions);
            }
          }

          if (changesObj.enableMapClick) {
            setMapClick(angular.isFunction(changesObj.enableMapClick.currentValue));
          }

          // refocus the map if there are any elements drawn on it and we did not only change
          // the color of some vineyard(s)
          if ((vineyardElements.length > 0 || userElements.length > 0 || tempWorkerElements.length > 0 || iconElements.length > 0) && !isOnlyChangeOptions(changesObj) && (angular.isUndefined(vm.zoomMode) || vm.zoomMode)) {
            // the value of vm.zoomMode could change between now and the time the promise
            // resolve handler gets called, so we fixate the current value with a local
            // reference
            let zoomMode = vm.zoomMode;
            mapReady.promise.then((map) => {
              let bounds = new google.maps.LatLngBounds(),
                  cnt;
              // we are not explicitely zooming to include the icons as those are
              // always positioned in the center of their respective vineyard anyway
              extendBounds(bounds, vineyardElements.filter(v => !angular.isObject(zoomMode) || !angular.isObject(zoomMode.vineyards) || zoomMode.vineyards[v.vineyardId]));
              extendBounds(bounds, iconElements.filter(i => !angular.isObject(zoomMode) || !angular.isObject(zoomMode.icons) || zoomMode.icons[i.iconId]));
              extendBounds(bounds, userElements);
              extendBounds(bounds, tempWorkerElements);
              // it is still possible that nothing was added to the bounds because none
              // of the candidate objects were actually included in the zoom
              if (!bounds.isEmpty()) {
                map.fitBounds(bounds);
                cnt = map.getCenter();
                cnt.e += 0.000001;
                map.panTo(cnt);
                cnt.e -= 0.000001;
                map.panTo(cnt);
              }
            });
          }
        };

        function isOnlyChangeOptions(changesObj) {
          let keys = Object.keys(changesObj),
              changeOptionInputs = [
                'changeVineyardOptions',
                'changeIconOptions'
              ];
          // filter all keys that signify change option inputs
          keys = keys.filter(key => changeOptionInputs.indexOf(key) < 0);
          // what is leftover should be anything that are not change option inputs
          return keys.length === 0;
        }

        function setMapClick(enabled) {
          overrideOverlayOptions.clickable = !enabled;
          mapReady.promise.then(map => {
            if (enabled) {
              mapClickListener = google.maps.event.addListener(map, 'click', vm.enableMapClick);
            } else if (mapClickListener !== null) {
              mapClickListener.remove();
              mapClickListener = null;
            }
          });
          // we want to enable or disable clicks on overlays if map clicks are activated
          angular.forEach(vineyardElements.concat(iconElements).concat(userElements).concat(tempWorkerElements), element => {
            setElementOptions(element.element, overrideOverlayOptions);
          });
        }

        function createDrawingManager(index, drawingOptions, defaultOptions = defaultDrawingManagerOptions) {
          let dManager = new google.maps.drawing.DrawingManager(angular.merge({}, defaultOptions, drawingOptions)),
              listeners = [];
          dManager.setDrawingMode(null);
          listeners.push(google.maps.event.addListener(dManager, 'overlaycomplete', (e) => {
            $rootScope.$apply(() => onMapOverlayCompleted(index, e));
          }));
          mapReady.promise.then(map => {
            if (!dManager.isRemoved) {
              dManager.setMap(map);
            }
          });
          return {element: dManager, listeners: listeners};
        }

        function onMapOverlayCompleted(index, e) {
          // We expect the user to do something with the element that was drawn instead of just
          // showing it on the map
          e.overlay.setMap(null);

          // get the points that fall within the new overlay
          let candidates = findCandidatesFromIndex(index, e.overlay),
              matches = findActualMatches(e.overlay, candidates);

          if (angular.isFunction(vm.onVineyardsSelected)) {
            vm.onVineyardsSelected({vineyardIds: matches, ctrlPressed: ctrlPressed, singleVineyard: false});
          }
        }

        function findCandidatesFromIndex({index, pointList}, element) {
          let candidates = [];
          if (element instanceof google.maps.Polygon) {
            let path = element.getPath(),
                minLat = null,
                minLng = null,
                maxLat = null,
                maxLng = null;
            path.forEach(p => {
              if (minLat === null || minLat > p.lat()) {
                minLat = p.lat();
              }
              if (minLng === null || minLng > p.lng()) {
                minLng = p.lng();
              }
              if (maxLat === null || maxLat < p.lat()) {
                maxLat = p.lat();
              }
              if (maxLng === null || maxLng < p.lng()) {
                maxLng = p.lng();
              }
            });
            candidates = index.range(minLng, minLat, maxLng, maxLat);
          } else if (element instanceof google.maps.Circle || element instanceof google.maps.Rectangle) {
            let bounds = element.getBounds(),
                min = bounds.getSouthWest(),
                max = bounds.getNorthEast();
            candidates = index.range(min.lng(), min.lat(), max.lng(), max.lat());
          }
          return candidates.map(i => pointList[i]);
        }

        function findActualMatches(element, candidatePoints) {
          let matches = [],
              pointCounts = {};
          // if the element is not a rectangle we need to filter the candidate point list for those
          // points that are actually contained in the element
          if (element instanceof google.maps.Polygon) {
            candidatePoints = candidatePoints.filter(p => {
              return google.maps.geometry.poly.containsLocation(p.latLng, element);
            });
          } else if (element instanceof google.maps.Circle) {
            candidatePoints = candidatePoints.filter(p => {
              return google.maps.geometry.spherical.computeDistanceBetween(element.getCenter(), p.latLng) <= element.getRadius();
            });
          } else if (!(element instanceof google.maps.Rectangle)) {
            $log.warn('Invalid element type: ', element);
          }

          // so now we only have to find those vineyards that are completely part of the rectangle
          candidatePoints.forEach(p => {
            if (angular.isUndefined(pointCounts[p.vineyardId])) {
              pointCounts[p.vineyardId] = 0;
            }
            pointCounts[p.vineyardId] += 1;
            if (pointCounts[p.vineyardId] === p.totalPoints) {
              matches.push(p.vineyardId);
            }
          });
          return matches;
        }

        function buildPointIndex(vineyardData) {
          /* globals kdbush */
          let pointList = [],
              index;

          angular.forEach(vineyardData, (vineyard, id) => {
            if (vineyard.outline) {
              let pointCount = vineyard.outline.path.length;
              vineyard.outline.path.forEach(point => {
                pointList.push({
                  vineyardId: parseInt(id, 10),
                  totalPoints: pointCount,
                  latLng: new google.maps.LatLng(point.latitude, point.longitude)
                });
              });
            }
          });

          index = kdbush(pointList, p => p.latLng.lng(), p => p.latLng.lat());

          return {
            pointList,
            index
          };
        }

        function createElementsMapping(elements, idHashKey, elementMapping = {}) {
          angular.forEach(elements, element => {
            if (element.hasOwnProperty(idHashKey)) {
              if (!elementMapping.hasOwnProperty(element[idHashKey])) {
                elementMapping[element[idHashKey]] = [];
              }
              elementMapping[element[idHashKey]].push(element);
            }
          });
          return elementMapping;
        }

        function drawUsers(userData) {
          angular.forEach(userData, (user, userId) => {
            // change position-ordering for master users
            let sortedUser = $filter('orderBy')(user, 'capturedDate', true),
                location = new google.maps.LatLng(sortedUser[0].latitude, sortedUser[0].longitude),
                userColor = getUserColor(parseInt(userId, 10)),
                scale = 1,
                userMarker = getUserMarker(location, userColor, vm.users[userId], scale),
                userPath,
                pathStartMarker;

            userElements.push({element: userMarker});

            if (sortedUser.length > 1) {
              let pathCoordinates = sortedUser.map(coord => {
                    return {lat: coord.latitude, lng: coord.longitude};
                  }),
                  startCoordinate = sortedUser[sortedUser.length - 1],
                  polylineOptions = {
                    path: pathCoordinates,
                    strokeColor: userColor,
                    strokeWeight: 1.5,
                    zIndex: 2
                  },
                  markerOptions = {
                    position: new google.maps.LatLng(startCoordinate.latitude, startCoordinate.longitude),
                    zIndex: 3,
                    icon: {
                      path: google.maps.SymbolPath.CIRCLE,
                      fillColor: 'black',
                      fillOpacity: 1,
                      scale: 5,
                      strokeColor: userColor,
                      strokeWeight: 5
                    }
                  };

              userPath = new google.maps.Polyline(angular.merge(polylineOptions, overrideOverlayOptions));

              pathStartMarker = new google.maps.Marker(angular.merge(markerOptions, overrideOverlayOptions));

              Array.prototype.push.apply(userElements, [
                {element: userPath},
                {element: pathStartMarker}
              ]);
            }

            mapReady.promise.then(map => {
              if (userPath && !userPath.isRemoved) {
                userPath.setMap(map);
              }
              if (pathStartMarker && !pathStartMarker.isRemoved) {
                pathStartMarker.setMap(map);
              }
              if (!userMarker.isRemoved) {
                userMarker.setMap(map);
              }
            });
          });
        }

        function drawTempWorkers(tempWorkerData) {
          angular.forEach(tempWorkerData, worker => {
            let location = new google.maps.LatLng(worker.latitude, worker.longitude),
                workerColor = getUserColor(worker.masterUserId),
                scale = 0.5,
                workerMarker = getUserMarker(location, workerColor, 'TEST', scale);

            tempWorkerElements.push({element: workerMarker, listeners: null});

            mapReady.promise.then(map => {
              if (!workerMarker.isRemoved) {
                workerMarker.setMap(map);
              }
            });
          });
        }

        function getUserMarker(location, userColor, userName, scale) {
          let markerOptions = {
            position: location,
            zIndex: 4,
            icon: {
              path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
              fillColor: userColor,
              fillOpacity: 0.9,
              strokeColor: '#000',
              strokeWeight: 1,
              scale: scale
            }
          };

          const infoWindow = new google.maps.InfoWindow({
              content: userName,
          });

          const marker = new google.maps.Marker(angular.merge(markerOptions, overrideOverlayOptions));
          marker.addListener('mouseover', () => {
              mapReady.promise.then(map => {
                  infoWindow.open(map, marker);
              });
          });
          marker.addListener('mouseout', () => {
              infoWindow.close();
          });
          return marker;
        }

        function drawVineyards(vineyardData) {
          let handlerDefinitions = [
            {ev: 'click', handler: onVineyardClick},
            {ev: 'mouseover', handler: onVineyardMouseOver},
            {ev: 'mouseout', handler: onVineyardMouseOut}
          ];

          angular.forEach(vineyardData, (vineyard, id) => {
              if (vineyard.isActive) {
                  {
                      let vineyardId = parseInt(id, 10),
                          vineyardOptions = angular.merge(getVineyardOptions(vineyardId), overrideOverlayOptions),
                          outline,
                          columns = [];
                      // ignore vineyards that have no path set
                      if (vineyard.outline && vineyard.outline.path && vineyard.outline.path.length) {
                          outline = new google.maps.Polygon({
                              paths: vineyard.outline.path.map(point => {
                                  return {lat: point.latitude, lng: point.longitude};
                              }),
                              zIndex: 2
                          });
                          setElementOptions(outline, vineyardOptions);

                          let listeners = registerEventHandlers(handlerDefinitions, outline, vineyardId);

                          vineyardElements.push({element: outline, listeners: listeners, vineyardId: vineyardId});
                      }

                      if (vineyard.columns) {
                          angular.forEach(vineyard.columns, column => {
                              let columnPolygon = new google.maps.Polyline({
                                  path: column.path.map(point => {
                                      return {lat: point.latitude, lng: point.longitude};
                                  }),
                                  zIndex: 1
                              });
                              setElementOptions(columnPolygon, vineyardOptions);
                              vineyardElements.push({element: columnPolygon, vineyardId: vineyardId});
                              columns.push(columnPolygon);
                          });
                      }

                      mapReady.promise.then(map => {
                          if (outline && !outline.isRemoved) {
                              outline.setMap(map);
                          }
                          angular.forEach(columns, column => {
                              if (!column.isRemoved) {
                                  column.setMap(map);
                              }
                          });
                      });
                  }
              }
          });
        }

        function drawIcons(iconData) {
          angular.forEach(iconData, (icon, iconId) => {
            let iconOptions = getIconOptions(icon);

            // now draw the icon if we have a position
            if (iconOptions !== null) {
              let marker = new google.maps.Marker(iconOptions);

              if (angular.isDefined(icon.infoMessage)) {
                let infoWindow = new google.maps.InfoWindow({
                      content: $sanitize(icon.infoMessage)
                    }),
                    handlers;

                // we retrieve the map inside of the event handlers and not in the scope of
                // drawIcons because we have to make sure that drawIcons adds the icon elements
                // synchroniously to the iconElements array otherwise initial zooming for icon
                // only maps will not work
                if (icon.trigger === 'click') {
                  handlers = [
                    {ev: 'click', handler: () => {
                      mapReady.promise.then(map => {
                        infoWindow.open(map, marker);
                      });
                    }}
                  ];
                } else {
                  handlers = [
                    {ev: 'mouseover', handler: () => {
                      mapReady.promise.then(map => {
                        infoWindow.open(map, marker);
                      });
                    }},
                    {ev: 'mouseout', handler: () => infoWindow.close()},
                    {ev: 'click', handler: () => {
                      if (angular.isFunction(vm.onIconClick)) {
                        vm.onIconClick({iconId: iconId});
                      }
                    }}
                  ];
                }

                iconElements.push({
                  element: infoWindow,
                  listeners: registerEventHandlers(handlers, marker)
                });
              }

              iconElements.push({
                element: marker,
                // we are explicitely not converting the id to int here because we want
                // to allow non numeric icon ids for custom frontend generated icons that
                // should not clash with ids provided by the backend for pois
                iconId: iconId
              });

              mapReady.promise.then(map => {
                if (!marker.isRemoved) {
                  marker.setMap(map);
                }
              });
            }
          });
        }

        function getIconOptions(icon, overrideOptions = overrideOverlayOptions) {
          // first we need to determine the marker position
          let position = null,
              iconOptions = null;
          if (angular.isDefined(icon.position)) {
            // this can be done by specifying the position directly
            position = new google.maps.LatLng(icon.position.latitude, icon.position.longitude);
          } else if (angular.isDefined(icon.vineyardId)) {
            // or by specifying a vineyard whose center will become the icon position
            let vineyardId = parseInt(icon.vineyardId, 10);
            if (vineyardElementMapping[vineyardId]) {
              // the vineyard element mapping contains the outline polygon and the column polylines, we are only interested in the outlines
              let vineyardPolygon = vineyardElementMapping[vineyardId].filter(v => v.element instanceof google.maps.Polygon),
                  vineyardBounds = new google.maps.LatLngBounds();
              extendBounds(vineyardBounds, vineyardPolygon);
              position = vineyardBounds.getCenter();
            }
          }
          if (position !== null) {
            iconOptions = angular.merge(angular.copy(icon.options), overrideOptions);
            iconOptions.position = position;
          }
          return iconOptions;
        }

        function setElementOptions(element, opts) {
          let options = angular.copy(opts);
          if (element instanceof google.maps.Polygon) {
            element.setOptions(options);
          } else if (element instanceof google.maps.Polyline) {
            element.setOptions(options);
          } else if (element instanceof google.maps.Marker) {
            element.setOptions(options);
          } else if (element instanceof google.maps.InfoWindow) {
            // this is an expected element type, we just don't do anything with it
          } else {
            console.warn('Unkown type:', element);
          }
        }

        function registerEventHandlers(handlerDefnitions, element, elementId) {
          let listeners = [];
          angular.forEach(handlerDefnitions, ({ev, handler}) => {
            listeners.push(google.maps.event.addListener(element, ev, () => {
              $rootScope.$apply(() => handler(elementId));
            }));
          });
          return listeners;
        }

        function onVineyardMouseOver(vineyardId) {
          if (angular.isFunction(vm.onVineyardMouseOver)) {
            vm.onVineyardMouseOver({vineyardId: vineyardId});
          }
        }

        function onVineyardMouseOut(vineyardId) {
          if (angular.isFunction(vm.onVineyardMouseOut)) {
            vm.onVineyardMouseOut({vineyardId: vineyardId});
          }
        }

        function onVineyardClick(vineyardId) {
          if (angular.isFunction(vm.onVineyardClick)) {
            vm.onVineyardClick({vineyardId: vineyardId});
          }
          if (vm.selectionMode && angular.isFunction(vm.onVineyardsSelected)) {
            vm.onVineyardsSelected({vineyardIds: [vineyardId], ctrlPressed: ctrlPressed, singleVineyard: true});
          }
        }

        function extendBounds(bounds, elements) {
          angular.forEach(elements, ({element}) => {
            if (element instanceof google.maps.Marker) {
              bounds.extend(element.getPosition());
            } else if (element instanceof google.maps.Polygon || element instanceof google.maps.Polyline) {
              element.getPath().forEach(point => bounds.extend(point));
            } else if (element instanceof google.maps.InfoWindow) {
              // ignore this as a info window will always be anchored at a marker
            } else {
              console.warn('Unkown type:', element);
            }
          });
        }

        function clearElements(elements) {
          while (elements.length > 0) {
            let {element, listeners} = elements.pop();
            if (element instanceof google.maps.InfoWindow) {
              element.close();
            } else {
              element.setMap(null);
              // it is possible that promise handlers are still queued which would reset the
              // map on this element and therefore create a phantom element on the map
              // to make sure any potential promise handler knows that the element is actually
              // removed we set an appropriate instance attribute which will be checked
              // by the handler
              element.isRemoved = true;
            }
            angular.forEach(listeners, listener => listener.remove());
          }
        }

        function getUserColor(userId) {
          if (angular.isFunction(vm.determineUserColor)) {
            return vm.determineUserColor({userId: userId});
          }
          return '#000';
        }

        function getVineyardOptions(vineyardId) {
          if (angular.isFunction(vm.determineVineyardOptions)) {
            return vm.determineVineyardOptions({vineyardId: vineyardId});
          }
          return MapHelper.getVineyardOptions(null, 'selected');
        }

        function setCtrlPressed(pressed) {
          ctrlPressed = pressed;
        }
      },
      link(scope, element, attrs, controller) {
        $document[0].addEventListener('keydown', checkCtrlPressed);
        $document[0].addEventListener('click', checkCtrlPressed);
        $document[0].addEventListener('mouseup', checkCtrlPressed);
        $document[0].addEventListener('mousedown', checkCtrlPressed);
        $document[0].addEventListener('keyup', checkCtrlReleased);
        $document[0].addEventListener('click', checkCtrlReleased);
        $document[0].addEventListener('mouseup', checkCtrlReleased);
        $document[0].addEventListener('mousedown', checkCtrlReleased);

        scope.$on('$destroy', () => {
          $document[0].removeEventListener('keydown', checkCtrlPressed);
          $document[0].removeEventListener('click', checkCtrlPressed);
          $document[0].removeEventListener('mouseup', checkCtrlPressed);
          $document[0].removeEventListener('mousedown', checkCtrlPressed);
          $document[0].removeEventListener('keyup', checkCtrlReleased);
          $document[0].removeEventListener('click', checkCtrlReleased);
          $document[0].removeEventListener('mouseup', checkCtrlReleased);
          $document[0].removeEventListener('mousedown', checkCtrlReleased);
        });

        function checkCtrlPressed(event) {
          // The obvious way to check if the control key is pressed is to listen to the
          // keydown event and see if the key pressed was the control key.
          // This approach does not work though if the user pressed the control key outside
          // of the browser window, moves the mouse into the window and expects the control
          // key to register as pressed anyway. To handle this case too we also listen for
          // all click related events and check the event to see if the control key was pressed
          // when the click happened. This way we see the pressed control key at the very
          // latest when the user clicks into the map.
          if (
            event.type === 'keydown' && (event.key === 'Control' || event.ctrlKey) ||
            (event.type === 'click' || event.type === 'mouseup' || event.type === 'mousedown') && event.ctrlKey
          ) {
            scope.$apply(() => controller.setCtrlPressed(true));
          }
        }

        function checkCtrlReleased(event) {
          // The same considerations as for registering a pressed control key need to be taken
          // into account for releasing it. The user might release the control key outside of
          // the browser window, so we use the same approach as above.
          if (
            event.type === 'keyup' && (event.key === 'Control' || event.ctrlKey) ||
            (event.type === 'click' || event.type === 'mouseup' || event.type === 'mousedown') && !event.ctrlKey
          ) {
            scope.$apply(() => controller.setCtrlPressed(false));
          }
        }
      }
    };
  }
}());

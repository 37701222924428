(function () {
  'use strict';

  angular
    .module('admin.customers')
    .config(config);

  function config($stateProvider, permissions) {
    $stateProvider
      .state('customers', {
        url: '/customers',
        abstract: true,
        template: '<ui-view />',
        data: {requiredPerms: [permissions.ADMIN_CAN_USE]}
      });
  }
}());

<!-- VINEYARD NEW -->
<div class="form-group"
     ng-class="{
       'has-error': formAssignments.vineyards.$invalid
     }">
    <label class="control-label">{{'ORDER_VINEYARD' | translate}}</label>
    <ng-include src="'orders/edit/views/edit-parcel-selection.tpl.html'"></ng-include>
</div>
</div>
<!-- ASSIGNED USERS -->
<div class="form-group"
     ng-if="!edit.isCreate && (edit.type === 'regular' || edit.type === 'finished')"
     ng-class="{
       'has-error': formAssignments.selectedUsers.$invalid
     }">
    <label class="control-label">{{'ORDER_ASSIGNED_USERS' | translate}}</label>
    <div class="list-group"
         ng-if="edit.users.length && (edit.users|filter:{assigned: true}).length">
        <div class="list-group-item"
             ng-repeat="user in edit.users|filter:{assigned: true}">
            <div class="checkbox-inline">
                <input type="checkbox"
                       ng-disabled="(!edit.isCreate && !edit.canEdit) || !edit.canEditReadOnlyOrder()"
                       ng-model="user.track"
                       ng-click="edit.getProgress();">
                <span style="color: {{user.color}};">
                    {{user.lastname}}, {{user.firstname}}
                    <span ng-if="!user.isActive"> ({{'ADMIN_CUSTOMER_INFO_INACTIVE' | translate}})</span>
                </span>
            </div>
            <span class="badge"
                  ng-if="edit.canAssignUsers && edit.canEdit"
                  ng-click="user.assigned = false; edit.assignUser = null; edit.userWasRemoved = true; formAssignments.$setDirty()">
        <span class="glyphicon glyphicon-remove"
              uib-tooltip="{{'TOOLTIP_USER_REMOVE' | translate }}"
              tooltip-placement="left"
              tooltip-append-to-body="true"></span>
      </span>
        </div>
    </div>
    <div ng-if="edit.canAssignUsers && edit.canEdit && edit.users.length && (edit.users|filter:{assigned: false, active: true, isActive: true, customerId: edit.order.customerId}).length">
        <select class="form-control"
                ng-model="edit.assignUser"
                ng-options="user as (user.lastname + ', ' + user.firstname + (!user.canDoOrders ? ' (*)' : '')) disable when !user.canDoOrders for user in edit.users|filter:{assigned: false, isActive: true, active: true, customerId: edit.order.customerId}"
                ng-change="edit.assignUser.assigned = true"
                ng-disabled="!edit.canEditReadOnlyOrder()">
            <option value="">{{'CHOOSE_TO_ADD_USER' | translate}}</option>
        </select>
        <p class="help-block"
           ng-if="edit.userWasRemoved">
            <span class="text-danger">{{'ORDER_USER_WAS_REMOVED' | translate }}</span>
        </p>
        <p class="help-block">
            {{'FORM_HELP_DISABLED_USERS' | translate}}
        </p>
    </div>
    <input type="hidden" name="selectedUsers" ng-model="edit.multiVineyardCase">
</div>
<div class="alert alert-warning"
     role="alert"
     ng-if="!edit.isCreate && (edit.type === 'regular' || edit.type === 'finished') && edit.canAssignUsers && !edit.users.length">
    {{'ORDER_NO_USERS_FOUND_WARNING' | translate}}
</div>
<div class="alert alert-warning"
     role="alert"
     ng-if="!edit.isCreate && (edit.type === 'regular' || edit.type === 'finished') && !edit.canAssignUsers && !(edit.users|filter:{assigned: true}).length">
    {{'ORDER_NO_USERS_ASSIGNED' | translate}}
</div>

<!-- ASSIGNED EQUIPMENT -->
<div class="form-group equipment-assignments"
     ng-if="!edit.isCreate && (edit.type === 'regular' || edit.type === 'finished')">
    <label class="control-label">{{'ORDER_ASSIGNED_EQUIPMENTS' | translate}}</label>
    <div class="input-group-item list-group"
         ng-if="edit.equipment.length && (edit.equipment|filter:{assigned: true}).length">
        <div class="input-group"
             ng-repeat="equipment in edit.equipment|filter:{assigned: true}"
             ng-class="{'has-error': formAssignments[equipment.id].$invalid}">
            <span class="input-group-addon">
                <div class="inline-flex-1">
                    <span>{{equipment.label}}</span>
                    <span ng-if="equipment.type.label !== 'INVALID'"
                          ng-click="edit.showEquipment(equipment)"
                          class="hover glyphicon pull-right text-muted glyphicon-time"></span>
                </div>
            </span>
            <select ng-if="edit.canAssignUserToEquipment(equipment)" class="form-control"
                    ng-model="equipment.assignedUser"
                    name="{{equipment.id}}"
                    ng-disabled="!edit.canEdit || !edit.canEditReadOnlyOrder()"
                    ng-options="user as (user.lastname + ', ' + user.firstname) for user in edit.users |filter:{assigned: true}"
                    required>
                <!-- ng-options="user as (user.lastname + ', ' + user.firstname) for user in edit.users |filter:{assigned: true}"-->
                <option value="">{{'CHOOSE_TO_ADD_USER' | translate}}</option>
            </select>
            <span ng-if="!edit.canAssignUserToEquipment(equipment)"
                  class="form-control">{{'DEVICE_ALREADY_ASSIGNED' | translate}}</span>
            <span class="input-group-btn" ng-if="edit.canEdit">
                <button class="btn btn-default"
                        ng-disabled="!edit.canAssignUserToEquipment(equipment)"
                        ng-class="formAssignments[equipment.id].$invalid ? 'btn-danger' : 'btn-default'"
                        type="button"
                        ng-click="equipment.assigned = false; edit.assignEquipment = null; formAssignments.$setDirty();">
                      <span class="glyphicon glyphicon-remove"
                            uib-tooltip="{{'TOOLTIP_EQUIPMENT_REMOVE' | translate }}"
                            tooltip-placement="left"
                            tooltip-append-to-body="true">
                      </span>
                </button>
            </span>
        </div>
    </div>
    <select class="form-control"
            ng-if="edit.equipment.length && (edit.equipment|filter:{assigned: false}).length && edit.canEdit"
            ng-model="edit.assignEquipment"
            ng-options="equipment as (equipment.label + '' + (equipment.type.label === 'INVALID' ? ' (*)' : '')) disable when equipment.type.label === 'INVALID' for equipment in edit.equipment|filter:{assigned: false}"
            ng-change="(edit.assignEquipment.assigned = true) && (edit.assignEquipment.alreadyAssigned = true)"
            ng-disabled="!edit.canEditReadOnlyOrder()">
        <option value="">{{'CHOOSE_TO_ADD_EQUIPMENT' | translate}}</option>
    </select>
    <p class="help-block">
        {{'equipment.formHelp.invalidType' | translate}}
    </p>
</div>
<div class="alert alert-warning"
     role="alert"
     ng-if="!edit.isCreate && (edit.type === 'regular' || edit.type === 'finished') && !edit.equipment.length">
    {{'ORDER_NO_EQUIPMENT_FOUND_WARNING' | translate}}
</div>

<!-- ASSIGNED USER PER SITE -->
<div class="form-group equipment-assignments"
     ng-if="!edit.isCreate && (edit.type === 'regular' || edit.type === 'finished')">
    <label class="control-label">{{'ORDER_ASSIGNED_USER_PER_SITE' | translate}}</label>
    <div class="input-group-item list-group"
         ng-if="edit.userPerSite.length && (edit.userPerSite|filter:{assigned: true}).length">
        <div class="input-group"
             ng-repeat="userPerSiteEntry in edit.userPerSite|filter:{assigned: true}"
             ng-class="{'has-error': formAssignments[userPerSiteEntry.site.id].$invalid}">
            <span class="input-group-addon">
                <div class="inline-flex-1">
                    <span>{{userPerSiteEntry.site.label}}</span>
                </div>
            </span>
            <select ng-if="edit.canAssignUserToSite(userPerSiteEntry)" class="form-control"
                    ng-model="userPerSiteEntry.user"
                    name="{{userPerSiteEntry.site.id}}"
                    ng-disabled="!edit.canEdit || !edit.canEditReadOnlyOrder()"
                    ng-options="user as (user.lastname + ', ' + user.firstname) for user in edit.users |filter:{assigned: true}"
                    required>
                <!-- ng-options="user as (user.lastname + ', ' + user.firstname) for user in edit.users |filter:{assigned: true}"-->
                <option value="">{{'CHOOSE_TO_ADD_USER' | translate}}</option>
            </select>
            <span ng-if="!edit.canAssignUserToSite(userPerSiteEntry)"
                  class="form-control">{{'USER_PER_SITE_ALREADY_ASSIGNED' | translate}}</span>
            <span class="input-group-btn" ng-if="edit.canEdit">
                <button class="btn btn-default"
                        ng-disabled="!edit.canAssignUserToSite(userPerSiteEntry)"
                        ng-class="formAssignments[userPerSiteEntry.site.id].$invalid ? 'btn-danger' : 'btn-default'"
                        type="button"
                        ng-click="userPerSiteEntry.assigned = false; edit.assignSite = null; formAssignments.$setDirty();">
                      <span class="glyphicon glyphicon-remove"
                            uib-tooltip="{{'TOOLTIP_USER_PER_SITE_REMOVE' | translate }}"
                            tooltip-placement="left"
                            tooltip-append-to-body="true">
                      </span>
                </button>
            </span>
        </div>
    </div>
    <select class="form-control"
            ng-if="edit.userPerSite.length && edit.canEdit"
            ng-model="edit.assignSite"
            ng-options="userPerSite as (userPerSite.site.label) for userPerSite in edit.userPerSite|filter:{assigned: false}"
            ng-change="(edit.assignSite.assigned = true) && (edit.assignSite.alreadyAssigned = true)"
            ng-disabled="!edit.canEditReadOnlyOrder()">
        <option value="">{{'CHOOSE_TO_ADD_SITE' | translate}}</option>
    </select>
</div>
<div class="alert alert-warning"
     role="alert"
     ng-if="!edit.isCreate && (edit.type === 'regular' || edit.type === 'finished') && !edit.userPerSite.length">
    {{'ORDER_NO_SITE_FOUND_WARNING' | translate}}
</div>

<div class="row" ng-if="!edit.shapeUpload.outcomeHeader">
    <div class="col-md-3">

        <h3>Select files</h3>

        <input type="file"
               id="shape-file-upload"
               nv-file-select=""
               uploader="edit.shapeMultiUploader"
               multiple/><br/>
    </div>

    <div class="col-md-9" style="margin-bottom: 40px">

        <h3>Upload queue</h3>
        <p>Queue length: {{ edit.shapeMultiUploader.queue.length }}</p>

        <table class="table">
            <thead>
            <tr>
                <th width="50%">Name</th>
                <th ng-show="edit.shapeMultiUploader.isHTML5">Size</th>
                <th ng-show="edit.shapeMultiUploader.isHTML5">Progress</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr ng-repeat="item in edit.shapeMultiUploader.queue">
                <td><strong>{{ item.file.name }}</strong></td>
                <td ng-show="edit.shapeMultiUploader.isHTML5" nowrap>{{ item.file.size/1024/1024|number:2 }} MB</td>
                <td ng-show="edit.shapeMultiUploader.isHTML5">
                    <div class="progress" style="margin-bottom: 0;">
                        <div class="progress-bar" role="progressbar" ng-style="{ 'width': item.progress + '%' }"></div>
                    </div>
                </td>
                <td class="text-center">
                    <span ng-show="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                    <span ng-show="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
                    <span ng-show="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                </td>
            </tr>
            </tbody>
        </table>

        <div>
            <div>
                Queue progress:
                <div class="progress" style="">
                    <div class="progress-bar" role="progressbar" ng-style="{ 'width': edit.shapeMultiUploader.progress + '%' }"></div>
                </div>
            </div>
            <button type="button" class="btn btn-success btn-s" ng-click="edit.shapeMultiUploader.uploadAll()" ng-disabled="!edit.shapeMultiUploader.getNotUploadedItems().length">
                <span class="glyphicon glyphicon-upload"></span> Upload all
            </button>
            <button type="button" class="btn btn-warning btn-s" ng-click="edit.shapeMultiUploader.cancelAll()" ng-disabled="!edit.shapeMultiUploader.isUploading">
                <span class="glyphicon glyphicon-ban-circle"></span> Cancel all
            </button>
            <button type="button" class="btn btn-danger btn-s" ng-click="edit.deleteFiles()" ng-disabled="!edit.shapeMultiUploader.queue.length">
                <span class="glyphicon glyphicon-trash"></span> Remove all
            </button>
        </div>
    </div>
    <br/>
    <!--Business-->
    <ng-form name="shapeUploadform" id="upload_complete" ng-if="edit.shapeMultiUploader.queue.length">
        <div class="form-group"
             ng-class="{
                 'has-error': shapeUploadform.businessId.$invalid
               }">
            <label class="control-label">{{'PARCELS_PARAM_WINERY' | translate}}</label>
            <select class="form-control"
                    ng-options="business.id as business.label for business in edit.businesses"
                    ng-model="edit.shapeUpload.businessId"
                    name="businessId"
                    ng-disabled="!edit.fullEditAvailable"
                    required>
                <option value=""
                        ng-if="!edit.shapeUpload.businessId">{{'MAKE_CHOICE' | translate}}
                </option>
            </select>
        </div>
        <!--flurstücknummer-->
        <div class="form-group">
            <label class="control-label">{{'PARCEL_FLURNUMBER' | translate}}</label>
            <select class="form-control"
                    ng-options="selection.key as selection.value for selection in edit.shapeUpload.selectionList"
                    ng-model="edit.shapeUpload.number"
                    name="number"
                    ng-disabled="!edit.fullEditAvailable"
                    >
                <option value=""
                        ng-if="!edit.shapeUpload.number">{{'MAKE_CHOICE' | translate}}
                </option>
            </select>
        </div>
        <!--label-->
        <div class="form-group"
             ng-class="{
                 'has-error': shapeUploadform.label.$invalid
               }">
            <label class="control-label">{{'PARCEL_LABEL' | translate}}</label>
            <select class="form-control"
                    ng-options="selection.key as selection.value for selection in edit.shapeUpload.selectionList"
                    ng-model="edit.shapeUpload.label"
                    name="label"
                    ng-disabled="!edit.fullEditAvailable"
                    required>
                <option value=""
                        ng-if="!edit.shapeUpload.label">{{'MAKE_CHOICE' | translate}}
                </option>
            </select>
        </div>
        <!--Area-->
        <div class="form-group">
            <label class="control-label">{{'PARCELS_PARAM_AREA' | translate}}</label>
            <select class="form-control"
                    ng-options="selection.key as selection.value for selection in edit.shapeUpload.selectionList"
                    ng-model="edit.shapeUpload.area"
                    name="business"
                    ng-disabled="!edit.fullEditAvailable"
                    >
                <option value=""
                        ng-if="!edit.shapeUpload.area">{{'MAKE_CHOICE' | translate}}
                </option>
            </select>
        </div>
        <!--Flik-->
        <div class="form-group">
            <label class="control-label">{{'PARCELS_PARAM_FLIK' | translate}}</label>
            <select class="form-control"
                    ng-options="selection.key as selection.value for selection in edit.shapeUpload.selectionList"
                    ng-model="edit.shapeUpload.flik"
                    name="business"
                    ng-disabled="!edit.fullEditAvailable"
                    >
                <option value=""
                        ng-if="!edit.shapeUpload.flik">{{'MAKE_CHOICE' | translate}}
                </option>
            </select>
        </div>
        <!--Rebe-->
        <div class="form-group">
            <label class="control-label">{{'PARCELS_PARAM_WINE_THIRD' | translate}}</label>
            <select class="form-control"
                    ng-options="selection.key as selection.value for selection in edit.shapeUpload.selectionList"
                    ng-model="edit.shapeUpload.rebe"
                    name="business"
                    ng-disabled="!edit.fullEditAvailable"
                    >
                <option value=""
                        ng-if="!edit.shapeUpload.rebe">{{'MAKE_CHOICE' | translate}}
                </option>
            </select>
        </div>
        <button ng-if="!edit.shapeUpload.outcomeHeader"
                type="button"
                class="btn btn-primary ng-binding"
                ng-disabled="shapeUploadform.$invalid"
                ng-click="edit.shapeUploadDo();">{{'CONTINUE' | translate}}</button>
    </ng-form>
</div>
<div class = "ng-binding">{{edit.shapeUpload.outcomeHeader | translate}} </div>
</div>

<button ng-if="!edit.shapeUpload.outcomeMessage && !edit.shapeUpload.outcomeHeader==''"  type="button" class="btn btn-primary ng-binding" ng-click="edit.showResultingParcels();">{{'BATCH_EDITING_FINISH_VINEYARDS' | translate}}</button>
<div class = "ng-binding"> {{edit.shapeUpload.outcomeMessage}} </div>

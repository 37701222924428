(function () {
  'use strict';

  angular
    .module('parcels.edit.orders')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('parcels.edit.orders', {
        abstract: true,
        url: '/orders',
        template: '<ui-view></ui-view>'
      });
  }
}());

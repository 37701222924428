(function () {
  'use strict';

  /* @ngdoc object
   * @name fertilization.phDemandExports
   * @description
   *
   */
  angular
    .module('fertilization.phDemandExports', [
      'ui.router',
      'fertilization.phDemandExports.edit'
    ]);
}());

(function () {
  'use strict';

  class UsersDeleteCtrl {
    constructor(item) {
      let vm = this;
      vm.item = item;
    }
  }

  UsersDeleteCtrl.$inject = ['item'];

  /**
   * @ngdoc object
   * @name users.delete.controller:UsersDeleteCtrl
   *
   * @description
   *
   */
  angular
    .module('users.delete')
    .controller('UsersDeleteCtrl', UsersDeleteCtrl);
}());

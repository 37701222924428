(function () {
  'use strict';

  class TemporaryWorkersDeleteCtrl {
    constructor(item) {
      let vm = this;
      vm.item = item;
    }
  }

  /**
   * @ngdoc object
   * @name temporaryWorkers.delete.controller:TemporaryWorkersDeleteCtrl
   *
   * @description
   *
   */
  angular
    .module('temporaryWorkers.delete')
    .controller('TemporaryWorkersDeleteCtrl', TemporaryWorkersDeleteCtrl);
}());


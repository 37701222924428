(function () {
    'use strict';

    class OrdersEditCtrl {
        constructor(
            $scope,
            $q,
            $http,
            $state,
            $stateParams,
            $interval,
            $filter,
            OrderTypeHelper,
            UserService,
            CrudApi,
            colorConstants,
            CustomerMapDataService,
            permissions,
            ModuleService,
            userSiteStatus,
            orderStatus,
            ParcelStatus,
            additionalDataDataTypes,
            PoiHelper,
            $uibModal,
            MapHelper,
            chunkByFilter,
            CustomerMapDataTemporaryService,
            siteEntities
        ) {
            let vm = this,
                CrudApiIgnoreSpinner = CrudApi.newInstance({
                    get: {
                        method: 'GET',
                        ignoreSpinner: true
                    },
                    save: {
                        method: 'POST',
                        ignoreSpinner: true
                    }
                }),
                // helper variables to save previous state when switching between temporary and non
                // temporary vineyards
                previousTemporaryVineyardPolygons = null,
                previousSiteIdSelection = null;


            vm.pathtime = {};
            vm.accountId = UserService.user.accountId;
            vm.canReadGPSProfile = UserService.hasPerm(permissions.BASE_CAN_READ_GPS_PROFILE);

            // Initialize Selection
            vm.selectedSites = [];

            // Initialize AreaSums
            vm.areaSums = {};

            vm.additionalDataMap = new Map();

            // the current additional data for this order
            vm.additionalData = {};

            // Constants for vineyard status
            vm.userSiteStatus = userSiteStatus;

            // Bind parcel status to scope, so we can use getVineyardColor on maps directive
            vm.parcelStatus = ParcelStatus;

            // A color is picked per assigned employee and stored in an object for lookup
            vm.colors = {};

            vm.userNames = {};

            // slider init for timestamp sliders
            vm.slider = {};

            vm.userPerSite = [];

            vm.isEmpty = (obj) => {
                return Object.keys(obj).length === 0;
            };

            vm.orderVisibilityModeIcon = (item) => {
                if (item?.orderVisibilityMode === 'ACCOUNT') {
                    return 'images/orderReadWrite.svg';
                } else if (item?.orderVisibilityMode === 'ACCOUNT_READ_ONLY') {
                    return 'images/orderReadOnly.svg';
                } else {
                    return 'images/orderTenant.svg';
                }
            };

            vm.getUserName = (users, userId, name) => {
                for (let i = 0; i <= users.length; i++) {
                    if (users[i].id === userId) {
                        return users[i][name];
                    }
                }
            };

            $scope.$watch('edit.selectedSites', () => {
                const newUserPerSite = [];
                vm.selectedSites.forEach(selectedSite => {
                    if (!vm.order.assignedUsersPerSite) {
                        vm.order.assignedUsersPerSite = [];
                    }
                    const savedEntry = vm.order.assignedUsersPerSite.find(o => o.siteId === selectedSite.id);
                    if (savedEntry) {
                        newUserPerSite.push(
                            {
                                site: selectedSite,
                                user: vm.users.find(user => user.id === savedEntry.userId),
                                assigned: true,
                                alreadyAssigned: true,
                            }
                        );
                    } else {
                        newUserPerSite.push(
                            {
                                site: selectedSite,
                                user: undefined,
                                assigned: false,
                                alreadyAssigned: false,
                            }
                        );
                    }
                });
                vm.userPerSite.forEach(oldUserPerSite => {
                    const entry = newUserPerSite.find(o => o.site.id === oldUserPerSite.site.id);
                    if (entry) {
                        entry.user = oldUserPerSite.user;
                        entry.assigned = oldUserPerSite.assigned;
                        entry.alreadyAssigned = oldUserPerSite.alreadyAssigned;
                    }
                });
                vm.userPerSite = newUserPerSite;
            });


            vm.msToTime = (duration) => {
                if (angular.isDefined(duration)) {
                    //var milliseconds = parseInt((duration%1000)/100)
                    var seconds = parseInt((duration / 1000) % 60)
                        , minutes = parseInt((duration / (1000 * 60)) % 60)
                        , hours = parseInt((duration / (1000 * 60 * 60)) % 24)
                        , days = parseInt(duration / (1000 * 60 * 60 * 24));

                    hours = (hours < 10) ? '0' + hours : hours;
                    minutes = (minutes < 10) ? '0' + minutes : minutes;
                    seconds = (seconds < 10) ? '0' + seconds : seconds;

                    return days + 'Tage ' + hours + ':' + minutes + ':' + seconds;
                }
            };

            vm.showEquipment = (equipment) => {
                window.location.assign('/equipment/detail/' + equipment.uuid);
            };

            vm.pathTimeSummary = () => {
                let travelDuration = 0, overallTime = 0, workDuration = 0;
                angular.forEach(vm.pathtime, (pathTimeStruct) => {
                    travelDuration = travelDuration + pathTimeStruct.travelDuration;
                    overallTime = overallTime + pathTimeStruct.overallTime;
                    workDuration = workDuration + pathTimeStruct.workDuration;
                });
                vm.pathTimeSummary = {
                    travelDuration: travelDuration,
                    workDuration: workDuration,
                    overallTime: overallTime,
                    travelDurationNorm: (travelDuration / overallTime * 100).toFixed(2),
                    workDurationNorm: (workDuration / overallTime * 100).toFixed(2),
                    overallTimeNorm: (100).toFixed(2)
                };
            };

            /**
             * function to calculate the travelDuration and workDuration given the userID
             * The function uses the masterUsersRaw, which contains a list of coordinates per user.
             * It adds up the time differences between coordinates.
             * @param userId
             */
            vm.pathtimeCalculations = (userId) => {
                if (angular.isDefined(vm.masterUsersRaw[userId])) {
                    let prevEntry = vm.masterUsersRaw[userId][0],
                        travelDuration = 0,
                        workDuration = 0,
                        timeInBetweenTask = 0;
                    for (let i = 1; i < vm.masterUsersRaw[userId].length; i++) {
                        const entry = vm.masterUsersRaw[userId][i];
                        // to prevent that coordinates between breaks or even workdays are counted (1200000 corresponds to 20 minutes -> time / 60 seconds / 1000 nanoseconds)
                        if (new Date(entry.capturedDate).getTime() - new Date(prevEntry.capturedDate).getTime() < 1200000) {
                            //if both coordinates are inside the field the user is considered working
                            if (!prevEntry.traveling && !entry.traveling) {
                                workDuration = workDuration + new Date(entry.capturedDate).getTime() - new Date(prevEntry.capturedDate).getTime();
                            } else {
                                travelDuration = travelDuration + new Date(entry.capturedDate).getTime() - new Date(prevEntry.capturedDate).getTime();

                            }
                        } else {
                            timeInBetweenTask = timeInBetweenTask + new Date(entry.capturedDate).getTime() - new Date(prevEntry.capturedDate).getTime();
                        }
                        prevEntry = entry;
                    }
                    vm.pathtime[userId] = {
                        travelDuration: travelDuration,
                        overallTime: new Date(vm.masterUsersRaw[userId][vm.masterUsersRaw[userId].length - 1].capturedDate).getTime() - new Date(vm.masterUsersRaw[userId][0].capturedDate).getTime() - timeInBetweenTask,
                        workDuration: workDuration
                    };
                }
            };

            /**
             * Sets the options for the slider in the pathTime view.
             * @param userId
             * @returns {{noSwitching: boolean, onChange: onChange, stepsArray: *, draggableRange: boolean, translate: translate}}
             */
            vm.getSliderOptions = (userId) => {
                if (angular.isUndefined(vm.masterUsersRaw[userId])) {
                    vm.getProgress();
                }
                return {
                    noSwitching: true,
                    draggableRange: true,
                    stepsArray: vm.masterUsersRaw[userId].map(coordinate => coordinate.capturedDate),
                    translate: function (date) {
                        if (date !== null) {
                            const options = {
                                second: '2-digit',
                                minute: '2-digit',
                                hour: '2-digit',
                                day: '2-digit',
                                month: '2-digit',
                                year: '2-digit'
                            };
                            date = new Date(date);
                            return date.toLocaleString('de-DE', options);
                            // return ('0'+date.getHours()).slice(-2) + ':' +
                            //        ('0'+date.getMinutes()).slice(-2) + ':' +
                            //        ('0'+date.getSeconds()).slice(-2) + ', ' +
                            //        ('0'+date.getDay()).slice(-2) + '.' +
                            //        ('0'+(date.getMonth()+1)).slice(-2) + '.' +
                            //        date.getFullYear();
                        }
                        return '';
                    },
                    onChange: () => {
                        vm.masterUsers = filterCoordinatesAccordingToSlider();
                    }
                };
            };

            function filterCoordinatesAccordingToSlider() {
                let masterUsersTmp = {};
                angular.forEach(vm.masterUsersRaw, (coordinates, userId) => {
                    if (angular.isDefined(coordinates)) {
                        let coordinatesList = [];
                        for (let i = 0; i < coordinates.length; i++) {
                            const coordinate = coordinates[i];
                            if (vm.sliderMin[userId] <= coordinate.capturedDate && coordinate.capturedDate <= vm.sliderMax[userId]) {
                                coordinatesList.push(coordinate);
                            }
                        }
                        masterUsersTmp[userId] = coordinatesList;
                    }
                });
                return masterUsersTmp;
            }

            vm.canAssignUserToEquipment = (equipment) => {
                return equipment.alreadyAssigned;
            };

            vm.canAssignUserToSite = (site) => {
                return site.alreadyAssigned;
            };

            vm.sliderOptions = {};
            vm.sliderMin = {};
            vm.sliderMax = {};
            vm.getMasterUserCoordinate = (userId, bo) => {
                if (angular.isUndefined(vm.masterUsersRaw[userId])) {
                    return new Date();
                }
                if (bo === 0) {
                    return vm.masterUsersRaw[userId][0].capturedDate;
                } else {
                    return vm.masterUsersRaw[userId][vm.masterUsersRaw[userId].length - 1].capturedDate;
                }
            };

            // Flag for "new order" or "edit order"
            vm.isCreate = angular.isUndefined($stateParams.id);

            // flag to determine whether the selection directive starts in edit mode
            // this might get reset later when switching between temporary vineyards and not
            vm.editSelection = vm.isCreate;

            // check whether the user can edit the order or not according to order visibility mode
            vm.canEditReadOnlyOrder = () => {
                if (vm.order !== undefined) {
                    if (vm.order.orderVisibilityMode !== 'ACCOUNT_READ_ONLY') {
                        return true;
                    } else {
                        let authToken = UserService.getAuthToken();
                        let decodedToken = UserService.decodeToken(authToken.accessToken);
                        return vm.order.customerId === decodedToken.customerId;
                    }
                }
            };

            vm.canEditAssignedSites = () => {
                const currentOrderType = vm.getOrderTypeOfOrder() || {group: ''};
                return (currentOrderType.group !== 'CLASSIFICATION') && (vm.order.orderVisibilityMode === 'TENANT' || vm.order.orderVisibilityMode === 'ACCOUNT');
            };

            vm.canEditType = () => {
                const currentOrderType = vm.getOrderTypeOfOrder() || {group: ''};
                return (!vm.isCreate && currentOrderType.group !== 'CLASSIFICATION');
            };

            vm.finishStrategyFixed = () => {
                if (vm.order !== undefined) {
                    return vm.order.finishStrategy === 'fixed';
                }
            };

            vm.isReadOnlyOrder = () => {
                return !vm.canEditReadOnlyOrder();
            };

            // Rights
            vm.canAssignUsers = !vm.isCreate && UserService.hasPerm(
                permissions.BASE_CAN_ASSIGN_USER_TO_ORDER);
            vm.canMarkRedo = UserService.hasPerm(
                permissions.BASE_CAN_MARK_VINEYARD_AS_REDO);
            vm.canCreateOrderTypes = UserService.canAccessState('orderTypes.create');
            vm.canDeleteImage = UserService.hasPerm(permissions.BASE_CAN_DELETE_FILE);
            vm.canDownloadImage = UserService.hasPerm(
                permissions.BASE_CAN_DOWNLOAD_FILE);
            vm.canCreateTemporaySite = UserService.hasPerm(
                permissions.BASE_CAN_CREATE_TEMPORARY_SITE);
            vm.canUsePathTime = UserService.hasPerm(permissions.PATHTIME_CAN_USE);
            // check whether the user can see and edit visibility mode
            vm.canEditVisibility = UserService.hasPerm(permissions.ADMIN_CAN_EDIT_VISIBILITY_MODE);

            // Initial message
            vm.message = $stateParams.message;

            // Determine type of order (cyclic or not)
            vm.type = $stateParams.type;
            vm.isCyclic = vm.type === 'cyclic'

            // Serves as minimal date for the datepicker
            vm.today = new Date();
            vm.masterUserstmp = {};
            // we only save which vineyards should get REDO instead of setting the status directly in the siteStatus object of the order,
            // because we will recreate the siteStatus list of only the vineyards that should be redone before saving.
            // We do this so we don't trigger a REDO on a vineyard that the user did not explicitely request. This could happen
            // if the initial state of the vineyard that is ruserideturned from the backend was REDO already.
            vm.redoVineyards = {};

            // this determines whether the map zooms when status data changes, initially this is true
            // as we want to zoom the map on initial load. later this will be disabled
            vm.zoomToSelection = true;

            // necessary options for map edit directive
            vm.polygonOptions = MapHelper.getVineyardOptions(null, 'selected', false,
                {editable: vm.canCreateTemporaySite});
            vm.drawingControlOptions = {
                drawingModes: ['polygon'],
                position: 2
            };

            // fetch all Orders
            if (!vm.isCreate) {
                vm.orders = [];
                $http.get('/api/v1/orders/' + $stateParams.id + '/workflow/options').then(function (response) {
                    vm.orders = response.data;
                });
            }

            // special function for handling vineyard clicks on the map to ignore clicks on temporary vineyards
            vm.onVineyardClick = onVineyardClick;

            // Fetch order types
            OrderTypeHelper.ready().then(() => {
                vm.orderTypes = CrudApi.query({
                    entity: 'orderTypes'
                }, (types) => {
                    types.forEach((type) => {
                        // Check if the user can create a task of the given type. If not so, the type
                        // will be filtered in template
                        type.canCreate = (
                                vm.isCreate &&
                                UserService.hasPerm(type.permissionCreate) &&
                                type.label !== 'BONITUR') ||
                            !vm.isCreate;
                        // Prepare labels and grouplabels for optgroups
                        type.labelProcessed = OrderTypeHelper.getLabel(type);
                        type.groupLabel = OrderTypeHelper.getGroupLabel(type);
                        type.disabled = type.label === 'BONITUR' || type.label === 'CLASSIFICATION' || type.label === 'CUTTING_PROVISION';
                    });
                });
            });

            vm.filterOrderType = function (orderType) {
                return orderType.groupPosition !== 0;
            };

            vm.groupOrderTypes = OrderTypeHelper.groupOrderTypes;

            // Takes in a ng-form and returns counted errors
            vm.countErrorsOnTab = (form) => {
                if (!form || !form.$error) {
                    return 0;
                }
                let count = 0;
                angular.forEach(form.$error, (errors) => {
                    count += errors.length;
                });
                return count;
            };

            vm.addFollowUpOrder = () => {
                if (vm.order.workflow.followUpOrder === null) {
                    vm.order.workflow = null;
                } else {
                    vm.order.workflow.type = 'FollowUp';
                    vm.order.workflow.order = vm.order.id;
                }
            };

            // Validate form for condition to check if the cyclic order creation is possible or not
            vm.validateForm = (order, form) => {
                let now = new Date(),
                    compare = new Date(order.firstDeadlineDate),
                    gap = order.creationGap,
                    comparisonResult;

                comparisonResult = new Date(compare.setDate(compare.getDate() - gap));

                if (comparisonResult < now) {
                    form.firstDeadlineDate.$setValidity('validationError', false);
                    form.creationGap.$setValidity('validationError', false);
                } else {
                    form.firstDeadlineDate.$setValidity('validationError', true);
                    form.creationGap.$setValidity('validationError', true);
                }
            };

            // Api call interval for fetching user data
            vm.getProgress = () => {
                let trackedUsers = $filter('filter')(vm.users, {
                        assigned: true,
                        track: true
                    }, true),
                    trackedUsersIds = trackedUsers.map((element) => {
                        return element.id;
                    }),
                    p = {};

                // Upon each request, remove already exsisted paths
                // This thing helped me in fixing that wrong color issuance bug
                if (trackedUsersIds.length && vm.canReadGPSProfile) {
                    p.progressData = CrudApiIgnoreSpinner.save({
                        entity: 'maps',
                        subentity: 'order',
                        subentityid: $stateParams.id
                    }, trackedUsersIds).$promise;
                } else {
                    p.progressData = {
                        userCoordinates: [],
                        temporaryWorkerCoordinates: []
                    };
                }

                // get an updated order status
                p.statusData = CrudApiIgnoreSpinner.get({
                    id: $stateParams.id,
                    entity: 'orders',
                    subentity: 'status'
                }).$promise;

                $q.all(p).then(({progressData, statusData}) => {
                    // user progress

                    vm.masterUsersRaw = groupBy(progressData.userCoordinates, 'userId');
                    angular.forEach(vm.masterUsersRaw, (coordinates, userId) => {
                        coordinates.sort((a, b) => {
                            if (a.capturedDate > b.capturedDate) {
                                return 1;
                            } else if (b.capturedDate > a.capturedDate) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });
                        vm.pathtimeCalculations(userId);
                        vm.sliderOptions[userId] = vm.getSliderOptions(userId);
                        vm.sliderMin[userId] = vm.getMasterUserCoordinate(userId, 0);
                        vm.sliderMax[userId] = vm.getMasterUserCoordinate(userId, 1);
                        vm.masterUsers = filterCoordinatesAccordingToSlider();
                    });
                    vm.pathTimeSummary();
                    vm.temporaryWorkers = progressData.temporaryWorkerCoordinates;
                    // status progress
                    vm.siteStatus = ParcelStatus.constructStatusObject(
                        statusData.siteStatus, vm.order.finishStrategy);
                    // make sure we correctly track REDO status for vineyards that are designated to be redone
                    angular.forEach(vm.redoVineyards, (_, vineyardId) => {
                        vm.siteStatus[vineyardId] = userSiteStatus.REDO;
                    });
                    // we don't want to zoom the map after the initial load
                    vm.zoomToSelection = false;
                });
            };

            vm.showProcessedLabel = (typeId, orderTypes = []) => {
                const orderType = vm.getOrderTypeById(typeId, orderTypes);
                if (orderType) {
                    return orderType.labelProcessed;
                }
                return '';
            };

            vm.getOrderTypeOfOrder = () => {
                if (vm.order) {
                    return vm.getOrderTypeById(vm.order.typeId, vm.orderTypes);
                } else {
                    return undefined;
                }
            };

            vm.getOrderTypeById = (typeId, orderTypes = []) => {
                for (let i = 0; i < orderTypes.length; i++) {
                    const orderType = orderTypes[i];
                    if (orderType.id === typeId) {
                        return orderType;
                    }
                }
                return undefined;
            };

            $scope.$watch('edit.order.siteIds', (newSelection) => {
                if (angular.isDefined(newSelection)) {
                    vm.newVineyards = {};
                    vm.selectedVineyards = {};
                    newSelection.forEach(vId => {
                        if (vm.originalSelectedVineyards && !vm.originalSelectedVineyards[vId]) {
                            vm.newVineyards[vId] = true;
                        } else {
                            vm.selectedVineyards[vId] = true;
                        }
                    });
                }
            }, true);

            function groupBy(collection, key) {
                let result = {};
                for (let i = 0; i < collection.length; i++) {
                    const element = collection[i],
                        k = element[key];
                    if (angular.isUndefined(result[k])) {
                        result[k] = [];
                    }
                    result[k].push(element);
                }
                return result;
            }

            function additionalDataDataType(postdata) {
                let additionalDataDataType = additionalDataDataTypes[
                    $filter('filter')(
                        vm.orderTypes,
                        {id: postdata.typeId},
                        true
                    )[0].group];
                if (additionalDataDataType) {
                    return additionalDataDataType;
                } else {
                    return 'MaterialOrderData';
                }
            }

            vm.changeOrderType = () => {
                if (!vm.isCreate) {
                    vm.additionalDataMap.set(vm.additionalData.dataType, vm.additionalData);
                    const additionalDataType = additionalDataDataType({typeId: vm.order.typeId})
                    const savedAdditionalData = vm.additionalDataMap.get(additionalDataType);
                    if (savedAdditionalData) {
                        vm.additionalData = savedAdditionalData;
                    } else {
                        vm.additionalData = {
                            dataType: additionalDataType,
                            lastModified: vm.additionalData.lastModified,
                            orderId: vm.order.id
                        };
                    }
                } else {
                    vm.order.label = vm.showProcessedLabel(vm.order.typeId, vm.orderTypes);
                }
            }

            // Add items to submenu
            vm.submenu = {
                items: [{
                    type: 'button',
                    text: 'BUTTON_SAVE',
                    click: () => {
                        console.log('BUTTON_SAVE');
                        let postdata = angular.copy(vm.order),
                            promises = {},
                            temporaryVineyardPromise;
                        if ($stateParams.id) {
                            postdata.assignedUsersPerSite = ($filter('filter')(vm.userPerSite, {
                                assigned: true
                            }, true) || []).map((userPerSite) => {
                                return {
                                    siteId: userPerSite.site.id,
                                    userId: userPerSite.user.id ? userPerSite.user.id : userPerSite.user
                                };
                            });
                            postdata.assignedEquipments = ($filter('filter')(vm.equipment, {
                                assigned: true
                            }, true) || []).map((equipment) => {
                                return {
                                    equipmentId: equipment.id,
                                    userId: equipment.assignedUser.id ? equipment.assignedUser.id : equipment.assignedUser
                                };
                            });
                            postdata.assignedUserIds = ($filter('filter')(vm.users, {
                                assigned: true
                            }, true) || []).map((user) => {
                                return user.id;
                            });
                            postdata.assignedEquipments.forEach(el => {
                                if (!postdata.assignedUserIds.includes(el.userId)) {
                                    postdata.assignedUserIds.push(el.userId);
                                }
                            });
                        }

                        if (angular.isArray(postdata.siteStatus)) {
                            postdata.siteStatus = postdata.siteStatus.filter(
                                vs => angular.isDefined(vm.redoVineyards[vs.id]));
                            postdata.siteStatus.forEach(vs => {
                                vs.userStatus.forEach(us => us.status = userSiteStatus.REDO);
                            });
                        }

                        if (postdata.siteIds.indexOf(-1) > -1 && (!vm.temporaryVineyard || !vm.temporaryVineyard.id)) {
                            temporaryVineyardPromise = CrudApi.save({
                                entity: 'vineyards',
                                subentity: 'temporary'
                            }, {}).$promise;
                        } else {
                            temporaryVineyardPromise = $q.when(vm.temporaryVineyard);
                        }

                        temporaryVineyardPromise.then(temporaryVineyard => {
                            if (temporaryVineyard && vm.temporaryVineyardPolygons) {
                                postdata.siteIds = [temporaryVineyard.id];
                                promises.mapsData = CrudApi.save({
                                    entity: 'sites',
                                    subentity: 'mapsdata',
                                    subentityid: temporaryVineyard.id
                                }, {
                                    outline: vm.temporaryVineyardPolygons[0],
                                    columns: []
                                }).$promise;
                            }

                            if (vm.additionalData && !vm.isCreate) {
                                angular.extend(vm.additionalData, {
                                    dataType: additionalDataDataType(postdata)
                                });
                            }

                            if (vm.isCreate) {
                                promises.base = CrudApi.save({
                                    id: $stateParams.id,
                                    entity: vm.type === 'cyclic' ? 'cyclicorders' : 'orders',
                                }, postdata).$promise;
                            } else {
                                promises.base = CrudApi.save({
                                    id: $stateParams.id,
                                    entity: vm.type === 'cyclic' ? 'cyclicorders' : 'orders',
                                    subentity: 'withAdditionalData'
                                }, {
                                    order: postdata,
                                    additionalOrderData: vm.additionalData
                                }).$promise;
                            }

                            $q.all(promises).then(({base}) => {

                                $state.go('orders.edit', {
                                    id: base.id,
                                    message: 'savingSuccess',
                                    type: vm.type
                                }, {
                                    reload: true
                                });
                            }, (response) => {
                                if (response.status === 410) {
                                    vm.message = 'entityHasChanged';
                                } else {
                                    vm.message = 'savingError';
                                }
                            });
                        }).catch(() => {
                            // This can occur when someone tries to save a temporary vineyard without having the permission to do so.
                            vm.message = 'savingError';
                        });
                    }
                },
                    {
                        type: 'download-taskdata',
                        position: 'right',
                        hide: false,
                        click: () => {
                            $http({
                                method: 'GET',
                                url: `/api/v1/taskdata/${vm.order.uuid}`,
                                responseType: 'blob'
                            }).then(function (response) {
                                const blob = new Blob([response.data], {resonseType: 'application/zip'});
                                let urlObject = window.URL.createObjectURL(blob);
                                let fileName = `${vm.order.label}.zip`;
                                let downloadLink = angular.element('<a></a>');
                                downloadLink.css('display', 'none');
                                downloadLink.attr('href', urlObject);
                                downloadLink.attr('download', fileName);
                                downloadLink[0].click();

                                // cleanup
                                downloadLink.remove();
                                window.URL.revokeObjectURL(urlObject);
                            })
                                .catch(function (error) {
                                    console.error('Error downloading the zip file:', error);
                                });
                        }
                    },
                    {
                        type: 'download-excel',
                        position: 'right',
                        hide: false,
                        click: () => {
                            $http({
                                method: 'GET',
                                url: `/api/v1/orders/${vm.order.uuid}/sites/excel`,
                                responseType: 'blob'
                            }).then(function (response) {
                                const blob = new Blob([response.data], {resonseType: 'application/vnd.ms-excel'});
                                let urlObject = window.URL.createObjectURL(blob);
                                let fileName = `${vm.order.label}.xlsx`;
                                let downloadLink = angular.element('<a></a>');
                                downloadLink.css('display', 'none');
                                downloadLink.attr('href', urlObject);
                                downloadLink.attr('download', fileName);
                                downloadLink[0].click();

                                // cleanup
                                downloadLink.remove();
                                window.URL.revokeObjectURL(urlObject);
                            })
                                .catch(function (error) {
                                    console.error('Error downloading the zip file:', error);
                                });
                        }
                    }

                    ]
            };

            if (!$stateParams.id) {
                // NEW ORDER
                // Order object initialization
                vm.order = {
                    creationType: vm.type,
                    managementUnitUpdateStrategy: 'KEEP_FINISHED',
                    orderVisibilityMode: 'TENANT',
                    // preselected vineyard ids can be provided via the "v" param
                    siteIds: $stateParams.v,
                    siteStatus: []
                };
                if (vm.type === 'finished') {
                    vm.order.finishStrategy = 'fixed';
                } else {
                    vm.order.finishStrategy = 'any';
                }
                // Main menu item "new equipment"
                vm.mainmenu = {
                    items: [{
                        label: 'ORDER_NEW',
                        state: 'orders.edit'
                    }]
                };
                // Initialize datepicker with today otherwise it will send wrong date (yesterday - UTC Issue)
                if (vm.type === 'regular' || vm.type === 'finished') {
                    vm.order.deadlineDate = vm.today;
                } else {
                    vm.order.firstDeadlineDate = vm.today;
                }
                // we start with the normal site selection
                vm.parcelTabs = 'normal';

            } else {
                // EDIT ORDER
                // Api call for the order item
                CrudApi.get({
                    id: $stateParams.id,
                    entity: vm.type === 'cyclic' ? 'cyclicorders' : 'orders'
                }, (order) => {
                    if ($stateParams.copied) {
                        vm.message = 'copySuccess';
                    }
                    // Order object
                    vm.order = order;

                    if (vm.order.orderVisibilityMode === null) {
                        vm.order.orderVisibilityMode = 'TENANT';
                    }

                    // Main menu item "order XYZ"
                    vm.mainmenu = {
                        items: [{
                            label: order.label,
                            translate: false,
                            state: 'orders.edit',
                            params: {
                                id: $stateParams.id
                            }
                        }]
                    };

                    vm.orderTypes.$promise.then((data) => {
                        let type = $filter('filter')(data, {
                            id: order.typeId
                        }, true)[0];
                        vm.canEdit = UserService.hasPerm(type.permissionModify);
                    });

                    if (vm.type === 'regular' || vm.type === 'finished') {
                        // Convert deadlinedate to a real date object
                        order.deadlineDate = order.deadlineDate ? new Date(
                            order.deadlineDate) : null;

                        CrudApi.get({
                            entity: 'orders',
                            id: $stateParams.id,
                            subentity: 'data'
                        }, data => {
                            vm.additionalData = data
                            vm.additionalDataMap.set(data.dataType, vm.additionalData);
                        }, () => {
                            vm.additionalData = {
                                calculationMethod: 'ground',
                                areaMethod: 'gross',
                                speed: 7,
                                jetPressure: 10,
                                openJetsCount: 6,
                            };
                        });

                        // Fetch employees
                        ModuleService.initialize().then(() => {
                            // Api call for fetching user data
                            vm.users = CrudApi.query({
                                entity: 'users',
                                sortBy: 'lastname'
                            }, (users) => {
                                // Enhance fetched employees
                                users.forEach((user, index) => {
                                    // Flag employees which are assigned to the task
                                    user.assigned = vm.order.assignedUserIds.indexOf(user.id) !== -1;
                                    // All employees are initially kept track of
                                    user.track = true;
                                    // Find out if the employee is eligible to work on order due to rights
                                    user.canDoOrders = ModuleService.userHasPermissions(user,
                                        [permissions.BASE_CAN_DO_ORDERS]);
                                    // Assigning color to user and also repeating color after 10 users
                                    if (user.color === null) {
                                        vm.colors[user.id] = colorConstants[index % colorConstants.length];
                                    } else {
                                        vm.colors[user.id] = user.color;
                                    }
                                    vm.userNames[user.id] = user.firstname + ' ' + user.lastname.charAt(0);
                                });

                                // Fetch assignedSites


                                // Fetch equipments
                                vm.equipment = CrudApi.query({
                                    entity: 'equipment',
                                    sortBy: 'label'
                                }, (equipments) => {
                                    equipments.forEach((equipment) => {
                                        let eq = vm.order.assignedEquipments.find(element => {
                                            return element.equipmentId === equipment.id;
                                        });
                                        equipment.assigned = angular.isDefined(eq);
                                        if (angular.isDefined(eq)) {
                                            equipment.assignedUser = vm.users.find(element => {
                                                return eq.userId === element.id;
                                            });
                                            // mark equipment as already assigned with user
                                            if (equipment.assignedUser) {
                                                equipment.alreadyAssigned = true;
                                            } else {
                                                // if the equipment doesn't have assignedUser, give it one, to make later saving of the order possible
                                                let alreadyAssignedEquipment = vm.order.assignedEquipments.find(element => {
                                                    if (equipment.id === element.equipmentId) {
                                                        return element.userId;
                                                    }
                                                    return undefined;
                                                });
                                                equipment.assignedUser = alreadyAssignedEquipment.userId;
                                            }
                                        }
                                    });
                                }, () => {
                                    vm.message = 'loadingError';
                                });

                                // Fetch comments
                                vm.comments = CrudApi.query({
                                    entity: 'comments',
                                    orderId: $stateParams.id
                                }, (comments) => {
                                    comments.forEach((comment) => {
                                        comment.timestamp = new Date(comment.timestamp);
                                        comment.color = vm.colors[comment.userId];
                                    });
                                });

                                let promises = {
                                    // Fetch map status
                                    // we do this now because we need the map data to exist, before we can call
                                    // vm.getProgress. This method will disable zooming in the map so all data
                                    // relevant to the map needs to be there for the inital zoom to work correctly
                                    // before this happens

                                    data: CustomerMapDataService.getCustomerMapsData(),
                                    pois: CrudApi.query({
                                        entity: 'poi',
                                        orderId: $stateParams.id
                                    }).$promise
                                };

                                // if the order only contains a single vineyard it might be a temporary vineyard
                                if (order.siteIds.length === 1) {
                                    const siteId = order.siteIds[0];
                                    promises.siteData = CrudApi.get({
                                        entity: siteEntities,
                                        id: siteId
                                    }).$promise.then(site => {
                                        // we only want to add the vineyard to the temp vineyard cache if it is actually temporary
                                        if (site.isTemporary) {
                                            // in case of a temporary vineyard we want to refresh the cache for it
                                            return CustomerMapDataTemporaryService.getCustomerMapsDataById(
                                                siteId, true).then(temporaryMapData => {
                                                temporaryMapData.isTemporary = true;
                                                return {site, temporaryMapData};
                                            });
                                        }
                                        // otherwise we don't do anything
                                        return $q.when({site});
                                    });
                                }

                                $q.all(promises).then(({data, pois, siteData = {}}) => {
                                    /* eslint max-nested-callbacks: 0 */
                                    let iconPromises = {},
                                        {site, temporaryMapData = {}} = siteData;

                                    pois.forEach(poi => {
                                        iconPromises[poi.id] = PoiHelper.createIconFromPoi(poi);
                                    });

                                    $q.all(iconPromises).then(icons => {
                                        vm.mapData = data;
                                        vm.iconData = icons;

                                        if (site && site.isTemporary) {
                                            vm.temporaryVineyard = site;
                                            vm.temporaryVineyardPolygons = [angular.extend({},
                                                vm.polygonOptions, temporaryMapData.outline)];
                                            vm.parcelTabs = 'temporary';
                                            vm.drawingControlOptions.drawingModes = [];
                                        } else {
                                            vm.parcelTabs = 'normal';
                                        }

                                        // Update porgress data every 30 seconds
                                        let progress = $interval(() => {
                                            // Update progress only for running order (not for finished orders) also Stop the interval for finished orders
                                            if (vm.order.status !== orderStatus.FINISHED) {
                                                vm.getProgress();
                                            } else {
                                                $interval.cancel(progress);
                                            }
                                        }, 30000);

                                        // Unregister the interval event when the view is closed
                                        $scope.$on('$destroy', () => {
                                            $interval.cancel(progress);
                                        });

                                        // Fetch progress data initially
                                        vm.getProgress();
                                    });
                                }, () => {
                                    vm.message = 'loadingError';
                                });
                            }, () => {
                                vm.message = 'loadingError';
                            });
                        }, () => {
                            vm.message = 'loadingError';
                        });

                        if (vm.canDownloadImage) {
                            // get potential (image) files
                            loadFiles();
                        }
                    } else {
                        CustomerMapDataService.getCustomerMapsData().then(data => {
                            vm.mapData = data;
                        }, () => {
                            vm.message = 'loadingError';
                        });

                        // Convert deadlinedate to a real date object
                        order.firstDeadlineDate = order.firstDeadlineDate ? new Date(
                            order.firstDeadlineDate) : null;
                        // cyclic orders cannot have temporary vineyards
                        vm.parcelTabs = 'normal';
                    }

                    // Construct vineyard status
                    vm.siteStatus = ParcelStatus.constructStatusObject(
                        angular.isArray(vm.order.siteStatus) ? vm.order.siteStatus : [],
                        vm.order.finishStrategy);

                    // initialize selected vineyards
                    vm.originalSelectedVineyards = {};
                    vm.selectedVineyards = {};
                    vm.order.siteIds.forEach(
                        v => vm.originalSelectedVineyards[v] = vm.selectedVineyards[v] = true);
                });
            }

            vm.createNewOrderType = () => {
                let popup = $uibModal.open({
                    controller: 'OrderTypesEditCtrl',
                    controllerAs: 'edit',
                    templateUrl: 'orders/edit/views/order-type-modal.tpl.html',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        $stateParams: () => {
                            return {};
                        }
                    }
                });
                popup.result.then(type => {
                    if (UserService.hasPerm(type.permissionCreate)) {
                        type.canCreate = true;
                        type.labelProcessed = OrderTypeHelper.getLabel(type);
                        type.groupLabel = OrderTypeHelper.getGroupLabel(type);
                        vm.orderTypes.push(type);
                        vm.order.typeId = type.id;
                        vm.order.label = type.labelProcessed;
                    }
                });
            };

            vm.useVineyardSelection = () => {
                // save existing polygon for later
                previousTemporaryVineyardPolygons = vm.temporaryVineyardPolygons;
                vm.temporaryVineyardPolygons = null;
                // then restore any potential existing previous selection
                if (previousSiteIdSelection) {
                    vm.order.siteIds = previousSiteIdSelection;
                }
                // if we switched from temporary to non temporary we want to enable the selection edit
                // mode
                if (previousTemporaryVineyardPolygons) {
                    vm.editSelection = true;
                }
                // we don't need to reset the vm.order.siteIds value here because in case it only
                // contains the id of the temporary vineyard the selection directive will reset
                // it anyway
                triggerUpdate();
            };

            vm.useTemporaryVineyard = () => {
                // this only needs to be set once to make sure the map is correctly initialized
                // after that it will never be set to false to avoid recreating the edit map
                // directive. recreating the edit map directive results in a $compile:multilink error
                // with regards to the custom control directive
                vm.temporaryVineyardMapInitialize = true;
                // save existing selection
                previousSiteIdSelection = vm.order.siteIds;
                vm.order.siteIds = [getTemporaryVineyardId()];
                // then restore any potential existing previous polygon
                if (previousTemporaryVineyardPolygons) {
                    vm.temporaryVineyardPolygons = previousTemporaryVineyardPolygons;
                }
                // if there are no previous polygons we initialize our scope variable
                if (!vm.temporaryVineyardPolygons) {
                    vm.temporaryVineyardPolygons = [];
                }
                // only allowing drawing if there is not a polygon already
                vm.drawingControlOptions.drawingModes = vm.temporaryVineyardPolygons.length ? [] : ['polygon'];
                triggerUpdate(vm.temporaryVineyardPolygons.length ? vm.temporaryVineyardPolygons[0].path : null);
            };

            function triggerUpdate(path) {
                const temporaryVineyardId = getTemporaryVineyardId();

                if (vm.temporaryVineyardPolygons) {
                    vm.temporaryVineyardPolygons = vm.temporaryVineyardPolygons.slice();
                }
                // make sure the map will get updated site data
                vm.mapData = angular.extend({}, $scope.edit.mapData);
                vm.siteStatus = angular.extend({}, $scope.edit.siteStatus);
                // delete existing temporary site data
                delete vm.mapData[temporaryVineyardId];
                delete vm.siteStatus[temporaryVineyardId];

                if (path) {
                    vm.mapData[temporaryVineyardId] = {
                        outline: {
                            id: 'temporary',
                            path: path
                        },
                        isTemporary: true
                    };

                    vm.siteStatus[temporaryVineyardId] = userSiteStatus.NONE;
                }
            }

            function getTemporaryVineyardId() {
                if (angular.isObject(vm.temporaryVineyard)) {
                    return vm.temporaryVineyard.id;
                }
                return -1;
            }

            // Events that would be fired when the user draws on the google map
            vm.drawingManagerEvents = {
                polygonCompleted: function (polygon) {
                    let path = MapHelper.getPathFromGmapShape(polygon);
                    // Prevent polygons with less than 2 points to be added
                    if (path.length > 2) {
                        vm.temporaryVineyardPolygons.push(
                            angular.merge({}, vm.polygonOptions, {
                                path: path
                            }));
                        triggerUpdate(path);
                        // Set the form to be dirty, so the user can save again
                        $scope.form.$setDirty();
                        vm.drawingControlOptions.drawingModes = [];
                    }
                }
            };

            // Events that will be fired when the user interacts with map objects
            vm.objectEvents = {
                polygon: {
                    pathChanged: (polygon, model) => {
                        let path = MapHelper.getPathFromGmapShape(model);
                        polygon.path = path;
                        triggerUpdate(path);
                        $scope.form.$setDirty();
                    }
                }
            };

            vm.resetMap = () => {
                vm.temporaryVineyardPolygons = [];
                vm.message = null;
                triggerUpdate();
                vm.drawingControlOptions.drawingModes = ['polygon'];
            };

            function onVineyardClick(vineyardId) {
                if (vm.mapData && vm.mapData[vineyardId] && !vm.mapData[vineyardId].isTemporary) {
                    $state.go('parcels.edit.orders.list', {
                        id: vineyardId
                    });
                }
            }

            vm.deleteFile = (file) => {
                let popup = $uibModal.open({
                    controller: ['item', function (item) {
                        /* eslint "angular/ng_controller_as_vm": 0 */
                        this.item = item;
                    }],
                    controllerAs: 'delete',
                    templateUrl: 'orders/edit/views/delete-image-modal.tpl.html',
                    resolve: {
                        item: () => {
                            return file;
                        }
                    }
                });
                popup.result.then(() => CrudApi.delete({
                    id: $stateParams.id,
                    entity: 'orders',
                    subentity: 'files',
                    subentityid: file.filename
                }, () => loadFiles().then(() => {
                    // if we display the image tab and there are no images left we show the base tab
                    if (vm.activeTab === 5 && vm.files.length === 0) {
                        vm.activeTab = 0;
                    }
                }), () => {
                    vm.message = 'savingError';
                }));
            };

            function loadFiles() {
                return CrudApi.query({
                    id: $stateParams.id,
                    entity: 'orders',
                    subentity: 'files'
                }, files => {
                    vm.files = files;
                    // we save the result of the filter explicitely on the scope because it seems to
                    // return new objects after a certain amount of digest cycles which results in
                    // unnecessary recreation of the list elements
                    vm.fileChunks = chunkByFilter(files, 4);
                }, () => {
                    vm.message = 'loadingError';
                }).$promise;
            }

            vm.imagePopup = (image) => {
                $uibModal.open({
                    bindToController: true,
                    controller: ['image', (img) => {
                        /* eslint "angular/ng_controller_as_vm": 0 */
                        this.image = img;
                    }],
                    controllerAs: 'modal',
                    templateUrl: 'orders/edit/views/image-modal.tpl.html',
                    size: 'lg',
                    resolve: {
                        image: () => {
                            return image;
                        }
                    }
                });
            };
        }
    }

    OrdersEditCtrl.$inject = [
        '$scope',
        '$q',
        '$http',
        '$state',
        '$stateParams',
        '$interval',
        '$filter',
        'OrderTypeHelper',
        'UserService',
        'CrudApi',
        'colorConstants',
        'CustomerMapDataService',
        'permissions',
        'ModuleService',
        'userSiteStatus',
        'orderStatus',
        'ParcelStatus',
        'additionalDataDataTypes',
        'PoiHelper',
        '$uibModal',
        'MapHelper',
        'chunkByFilter',
        'CustomerMapDataTemporaryService',
        'siteEntities'
    ];

    /**
     * @ngdoc object
     * @name orders.edit.controller:OrdersEditCtrl
     *
     * @description
     *
     */
    angular
        .module('orders.edit')
        .controller('OrdersEditCtrl', OrdersEditCtrl);
}());

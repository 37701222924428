(function () {
  'use strict';

  angular
    .module('poi.edit')
    .config(config);

  function config($stateProvider, permissions) {
    $stateProvider
      .state('poi.edit', {
        url: '/edit/{id:int}',
        templateUrl: 'poi/edit/views/edit.tpl.html',
        controller: 'PoiEditCtrl',
        controllerAs: 'edit',
        params: {
          message: {
            value: null
          }
        }
      })
      .state('poi.create', {
        url: '/create?fromPoi',
        templateUrl: 'poi/edit/views/edit.tpl.html',
        controller: 'PoiEditCtrl',
        controllerAs: 'edit',
        params: {
          fromPoi: {
            type: 'int'
          }
        },
        data: {requiredPerms: [
          permissions.BASE_CAN_CREATE_POI
        ]}
      });
  }
}());

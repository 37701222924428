(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name constants.constant:additionalDataDataTypes
   *
   * @description
   *
   */
  angular
    .module('constants')
    .constant('additionalDataDataTypes', {
      FERTILIZATION: 'FertilizationOrderData',
      PLANT_PROTECTION: 'PlantProtectionOrderData',
    });
}());

<vc-map-base
    map-id="vcMapDisplay.mapId"
    vineyard-data="vcMapDisplay.vineyardMapData"
    icons="!vcMapDisplay.hideIcons ? vcMapDisplay.iconData : vcMapDisplay.emptyIcons"
    change-icon-options="vcMapDisplay.changeIconOptions"
    zoom-mode="vcMapDisplay.zoomMode"
    users="vcMapDisplay.users"
    user-data="vcMapDisplay.userPathData"
    temp-worker-data="vcMapDisplay.tempWorkerPathData"
    determine-vineyard-options="vcMapDisplay.getVineyardOptions(vineyardId)"
    determine-user-color="vcMapDisplay.getUserColor(userId)"
    change-vineyard-options="vcMapDisplay.changeVineyardOptions"
    on-vineyard-mouse-over="vcMapDisplay.onVineyardMouseChange(vineyardId, true)"
    on-vineyard-mouse-out="vcMapDisplay.onVineyardMouseChange(vineyardId, false)"
    on-vineyard-click="vcMapDisplay.onVineyardClick(vineyardId)"
    on-icon-click="vcMapDisplay.onIconClick(iconId)"
    enable-map-click="vcMapDisplay.onMapClick">
    <custom-control class="hide-icons" position="LEFT_BOTTOM" index="1" ng-if="!vcMapDisplay.hideIconControl">
        <div class="checkbox">
            <label>
                <input type="checkbox" ng-model="vcMapDisplay.hideIcons"> {{ 'MAP_HIDE_ICONS_CONTROL' | translate }}
            </label>
        </div>
    </custom-control>
    <ng-transclude></ng-transclude>
</vc-map-base>

(function () {
    'use strict';

    class EditRemoveModuleAlertCtrl {
        constructor() {
            let vm = this;
        }
    }

    EditRemoveModuleAlertCtrl.$inject = [];

    /**
     * @ngdoc object
     * @name admin.customers.edit.controller:EditRemoveModuleAlertCtrl
     *
     * @description
     *
     */
    angular
        .module('admin.customers.edit')
        .controller('EditRemoveModuleAlertCtrl', EditRemoveModuleAlertCtrl);
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name vcMain.filter:escapeHtml
   *
   * @description a filter that escapes special html characters.
   *              @see https://github.com/janl/mustache.js/blob/24653984d08c7863d30451850c6e05fdafa31e37/mustache.js#L71
   *
   * @param {String} input The html string to escape
   * @returns {String} The escaped html string
   *
   */
  angular
    .module('vcMain')
    .filter('escapeHtml', escapeHtml);

  function escapeHtml() {
    return (input) => {
      let entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        '\'': '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;'
      };
      return String(input).replace(/[&<>"'`=\/]/g, s => {
        return entityMap[s];
      });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name vcMain.factory:MapExtApi
   *
   * @description
   *
   */
  angular
    .module('vcMain')
    .factory('MapExtApi', MapExtApi);

  function MapExtApi($resource, apiBasePath) {
    return $resource(apiBasePath + 'maps/:action/:id');
  }

  MapExtApi.$inject = ['$resource', 'apiBasePath'];
}());

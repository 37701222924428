<ng-form class="form-group" ng-class="{
    'has-error': flurForm.$invalid
  }" name="flurForm" style="display: block;">
    <label class="control-label">{{'PARCELS_PARAM_FLURNUMBER' | translate}}</label>
    <ui-select multiple ng-model="edit.item.plots" theme="bootstrap" tagging="edit.newPlot" style="margin-bottom: 5px;" ng-disabled="!edit.fullEditAvailable || edit.isShared" required>
      <ui-select-match placeholder="  {{'PLEASE_MAKE_CHOICE' | translate}}">
        {{$item.label}}
      </ui-select-match>
      <ui-select-choices repeat="plot in edit.getAvailablePlots($select.search)">
        {{plot.label}}
      </ui-select-choices>
    </ui-select>
    <div class="row"
         style="margin-bottom: 5px;"
         ng-repeat="plot in edit.item.plots | orderBy : 'label'">
      <div class="col-xs-9">
          <input class="form-control"
                 type="text"
                 placeholder="{{'PARCEL_FLURNUMBER' | translate}}"
                 readonly
                 ng-model="plot.label" />
      </div>
      <div class="col-xs-3">
        <div class="input-group">
          <input class="form-control"
                 type="number"
                 placeholder="{{'PARCELS_PARAM_BRUTTOAREA' | translate}}"
                 ng-readonly="!edit.fullEditAvailable || edit.isShared"
                 pattern="^\d{1,6}(?:[,\.]\d{1,4})?$"
                 ng-model="plot.area"
                 required />
          <span class="input-group-addon">{{'PARCELS_PARAM_AREA_UNIT' | translate}}</span>
        </div>
      </div>
    </div>
    <div class="row"
         style="margin-bottom: 5px;"
         ng-if="edit.item.plots.length > 1">
      <div class="col-xs-3 col-xs-offset-9 text-right">
        <div class="input-group">
          <input class="form-control"
                 type="number"
                 ng-disabled="true"
                 ng-value="edit.plotSum().toFixed(4)" />
          <span class="input-group-addon">{{'PARCELS_PARAM_AREA_UNIT' | translate}}</span>
        </div>
      </div>
    </div>
    <p class="help-block"
       ng-messages="flurForm.$error">
      <span ng-message="pattern">{{'PARCELS_PARAM_AREA_FRACTION_SIZE' | translate}}</span>
      <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
    </p>
</ng-form>

(function () {
  'use strict';

  angular
    .module('orderTypes.edit')
    .config(config);

  function config($stateProvider, permissions) {
    $stateProvider
      .state('orderTypes.edit', {
        url: '/edit/{id:int}',
        templateUrl: 'order-types/edit/views/edit-scaffold.tpl.html',
        controller: 'OrderTypesEditCtrl',
        controllerAs: 'edit',
        params: {
          message: {
            value: null
          }
        }
      })
      .state('orderTypes.create', {
        url: '/create',
        templateUrl: 'order-types/edit/views/edit-scaffold.tpl.html',
        controller: 'OrderTypesEditCtrl',
        controllerAs: 'edit',
        data: {requiredPerms: [
          permissions.BASE_CAN_CREATE_ORDERTYPE
        ]}
      });
  }
}());

(function () {
  'use strict';

  angular
    .module('temporaryWorkers.edit')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('temporaryWorkers.edit', {
        url: '/edit',
        templateUrl: 'temporary-workers/edit/views/edit.tpl.html',
        controller: 'TemporaryWorkersEditCtrl',
        controllerAs: 'edit',
        params: {
          id: null,
          message: null
        }
      });
  }
}());

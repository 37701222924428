(function () {
  'use strict';

  class DeleteCtrl {
    constructor(item) {
      let vm = this;
      vm.item = item;
    }
  }

  DeleteCtrl.$inject = ['item'];

  /**
   * @ngdoc object
   * @name businessManagement.businesses.delete.controller:DeleteCtrl
   *
   * @description
   *
   */
  angular
    .module('businessManagement.businesses.delete')
    .controller('BusinessesDeleteCtrl', DeleteCtrl);
}());

(function () {
  'use strict';

  class ParcelsDeleteCtrl {
    constructor(item, business) {
      let vm = this;
      vm.item = item;
      vm.business = business;
    }
  }

  ParcelsDeleteCtrl.$inject = ['item', 'business'];

  /**
   * @ngdoc object
   * @name parcels.delete.controller:ParcelsDeleteCtrl
   *
   * @description
   *
   */
  angular
    .module('parcels.delete')
    .controller('ParcelsDeleteCtrl', ParcelsDeleteCtrl);
}());

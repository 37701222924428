(function () {
  'use strict';

  angular
    .module('managementUnits')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('managementUnits', {
        url: '/management-units',
        abstract: true,
        template: '<ui-view />'
      });
  }
}());

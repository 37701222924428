(function () {
  'use strict';

  class ForgotPasswordCtrl {
    constructor(AuthApi) {
      let vm = this;
      vm.user = {};
      vm.ctrlName = 'ForgotPasswordCtrl';

      vm.send = () => {
        vm.emailNotFound = false;
        AuthApi.save({
          action: 'sendPasswordForgottenEmail'
        }, vm.user, () => {
          vm.emailSent = true;
        }, () => {
          vm.emailNotFound = true;
        });
      };
    }
  }

  ForgotPasswordCtrl.$inject = ['AuthApi'];
  /**
   * @ngdoc object
   * @name forgotPassword.controller:ForgotPasswordCtrl
   *
   * @description
   *
   */
  angular
    .module('auth.forgotPassword')
    .controller('ForgotPasswordCtrl', ForgotPasswordCtrl);
}());

<main-message message="edit.message" fluid="'nested'"></main-message>
<ng-form name="form"
         class="order-types-edit">
    <!-- MODEL -->
    <div class="form-group" ng-class="{'has-error': form.group.$invalid}">
        <label class="control-label"
               for="group">{{'orderType.group.label' | translate}}</label>
        <select class="form-control"
                id="group"
                name="group"
                ng-model="edit.item.group"
                ng-options="orderTypeGroup.label as orderTypeGroup.labelProcessed for orderTypeGroup in edit.orderTypeGroups"
                required>
            <option value="" ng-if="!edit.item.group">{{'MAKE_CHOICE' | translate}}</option>
        </select>
        <p class="help-block"
           ng-messages="form.group.$error">
            <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
        </p>
    </div>
    <div class="form-group" ng-class="{'has-error': form.label.$invalid}">
        <label class="control-label">{{'ORDER_ORDER_TYPE' | translate}}</label>
        <input class="form-control"
               type="text"
               name="label"
               id="label"
               ng-model="edit.item.label"
               ng-disabled="!edit.new && !edit.canEdit"
               required/>
        <p class="help-block"
           ng-messages="form.label.$error">
            <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
        </p>
    </div>

    <!-- VISIBILITY MODE -->
    <div ng-if="edit.canEditVisibility" class="form-group"
         ng-class="{'has-error': form.visibilityMode.$invalid}">
        <label class="control-label"
               for="visibilityMode">{{'orderType.orderTypeVisibilityMode.label' | translate}}</label>
        <select class="form-control"
                id="visibilityMode"
                name="visibilityMode"
                ng-model="edit.item.visibilityMode"
                required>
            <option value="TENANT">{{'orderType.orderTypeVisibilityMode.values.TENANT.label' | translate}}</option>
            <option value="ACCOUNT">{{'orderType.orderTypeVisibilityMode.values.ACCOUNT.label' | translate}}</option>
        </select>
        <p class="help-block"
           ng-messages="form.label.$error">
            <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
        </p>
        <p class="help-block">
            <span>{{'orderType.orderTypeVisibilityMode.values.' + edit.item.visibilityMode + '.help' | translate}}</span>
        </p>
    </div>

</ng-form>

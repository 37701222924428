(function () {
  'use strict';

  angular
    .module('businesses.factsheet')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('factsheet', {
        url: '/{id}/factsheet',
        templateUrl: 'business-management/businesses/factsheet/views/factsheet.tpl.html',
        controller: 'FactsheetCtrl',


        controllerAs: 'factsheet'
      });
  }
}());

(function () {
  'use strict';

  angular
    .module('comments')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('comments', {
        url: '/comments?page&sorter&dir&filter&commentsType&case',
        templateUrl: 'comments/views/comments.tpl.html',
        controller: 'CommentsCtrl',
        controllerAs: 'comments'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name constants.constant:siteEntities
   *
   * @description
   *
   */
  angular
    .module('constants')
    .constant('siteEntities', 'sites');
}());

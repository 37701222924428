(function () {
  'use strict';

  /* @ngdoc object
   * @name comments
   * @description
   *
   */
  angular
    .module('comments', [
      'ui.router'
    ]);
}());

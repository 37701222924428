(function () {
  'use strict';

  angular
    .module('timeTracking.costCenters.edit')
    .config(config);

  function config($stateProvider, permissions) {
    $stateProvider
      .state('timeTracking.costCenters.edit', {
        url: '/edit/{id:int}',
        templateUrl: 'time-tracking/cost-centers/edit/views/edit.tpl.html',
        controller: 'TimeTrackingCostCentersEditCtrl',
        controllerAs: 'edit',
        params: {
          message: {
            value: null
          }
        }
      })
      .state('timeTracking.costCenters.create', {
        url: '/create',
        templateUrl: 'time-tracking/cost-centers/edit/views/edit.tpl.html',
        controller: 'TimeTrackingCostCentersEditCtrl',
        controllerAs: 'edit',
        data: {
          requiredPerms: [
            permissions.TIME_TRACKING_CAN_USE,
            permissions.TIME_TRACKING_CAN_CREATE_COST_CENTER
          ]
        }
      });
  }
}());

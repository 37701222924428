(function () {
  'use strict';

  angular
    .module('orders.edit')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('orders.edit', {
        url: '/edit/{id}?type&copied',
        templateUrl: 'orders/edit/views/edit.tpl.html',
        controller: 'OrdersEditCtrl',
        controllerAs: 'edit',
        params: {
          message: {
            value: null
          }
        }
      })
      .state('orders.create', {
        url: '/create?type&v',
        templateUrl: 'orders/edit/views/edit.tpl.html',
        controller: 'OrdersEditCtrl',
        controllerAs: 'edit',
        params: {
          v: {
            type: 'int',
            array: true,
            value: () => []
          }
        }
      });
  }
}());

(function () {
  'use strict';

  class OrderTypesDeleteCtrl {
    constructor(item) {
      let vm = this;
      vm.item = item;
    }
  }

  OrderTypesDeleteCtrl.$inject = ['item'];

  /**
   * @ngdoc object
   * @name orderTypes.delete.controller:OrderTypesDeleteCtrl
   *
   * @description
   *
   */
  angular
    .module('orderTypes.delete')
    .controller('OrderTypesDeleteCtrl', OrderTypesDeleteCtrl);
}());

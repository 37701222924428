(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name parcels.selection.constants:parcelAreaDefaultUnit
   *
   * @description
   *
   */
  angular
      .module('parcels.selection')
      .constant('parcelAreaDefaultUnit', 'brutto');
}());

(function () {
    'use strict';

    class OrderTypesListCtrl {
        constructor($state, $stateParams, $uibModal, CrudApi, UserService, permissions) {
            let vm = this;

            vm.message = $stateParams.message;
            vm.canCreate = UserService.canAccessState('orderTypes.create');
            vm.canDelete = UserService.hasPerm(permissions.BASE_CAN_DELETE_ORDERTYPE);

            vm.ownEntity = (entityOwnerId) => {
                return UserService.ownEntity(entityOwnerId);
            };

            vm.canDeleteEntity = (entityOwnerId) => {
                let isOwnedEntity = vm.ownEntity(entityOwnerId);
                let userIsAdmin = UserService.hasPerm(permissions.ADMIN_CAN_EDIT_ALL_CUSTOMER_ORDER_TYPES);
                return isOwnedEntity || userIsAdmin;
            };

            // Api Call
            vm.items = CrudApi.query({
                entity: 'orderTypes',
                customOnly: true
            }, (data, headers, status) => {
                if (status === 204 || !data.length) {
                    vm.message = 'noData';
                }
            }, () => {
                vm.message = 'loadingError';
            });

            // Add submenu items
            vm.submenu = {
                items: [{
                    type: 'button',
                    text: 'BUTTON_ADD_ORDER_TYPE',
                    hide: !vm.canCreate,
                    click: () => {
                        $state.go('orderTypes.create');
                    }
                }]
            };

            // Delete Popup
            vm.delete = (item) => {
                let popup = $uibModal.open({
                    controller: 'OrderTypesDeleteCtrl',
                    controllerAs: 'OrderTypesDelete',
                    templateUrl: 'order-types/delete/views/delete.tpl.html',
                    resolve: {
                        item: () => {
                            return item;
                        }
                    }
                });
                popup.result.then(() => {
                    CrudApi.delete({
                        entity: 'orderTypes',
                        id: item.id,
                        lastModified: item.lastModified
                    }, () => {
                        $state.go($state.current.name, {
                            message: 'deletingSuccess'
                        }, {
                            reload: true
                        });
                    }, (response) => {
                        if (response.status === 409) {
                            vm.message = 'orderTypesDependantOrders';
                        } else if (response.status === 410) {
                            vm.message = 'entityHasChanged';
                        } else {
                            vm.message = 'deletingError';
                        }
                    });
                });
            };
        }
    }

    OrderTypesListCtrl.$inject = ['$state', '$stateParams', '$uibModal', 'CrudApi', 'UserService', 'permissions'];

    /**
     * @ngdoc object
     * @name orderTypes.list.controller:OrderTypesListCtrl
     *
     * @description
     *
     */
    angular
        .module('orderTypes.list')
        .controller('OrderTypesListCtrl', OrderTypesListCtrl);
}());

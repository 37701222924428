(function () {
    'use strict';

    const entryFlowByAccount = new Map();

    //VC AccountId = 1
    entryFlowByAccount.set('1', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //WV Freyburg AccountId = 2
    entryFlowByAccount.set('2', '1f5ac038-c452-4345-a822-80da4cab3408');
    //Weingut Robert Weil AccountId = 4
    entryFlowByAccount.set('4', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //Antoniri AccountId = 6
    entryFlowByAccount.set('6', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //Agrar Fleischmann AccountId = 7
    entryFlowByAccount.set('7', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //Demo Vineyard Cloud AccountId = 8
    entryFlowByAccount.set('8', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //GWF AccountId = 9
    entryFlowByAccount.set('9', 'f25da604-1a25-459d-846a-fcdd2f7f72e1');
    //Zonin AccountId = 10
    entryFlowByAccount.set('10', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //Weingut Leitz AccountId = 13
    entryFlowByAccount.set('13', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //MvB AccountId = 14
    entryFlowByAccount.set('14', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //HSW AccountId = 15
    entryFlowByAccount.set('15', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //Demo-Account AccountId = 16
    entryFlowByAccount.set('16', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //Crimson Vineyard AccountId = 17
    entryFlowByAccount.set('17', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //Vitenova AccountId = 18
    entryFlowByAccount.set('18', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //Demo Account IT AccountId = 22
    entryFlowByAccount.set('22', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //Allegrini AccountId = 23
    entryFlowByAccount.set('23', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //Planeta AccountId = 24
    entryFlowByAccount.set('24', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //Weinbau Hettich AccountId = 25
    entryFlowByAccount.set('25', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //Agrorent AccountId = 26
    entryFlowByAccount.set('26', '8f417758-6785-4067-804c-b484e8d9f7cb');
    //Kubota AccountId = 27
    entryFlowByAccount.set('27', '8f417758-6785-4067-804c-b484e8d9f7cb');

    /**
     * @ngdoc service
     * @name auth.constant:permissions
     *
     * @description
     *
     */
    angular
        .module('auth')
        .constant('entryFlowByAccount', entryFlowByAccount);
}());

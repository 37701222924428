(function () {
  'use strict';

  angular
    .module('parcels.selection')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('selection', {
        url: '/selection',
        templateUrl: 'parcels/selection/views/selection.tpl.html',
        controller: 'SelectionCtrl',
        controllerAs: 'selection'
      });
  }
}());

<sub-menu items="edit.submenu.items"
          form="form" ng-if="edit.canEdit"></sub-menu>
<main-menu items="edit.mainmenu.items"></main-menu>

<div class="base-layout user-edit">
  <div class="base-layout-default">
    <main-message message="edit.message" fluid="'nested'"></main-message>
    <ng-form name="form"
             class="equipment-edit"
             autocomplete="off">
        <div class="checkbox" style="margin-bottom: 15px">
            <label style="font-weight: 700">
                <input type="checkbox"
                       ng-readonly="!edit.canEdit"
                       ng-model="edit.user.isActive" />
                {{'basicTerms.isActive' | translate}}
            </label>
        </div>
      <!-- FIRSTNAME -->
      <div class="form-group"
           ng-class="{
             'has-error': form.firstname.$invalid
           }">
        <label class="control-label"
               for="model">{{'FIRSTNAME' | translate}}</label>
        <input class="form-control"
               type="text"
               name="firstname"
               id="firstname"
               maxlength="45"
               ng-model="edit.user.firstname"
               ng-disabled="!edit.canEdit"
               required />
        <p class="help-block"
           ng-messages="form.firstname.$error">
          <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
        </p>
      </div>

      <!-- LASTNAME -->
      <div class="form-group"
           ng-class="{
             'has-error': form.lastname.$invalid
           }">
        <label class="control-label"
               for="model">{{'LASTNAME' | translate}}</label>
        <input class="form-control"
               type="text"
               name="lastname"
               id="lastname"
               maxlength="45"
               ng-model="edit.user.lastname"
               ng-disabled="!edit.canEdit"
               required />
        <p class="help-block"
           ng-messages="form.lastname.$error">
          <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
        </p>
      </div>

      <!-- EMAIL -->
      <div class="form-group"
           ng-class="{
             'has-error': form.email.$invalid
           }">
        <label class="control-label"
               for="model">{{'EMAIL' | translate}}</label>
        <input class="form-control"
               type="email"
               name="email"
               id="email"
               maxlength="254"
               ng-model="edit.user.email"
               ng-disabled="!edit.canEdit"
               required
               autocomplete="new-email"/>
        <p class="help-block"
           ng-messages="form.email.$error">
          <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
        </p>
      </div>

      <!-- PASSWORD -->
      <div class="form-group"
           ng-class="{
             'has-error': form.password.$invalid
           }">
        <label class="control-label"
               for="model">{{'PASSWORD' | translate}}</label>
        <input class="form-control"
               type="password"
               name="password"
               id="password"
               ng-model="edit.user.password"
               ng-disabled="!edit.canEdit"
               autocomplete="new-password"
               ng-minlength="8" />
        <p class="help-block"
           ng-messages="form.password.$error" ng-if="!form.password.$valid">
          <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
          <span ng-message="minlength">{{('FORM_HELP_MINLENGTH' | translate:{min: 8})}}</span>
        </p>
        <p class="help-block"
           ng-if="form.password.$valid">
           <span ng-if="!edit.user.password && !edit.isCreate">{{'FORM_HELP_PASSWORD_WILL_NOT_BE_CHANGED' | translate}}</span>
           <span ng-if="!edit.user.password && edit.isCreate">{{'FORM_HELP_PASSWORD_WILL_BE_SET_BY_MAIL' | translate}}</span>
           <span ng-if="edit.user.password"
                 ng-bind-html="'FORM_HELP_PASSWORD_WILL_BE_CHANGED' | translate:{password: edit.user.password}"></span>
        </p>
      </div>

        <!-- Login Tag -->
        <div class="form-group"
             ng-class="{
             'has-error': form.loginTag.$invalid
           }">
            <label class="control-label"
                   for="model">{{'LOGIN_TAG' | translate}}</label>
            <input class="form-control"
                   type="text"
                   name="loginTag"
                   id="loginTag"
                   ng-model="edit.user.loginTag"
                   ng-disabled="!edit.canEdit" />
            <p class="help-block"
               ng-if="form.loginTag.$valid">
                <span ng-if="!edit.user.loginTag && !edit.isCreate">{{'FORM_HELP_LOGIN_TAG_WILL_NOT_BE_CHANGED' | translate}}</span>
                <span ng-if="edit.user.loginTag"
                      ng-bind-html="'FORM_HELP_LOGIN_TAG_WILL_BE_CHANGED' | translate:{loginTag: edit.user.loginTag}"></span>
            </p>
        </div>

        <!-- Internal Cost per Hour -->
        <div class="form-group" ng-if="edit.canReadCostsPerHour">
            <label class="control-label" for="internalCostPerHour">{{'user.internalCostPerHour.label' | translate}}</label>
            <input class="form-control"
                   type="number"
                   name="internalCostPerHour"
                   id="internalCostPerHour"
                   ng-model="edit.user.internalCostPerHour"
                   ng-disabled="!edit.canEdit">
        </div>

        <!-- External Cost per Hour -->
        <div class="form-group" ng-if="edit.canReadCostsPerHour">
            <label class="control-label" for="externalCostPerHour">{{'user.externalCostPerHour.label' | translate}}</label>
            <input class="form-control"
                   type="number"
                   name="externalCostPerHour"
                   id="externalCostPerHour"
                   ng-model="edit.user.externalCostPerHour"
                   ng-disabled="!edit.canEdit">
        </div>

      <!-- COLOR -->
      <div class="form-group">
        <label class="control-label" for="color">{{'POI_COLOR' | translate}}</label>
        <div class="form-control"
             colorpicker
             name="color"
             id="color"
             ng-model="edit.user.color"
             ng-style="{'background-color': edit.getSelectedUserColor()}">
        </div>
      </div>

        <div class="form-group" ng-if="edit.isRegionalManager && edit.wineArea.length == 0">
            <label class="control-label" for="wineArea">{{'WINE_AREA' | translate}}</label>
            <input class="form-control"
                   type="text"
                   name="wineArea"
                   id="wineArea"
                   maxlength="254"
                   ng-model="edit.user.wineArea"
                   ng-disabled="!edit.canEdit" />
        </div>
        <div class="form-group" ng-if="edit.isRegionalManager && edit.wineArea.length > 0">
            <label class="control-label">{{ 'WINE_AREA' | translate }}</label>
            <select class="form-control"
                    name="wineArea"
                    ng-disabled="!edit.canEdit"
                    ng-model="edit.user.wineArea"
                    ng-options="wineArea for wineArea in edit.wineArea">
                <option value="">{{'MAKE_CHOICE' | translate}}</option>
            </select>
        </div>

      <!-- PERMISSIONS -->
      <div class="form-group"
           ng-class="{
             'has-warning': !edit.isPermissionsReasonable()
           }">
        <label class="control-label">{{'PERMISSIONS' | translate}}</label>
        <uib-accordion close-others="false">
            <div uib-accordion-group class="panel-default" ng-repeat="module in edit.modules" ng-class="{selected: edit.getSelected(module.permissions).length > 0}">
                <uib-accordion-heading>
                    {{module.translatedLabel}} ({{edit.getSelected(module.permissions).length}}/{{module.permissions.length}}) <a href="#" class="pull-right select-link" ng-click="$event.preventDefault(); $event.stopPropagation(); edit.setPermissionsSelected(module.permissions, edit.getSelected(module.permissions).length === 0); form.$setDirty()" ng-if="edit.canEdit">{{ edit.getSelected(module.permissions).length === 0 ? ('SELECT_ALL' | translate) : ('DESELECT_ALL' | translate) }}</a>
                </uib-accordion-heading>
                <ul class="list-group">
                  <li class="list-group-item" ng-repeat="permission in module.permissions">
                    <div class="checkbox">
                      <label>
                          <input type="checkbox" ng-model="permission.selected" ng-disabled="!edit.canEdit"><span>{{permission.translatedLabel}}</span> <small class="help-block text-muted" ng-bind-html="edit.getPermissionTooltip(module, permission)"></small>
                      </label>
                    </div>
                  </li>
                </ul>
            </div>
        </uib-accordion>
        <p class="help-block" ng-if="!edit.isPermissionsReasonable()">
          {{'FORM_HELP_PERMISSIONS_NOT_REASONABLE' | translate}}
        </p>
      </div>
    </ng-form>
  </div>
</div>

(function () {
  'use strict';

  class PoiDeleteCtrl {
    constructor(item, vineyards) {
      let vm = this,
          maxVineyards = 10,
          vineyardNames = item.siteIds.map(vId => vineyards[vId].label),
          reducedVineyardNames = vineyardNames.slice(0, maxVineyards);
      if (reducedVineyardNames.length < vineyardNames.length) {
        reducedVineyardNames.push('...');
      }
      vm.item = item;
      vm.fullVineyards = vineyardNames.join(', ');
      vm.reducedVineyards = reducedVineyardNames.join(', ');
    }
  }

  PoiDeleteCtrl.$inject = ['item', 'vineyards'];

  /**
   * @ngdoc object
   * @name poi.delete.controller:PoiDeleteCtrl
   *
   * @description
   *
   */
  angular
    .module('poi.delete')
    .controller('PoiDeleteCtrl', PoiDeleteCtrl);
}());

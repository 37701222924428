<div class="form-group">
    <label class="control-label">{{'PARCELS_PARAM_NUMBER_OF_ROWS' | translate}}</label>
    <div class="form-control-static">
        <span ng-if="edit.item.numberOfRows">{{edit.item.numberOfRows}}</span>
        <span ng-if="!edit.item.numberOfRows">-</span>
    </div>
</div>
<div class="form-group">
    <label class="control-label">{{'PARCELS_PARAM_AVG_STRIKE_LENGTH' | translate}}</label>
    <div class="form-control-static">
        <span ng-if="edit.item.avgStrikeLength">{{edit.item.avgStrikeLength}} m</span>
        <span ng-if="!edit.item.avgStrikeLength">-</span>
    </div>
</div>
<div class="form-group">
    <label class="control-label" ng-if="!edit.domainWachau">{{'PARCELS_PARAM_NUMBER_OF_GRAPEVINES' | translate}}</label>
    <label class="control-label" ng-if="edit.domainWachau">Stöcke/ha</label>
    <div class="form-control-static" ng-if="!edit.domainWachau">
        <span ng-if="edit.item.numberOfGrapevines">{{edit.item.numberOfGrapevines}}</span>
        <span ng-if="!edit.item.numberOfGrapevines">-</span>
    </div>
    <div class="form-control-static" ng-if="edit.domainWachau">
        <span ng-if="edit.item.numberOfGrapevines">{{edit.item.numberOfGrapevines / edit.item.area}}</span>
        <span ng-if="!edit.item.numberOfGrapevines">-</span>
    </div>
</div>

<div class="forgot-password-page">
  <div class="forgot-password">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-sm-offset-3">
          <form ng-submit="forgotPassword.send()" name="forgotPasswordForm">
            <img class="image-responsive" src="images/logo_white.svg" />
            <h4>{{'FORGOT_PASSWORD_DIALOG' | translate}}</h4>
            <div class="form-group" ng-class="{
              'has-error': loginForm.username.$error.username
            }">
              <input class="form-control" type="email" ng-model="forgotPassword.user.username" name="username" placeholder="{{'EMAIL' | translate}}" required />
            </div>
            <div class="alert alert-danger" ng-if="forgotPassword.emailNotFound">
              <p>{{'MESSAGE_EMAIL_NOT_FOUND' | translate}}</p>
            </div>
            <div class="form-group">
              <input class="btn btn-primary" type="submit" value="{{'SEND_EMAIL_BUTTON' | translate}}" ng-disabled="forgotPasswordForm.$invalid || forgotPassword.emailSent" />
            </div>
            <div class="alert alert-success" ng-if="forgotPassword.emailSent">
              <p>{{'FORGOT_PASSWORD_EMAIL_SENT' | translate}}</p>
            </div>
            <div class="back-to-login">
            	<a href="" ui-sref="login">{{'FORGOT_PASSWORD_GO_LOGIN' | translate}}</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div ng-include src="'views/footer.tpl.html'"></div>
</div>
(function () {
  'use strict';

  /* @ngdoc object
   * @name exports
   * @description
   *
   */
  angular
    .module('exports.exports', [
      'ui.router',
      'exports.exports.list',
      'exports.exports.delete'
    ]);
}());

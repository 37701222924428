(function () {
  'use strict';

  /* @ngdoc object
   * @name parcels
   * @description
   *
   */
  angular
    .module('parcels', [
      'constants',
      'ui.router',
      'parcels.list',
      'parcels.edit',
      'parcels.delete',
      'parcels.selection'
    ]);
}());

<sub-menu items="edit.submenu.items" fluid="true" form="form"></sub-menu>
<main-menu items="edit.mainmenu.items"></main-menu>

<ng-form name="form" class="base-layout order-edit">
    <div ng-class="{
    'base-layout-map': !edit.isCreate,
    'base-layout-default': edit.isCreate
  }">
        <div ng-class="{
      'base-layout-map-content': !edit.isCreate
    }">

            <main-message message="edit.message" fluid="'nested'"></main-message>

            <ul ng-init="edit.activeTab = 0"
                class="nav nav-tabs">

                <!-- BASE -->
                <li ng-click="edit.activeTab = 0"
                    ng-class="{
              'active': edit.activeTab === 0
            }">
                    <a>
            <span ng-class="{
              'text-danger': edit.countErrorsOnTab(formBase)
            }">
              {{'ORDER_TAB_BASE' | translate}}
              <span style="margin-left: 5px;"
                    class="badge"
                    ng-if="edit.countErrorsOnTab(formBase)">{{edit.countErrorsOnTab(formBase)}}</span>
            </span>
                    </a>
                </li>

                <!-- ASSIGNMENTS -->
                <li ng-click="edit.activeTab = 1"
                    ng-class="{
              'active': edit.activeTab === 1
            }">
                    <a>
            <span ng-class="{
                'text-danger': edit.countErrorsOnTab(formAssignments)
              }">
              {{'ORDER_TAB_ASSIGNMENTS' | translate}}
              <span style="margin-left: 5px;"
                    class="badge"
                    ng-if="edit.countErrorsOnTab(formAssignments)">{{edit.countErrorsOnTab(formAssignments)}}</span>
            </span>
                    </a>
                </li>

                <!-- COMMENT -->
                <li ng-if="!edit.isCreate && (edit.type === 'regular' || edit.type === 'finished') && edit.comments.length"
                    ng-click="edit.activeTab = 2"
                    ng-class="{
          'active': edit.activeTab === 2
        }">
                    <a>
                        {{'ORDER_TAB_COMMENTS' | translate}}
                    </a>
                </li>

                <!-- PATHTIME -->
                <li ng-if="!edit.isCreate && (edit.type === 'regular' || edit.type === 'finished') && edit.comments.length && edit.canUsePathTime && edit.canReadGPSProfile"
                    ng-click="edit.activeTab = 3"
                    ng-class="{
          'active': edit.activeTab === 3
        }">
                    <a>
                        {{'ORDER_TAB_PATHTIME' | translate}}
                    </a>
                </li>

                <!-- ADDITIONAL -->
                <li ng-if="!edit.isCreate && (edit.type === 'regular' || edit.type === 'finished') && edit.additionalData !== null"
                    ng-click="edit.activeTab = 'additionalData'"
                    ng-class="{
              'active': edit.activeTab === 'additionalData'
            }">
                    <a>
                        <ng-include src="'orders/edit/views/edit-additional-tab-header.tpl.html'"></ng-include>
                    </a>
                </li>

                <!-- CYCLIC -->
                <li ng-if="edit.type === 'cyclic'"
                    ng-click="edit.activeTab = 4"
                    ng-class="{
              'active': edit.activeTab === 4
            }">
                    <a>
            <span ng-class="{
              'text-danger': edit.countErrorsOnTab(formCyclic)
            }">
              {{'ORDER_TAB_CYCLIC' | translate}}
              <span style="margin-left: 5px;"
                    class="badge"
                    ng-if="edit.countErrorsOnTab(formCyclic)">{{edit.countErrorsOnTab(formCyclic)}}</span>
            </span>
                    </a>
                </li>

                <!-- IMAGES -->
                <li ng-if="edit.canDownloadImage && edit.files && edit.files.length"
                    ng-click="edit.activeTab = 5"
                    ng-class="{
              'active': edit.activeTab === 5
            }">
                    <a>
                        {{'ORDER_TAB_IMAGES' | translate}}
                        <span class="badge">{{edit.files.length}}</span>
                    </a>
                </li>
            </ul>

            <!-- BASE -->
            <ng-form name="formBase"
                     ng-show="edit.activeTab === 0">
                <ng-include src="'orders/edit/views/edit-base.tpl.html'"></ng-include>
                <div class="row">
                    <div class="col-md-12">
                        <div class="alert alert-warning"
                             role="alert"
                             ng-if="!edit.isCreate && edit.type === 'cyclic'">
                            {{'CYCLIC_ORDER_CREATION_WARNING' | translate}}
                        </div>
                    </div>
                </div>
            </ng-form>

            <!-- ASSIGNMENTS -->
            <ng-form name="formAssignments"
                     ng-show="edit.activeTab === 1">
                <ng-include src="'orders/edit/views/edit-assignments.tpl.html'"></ng-include>
            </ng-form>

            <!-- COMMENT -->
            <ng-form name="formComments"
                     ng-if="edit.activeTab === 2 && !edit.isCreate && (edit.type === 'regular' || edit.type === 'finished') && edit.comments.length">
                <ng-include src="'orders/edit/views/edit-comments.tpl.html'"></ng-include>
            </ng-form>

            <!-- PATHTIME -->
            <ng-form name="formTraveTime"
                     ng-if="edit.activeTab === 3 && !edit.isCreate && (edit.type === 'regular' || edit.type === 'finished')">
                <ng-include src="'orders/edit/views/edit-path-time.tpl.html' "></ng-include>
            </ng-form>

            <!-- ADDITIONAL -->
            <ng-form name="formAdditional"
                     ng-show="edit.activeTab === 'additionalData' && !edit.isCreate && (edit.type === 'regular' || edit.type === 'finished') && edit.additionalData !== null">
                <ng-include src="'orders/edit/views/edit-additional-tab-content.tpl.html'"></ng-include>
            </ng-form>

            <!-- CYCLIC -->
            <ng-form name="formCyclic"
                     ng-show="edit.type === 'cyclic' && edit.activeTab === 4">
                <ng-include src="'orders/edit/views/edit-cyclic.tpl.html'"
                            ng-if="edit.type === 'cyclic'"></ng-include>
            </ng-form>

            <!-- IMAGES -->
            <ng-include src="'orders/edit/views/edit-images.tpl.html'"
                        ng-if="edit.activeTab === 5 && edit.canDownloadImage && edit.files && edit.files.length"></ng-include>
        </div>
        <div ng-if="!edit.isCreate"
             class="base-layout-map-map">
            <vc-map-display
                    map-id="'order-map-id'"
                    zoom-to-selection="edit.zoomToSelection"
                    selected-vineyards="edit.selectedVineyards"
                    new-vineyards="edit.newVineyards"
                    vineyard-map-data="edit.mapData"
                    site-status-data="edit.siteStatus"
                    icon-data="edit.modifiedIconData ? edit.modifiedIconData : edit.iconData"
                    users="edit.userNames"
                    user-path-data="edit.masterUsers"
                    temp-worker-path-data="edit.temporaryWorkers"
                    user-color-data="edit.colors"
                    on-vineyard-click="edit.onVineyardClick(vineyardId)">
            </vc-map-display>
        </div>
    </div>
</ng-form>

(function () {
  'use strict';

  angular
    .module('fertilization')
    .config(config);

  function config($stateProvider, permissions) {
    $stateProvider
      .state('fertilization', {
        abstract: true,
        url: '/fertilization',
        template: '<ui-view />',
        data: {
          requiredPerms: [permissions.FERTILIZATION_CAN_USE]
        }
      });
  }
}());

(function () {
  'use strict';

  angular
    .module('auth')
    .config(config);

  function config($httpProvider) {
    $httpProvider.interceptors.push(['$window', '$q', '$injector', 'apiBasePath', ($window, $q, $injector, apiBasePath) => {
      return {
        request: (cfg) => {
          let UserService = $injector.get('UserService');
          if (cfg.headers.IGNORE_INTERCEPTOR) {
            delete cfg.headers.IGNORE_INTERCEPTOR;
          } else if (UserService.user) {
            cfg.headers.Auth = UserService.user.token;
          }
          return cfg;
        },
        responseError: (response) => {
          let UserService = $injector.get('UserService'),
              AuthApi = $injector.get('AuthApi'),
              $http = $injector.get('$http'),
              $state = $injector.get('$state'),
              startState = $injector.get('startState');
          switch (response.status) {
            case 401:
              let deferred = $q.defer();
              if (response.config.url.match(new RegExp('\/refresh$')) || UserService.user === null) {
                deferred.reject();
              } else {
                let vcMain = UserService.getAuthToken();
                AuthApi.save({
                  action: 'refresh'
                }, {
                  refreshToken: vcMain.refreshToken
                }, (data) => {
                  let customerId = UserService.user.customerId,
                      isImpersonating = UserService.isImpersonating(),
                      accessToken = UserService.user.token;
                  // make sure any saved access non impersonation token is set as the actual
                  // access token
                  UserService.stopImpersonation();
                  // then store the actual new access token
                  UserService.storeAuthToken(data.accessToken, data.refreshToken);
                  // if the user is currently impersonating then we need to get a new
                  // impersonation token
                  if (isImpersonating) {
                    // use $http directly here as the access token has to be set manually to the
                    // correct one as the impersonation will continue while this request
                    // dispatches which would set the invalid impersonation token into the Auth
                    // header
                    $http.post(apiBasePath + 'auth/impersonate', {}, {
                      headers: {
                        Auth: data.accessToken,
                        IGNORE_INTERCEPTOR: true
                      },
                      params: {
                        customerId: customerId
                      }
                    }).then(tokenResponse => {
                      UserService.stopImpersonation();
                      UserService.startImpersonation(tokenResponse.data.accessToken);
                      dispatchRequest(response.config, deferred);
                    }).catch(() => {
                      UserService.stopImpersonation();
                      deferred.reject();
                      $state.reload();
                    });
                    // because the user is impersonating, accessToken is an impersonation token;
                    // to keep the "impersonating" status while the token is being refreshed we
                    // make sure that the (now invalid) token is set for impersonation
                    UserService.startImpersonation(accessToken);
                  } else {
                    dispatchRequest(response.config, deferred);
                  }
                }, () => {
                  // at this point we just give up and logout the user
                  UserService.clearAuthToken();
                  $state.go('login');
                  deferred.reject();
                });
              }
              return deferred.promise;
            case 403:
              $state.go(startState);
              return $q.reject(response);
            default:
              return $q.reject(response);
          }

          function dispatchRequest(requestConfig, deferred) {
            $http(requestConfig).then(deferred.resolve, deferred.reject);
          }
        }
      };
    }]);
  }
  config.$inject = ['$httpProvider'];
}());

(function () {
  'use strict';

  angular
    .module('businessManagement.businesses.list')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('businesses.list', {
        url: '/list?sorter&dir&filter',
        templateUrl: 'business-management/businesses/list/views/list.tpl.html',
        controller: 'BusinessesListCtrl',
        controllerAs: 'list',
        params: {
          dir: {
            dynamic: true,
            value: 'asc',
            squash: true
          },
          sorter: {
            dynamic: true,
            value: 'label',
            squash: true
          },
          filter: {
            value: '',
            squash: true
          },
          message: {
            value: null
          }
        }
      });
  }
}());

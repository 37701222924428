<div class="form-group">
    <label class="control-label" ng-if="!edit.domainWachau">{{'site.region.label' | translate}}</label>
    <label class="control-label" ng-if="edit.domainWachau">Weinbaugebiet</label>
    <input class="form-control"
            type="text"
            ng-maxlength="255"
            ng-readonly="!edit.fullEditAvailable || edit.isShared"
            ng-model="edit.item.region" />
    <p class="help-block"
       ng-messages="form.region.$error">
        <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 255})}}</span>
    </p>
</div>

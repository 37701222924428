<div ng-class="{
    'container': !mainMessage.fluid,
    'container-fluid': mainMessage.fluid === true
  }">
  <div ng-messages="mainMessage.messages">
    <div class="alert alert-info"
         ng-message="noData">
      {{'MESSAGE_INFO_NO_DATA' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="loadingError">
      {{'MESSAGE_DANGER_LOADING_ERROR' | translate}}
    </div>
    <div class="alert alert-success"
         ng-message="deletingSuccess">
      {{'MESSAGE_DANGER_DELETING_SUCCESS' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="deletingError">
      {{'MESSAGE_DANGER_DELETING_ERROR' | translate}}
    </div>
    <div class="alert alert-success"
         ng-message="savingSuccess">
      {{'MESSAGE_SUCCESS_SAVING_SUCCESS' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="savingError">
      {{'MESSAGE_DANGER_SAVING_ERROR' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="mandatoryToHaveWorkers">
      {{'MESSAGE_MANDATORY_TO_HAVE_USERS' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="errorUpload">
      {{'MESSAGE_UPLOAD_ERROR' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="emailAlreadyExists">
      {{'MESSAGE_EMAIL_ALREADY_REGISTERED' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="invalidPolyLine">
      {{'WINERY_MANAGEMENT_WINERIES_MESSAGE_INVALID_POLYLINE' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="errorKmlUnevenPoints">
      {{'WINERY_MANAGEMENT_WINERIES_MESSAGE_KML_UNEVEN_POINTS' | translate}}
    </div>
    <div class="alert alert-success"
         ng-message="copySuccess">
      {{'MESSAGE_SUCCESS_COPY_SUCCESS' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="copyError">
      {{'MESSAGE_DANGER_COPY_ERROR' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="alreadyExists">
      {{'MESSAGE_ALREADY_EXISTS' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="orderTypesDependantOrders">
      {{'MESSAGE_ORDER_TYPES_DEPENDANT_ORDERS' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="overlappingTimeLogError">
      {{'TIME_TRACKING_TIME_LOG_NEW_ERROR' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="equipmentOfTypeExisting">
      {{'EQUIPMENT_TYPES_DELETE_ERROR' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="entityHasChanged">
      {{'ENTITY_HAS_CHANGED_ERROR' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="orderDeletingErrorFollowUp">
      {{'order.error.delete.used_in_workflow' | translate}}
    </div>
    <div class="alert alert-danger"
         ng-message="tenantDeletingErrorIsCurrent">
      {{'tenant.error.delete.is_current' | translate}}
    </div>
  </div>
</div>

(function () {
  'use strict';

  /* @ngdoc object
   * @name timeTracking
   * @description
   *
   */
  angular
    .module('timeTracking', [
      'ui.router',
      'timeTracking.costCenters',
      'ngCookies'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dashboard.directive:alarmPanel
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="dashboard">
       <file name="index.html">
        <alarm-panel></alarm-panel>
       </file>
     </example>
   *
   */
  angular
    .module('dashboard')
    .directive('alarmPanel', alarmPanel);

  function alarmPanel() {
    return {
      restrict: 'E',
      scope: {
        tasks: '<',
      },
      templateUrl: 'dashboard/directives/alarm-panel-directive.tpl.html',
      replace: false,
      bindToController: true,
      controllerAs: 'alarmPanel',
      controller(UserService) {
          let vm = this;

          vm.accountId = UserService.user.accountId;

          vm.orderVisibilityModeIcon = (item) => {
              if (item.orderVisibilityMode === 'ACCOUNT') {
                  return 'images/orderReadWrite.svg';
              } else if (item.orderVisibilityMode === 'ACCOUNT_READ_ONLY') {
                  return 'images/orderReadOnly.svg';
              } else {
                  return 'images/orderTenant.svg';
              }
          };
      }
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('parcels.edit.orders.list')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('parcels.edit.orders.list', {
        url: '/list?page&sorter&dir',
        // templateUrl: 'orders/list/views/list.tpl.html',
        templateUrl: 'parcels/edit/orders/list/views/list.tpl.html',
        controller: 'RegularOrdersListCtrl',
        controllerAs: 'list'
      });
  }
}());

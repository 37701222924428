<div ng-if="!login.isGwfUrl && !login.isDwUrl" class="login-page">
  <div class="login">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-sm-offset-3">
          <form ng-submit="login.login()" name="loginForm">
            <img class="image-responsive" src="images/logo_white.svg" />
            <h4>{{'LOGIN_DIALOG' | translate}}</h4>
            <div class="form-group" ng-class="{
              'has-error': loginForm.email.$error.email
            }">
              <input class="form-control" type="email" ng-model="login.user.username" name="email" placeholder="{{'EMAIL' | translate}}" required />
            </div>
            <div class="form-group">
              <input class="form-control" type="password" ng-model="login.user.password" placeholder="{{'PASSWORD' | translate}}" required />
            </div>
            <div class="alert alert-danger" ng-if="login.error !== null">
              {{ (login.error === 'loadingError' ? 'LOGIN_ERROR' : 'LOGIN_PERMISSION_ERROR') | translate}}
            </div>
            <div class="form-group">
              <input class="btn btn-primary" type="submit" value="{{'LOGIN' | translate}}" ng-disabled="loginForm.$invalid" />
            </div>
            <div class="forgot-password">
              <a href="#" ui-sref="forgotPassword">{{'FORGOT_PASSWORD' | translate}}</a>
            </div>
          </form>
        </div>
     </div>
    </div>
  </div>
  <div ng-include src="'views/footer.tpl.html'"></div>
</div>

<div class="login-page-dw" ng-if="login.isDwUrl">
    <div class="login">
        <div class="container">
            <div class="row spacer-bottom">
                <div class="col-sm-6 col-sm-offset-3">
                    <img class="image-responsive" src="images/dw-icon.svg" />
                    <form ng-submit="login.login()" name="loginForm">
                        <h2>Mitgliederportal</h2>
                        <div class="form-group" ng-class="{'has-error': loginForm.email.$error.email}">
                            <input class="form-control" type="email" ng-model="login.user.username" name="email" placeholder="{{'EMAIL' | translate}}" required />
                        </div>
                        <div class="form-group">
                            <input class="form-control" type="password" ng-model="login.user.password" placeholder="{{'PASSWORD' | translate}}" required />
                        </div>
                        <div class="alert alert-danger" ng-if="login.error !== null">
                            {{ (login.error === 'loadingError' ? 'LOGIN_ERROR' : 'LOGIN_PERMISSION_ERROR') | translate}}
                        </div>
                        <div class="form-group">
                            <input class="btn btn-dw-orange" type="submit" value="{{'LOGIN' | translate}}" ng-disabled="loginForm.$invalid" />
                        </div>
                        <div class="forgot-password">
                            <a class="link-dw-grey" href="#" ui-sref="forgotPassword">{{'FORGOT_PASSWORD' | translate}}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="login-page-gwf" ng-if="login.isGwfUrl">
    <div class="login">
        <div class="container">
            <div class="row spacer-bottom">
                <div class="col-sm-6 col-sm-offset-3">
                    <img class="image-responsive" src="images/gwf-icon.svg" />
                    <form ng-submit="login.login()" name="loginForm">
                        <h2>Mitgliederportal</h2>
                        <div class="form-group" ng-class="{
              'has-error': loginForm.email.$error.email
            }">
                            <input class="form-control" type="email" ng-model="login.user.username" name="email" placeholder="{{'EMAIL' | translate}}" required />
                        </div>
                        <div class="form-group">
                            <input class="form-control" type="password" ng-model="login.user.password" placeholder="{{'PASSWORD' | translate}}" required />
                        </div>
                        <div class="alert alert-danger" ng-if="login.error !== null">
                            {{ (login.error === 'loadingError' ? 'LOGIN_ERROR' : 'LOGIN_PERMISSION_ERROR') | translate}}
                        </div>
                        <div class="form-group">
                            <input class="btn btn-gwf-grey" type="submit" value="{{'LOGIN' | translate}}" ng-disabled="loginForm.$invalid" />
                        </div>
                        <div class="forgot-password">
                            <a class="link-gwf-grey" href="#" ui-sref="forgotPassword">{{'FORGOT_PASSWORD' | translate}}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

(function () {
  'use strict';

  /* @ngdoc object
   * @name parcels.edit.beacons
   * @description
   *
   */
  angular
    .module('parcels.edit.beacons', []);
}());

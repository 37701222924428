(function () {
    'use strict';

    class ManagementUnitEditCtrl {
        constructor($state, $stateParams, CrudApi, siteEntities, UserService, permissions) {
            let vm = this;

            vm.new = $stateParams.id ? false : true;

            // Initial message
            vm.message = $stateParams.message;

            // check whether the user can see and edit visibility mode
            vm.canEditVisibility = UserService.hasPerm(permissions.ADMIN_CAN_EDIT_VISIBILITY_MODE);

            // Add items to submenu
            vm.submenu = {
                items: [{
                    type: 'button',
                    text: 'BUTTON_SAVE',
                    click: () => {
                        CrudApi.save({
                            id: $stateParams.id,
                            entity: 'managementUnits'
                        }, vm.item, (data) => {
                            $state.go('managementUnits.edit', {
                                id: data.id,
                                message: 'savingSuccess'
                            }, {
                                reload: true
                            });
                        }, (response) => {
                            if (response.status === 410) {
                                vm.message = 'entityHasChanged';
                            } else {
                                vm.message = 'savingError';
                            }
                        });
                    }
                }]
            };

            vm.mainmenu = {
                items: []
            };

            if (!vm.new) {
                vm.parcels = CrudApi.query({
                    entity: siteEntities,
                    sortBy: 'label',
                    sortDir: 'asc',
                    managementUnit: $stateParams.id
                });
            }

            if (!$stateParams.id) {
                vm.item = {
                    visibilityMode: 'TENANT'
                };
                vm.mainmenu.items.push({
                    label: 'MANAGEMENT_UNIT_NEW',
                    state: 'managementUnits.edit'
                });
            } else {
                // Api call
                vm.item = CrudApi.get({
                    id: $stateParams.id,
                    entity: 'managementUnits'
                }, (data) => {
                    vm.mainmenu.items.push({
                        label: data.label,
                        translate: false,
                        state: 'managementUnits.edit',
                        params: {
                            id: $stateParams.id
                        }
                    });
                }, () => {
                    vm.message = 'loadingError';
                });
            }
        }
    }

    ManagementUnitEditCtrl.$inject = ['$state', '$stateParams', 'CrudApi', 'siteEntities', 'UserService', 'permissions'];

    /**
     * @ngdoc object
     * @name managementUnits.edit.controller:EditCtrl
     *
     * @description
     *
     */
    angular
        .module('managementUnits.edit')
        .controller('ManagementUnitEditCtrl', ManagementUnitEditCtrl);
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name fertilization
   * @description
   *
   */
  angular
    .module('fertilization', [
      'ui.router',
      'fertilization.nutrientComparisonExports',
      'fertilization.phDemandExports',
      'fertilization.nDemandExports'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name constants.constant:tileColors
   *
   * @description
   *
   */
  angular
    .module('constants')
    .constant('tileColors', [
      '#3d55f2',
      '#a60058',
      '#00ff00',
      '#3de6f2',
      '#ff40a6',
      '#362699',
      '#ff0044',
      '#308fbf',
      '#aa00ff',
      '#00f281',
      '#3677d9',
      '#d93a00'
    ]);
}());

<sub-menu items="list.submenu.items"></sub-menu>
<main-menu></main-menu>

<div class="temporary-workers-list base-layout">
  <div class="base-layout-default">
    <main-message message="list.message" fluid="'nested'"></main-message>
    <div class="table-responsive" ng-if="list.items.length">
      <table class="table table-hover">
        <thead>
          <th ng-click="list.sort('name')">
            {{'TEMPORARY_WORKER_NAME' | translate}}
            <span class="glyphicon pull-right text-muted"
                  ng-if="list.sorter === 'name'"
                  ng-class="{
                    'glyphicon-chevron-down': list.dir === 'asc',
                    'glyphicon-chevron-up': list.dir === 'desc'
                  }"></span>
          </th>
          <th ng-click="list.sort('quantity')">
            {{'TEMPORARY_WORKER_QUANTITY' | translate}}
            <span class="glyphicon pull-right text-muted temporary-workers-list-header"
                  ng-if="list.sorter === 'quantity'"
                  ng-class="{
                    'glyphicon-chevron-down': list.dir === 'asc',
                    'glyphicon-chevron-up': list.dir === 'desc'
                  }"></span>
          </th>
          <th ng-click="list.sort('creationDate')">
            {{'TEMPORARY_WORKER_CREATION_DATE' | translate}}
            <span class="glyphicon pull-right text-muted"
                  ng-if="list.sorter === 'creationDate'"
                  ng-class="{
                    'glyphicon-chevron-down': list.dir === 'asc',
                    'glyphicon-chevron-up': list.dir === 'desc'
                  }"></span>
          </th>
          <th ng-click="list.sort('expirationDate')">
            {{'TEMPORARY_WORKER_EXPIRATION_DATE' | translate}}
            <span class="glyphicon pull-right text-muted"
                  ng-if="list.sorter === 'expirationDate'"
                  ng-class="{
                    'glyphicon-chevron-down': list.dir === 'asc',
                    'glyphicon-chevron-up': list.dir === 'desc'
                  }"></span>
          </th>
          <th class="text-center">{{'DOWNLOAD' | translate}}</th>
          <th class="text-center">{{'DELETE' | translate}}</th>
        </thead>
        <tbody>
          <tr ng-repeat="item in list.items"
              ui-sref="temporaryWorkers.edit.download({
                id: item.id,
                page: 1,
                message: null
              })"
              ng-class="{
                'danger': item.expired
              }">
            <td>{{item.name}}</td>
            <td>{{item.quantity}}</td>
            <td>{{item.creationDate | date}}</td>
            <td>{{item.expirationDate | date}}</td>
            <td class="text-center">
              <button class="btn btn-link"
                      ng-click="$event.stopPropagation(); list.download(item, 'twp')">
                <span class="glyphicon glyphicon-cloud-download"
                      uib-tooltip="{{'TOOLTIP_PACKAGE_DOWNLOAD' | translate }}"
                      tooltip-placement = "left"
                      tooltip-append-to-body = "true"></span>
              </button>
            </td>
            <td class="text-center">
              <button class="btn btn-link"
                      ng-click="$event.stopPropagation(); list.delete(item)">
                <span class="glyphicon glyphicon-trash"
                      uib-tooltip="{{'TOOLTIP_PACKAGE_REMOVE' | translate }}"
                      tooltip-placement = "left"
                      tooltip-append-to-body = "true"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ul uib-pagination
        total-items="list.total"
        ng-if="list.total > list.itemsPerPage"
        ng-model="list.page"
        items-per-page="list.itemsPerPage"
        ng-change="list.paginate()"
        boundary-links="true"
        max-size="list.maxSize"
        previous-text="&lsaquo;"
        next-text="&rsaquo;"
        first-text="&laquo;"
        last-text="&raquo;">
    </ul>
  </div>
</div>
<sub-menu items="edit.submenu.items"
          form="edit.form"
          ng-if="edit.canEdit || edit.canCreate"
          fluid="true"></sub-menu>
<main-menu items="edit.mainmenu.items"></main-menu>

<ng-form name="form" class="base-layout poi-edit">
  <div class="base-layout-map">
    <div class="base-layout-map-content">
        <main-message message="edit.message" fluid="'nested'"></main-message>
        <ng-form name="edit.form"
                class="poi-edit">
            <!-- TYPE -->
            <div class="form-group type"
                ng-class="{
                'has-error': edit.form.type.$invalid
                }">
                <label class="control-label"
                        for="type">{{'POI_TYPE' | translate}}</label>

                <uib-accordion>
                    <div uib-accordion-group
                        class="panel-default"
                        ng-repeat="(category, types) in edit.types | orderBy : ['categoryTranslated', 'id'] | groupBy: 'category'"
                        heading="{{ types[0].categoryTranslated }}"
                        ng-class="{'category-selected': edit.item.type.category === category}"
                        is-disabled="!edit.new && !edit.canEdit"
                        is-open="edit.categoryOpen[category]">
                        <button class="btn btn-default type-btn"
                                uib-tooltip="{{ type.labelTranslated }}"
                                ng-repeat="type in types"
                                ng-click="edit.item.type = type; edit.form.type.$setDirty()"
                                ng-class="{active: edit.item.type.id === type.id}"
                                ng-disabled="!edit.new && !edit.canEdit">
                            <img ng-src="{{ edit.generateSVGIconPath(type.iconCategory, type.iconName) }}" alt="{{ type.labelTranslated }}">
                        </button>
                    </div>
                </uib-accordion>
                <input type="hidden"
                       name="type"
                       id="type"
                       ng-model="edit.item.type.id"
                       ng-disabled="!edit.new && !edit.canEdit"
                       required />
                <p class="help-block"
                    ng-messages="edit.form.type.$error">
                    <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
                </p>
            </div>
            <!-- DESCRIPTION -->
            <div class="form-group"
                ng-class="{
                'has-error': edit.form.description.$invalid
                }">
                <label class="control-label"
                        for="description">{{'POI_DESCRIPTION' | translate}}</label>
                <textarea class="form-control"
                    name="description"
                    id="description"
                    ng-model="edit.item.description"
                    ng-disabled="!edit.new && !edit.canEdit"
                    ng-maxlength="10000">
                </textarea>
                <p class="help-block"
                    ng-messages="edit.form.description.$error">
                    <span ng-message="maxlength">{{('FORM_HELP_MAXLENGTH' | translate:{max: 10000})}}</span>
                </p>
            </div>
            <!-- POSITION -->
            <input type="hidden"
                    name="latitude"
                    id="latitude"
                    ng-model="edit.item.latitude"
                    ng-disabled="!edit.new && !edit.canEdit"
                    required />
            <input type="hidden"
                    name="longitude"
                    id="longitude"
                    ng-model="edit.item.longitude"
                    ng-disabled="!edit.new && !edit.canEdit"
                    required />
            <!-- COLOR -->
            <div class="form-group"
                ng-class="{
                'has-error': edit.form.color.$invalid
                }">
                <label class="control-label"
                        for="color">{{'POI_COLOR' | translate}}</label>
                <!-- EDIT ENABLED -->
                <div class="form-control"
                     colorpicker
                     name="color"
                     id="color"
                     ng-model="edit.item.color"
                     ng-if="edit.new || edit.canEdit"
                     ng-style="{'background-color': edit.item.color}"
                     required>
                </div>
                <!-- EDIT DISABLED -->
                <div class="form-control"
                     name="color"
                     id="color"
                     ng-if="!edit.new && !edit.canEdit"
                     ng-style="{'background-color': edit.item.color}"
                     disabled>
                </div>
                <p class="help-block"
                    ng-messages="edit.form.color.$error">
                    <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
                </p>
            </div>
            <!-- ROTATION -->
            <div class="form-group"
                ng-class="{
                'has-error': edit.form.rotation.$invalid
                }">
                <label class="control-label"
                        for="rotation">{{'POI_ROTATION' | translate}}</label>
                <div>
                    <rzslider ng-if="edit.item"
                              rz-slider-model="edit.item.rotation"
                              rz-slider-options="edit.sliderOptions">
                    </rzslider>
                </div>
                <input type="hidden"
                    name="rotation"
                    id="rotation"
                    ng-model="edit.item.rotation"
                    ng-disabled="!edit.new && !edit.canEdit"
                    required />
                <p class="help-block"
                    ng-messages="edit.form.rotation.$error">
                    <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
                </p>
            </div>
            <!-- VINEYARDS -->
            <div class="form-group">
                <label class="control-label"
                        for="vineyards">{{'POI_VINEYARDS' | translate}}</label>
                <parcels-selection
                    ng-model="edit.item.siteIds"
                    ng-if="edit.item && (edit.new || edit.canEdit || edit.item.siteIds.length)"
                    map-enabled="true"
                    initial-edit="edit.new"
                    ng-disabled="!edit.new && !edit.canEdit"
                    sums="false">
                </parcels-selection>
                <div
                    class="alert alert-info"
                    ng-if="!edit.new && !edit.canEdit && !edit.item.siteIds.length">
                    {{ 'POI_NO_VINEYARDS' | translate }}
                </div>
                <p class="help-block">
                    {{'POI_FORM_HELP_VINEYARDS' | translate}}
                </p>
            </div>
        </ng-form>
    </div>
    <div class="base-layout-map-map">
        <div class="map-container" ng-class="{'no-edit': !edit.canEdit || edit.new}">
            <vc-map-display
                map-id="edit.mapId"
                selected-vineyards="edit.selectedSites"
                new-vineyards="edit.newSites"
                vineyard-map-data="edit.mapData"
                icon-data="edit.iconData"
                hide-icon-control="true"
                change-icon-options="edit.changeIconOptions"
                zoom-to-selection="edit.zoomToSelection"
                on-map-click="edit.mapEditEnabled ? edit.onMapClick : null">
                <custom-control class="additional-button" position="TOP_RIGHT" index="1" ng-if="edit.canEdit && !edit.new">
                    <button class="btn btn-default"
                            ng-click="edit.mapEditEnabled = !edit.mapEditEnabled">
                        <span class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block glyphicon" ng-class="{
                            'glyphicon-pencil': !edit.mapEditEnabled,
                            'glyphicon-remove': edit.mapEditEnabled
                        }" aria-hidden="true"></span> <span class="hidden-xs hidden-sm hidden-md">{{ (edit.mapEditEnabled ? 'POI_DISABLE_MAP_EDIT_MODE' : 'POI_ENABLE_MAP_EDIT_MODE') | translate}}</span>
                    </button>
                </custom-control>
            </vc-map-display>
        </div>
    </div>
  </div>
</div>
(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name vcMain.directive:loadingSpinner
   * @restrict E
   *
   * @description This directive provides a simple loading spinner element as a indication that data on page is being loaded
   *
   * @example
     <example module="vcMain">
       <file name="index.html">
        <loading-spinner></loading-spinner>
       </file>
     </example>
   *
   */
  angular
    .module('vcMain')
    .directive('loadingSpinner', loadingSpinner);

  function loadingSpinner() {
    return {
      restrict: 'E',
      scope: true,
      templateUrl: 'directives/loading-spinner-directive.tpl.html',
      replace: false,
      controllerAs: 'loadingSpinner',
      bindToController: true,
      controller($timeout) {
        let vm = this;
        vm.showSpinner = false;

        $timeout(() => vm.showSpinner = true, 200);
      }
    };
  }
}());

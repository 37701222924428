(function () {
  'use strict';

  class WineTypeInfo {
    constructor($filter, wineTypes, $q, $translate) {
      // Contains all wines
      this.allWinesArray = wineTypes;

      // To return values for the multi select filter
      this.winesArray = [];

      // To return to parcel.edit
      this.masterObject = {};
      this.wineLevelTree = {};

      this.$filter = $filter;
      this.readyDefer = $q.defer();

      $translate.onReady(() => {
        this.setThirdWines($filter);
        this.setAllWines();
        this.readyDefer.resolve();
      });
    }

    whenReady() {
      return this.readyDefer.promise;
    }

    getWineKey(value) {
      let key = '',
          tValue;
      for (let i = 0; i < this.allWinesArray.length; i++) {
        tValue = this.$filter('translate')(this.allWinesArray[i]);
        if (tValue === value) {
          key = this.allWinesArray[i];
          break;
        }
      }
      return key;
    }

    setAllWines() {
      angular.forEach(this.allWinesArray, function (wineValue) {
        if (wineValue.indexOf('L3') === -1 && wineValue.indexOf('L2') === -1) {
          // First level Wine
          if (angular.isUndefined(this.wineLevelTree[wineValue])) {
            this.wineLevelTree[wineValue] = [];
          }

          if (angular.isUndefined(this.masterObject.firstWines)) {
            this.masterObject.firstWines = [];
          }
          this.masterObject.firstWines.push(wineValue);
        } else if (wineValue.indexOf('L3') === -1 && wineValue.indexOf('L2') !== -1) {
          // Second level Wine
          let stringArray = wineValue.split('_'),
              firstLevelKey = stringArray[stringArray.length - 2],
              parentWineKey = 'PARCELS_PARAM_VINE_' + firstLevelKey;

          // In case parent wine property is missing
          if (angular.isUndefined(this.wineLevelTree[parentWineKey])) {
            this.wineLevelTree[parentWineKey] = [];
          }

          this.wineLevelTree[parentWineKey].push(wineValue);

          if (angular.isUndefined(this.wineLevelTree[wineValue])) {
            this.wineLevelTree[wineValue] = [];
          }
        } else {
          // Third level Wine
          let stringArray = wineValue.split('_'),
              secondLevelKey = stringArray[stringArray.length - 2],
              firstLevelKey = stringArray[stringArray.length - 3],
              parentSecondWineKey = 'PARCELS_PARAM_VINE_' + firstLevelKey + '_' + secondLevelKey;

          if (angular.isUndefined(this.wineLevelTree[parentSecondWineKey])) {
            this.wineLevelTree[parentSecondWineKey] = [];
          }
          this.wineLevelTree[parentSecondWineKey].push(wineValue);
        }
      }, this);
    }

    getAllWines() {
      // Append key based tree structure for all parent wines
      this.masterObject.tree = this.wineLevelTree;
      return this.masterObject;
    }
    setThirdWines($filter) {
      angular.forEach(this.allWinesArray, function (wineValue) {
        if (wineValue.indexOf('L3') !== -1) {
          // This is a third level wine
          let stringArray = wineValue.split('_'),
              secondLevelKey = stringArray[stringArray.length - 2],
              firstLevelKey = stringArray[stringArray.length - 3];
          this.winesArray.push({
            thirdwine: $filter('translate')(wineValue),
            grp: this.getGroupName(firstLevelKey, secondLevelKey, $filter)
          });
        }
      }, this);
    }

    getThirdWines() {
      return this.winesArray;
    }

    getWinesObject() {
      return this.allWinesArray;
    }
    getGroupName(firstLevelKey, secondLevelKey, $filter) {
      let stringToReturn = '',
          firstLevelWineName = 'PARCELS_PARAM_VINE_' + firstLevelKey,
          secondLevelWineName = firstLevelWineName + '_' + secondLevelKey;
      stringToReturn = $filter('translate')(firstLevelWineName) + ' - ' + $filter('translate')(secondLevelWineName);

      return stringToReturn;
    }
  }

  /**
   * @ngdoc service
   * @name vcMain.service:WineTypeInfo
   *
   * @description
   *
   */
  angular
    .module('vcMain')
    .service('WineTypeInfo', WineTypeInfo);
}());

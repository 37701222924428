(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name orders.edit.filter:mapCoordinates
   *
   * @description returns an array of [langitude,longitude]. If no lang,long defined we return [0,0]
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('orders.edit')
    .filter('mapCoordinates', mapCoordinates);

  function mapCoordinates() {
    return (input) => {
      return angular.isDefined(input) && input.latitude && input.longitude ? [input.latitude, input.longitude] : [0, 0];
    };
  }
}());

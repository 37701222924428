(function () {
  'use strict';

  angular
    .module('timeTracking.costCenters')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('timeTracking.costCenters', {
        abstract: true,
        url: '/cost-centers',
        template: '<ui-view />'
      });
  }
}());

(function () {
  'use strict';

  angular
    .module('auth')
    .run(run);

  function run($rootScope, $state, startState, UserService) {
    let keepUntilAppCloses = $rootScope.$on('$stateChangeStart', (e, toState) => {
      UserService.reloadUser();
      if (UserService.user !== null && (toState.name === 'login' || toState.name === 'registration' || toState.name === 'forgotPassword')) {
        e.preventDefault();
        $state.go(startState);
      } else if (UserService.user === null && toState.name !== 'login' && toState.name !== 'registration' && toState.name !== 'forgotPassword') {
        e.preventDefault();
        $state.go('login');
      } else if (angular.isObject(toState.data) && angular.isArray(toState.data.requiredPerms) && !UserService.hasAllPerms(toState.data.requiredPerms)) {
        e.preventDefault();
        $state.go(startState);
      }
    });
    // Get rid of the event listener when the application closes
    $rootScope.$on('$destroy', () => {
      keepUntilAppCloses();
    });
  }
  run.$inject = ['$rootScope', '$state', 'startState', 'UserService'];
}());

<ng-form name="form">
<div>
    <div class="well well-lg" style="background-color: white;">
        <h4>{{'BATCH_EDITING_HEADER_LABEL' | translate}} ({{batchEdit.entities.length}} {{'BATCH_EDITING_LABEL_' + batchEdit.entityName | uppercase | translate}})</h4>
        <ul>
            <li ng-style="batchEdit.currentStep >= 0 && {'font-weight':'bold'}">{{'BATCH_EDITING_LABEL_CHOOSE_' + batchEdit.entityName | uppercase | translate}}</li>
            <li ng-style="batchEdit.currentStep >= 1 && {'font-weight':'bold'}">{{'BATCH_EDITING_CHOOSE_OPERATION' | translate}}</li>
            <li ng-style="batchEdit.currentStep >= 2 && {'font-weight':'bold'}">{{'CONFIRM' | translate}}</li>
        </ul>
        <div class="animate-switch-container"
                ng-switch on="batchEdit.currentStep">
            <div class="animate-switch" ng-switch-when="1">
                <uib-tabset active="batchEdit.selectedTab">
                    <uib-tab index="0" heading="{{'EDIT' | translate}}" select="batchEdit.renderSlider()">
                        <div class="container-fluid">
                            <ng-form name="form">
                                <div class="row" ng-repeat="field in batchEdit.fieldDescriptors" ng-if="$index % 2 == 0">
                                    <!-- 
                                        ng-if creates a child scope, in whose context 'item' is set only for this specific element.
                                     -->
                                    <div class="form-group col-md-6" ng-include="'directives/batch-editing-elements.html'" onload="item = batchEdit.fieldDescriptors[$index]" ng-if="$index < batchEdit.fieldDescriptors.length">
                                    </div>
                                    <div class="form-group col-md-6" ng-include="'directives/batch-editing-elements.html'" onload="item = batchEdit.fieldDescriptors[$index+1]" ng-if="($index + 1) < batchEdit.fieldDescriptors.length">
                                    </div>
                                </div>
                            </ng-form>
                        </div>
                    </uib-tab>
                    <uib-tab index="1" heading="{{'DELETE' | translate}}" ng-if="batchEdit.canDelete">
                        <span>{{'BATCH_EDITING_DELETE_' + batchEdit.entityName | uppercase | translate}}</span>
                    </uib-tab>
                </uib-tabset>
            </div>
            <div class="animate-switch" ng-switch-when="2|3" ng-switch-when-separator="|">
                <div ng-if="batchEdit.selectedTab === 0">
                    <span>{{'BATCH_EDITING_EDIT_' + batchEdit.entityName | uppercase | translate}}</span>
                    <ul>
                        <!-- 
                            We need to check the fieldname too because descriptor.value.value for 'wineThird' is always undefined. 
                            It is sufficient to only check wineThird because the form forces the user to enter all three values.
                        -->
                        <li ng-repeat="descriptor in batchEdit.fieldDescriptors" ng-if="descriptor.value.value !== undefined || descriptor.fieldname === 'wineThird' && descriptor.value.wineThird !== undefined">
                            {{descriptor.label | translate}}: 
                            <span ng-if="descriptor.fieldname === 'wineThird'">{{descriptor.value.wineFirst.readableValue}}, {{descriptor.value.wineSecond.readableValue}}, {{descriptor.value.wineThird.readableValue}}</span>
                            <span ng-if="descriptor.fieldname !== 'wineThird'">{{descriptor.value.readableValue}}</span>
                        </li>
                    </ul>
                </div>
                <div ng-if="batchEdit.selectedTab === 1">
                    <span>{{'BATCH_EDITING_DELETE_' + batchEdit.entityName | uppercase | translate}}</span>
                </div>
            </div>
            <div class="animate-switch" ng-switch-when="3|4" ng-switch-when-separator="|">
                <uib-progressbar max="100" value="batchEdit.progress"><span style="color:white; white-space:nowrap;">{{batchEdit.progress | number: 2}} %</span></uib-progressbar>
            </div>
            <div class="animate-switch" ng-switch-when="4">
                <span>{{'BATCH_EDITING_COMPLETED' | translate}}</span>
                <ul>
                    <li>{{batchEdit.entities.length - batchEdit.errorneousEntities.length}} {{'BATCH_EDITING_SUCCESS_' + batchEdit.entityName  | uppercase | translate}}</li>
                    <li ng-if="batchEdit.errorneousEntities.length">{{batchEdit.errorneousEntities.length}} {{'BATCH_EDITING_ERROR_' + batchEdit.entityName | uppercase | translate}}
                        <ul>
                            <li ng-repeat="entity in batchEdit.errorneousEntities">{{entity.label}}</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>

        <br>

        <button class="btn btn-primary"
                ng-if="batchEdit.currentStep !== 2 && batchEdit.progress !== 100"
                ng-click="batchEdit.continueClicked()"
                ng-disabled="form.$invalid">
            {{'CONTINUE' | translate}}
        </button>
        <button class="btn btn-primary"
                ng-if="batchEdit.currentStep === 2 && batchEdit.progress !== 100"
                ng-click="batchEdit.confirmClicked()">
            {{'CONFIRM' | translate}}
        </button>
        <button class="btn btn-primary"
                ng-click="batchEdit.cancelClicked()">
            {{batchEdit.progress === 100 ? 'BATCH_EDITING_FINISH_' + batchEdit.entityName  : 'ABORT' | uppercase | translate}}
        </button>
    </div>
</div>
</ng-form>
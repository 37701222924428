(function () {
  'use strict';

  /* @ngdoc object
   * @name businesses.factsheet
   * @description
   *
   */
  angular
    .module('businesses.factsheet', [
      'ui.router'
    ]);
}());

<hr />
<div class="form-group">
  <label class="control-label">{{'FERTILIZATION' | translate}}</label>
  <div class="alert alert-info"
          ng-if="edit.hasFertilizationModule && edit.item.maximumPhosphateValue === null && edit.item.maximumNitrogenValue === null">
      {{'FERTILIZATION_ORDER_VINEYARD_NO_FERTILIZATION_DATA' | translate}}
  </div>
  <table class="table table-bordered"
      ng-if="edit.hasFertilizationModule && (edit.item.maximumPhosphateValue !== null || edit.item.maximumNitrogenValue !== null)">
    <thead>
      <tr>
        <th></th>
        <th class="text-right">{{'FERTILIZATION_ARTICLE_INGREDIENTS_NITROGEN' | translate}}</th>
        <th class="text-right">{{'FERTILIZATION_ARTICLE_INGREDIENTS_PHOSPHATE' | translate}}</th>
      </tr>
      <tr>
        <th>{{'FERTILIZATION_ORDER_VINEYARD_MAXIMUM_VINEYARD' | translate}}</th>
        <th class="text-right">{{ edit.item.maximumNitrogenValue !== null ? (edit.item.maximumNitrogenValue | number : 4) : '-' }}</th>
        <th class="text-right">{{ edit.item.maximumPhosphateValue !== null ? (edit.item.maximumPhosphateValue | number : 4) : '-' }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-muted">{{'FERTILIZATION_ORDER_VINEYARD_DEDUCTION_FINISHED' | translate}}</td>
        <td class="text-muted text-right">{{ edit.item.nitrogenDeductionFinished !== null ? (-1 * edit.item.nitrogenDeductionFinished | number : 4) : '-' }}</td>
        <td class="text-muted text-right">{{ edit.item.phosphateDeductionFinished !== null ? (-1 * edit.item.phosphateDeductionFinished | number : 4) : '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">{{'FERTILIZATION_ORDER_VINEYARD_DEDUCTION_PENDING' | translate}}</td>
        <td class="text-muted text-right">{{ edit.item.nitrogenDeductionPending !== null ? (-1 * edit.item.nitrogenDeductionPending | number : 4) : '-' }}</td>
        <td class="text-muted text-right">{{ edit.item.phosphateDeductionPending !== null ? (-1 * edit.item.phosphateDeductionPending | number : 4) : '-' }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>{{'FERTILIZATION_PARCEL_FERTILIZATION_DATA_SUM' | translate}}</td>
        <td class="text-right">{{ edit.item.maximumNitrogenValue !== null ? (edit.item.maximumNitrogenValue - edit.item.nitrogenDeductionFinished - edit.item.nitrogenDeductionPending | number : 4) : '-' }}</td>
        <td class="text-right">{{ edit.item.maximumPhosphateValue !== null ? (edit.item.maximumPhosphateValue - edit.item.phosphateDeductionFinished - edit.item.phosphateDeductionPending | number : 4) : '-' }}</td>
      </tr>
    </tfoot>
  </table>
</div>
<form name="deleteBusinessForm">
    <div class="modal-header">
        <button type="button" class="close" ng-click="$dismiss();"><span>&times;</span></button>
        <h4 class="modal-title">{{'WINERY_MANAGEMENT_WINERIES_DELETE' | translate}}</h4>
    </div>
    <div class="modal-body text-center">
        <h3>{{delete.item.label}}</h3>
        <h4>{{delete.item.ice}}</h4>
    </div>
    <div class="modal-body" ng-bind-html="'WINERY_MANAGEMENT_WINERIES_DELETE_CONFIRM' | translate"></div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" ng-click="$dismiss();">{{'ABORT' | translate}}</button>
        <button type="button" class="btn btn-danger" ng-click="$close();">{{'OK' | translate}}</button>
    </div>
</form>

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name login.factory:AuthApi
   *
   * @description
   *
   */
  angular
    .module('vcMain')
    .factory('AuthApi', AuthApi);

  function AuthApi($resource, apiBasePath) {
    return $resource(apiBasePath + 'auth/:action');
  }

  AuthApi.$inject = ['$resource', 'apiBasePath'];
}());

(function () {
    'use strict';

    class EditFertilizationDeleteCtrl {
        constructor(item) {
            let vm = this;
            vm.item = item;
        }
    }

    EditFertilizationDeleteCtrl.$inject = ['item'];

    /**
     * @ngdoc object
     * @name parcels.edit.controller:EditFertilizationDeleteCtrl
     *
     * @description
     *
     */
    angular
        .module('parcels.edit')
        .controller('EditFertilizationDeleteCtrl', EditFertilizationDeleteCtrl);
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name temporaryWorkers.edit
   * @description
   *
   */
  angular
    .module('temporaryWorkers.edit', [
      'ui.router',
      'edit.download'
    ]);
}());

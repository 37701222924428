(function () {
  'use strict';

  angular
    .module('admin.customers.list')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('customers.list', {
        url: '/list?filter&page',
        templateUrl: 'admin/customers/list/views/list.tpl.html',
        controller: 'CustomersListCtrl',
        controllerAs: 'list',
        params: {
          message: {
            value: null
          },
          page: {
            type: 'int',
            value: 1
          },
          sorter: {
            dynamic: true,
            value: 'isActive',
            squash: true
          },
        }
      });
  }
}());

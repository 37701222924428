<sub-menu items="edit.submenu.items"
          form="form"></sub-menu>
<main-menu items="edit.mainmenu.items"></main-menu>

<div class="base-layout">
    <div class="base-layout-default">
        <main-message message="edit.message" fluid="'nested'"></main-message>
        <ng-form name="form" class="managementUnit-edit">
            <!-- MODEL -->
            <div class="form-group" ng-class="{'has-error': form.label.$invalid}">
                <label class="control-label" for="label">{{'MANAGEMENT_UNIT' | translate}}</label>
                <input class="form-control"
                       type="text"
                       name="label"
                       id="label"
                       ng-model="edit.item.label"
                       required/>
                <p class="help-block"
                   ng-messages="form.label.$error">
                    <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
                </p>
            </div>
            <!-- PARCELS -->
            <div class="form-group" ng-if="!edit.new && edit.parcels.$resolved">
                <label class="control-label" for="name">{{'PARCELS' | translate}}</label>
                <div class="alert alert-info"
                     ng-if="!edit.parcels.length">{{'MANAGEMENT_UNIT_NO_PARCELS' | translate}}
                </div>
                <div class="list-group"
                     ng-if="edit.parcels.length">
                    <a class="list-group-item"
                       ng-repeat="item in edit.parcels | orderBy:'label'"
                       ui-sref="parcels.edit.orders.list({id: item.id})">
                        {{item.label}}
                    </a>
                </div>
            </div>
            <!-- VISIBILITY MODE -->
            <div ng-if="edit.canEditVisibility" class="form-group"
                 ng-class="{'has-error': form.visibilityMode.$invalid}">
                <label class="control-label"
                       for="visibilityMode">{{'managementUnit.visibilityMode.label' | translate}}</label>
                <select class="form-control"
                        id="visibilityMode"
                        name="visibilityMode"
                        ng-model="edit.item.visibilityMode"
                        required>
                    <option value="TENANT">
                        {{'managementUnit.visibilityMode.values.TENANT.label' | translate}}
                    </option>
                    <option value="ACCOUNT">
                        {{'managementUnit.visibilityMode.values.ACCOUNT.label' | translate}}
                    </option>
                </select>
                <p class="help-block"
                   ng-messages="form.label.$error">
                    <span ng-message="required">{{'FORM_HELP_REQUIRED' | translate}}</span>
                </p>
                <p class="help-block">
                    <span>{{'managementUnit.visibilityMode.values.' + edit.item.visibilityMode + '.help' | translate}}</span>
                </p>
            </div>
        </ng-form>
    </div>
</div>

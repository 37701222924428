(function () {
    'use strict';

    class ParcelsFilterCtrl {
        constructor(CrudApi, WineTypeInfo, PoiHelper, CostCenterHelper, permissions, UserService) {
            let vm = this,
                // Filter object
                availableFilters = [{
                    id: 'business',
                    label: UserService.getVcToken().accountUuid !== '7f1d4f34-a92d-4b8e-906b-60046d14cbfb' ? UserService.getLocalizedLabelOrDefault('businessSingular', 'PARCEL_WINERY') : 'Winzer',
                    show: false,
                    apiKey: 'business',
                    assetType: 'ALL'
                }, {
                    id: 'sharedBusiness',
                    label: UserService.getLocalizedLabelOrDefault('businessSingular', 'PARCEL_WINERY'),
                    labelPrefix: 'SHARED',
                    show: false,
                    apiKey: 'sharedBusiness',
                    assetType: 'VINEYARD'
                }, {
                    id: 'district',
                    label: UserService.getVcToken().accountUuid !== '7f1d4f34-a92d-4b8e-906b-60046d14cbfb' ? 'PARCELS_PARAM_DISTRICT' : 'Ortsteil',
                    show: false,
                    apiKey: 'districtContains',
                    assetType: 'VINEYARD'
                }, {
                    id: 'label',
                    label: 'PARCELS_PARAM_LABEL',
                    show: false,
                    apiKey: 'labelContains',
                    assetType: 'ALL'
                }, {
                    id: 'region',
                    label: 'site.region.label',
                    show: false,
                    apiKey: 'regionContains',
                    assetType: 'ALL'
                }, {
                    id: 'slopeFacing',
                    label: 'site.slopeFacing.label',
                    show: false,
                    apiKey: 'slopeFacingContains',
                    assetType: 'ALL'
                }, {
                    id: 'dangerCategory',
                    label: 'site.dangerCategory.label',
                    show: false,
                    apiKey: 'dangerCategoryContains',
                    assetType: 'ALL'
                }, {
                    id: 'vineTraining',
                    label: 'site.vineTraining.label',
                    show: false,
                    apiKey: 'vineTraining',
                    assetType: 'ALL'
                }, {
                    id: 'vine',
                    label: 'PARCELS_PARAM_VINE',
                    show: false,
                    apiKey: 'wineThird',
                    assetType: 'VINEYARD'
                }, {
                    id: 'location',
                    label: UserService.getVcToken().accountUuid !== '7f1d4f34-a92d-4b8e-906b-60046d14cbfb' ? 'PARCELS_PARAM_WINELOCATION' : 'Riede',
                    show: false,
                    apiKey: 'wineLocationContains',
                    assetType: 'VINEYARD'
                }, {
                    id: 'comment',
                    label: 'PARCELS_PARAM_COMMENTS',
                    show: false,
                    apiKey: 'comment',
                    assetType: 'ALL'
                }, {
                    id: 'infected',
                    label: 'PARCELS_PARAM_INFECTED_WITH',
                    show: false,
                    apiKey: 'infestedWith',
                    assetType: 'VINEYARD'
                }, {
                    id: 'flurNumber',
                    label: 'PARCELS_PARAM_FLURNUMBER',
                    show: false,
                    apiKey: 'flurNumberContains',
                    assetType: 'VINEYARD'
                }, {
                    id: 'area',
                    label: 'PARCELS_PARAM_AREA',
                    show: false,
                    values: {
                        min: {
                            apiKey: 'areaMin'
                        },
                        max: {
                            apiKey: 'areaMax'
                        }
                    }
                }, {
                    id: 'numberOfRows',
                    label: 'PARCELS_PARAM_NUMBER_OF_ROWS',
                    show: false,
                    values: {
                        min: {
                            apiKey: 'numberOfRowsMin'
                        },
                        max: {
                            apiKey: 'numberOfRowsMax'
                        }
                    },
                    assetType: 'VINEYARD'
                }, {
                    id: 'laneWidth',
                    label: UserService.getVcToken().accountUuid !== '7f1d4f34-a92d-4b8e-906b-60046d14cbfb' ? 'PARCELS_PARAM_LANE_WIDTH' : 'Reihenabstand in cm',
                    show: false,
                    values: {
                        min: {
                            apiKey: 'laneWidthMin'
                        },
                        max: {
                            apiKey: 'laneWidthMax'
                        }
                    },
                    assetType: 'VINEYARD'
                }, {
                    id: 'slope',
                    label: 'site.slope.label',
                    show: false,
                    values: {
                        min: {
                            apiKey: 'slopeMin'
                        },
                        max: {
                            apiKey: 'slopeMax'
                        }
                    },
                    assetType: 'ALL'
                }, {
                    id: 'avgStrikeLength',
                    label: 'PARCELS_PARAM_AVG_STRIKE_LENGTH',
                    show: false,
                    values: {
                        min: {
                            apiKey: 'avgStrikeLengthMin'
                        },
                        max: {
                            apiKey: 'avgStrikeLengthMax'
                        }
                    },
                    assetType: 'VINEYARD'
                }, {
                    id: 'numberOfGrapevines',
                    label: 'PARCELS_PARAM_NUMBER_OF_GRAPEVINES',
                    show: false,
                    values: {
                        min: {
                            apiKey: 'numberOfGrapevinesMin'
                        },
                        max: {
                            apiKey: 'numberOfGrapevinesMax'
                        }
                    },
                    assetType: 'VINEYARD'
                }, {
                    id: 'underlay',
                    label: 'PARCELS_PARAM_UNDERLAY',
                    show: false,
                    apiKey: 'underlayContains',
                    assetType: 'VINEYARD'
                }, {
                    id: 'plantedDate',
                    label: 'PARCELS_PARAM_PLANTEDDATE',
                    show: false,
                    values: {
                        min: {
                            apiKey: 'plantedDateMin',
                            date: true
                        },
                        max: {
                            apiKey: 'plantedDateMax',
                            date: true
                        }
                    },
                    assetType: 'VINEYARD'
                }, {
                    id: 'yieldYear',
                    label: 'PARCELS_PARAM_YIELD_YEAR',
                    show: false,
                    values: {
                        min: {
                            apiKey: 'yieldYearMin',
                            date: true
                        },
                        max: {
                            apiKey: 'yieldYearMax',
                            date: true
                        }
                    },
                    assetType: 'VINEYARD'
                }, {
                    id: 'clearedDate',
                    label: 'PARCELS_PARAM_CLEAREDDATE',
                    show: false,
                    values: {
                        min: {
                            apiKey: 'clearedDateMin',
                            date: true
                        },
                        max: {
                            apiKey: 'clearedDateMax',
                            date: true
                        }
                    },
                    assetType: 'VINEYARD'
                }, {
                    id: 'stickDistance',
                    label: 'PARCELS_PARAM_STICKDISTANCE',
                    show: false,
                    values: {
                        min: {
                            apiKey: 'stickDistanceMin'
                        },
                        max: {
                            apiKey: 'stickDistanceMax'
                        }
                    },
                    assetType: 'VINEYARD'
                }, {
                    id: 'note1',
                    label: 'PARCELS_PARAM_NOTE1',
                    show: false,
                    apiKey: 'note1Contains',
                    assetType: 'ALL'
                }, {
                    id: 'note2',
                    label: 'PARCELS_PARAM_NOTE2',
                    show: false,
                    apiKey: 'note2Contains',
                    assetType: 'ALL'
                }, {
                    id: 'note3',
                    label: 'site.note3.label',
                    show: false,
                    apiKey: 'note3Contains',
                    assetType: 'ALL'
                }, {
                    id: 'note4',
                    label: 'site.note4.label',
                    show: false,
                    apiKey: 'note4Contains',
                    assetType: 'ALL'
                }, {
                    id: 'managementUnit',
                    label: 'PARCELS_PARAM_MANAGEMENT_UNIT',
                    show: false,
                    apiKey: 'managementUnit',
                    assetType: 'ALL'
                }, {
                    id: 'bbch',
                    label: 'PARCELS_PARAM_BBCH',
                    show: false,
                    values: {
                        min: {
                            apiKey: 'bbchMin'
                        },
                        max: {
                            apiKey: 'bbchMax'
                        }
                    },
                    assetType: 'VINEYARD'
                }, {
                    id: 'poiType',
                    label: 'PARCELS_PARAM_POI_TYPE',
                    show: false,
                    apiKey: 'poiType',
                    assetType: 'ALL'
                }, {
                    id: 'nitrogenAllowance',
                    label: 'PARCELS_PARAM_NITROGEN_ALLOWANCE',
                    show: false,
                    values: {
                        min: {
                            apiKey: 'nitrogenAllowanceMin'
                        },
                        max: {
                            apiKey: 'nitrogenAllowanceMax'
                        }
                    },
                    assetType: 'VINEYARD'
                }, {
                    id: 'leased',
                    label: 'site.leased.label',
                    show: false,
                    apiKey: 'leased',
                    assetType: 'ALL'
                }, {
                    id: 'underLeased',
                    label: 'site.underLeased.label',
                    show: false,
                    apiKey: 'underLeased',
                    assetType: 'ALL'
                }, {
                    id: 'droughtStress',
                    label: 'site.droughtStress.label',
                    show: false,
                    apiKey: 'droughtStress',
                    assetType: 'VINEYARD'
                }, {
                    id: 'testingGround',
                    label: 'site.testingGround.label',
                    show: false,
                    apiKey: 'testingGround',
                    assetType: 'VINEYARD'
                }, {
                    id: 'oidiumProne',
                    label: 'site.oidiumProne.label',
                    show: false,
                    apiKey: 'oidiumProne',
                    assetType: 'VINEYARD'
                }, {
                    id: 'organicEligible',
                    label: 'site.organicEligible.label',
                    show: false,
                    apiKey: 'organicEligible',
                    assetType: 'VINEYARD'
                }, {
                    id: 'cultivationProgram',
                    label:'CULTIVATION_PROGRAM',
                    show: false,
                    apiKey: 'cultivationProgram',
                    assetType: 'VINEYARD'
                }];

            if (UserService.hasPerm(permissions.TIME_TRACKING_CAN_USE)) {
                availableFilters.push({
                    id: 'costCenter',
                    label: 'PARCELS_PARAM_COST_CENTER',
                    show: false,
                    apiKey: 'costCenter',
                    assetType: 'ALL'
                });
            }


            vm.filters = availableFilters.filter(f => f.assetType === UserService.getAssetType() || f.assetType === 'ALL');

            vm.$onInit = () => {
                vm.selectedFilters.$render = () => {
                    removeFilters(vm.filters);

                    angular.forEach(vm.selectedFilters.$modelValue, (value, key) => {
                        enableFilters(key, value, vm.filters);
                    });
                };

                vm.showFilter = (filter) => {
                    if (filter === null) {
                        return;
                    }
                    //WORKAROUND - DIRTY SOLUTION
                    if (filter.id === 'leased' ||
                        filter.id === 'underLeased' ||
                        filter.id === 'droughtStress' ||
                        filter.id === 'organicEligible' ||
                        filter.id === 'oidiumProne' ||
                        filter.id === 'testingGround') {
                        filter.value = !filter.show;
                    }
                    filter.show = !filter.show;
                    vm.updateModel();
                };

                function enableFilters(apiKey, value, filters) {
                    let result = false;
                    angular.forEach(filters, filter => {
                        let found = false;
                        if (apiKey === filter.apiKey) {
                            if (filter.date && !angular.isDate(value)) {
                                value = new Date(value);
                            }
                            filter.value = value;
                            found = true;
                        } else if (filter.values) {
                            found = enableFilters(apiKey, value, filter.values);
                        }
                        if (found) {
                            filter.show = found;
                            result = found;
                        }
                    });
                    return result;
                }
            };

            // Set filters
            vm.updateModel = () => {
                let params = {};
                vm.filters.forEach((filter) => {
                    if (filter.values) {
                        angular.forEach(filter.values, (subFilter) => {
                            if (angular.isDefined(subFilter.value) && subFilter.value !== null) {
                                params[subFilter.apiKey] = subFilter.value;
                            }
                        });
                    } else if (angular.isDefined(filter.value) && filter.value !== null) {
                        params[filter.apiKey] = filter.value;
                    }
                });
                vm.selectedFilters.$setViewValue(params);
            };

            vm.removeFilter = (filter) => removeFilters([filter]);

            vm.fetchPoiTypes = () => {
                if (!vm.poiTypes) {
                    CrudApi.query({
                        entity: 'poi',
                        subentity: 'types'
                    }, poiTypes => {
                        poiTypes.forEach(type => {
                            type.categoryTranslated = PoiHelper.getTypeCategoryTranslation(type);
                            type.labelTranslated = PoiHelper.getTypeLabelTranslation(type);
                        });
                        vm.poiTypes = poiTypes;
                    });
                }
            };

            // Fetch businesses
            vm.fetchBusinesses = () => {
                if (!vm.businesses) {
                    CrudApi.query({
                        entity: 'businesses',
                        subentity: 'usable/forSelection',
                        // Maybe we need to adjust this value
                        perPage: 5000
                    }, businesses => vm.businesses = businesses);
                }
            };

            vm.fetchSharedBusinesses = () => {
                if (!vm.sharedBusinesses) {
                    CrudApi.query({
                        entity: 'businesses',
                        subentity: 'shared',
                    }, businesses => vm.sharedBusinesses = businesses);
                }
            };

            // Fetch management units
            vm.fetchManagementUnits = () => {
                if (!vm.managementUnits) {
                    CrudApi.query({
                        entity: 'managementUnits'
                    }, managementUnits => {
                        vm.managementUnits = managementUnits;
                        // We need custom tailored filter logic here, as "!" would not work otherwise.
                        // "!" is reserved for negation in standard angular filters
                        vm.getAvailableManagementUnits = (search) => {
                            let result = [];
                            vm.managementUnits.forEach((mu) => {
                                if (mu.label.match(new RegExp(search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'ig'))) {
                                    result.push(mu);
                                }
                            });
                            return result;
                        };
                    });
                }
            };

            // Fetch cost centers
            vm.fetchCostCenters = () => {
                if (!vm.costCenters) {
                    CrudApi.query({
                        entity: 'costCenter'
                    }, costCenters => {
                        costCenters = CostCenterHelper.makeFlat(costCenters, 0);
                        CostCenterHelper.setIndentedLabel(costCenters);
                        vm.costCenters = costCenters;

                        vm.getAvailableCostCenters = (search) => {
                            let result = [];
                            vm.costCenters.forEach((cc) => {
                                if (cc.indentedLabel.match(new RegExp(search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'ig'))) {
                                    result.push(cc);
                                }
                            });
                            return result;
                        };

                        vm.groupCostCenters = CostCenterHelper.groupCostCenters;
                    });
                }
            };

            // Fetch VineTypes
            WineTypeInfo.whenReady().then(() => {
                vm.wines = WineTypeInfo.getThirdWines();
            });

            function removeFilters(filters) {
                angular.forEach(filters, filter => {
                    filter.show = false;
                    if (filter.value) {
                        delete filter.value;
                    }
                    if (filter.values) {
                        removeFilters(filter.values);
                    }
                });
            }
        }
    }

    ParcelsFilterCtrl.$inject = ['CrudApi', 'WineTypeInfo', 'PoiHelper', 'CostCenterHelper', 'permissions', 'UserService'];

    /**
     * @ngdoc object
     * @name parcels.selection.controller:ParcelsFilterCtrl
     *
     * @description
     *
     */
    angular
        .module('parcels.selection')
        .controller('ParcelsFilterCtrl', ParcelsFilterCtrl);
}());

(function () {
  'use strict';

  angular
    .module('orders.list')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('orders.list', {
        url: '/list?page&sorter&dir&filter&case&{typeId:int}',
        templateUrl: 'orders/list/views/list.tpl.html',
        controller: 'OrdersListCtrl',
        controllerAs: 'list',
        params: {
          message: null
        }
      });
  }
}());

(function () {
  'use strict';

  class BatchEditingDescriptorHelper {
    constructor($q, $translate, BatchEditingHelper, WineTypeInfo, permissions, UserService) {
      this.readyDefer = $q.defer();

      this.BatchEditingHelper = BatchEditingHelper;
      this.WineTypeInfo = WineTypeInfo;
      this.UserService = UserService;
      this.permissions = permissions;

      $translate.onReady(() => {
        this.readyDefer.resolve();
      });
    }

    whenReady() {
      return this.readyDefer.promise;
    }

    getParcelDescriptors(options) {
      let fieldDescriptors = [],
          canUseTimeTracking = this.UserService.hasPerm(this.permissions.TIME_TRACKING_CAN_USE),
          fullEditAvailable = this.UserService.hasPerm(this.permissions.WINERY_MANAGEMENT_CAN_EDIT_VINEYARD);

      // restriced fields
      if (fullEditAvailable) {
        fieldDescriptors.push(...[
          {
            fieldname: 'district',
            label: 'PARCELS_PARAM_DISTRICT',
            type: 'text'
          },
          {
            fieldname: 'wineLocation',
            label: 'PARCELS_PARAM_WINELOCATION',
            type: 'text'
          },
          {
            fieldname: 'businessId',
            label: this.UserService.getLocalizedLabelOrDefault('businessSingular', 'PARCEL_WINERY'),
            type: 'select'
          }
        ]);
      }

      // unrestricted fields
      fieldDescriptors.push(...[
        {
          fieldname: 'bbch',
          label: 'PARCELS_PARAM_BBCH',
          type: 'slider',
          options: {floor: 0, ceil: 97, step: 1}
        },
        {
          fieldname: 'plantedDate',
          label: 'PARCELS_PARAM_PLANTEDDATE',
          type: 'date'
        },
          {
          fieldname: 'yieldYear',
          label: 'PARCELS_PARAM_YIELD_YEAR',
          type: 'date'
        },
        {
          fieldname: 'clearedDate',
          label: 'PARCELS_PARAM_CLEAREDDATE',
          type: 'date'
        },
        {
          fieldname: 'stickDistance',
          label: 'PARCELS_PARAM_STICKDISTANCE',
          type: 'slider',
          options: {floor: 50, ceil: 200, step: 1}
        },
        {
          fieldname: 'laneWidth',
          label: 'PARCELS_PARAM_LANE_WIDTH',
          type: 'number',
          step: 0.001
        },
          {
              fieldname: 'slope',
              label: 'site.slope.label',
              type: 'number',
              step: 0.001
          },
          {
              fieldname: 'slopeFacing',
              label: 'site.slopeFacing.label',
              type: 'text'
          },
          {
              fieldname: 'region',
              label: 'site.region.label',
              type: 'text'
          },
          {
              fieldname: 'vineTraining',
              label: 'site.vineTraining.label',
              type: 'text'
          },
          {
              fieldname: 'dangerCategory',
              label: 'site.dangerCategory.label',
              type: 'text'
          },
        {
          fieldname: 'note1',
          label: 'PARCELS_PARAM_NOTE1',
          type: 'text'
        },
        {
          fieldname: 'note2',
          label: 'PARCELS_PARAM_NOTE2',
          type: 'text'
        },
          {
              fieldname: 'note3',
              label: 'site.note3.label',
              type: 'text'
          },
          {
              fieldname: 'note4',
              label: 'site.note4.label',
              type: 'text'
          },
          {
              fieldname: 'cultivationProgram',
              label: 'PARCELS_PARAM_CULTIVATION_PROGRAM',
              type: 'text'
          },
        {
          fieldname: 'infestedWith',
          label: 'PARCELS_PARAM_INFECTED_WITH',
          type: 'select',
          options: this.BatchEditingHelper.getTranslatedOptions(
          ['PARCELS_PARAM_INFECTED_WITH_O1', 'PARCELS_PARAM_INFECTED_WITH_O2', 'PARCELS_PARAM_INFECTED_WITH_O3',
          'PARCELS_PARAM_INFECTED_WITH_O4', 'PARCELS_PARAM_INFECTED_WITH_O5', 'PARCELS_PARAM_INFECTED_WITH_O6'], true)
        },
          {
              fieldname: 'leased',
              label: 'site.leased.label',
              type: 'select',
              options: this.BatchEditingHelper.getTranslatedCheckboxOptions()
          },
          {
              fieldname: 'steep',
              label: 'site.steep.label',
              type: 'select',
              options: this.BatchEditingHelper.getTranslatedCheckboxOptions()
          },
          {
              fieldname: 'underLeased',
              label: 'site.underLeased.label',
              type: 'select',
              options: this.BatchEditingHelper.getTranslatedCheckboxOptions()
          },
          {
              fieldname: 'droughtStress',
              label: 'site.droughtStress.label',
              type: 'select',
              options: this.BatchEditingHelper.getTranslatedCheckboxOptions()
          },
          {
              fieldname: 'testingGround',
              label: 'site.testingGround.label',
              type: 'select',
              options: this.BatchEditingHelper.getTranslatedCheckboxOptions()
          },
          {
              fieldname: 'oidiumProne',
              label: 'site.oidiumProne.label',
              type: 'select',
              options: this.BatchEditingHelper.getTranslatedCheckboxOptions()
          },
          {
              fieldname: 'organicEligible',
              label: 'site.organicEligible.label',
              type: 'select',
              options: this.BatchEditingHelper.getTranslatedCheckboxOptions()
          },
        {
          fieldname: 'infestedSeverity',
          label: 'PARCELS_PARAM_INFECTED_SEVERITY',
          type: 'select',
          options: this.BatchEditingHelper.getTranslatedOptions(
          ['PARCELS_PARAM_INFECTED_SEVERITY_O1', 'PARCELS_PARAM_INFECTED_SEVERITY_O2', 'PARCELS_PARAM_INFECTED_SEVERITY_O3'], true)
        },
        {
          fieldname: 'underlay',
          label: 'PARCELS_PARAM_UNDERLAY',
          type: 'select',
          options: this.BatchEditingHelper.getTranslatedOptions(
        ['PARCELS_PARAM_UNDERLAY_O1', 'PARCELS_PARAM_UNDERLAY_O2', 'PARCELS_PARAM_UNDERLAY_O3',
          'PARCELS_PARAM_UNDERLAY_O4', 'PARCELS_PARAM_UNDERLAY_O5', 'PARCELS_PARAM_UNDERLAY_O6',
          'PARCELS_PARAM_UNDERLAY_O7', 'PARCELS_PARAM_UNDERLAY_O8', 'PARCELS_PARAM_UNDERLAY_10',
          'PARCELS_PARAM_UNDERLAY_11', 'PARCELS_PARAM_UNDERLAY_12', 'PARCELS_PARAM_UNDERLAY_13',
          'PARCELS_PARAM_UNDERLAY_14', 'PARCELS_PARAM_UNDERLAY_15', 'PARCELS_PARAM_UNDERLAY_16',
          'PARCELS_PARAM_UNDERLAY_17', 'PARCELS_PARAM_UNDERLAY_18', 'PARCELS_PARAM_UNDERLAY_O9'], true)
        },
        {
          fieldname: 'managementUnitId',
          label: 'PARCELS_PARAM_MANAGEMENT_UNIT',
          type: 'select'
        }
      ]);

      if (canUseTimeTracking) {
        fieldDescriptors.push({
          fieldname: 'costCenterId',
          label: 'PARCELS_PARAM_COST_CENTER',
          type: 'select'
        });
      }

      fieldDescriptors.push({
        fieldname: 'wineThird',
        label: 'PARCELS_PARAM_VINE',
        type: 'wineSelection'
      });

      if (angular.isDefined(options)) {
        fieldDescriptors.forEach(descriptor => {
          if (angular.isDefined(options[descriptor.fieldname])) {
            if (descriptor.fieldname === 'wineThird') {
              descriptor.options = options[descriptor.fieldname];
            } else {
              descriptor.options = this.BatchEditingHelper.getOptions(options[descriptor.fieldname]);
            }
          }
        });
      }

      return fieldDescriptors;
    }
  }

  /**
   * @ngdoc service
   * @name vcMain.service:BatchEditingDescriptorHelper
   *
   * @description
   *
   */
  angular
    .module('vcMain')
    .service('BatchEditingDescriptorHelper', BatchEditingDescriptorHelper);
}());

<div id="harvestplanning-list">
  <div class="form-group">
        <label class="control-label" >{{ 'MAJOR_WINE_REGION' | translate }}</label>
        <select class="form-control"
                name="majorWineRegion"
                ng-disabled="!edit.fullEditAvailable || edit.isShared"
                ng-model="edit.item.majorWineRegion"
                ng-options="majorWineRegion for majorWineRegion in edit.majorWineRegion">
            <option value="">{{'MAKE_CHOICE' | translate}}</option>
        </select>
    </div>
    <div class="form-group">
        <label class="control-label">{{ 'WINE_AREA' | translate }}</label>
        <select class="form-control"
                name="wineArea"
                ng-disabled="!edit.fullEditAvailable || edit.isShared"
                ng-model="edit.item.wineArea"
                ng-options="wineArea for wineArea in edit.wineArea">
            <option value="">{{'MAKE_CHOICE' | translate}}</option>
        </select>
    </div>
    <div class="form-group">
        <label class="control-label">{{'PLACE_OF_LOADING' | translate}}</label>
        <select class="form-control"
                name="placeOfLoading"
                ng-disabled="!edit.fullEditAvailable || edit.isShared"
                ng-model="edit.item.placeOfLoading"
                ng-options="placeOfLoading for placeOfLoading in edit.placeOfLoading">
            <option value="">{{'MAKE_CHOICE' | translate}}</option>
        </select>

    </div>
    <div class="form-group">
        <label class="control-label">{{'LOADING_POINT' | translate}}</label>
        <select class="form-control"
                name="loadingPoint"
                ng-disabled="!edit.fullEditAvailable || edit.isShared"
                ng-model="edit.item.loadingPoint"
                ng-options="loadingPoint for loadingPoint in edit.loadingPoint">
            <option value="">{{'MAKE_CHOICE' | translate}}</option>
        </select>

    </div>
</div>

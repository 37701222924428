<footer>
  <div class="impressum">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-sm-offset-3">
          <address class="small">
            <div class="row">
              <div class="col-sm-6">
                <p>{{'LEGAL.COMPANY.NAME' | translate}}</p>
                <p>{{'LEGAL.COMPANY.STREET' | translate}}</p>
                <p>{{'LEGAL.COMPANY.CITY' | translate}}</p>
              </div>
              <div class="col-sm-6">
                <p>{{'LEGAL.COMPANY.PHONE' | translate}} <span class="glyphicon glyphicon-phone-alt"></span></p>
                <p>{{'LEGAL.COMPANY.FAX' | translate}} <span class="glyphicon glyphicon-print"></span></p>
                <p>
                  <a href="mailto:{{'LEGAL.COMPANY.EMAIL' | translate}}">{{'LEGAL.COMPANY.EMAIL' | translate}}</a>
                  <span class="glyphicon glyphicon-envelope"></span>
                </p>
              </div>
            </div>
          </address>
        </div>
      </div>
    </div>
  </div>
</footer>

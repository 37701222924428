(function () {
  'use strict';

  angular
    .module('auth.registration')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('registration', {
        url: '/registration?valid',
        templateUrl: 'auth/registration/views/registration.tpl.html',
        controller: 'RegistrationCtrl',
        controllerAs: 'registration'
      });
  }
}());

(function () {
  'use strict';

  class ModuleService {
    constructor($translate, CrudApi, $q) {
      let vm = this;
      vm.$translate = $translate;
      vm.CrudApi = CrudApi;
      vm.$q = $q;
      vm.moduleCache = null;
      vm.permissionIndex = null;
      vm.initializationPromise = null;
    }

    initialize() {
      let vm = this;
      if (vm.initializationPromise === null) {
        let deferred = vm.$q.defer(),
            promises = {};
        promises.translateReady = vm.$translate.onReady();
        promises.modulesReady = vm.$getModules();
        vm.initializationPromise = deferred.promise;
        vm.$q.all(promises).then(() => deferred.resolve(), (e) => {
          vm.initializationPromise = null;
          deferred.reject(e);
        });
      }
      return vm.initializationPromise;
    }

    invalidateCache() {
      let vm = this;
      vm.initializationPromise = null;
    }

    sortModules(modules) {
      let vm = this,
          copy = modules.slice();
      copy.sort(vm.$sortFunc);
      return copy;
    }

    getSortedTranslatedModules() {
      var modules = this.sortModules(this.moduleCache);
      this.translateModuleLabels(modules);
      return modules;
    }

    filterModulesOnUniqueness(modules) {
      const modulesArray = [];
      const modulesMap = new Map();

      for (let i = 0; i <= modules.length - 1; i++) {
        if(!modulesMap.has(modules[i].id)) {
          modulesMap.set(modules[i].id, modules[i]);
          modulesArray.push(modulesMap.get(modules[i].id));
        }
      }
      return modulesArray;
    }

    sortPermissions(mod) {
      let vm = this,
          copy = mod.permissionsList.permissions.slice();
      copy.sort(vm.$sortFunc);
      return copy;
    }

    getPermissionTooltip(mod, permission) {
      let vm = this,
          translationKey = 'PERMISSION_' + mod.label + '_' + permission.label + '_TOOLTIP',
          translation = vm.$translate.instant(translationKey);
      // if the tooltip does not exist in the language file the translation will be equal to the
      // given translation key
      return translation !== translationKey ? translation : null;
    }

    $sortFunc(a, b) {
      if (a.system !== b.system) {
        return a.system < b.system ? 1 : -1;
      }
      if (a.translatedLabel < b.translatedLabel) {
        return -1;
      }
      if (a.translatedLabel > b.translatedLabel) {
        return 1;
      }
      return 0;
    }

    translateModuleLabels(modules) {
      let vm = this;
      return modules.forEach(mod => mod.translatedLabel = vm.$translateModule(mod));
    }

    translatePermissionLabels(mod) {
      let vm = this;
      return mod.permissionsList.permissions.forEach(perm => perm.translatedLabel = vm.$translatePermission(mod, perm));
    }

    $translateModule(mod) {
      let vm = this;
      return vm.$translate.instant('MODULE_' + mod.label);
    }

    $translatePermission(mod, perm) {
      let vm = this;
      return vm.$translate.instant('PERMISSION_' + mod.label + '_' + perm.label);
    }

    userHasPermissions(user, permissions) {
      let vm = this,
          permissionIds = permissions.map(perm => vm.permissionIndex.has(perm) ? vm.permissionIndex.get(perm).id : null),
          found = permissionIds.every(id => id !== null);
      for (let i = 0; i < permissionIds.length && found; i++) {
        const permId = permissionIds[i];
        found = user.permissionIds.indexOf(permId) !== -1;
      }
      return found;
    }

    $getModules() {
      let vm = this,
          deferred = vm.$q.defer();
      vm.CrudApi.query({
        entity: 'modules'
      }, modules => {
        vm.moduleCache = modules;
        vm.permissionIndex = vm.$getPermissionIndex(vm.moduleCache);
        deferred.resolve();
      }, deferred.reject);
      return deferred.promise;
    }

    $getPermissionIndex(modules) {
      let vm = this,
          index = new Map();
      for (let i = 0; i < modules.length; i++) {
        const mod = modules[i];
        for (let j = 0; j < mod.permissionsList.permissions.length; j++) {
          const perm = mod.permissionsList.permissions[j],
              label = vm.$getCanonicalPermissionLabel(mod, perm);
          index.set(label, perm);
        }
      }
      return index;
    }

    $getCanonicalPermissionLabel(mod, perm) {
      return mod.label + '.' + perm.label;
    }
  }

  ModuleService.$inject = ['$translate', 'CrudApi', '$q'];

  /**
   * @ngdoc service
   * @name vcMain.service:ModuleService
   *
   * @description
   *
   */
  angular
    .module('vcMain')
    .service('ModuleService', ModuleService);
}());

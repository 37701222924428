<div class="well well-sm ui-tabs">
    <!--
        the ng-if here ensures that the directive only gets created once the controller
        finished up all necessary initialization with regards to determining whether
        temporary vineyards are used
    -->
    <uib-tabset type="pills" active="edit.parcelTabs" ng-if="edit.parcelTabs">
        <uib-tab select="edit.useVineyardSelection();" index="'normal'" disable="!edit.canCreateTemporaySite">
            <uib-tab-heading>{{'ORDER_PARCEL_LIST' | translate}}</uib-tab-heading>
            <!--
                the selection directive removes selection of vineyards that it does not
                know so we make sure to only create it when we don't use temporary vineyards
                as it would remove the association to the temporary vineyard on creation
                otherwise
            -->
            <parcels-selection name="vineyards"
                               ng-if="edit.parcelTabs === 'normal'"
                               ng-model="edit.order.siteIds"
                               required
                               assigned-user="edit.order.assignedUserIds"
                               additional-data="edit.additionalData"
                               order-id="edit.order.id"
                               order-type="edit.getOrderTypeOfOrder()"
                               is-read-only="!edit.canEditAssignedSites()"
                               initial-edit="edit.editSelection"
                               map-enabled="true"
                               ng-disabled="!edit.isCreate && !edit.canEditAssignedSites()"
                               site-status="edit.siteStatus"
                               vineyard-strategy="edit.order.finishStrategy"
                               redo-vineyards="edit.redoVineyards"
                               redo-right="edit.canMarkRedo"
                               show-areas="true"
                               is-create="edit.isCreate"
                               selected-sites="edit.selectedSites"
                               sums="edit.areaSums"></parcels-selection>
        </uib-tab>
        <!--
            temporary vineyards are only available for regular orders
        -->
        <uib-tab select="edit.useTemporaryVineyard();"  index="'temporary'" ng-if="edit.canEditAssignedSites() && (edit.type === 'regular' || edit.type === 'finished')" disable="!edit.canCreateTemporaySite">
            <uib-tab-heading>{{'ORDER_TEMPORARY_PARCEL' | translate}}</uib-tab-heading>
            <div class="temporary-parcel" style="height: 300px !important;">
            <input type="hidden"
                   name="temporaryOutline"
                   ng-if="edit.parcelTabs === 'temporary'"
                   ng-model="edit.temporaryVineyardPolygons"
                   ui-validate="'edit.temporaryVineyardPolygons.length && edit.temporaryVineyardPolygons[0].path.length > 2'"
                   ui-validate-watch-collection="'edit.temporaryVineyardPolygons'">
            <vc-map-edit map-id="'temporaryVineyardMap'"
                         polygons="edit.temporaryVineyardPolygons"
                         ng-if="edit.temporaryVineyardMapInitialize"
                         trigger-zoom="true"
                         drawing-manager-options = "{
                             drawingControlOptions: edit.drawingControlOptions,
                             polygonOptions: edit.polygonOptions
                         }"
                         drawing-manager-events="edit.drawingManagerEvents"
                         object-events="edit.objectEvents">
                <custom-control class="additional-button" position="TOP_RIGHT" index="1">
                    <button class="btn btn-default"
                            ng-click="edit.resetMap()"
                            ng-if="edit.temporaryVineyardPolygons.length > 0 && edit.canCreateTemporaySite">
                        <span class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block glyphicon glyphicon-remove" aria-hidden="true"></span>
                        <span class="hidden-xs hidden-sm hidden-md">{{'WINERY_MANAGEMENT_PARCELS_MAP_RESET' | translate}}</span>
                    </button>
                </custom-control>
            </vc-map-edit>
            </div>
        </uib-tab>
    </uib-tabset>
</div>

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name vcMain.factory:QrApi
   *
   * @description
   *
   */
  angular
    .module('vcMain')
    .factory('DownloadApi', DownloadApi);

  function DownloadApi($resource, apiBasePath, Blob) {
    return $resource(apiBasePath + '/:entity/:id/:subentity', {},
      {
        get: {
          method: 'GET',
          responseType: 'arraybuffer',
          transformResponse: (data, headers) => {
            return {
              blob: new Blob([data], {type: 'image/png'}),
              filename: headers()['content-disposition'].split('=')[1].split('"')[1]
            };
          }
        }
      });
  }

  DownloadApi.$inject = ['$resource', 'apiBasePath', 'Blob'];
}());

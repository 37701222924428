<sub-menu items="list.submenu.items" fluid="true" ng-if="list.canCreate"></sub-menu>
<main-menu></main-menu>

<div class="base-layout poi-list">
  <div class="base-layout-map">
    <div class="base-layout-map-list">
      <main-message message="list.message"
                    style="margin-top: 20px;"
                    fluid="true"
                    ng-if="list.message"></main-message>
      <div class="table-responsive" ng-if="list.items.length">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>{{'POI' | translate}}</th>
              <th>{{'POI_VINEYARDS' | translate}}</th>
              <th class="text-center" ng-if="list.canDelete">{{'DELETE' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr ng-repeat="item in list.items | orderBy: ['type.labelTranslated', 'id'] | limitTo : list.itemsPerPage : (list.page - 1) * list.itemsPerPage"
                ui-sref="poi.edit({
                  id:item.id
                })"
                ng-mouseenter="list.rowHover(item, true)"
                ng-mouseleave="list.rowHover(item, false)">
              <td>{{item.type.labelTranslated}}</td>
              <td>
                <ul ng-if="item.siteIds.length && item.siteIds.length <= list.vineyardNameCutOff"
                    class="vineyard-list">
                  <li ng-repeat="sId in item.siteIds">{{list.vineyards[sId].label}}</li>
                </ul>
                <span ng-if="item.siteIds.length > list.vineyardNameCutOff">
                  {{ 'POI_VINEYARDS_AMOUNT' | translate : {amount: item.siteIds.length} }}
                </span>
                <span ng-if="!item.siteIds.length">
                  {{ 'POI_NO_VINEYARDS' | translate }}
                </span>
              </td>
              <td class="text-center shrink" ng-if="list.canDelete">
                <button class="btn btn-link"
                        ng-click="$event.stopPropagation(); list.delete(item)">
                  <span class="glyphicon glyphicon-trash"></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ul uib-pagination
          total-items="list.total"
          ng-if="list.total > list.itemsPerPage"
          ng-model="list.page"
          items-per-page="list.itemsPerPage"
          boundary-links="true"
          previous-text="&lsaquo;"
          next-text="&rsaquo;"
          first-text="&laquo;"
          last-text="&raquo;"></ul>
    </div>
    <div class="base-layout-map-map">
      <vc-map-display map-id="list.mapId"
                      zoom-to-selection="list.zoomToSelection"
                      selected-vineyards="list.selectedVineyards"
                      icon-data="list.iconData"
                      hide-icon-control="true"
                      change-icon-options="list.changeIconOptions"
                      vineyard-map-data="list.mapData"></vc-map-display>
    </div>
</div>
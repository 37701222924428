(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name constants.constant:orderStatus
   *
   * @description
   *
   */
  angular
    .module('constants')
    .constant('orderStatus', {
      NONE: 0,
      STARTED: 1,
      PAUSED: 2,
      FINISHED: 3
    });
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name admin.customers.edit
   * @description
   *
   */
  angular
    .module('admin.customers.edit', [
      'ui.router',
      'auth'
    ]);
}());
